import { Card, Grid } from '@material-ui/core';
import React, { useEffect, useState, memo } from 'react';
import { Tooltip } from 'react-tippy';

// ------------------------------------------------------------

import './index.css';
import format from './jsonFormat';
import TicketManagement from '../../TicketManagement/index';

import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import ActivitiesInvolved from './components/ActivitiesInvolved';
import Availability from './components/Availability';
import Average from './components/Average';
import Calls from './components/Calls';
import CallsAnswered1 from './components/CallsAnswered1';
import CallsAnswered2 from './components/CallsAnswered2';
import CallsYou from './components/CallsYou';
import NPS from './components/NPS';
import TotalCalls from './components/TotalCalls';
import Actions from '@data/Actions';
import TicketUtils from '../../../util/TicketUtils';

// ------------------------------------------------------------

const action = new Actions();

function View1({
  language,
  APIRoot,
  APIEndpoints,
  requests,
  users,
  redirect,
  user,
}) {
  let [year, setYear] = useState(moment().year());
  let [month, setMonth] = useState(moment().format('MM'));
  let [dt_ini, setDt_ini] = useState(moment().format('YYYY-MM-01'));
  let [dt_end, setDt_end] = useState(moment().format('YYYY-MM-DD'));
  let [tickets, setTickets] = useState([]);
  let [_calls, setCalls] = useState([]);
  let [projects, setProjects] = useState([]);
  let [_nps, setNps] = useState([]);
  let [risks, setRisk] = useState([]);
  let [slaCall, setSlaCall] = useState([]);
  let [ticketsMonth, setTicketsMonth] = useState([]);
  let [projectsMonth, setProjectsMonth] = useState([]);
  let [risksMonth, setRisksMonth] = useState([]);
  let [ticketsNPS, setTicketsNPS] = useState([]);
  let [openModalNPS, setOpenModalNPS] = useState(false);
  let [statusAwaiting, setStatusAwaiting] = useState([]);

  const [totalTicketsInService, setTotalTicketsInService] = useState([]);
  const [totalTicketsTotalCalls, setTotalTicketsTotalCalls] = useState([]);
  const [ticketsTop10, setTicketsTop10] = useState([]);

  useEffect(() => {
    action
      .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
      .then((res) => {
        setStatusAwaiting([...res.data]);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    action
      .execute('get', `${APIEndpoints.HOME}/calls?year=${year}`, 'calls')
      .then((res) => {
        setCalls(res.data);
      });
  }, [year]);

  useEffect(() => {
    action
      .execute('get', `${APIEndpoints.HOME}/calls?month=${month}&year=${year}`, 'c')
      .then((res) => {
        setSlaCall(res.data);
      });
  }, [month]);

  useEffect(() => {
    const actions = new Actions();

    action
      .execute('get', `${APIEndpoints.HOME}/ticketsCountCallsYou`, '')
      .then((res) => {
        setTotalTicketsInService(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/ticketsCountTotalCalls`, '')
      .then((res) => {
        setTotalTicketsTotalCalls(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/ticketsTop10`, '')
      .then((res) => {
        setTicketsTop10(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/projects?month=${month}&year=${year}`, 'projects')
      .then((res) => {
        setProjects(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/projects?dt_ini=${dt_ini}&dt_end=${dt_end}`, 'projectsMonth')
      .then((res) => {
        setProjectsMonth(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/nps?month=${month}&year=${year}`, 'nps')
      .then((res) => {
        setNps(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/risks?month=${month}&year=${year}`, 'risks')
      .then((res) => {
        setRisk(res.data);
      });

    action
      .execute('get', `${APIEndpoints.HOME}/risks?dt_ini=${dt_ini}&dt_end=${dt_end}`, 'risksMonth')
      .then((res) => {
        setRisksMonth(res.data);
      });

    actions
      .execute('get', APIEndpoints.TICKET + '/params', 'params');

    actions
      .execute(
        'get',
        APIEndpoints.TABLE_STRUCTURED + '/20',
        'type_solution'
      );
  }, [year, month, dt_ini, dt_end]);

  //----as regras de negócio de cada módulo fazer dentro do jsonFormat----
  let callsAnswered1 = format.CallsAnswered1({ calls: _calls, user });
  let callsAnswered2 = format.CallsAnswered2({ slaCall, year, month, user });
  let nps = format.NPS({ data: _nps, value: 1 });
  let activitiesInvolved = format.ActivitiesInvolved({ projects });
  let callsYou = format.CallsYou(ticketsMonth, projects, risksMonth, user);
  let totalCalls = format.TotalCalls(ticketsMonth);
  let okr = format.OKR();
  let average = format.Average({ risks });
  let calls = format.Calls({ tickets });
  let availability = format.Availability();

  // ----------------tables-------------------------------
  let listDetail_1 = format.ListDetail_1(ticketsMonth);

  let listDetail_2 = format.ListDetail_2();
  //let projectList = format.projectList({projects, users});
  let activitiesList = format.activitiesList(projects, users);
  let risksListData = format.riskList(risksMonth);
  let [idTicket, setidTicket] = useState(0);
  if (user.role == 'tech') {
    tickets = tickets.filter(
      (v) => v.fk_id_tech == user.id && v.progress != 100
    );
    ticketsMonth = ticketsMonth.filter(
      (v) => v.fk_id_tech == user.id && v.progress != 100
    );
  }
  let listDetail_5 = format.ListDetail_5(ticketsMonth);
  const OpenNPSTickets = (avaliation, type) => {
    let tickets = [];
    let type_ =
      type == 1 ? 'resolved' : type == 2 ? 'avaliation_level' : 'experience';
    if (avaliation == 'promotores') {
      tickets = _nps.filter((t) => t[type_] > 8);
    }
    if (avaliation == 'passivadores') {
      tickets = _nps.filter((t) => t[type_] == 7 || t[type_] == 8);
    }
    if (avaliation == 'detratores') {
      tickets = _nps.filter((t) => t[type_] < 7);
    }
    let tickets_ = formatArrayTable(tickets);
    setTicketsNPS(tickets_);
    setOpenModalNPS(true);
  };
  const formatArrayTable = (tickets) => {
    let array = tickets
      .filter((g) => g.tp == 'ticket')
      .map((f) => {
        try {
          f.id_request = null;
        } catch (g) {}
        let sla = null;
        let sla_stop = null;
        if (f.tp === 'request') {
          let item = { dt_cad: f.dtcadrequest, sla: f.sla_task };
          sla = TicketUtils.calcSla(item, 'request', language);
        } else {
          sla = TicketUtils.calcSla(
            f,
            'ticket',
            language,
            [],
            f.sla_task,
            undefined
          );
          if (f.status == 'Resolved' || f.status == 'Closed') {
            sla_stop = TicketUtils.stopSla(f, null, language, [], f.sla_task);
          }
        }
        let gp = '';
        try {
          gp = groups.find(
            (gr) => gr.id_group_users == f.fk_id_group_tech
          ).name;
        } catch (e) {}
        return {
          detail: (
            <i
              class="fas fa-eye"
              style={{ width: 10, height: 10, cursor: 'pointer' }}
              onClick={() =>
                setidTicket({ id_tickets: f.id_tickets, real_id: f.real_id })
              }
            ></i>
          ),
          status: (
            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                {f.status === 'Resolved' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.RESOLVED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="resolvedIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : f.status === 'In Progress' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.IN_PROGRESS}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="inProgressIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : f.status === 'Closed' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.CLOSED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="closedIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : f.status === 'Cancelled' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.CANCELLED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="cancelledIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.OPEN}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="openIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="orderdivpro w-100 ml-1" id={f.progress}>
                <div style={{ textAlign: 'center' }}>{f.progress}%</div>
                <div
                  style={{
                    width: `${f.progress}%`,
                    height: '7px',
                    backgroundColor:
                      f.progress > 66
                        ? '#85cc00'
                        : f.progress > 33
                        ? '#ffbf00'
                        : '#ff2e00',
                    borderRadius: '2px',
                    transition: 'all .2s ease-out',
                  }}
                />
              </div>
            </div>
          ),

          description: f.real_id,
          user: f.nameuser,
          tech: f.nametech,
          group_tech: gp,
          company: f.namecompany,
          subject: f.subject,
          sla: f.sla_task + 'h',
          sla_time:
            f.status === 'Resolved' || f.status === 'Closed' ? (
              <div style={{ width: 80 }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    background: TicketUtils.stopSlaTime(
                      f.time_total,
                      f.sla_task
                    ).color,
                    borderRadius: '50%',
                    float: 'left',
                    marginRight: 5,
                  }}
                />
                <span
                  style={{
                    color: TicketUtils.stopSlaTime(f.time_total, f.sla_task)
                      .color,
                  }}
                  className="sla_time"
                >
                  {TicketUtils.stopSlaTime(f.time_total, f.sla_task).time}
                </span>
              </div>
            ) : null,
          suggestions: f.suggestions,
          dt_cad: moment(f.dt_cad).format('L'),
          backlog:
            moment(f.dt_cad).format('YYMM') < moment().format('YYMM') ? (
              <div>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    background:
                      'transparent linear-gradient(180deg, #02F0DE 0%, #0B85BC 100%)',
                    borderRadius: '50%',
                    float: 'left',
                    marginRight: 5,
                  }}
                />
              </div>
            ) : null,
        };
      });
    return array;
  };

  return (
    <Card className="content" id="Home-View1">
      {idTicket != 0 ? (
        <Dialog
          title={''}
          open={idTicket == 0 ? false : true}
          maxWidth="xl"
          onClose={() => {
            setidTicket(0);
          }}
        >
          <TicketManagement
            detail={true}
            id_ticket_detail={idTicket}
          ></TicketManagement>
        </Dialog>
      ) : null}

      <Dialog
        title={''}
        open={openModalNPS}
        maxWidth="xl"
        onClose={() => {
          setOpenModalNPS(false);
        }}
      >
        <CommonTable
          searchColumn
          orderColumn
          title={''}
          language={language}
          columns={[
            {
              label: language.ACTION,
              key: 'detail',
              style: {
                width: 1,
                paddingRight: 5,
                paddingLeft: 5,
              },
              thConfig: true,
            },
            {
              label: language.STATUS,
              key: 'status',
              style: { width: 100, paddingRight: 0, paddingLeft: 0 },
              thConfig: true,
            },

            {
              label: language.ID,
              key: 'id',
              style: { width: 1, paddingRight: 0, textAlign: 'left' },
              thConfig: true,
            },

            {
              label: language.USER,
              key: 'user',
              style: { width: 300, paddingRight: 0 },
              thConfig: true,
            },
            {
              label: language.TECH,
              key: 'tech',
              style: { width: 300, paddingRight: 0 },
              thConfig: true,
            },
            {
              label: language.GROUP_TECH,
              key: 'group_tech',
              style: { width: 300, paddingRight: 0 },
              thConfig: true,
            },
            {
              label: language.COMPANY,
              key: 'company',
              style: { width: 100, paddingRight: 0 },
            },
            {
              label: language.SUBJECT,
              key: 'subject',
              style: { width: 400, paddingRight: 0 },
              thConfig: true,
            },
            {
              label: language.SLA,
              key: 'sla',
              style: { width: 10, paddingRight: 0, paddingLeft: 0 },
              thConfig: true,
            },
            {
              label: language.TIME,
              key: 'sla_time',
              style: { width: 10, paddingRight: 0, paddingLeft: 0 },
              thConfig: true,
            },
            {
              label: language.SUGGESTION,
              key: 'suggest',
              style: { width: 400 },
            },
            {
              label: language.DATE_CAD,
              key: 'dt_cad',
              style: { width: 100 },
            },
            {
              label: 'BackLog',
              key: 'backlog',
              style: { width: 20 },
            },
          ]}
          data={ticketsNPS}
        ></CommonTable>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CallsAnswered1
            user={user}
            data={callsAnswered1}
            setMonth={setMonth}
            month={month}
            setYear={setYear}
            year={year}
            language={language}
          />
        </Grid>
        <Grid item xs={user.role == 'solicitant' ? 6 : 3}>
          <CallsAnswered2 user={user} {...callsAnswered2} language={language} />
        </Grid>
        {user.role == 'solicitant' ? null : (
          <Grid item xs={3}>
            <NPS
              data={nps}
              OpenNPSTickets={OpenNPSTickets}
              language={language}
            />{' '}
          </Grid>
        )}

        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ActivitiesInvolved
                {...activitiesInvolved}
                activitiesListData={activitiesList}
              />
            </Grid>
            <Grid item xs={4}>
              <CallsYou
                {...callsYou}
                user={user}
                activitiesListData={activitiesList}
                demandListData={listDetail_5}
                risksListData={risksListData}
                redirect={redirect}
                statusAwaiting={statusAwaiting}
                totalTicketsInService={totalTicketsInService}
              />
            </Grid>
            <Grid item xs={4}>
              <TotalCalls
                user={user}
                data={totalCalls}
                demandListData={listDetail_1}
                requests={requests}
                totalTicketsTotalCalls={totalTicketsTotalCalls}
              />
            </Grid>
            <Grid item xs={4}>
              {/*<OKR data={okr} dataDialog={listDetail_2}/>*/}
            </Grid>
            <Grid item xs={8}>
              <Average {...average} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid containter>
            <Grid item xs={12}>
              <Calls
                data={ticketsTop10}
                setidTicket={setidTicket}
                dataDialog={listDetail_1}
              />
            </Grid>
            <Grid item xs={12} className="pt-2">
              {user.role != 'solicitant' ? (
                <Availability data={availability} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default memo(View1);
