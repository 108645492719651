import React, {useState} from 'react';
import './index.css';
/*---------------common------------------*/
import Header from '@common/Header';
import Button from '@common/Button';
import SwitchIos from '@common/SwitchIos';
/*---------------material=ui------------------*/
import {Card} from '@material-ui/core';
/*---------------components------------------*/
import Gantt from './Gantt';
import Table from './Table';

import Store from '@data/Store';

const { language } = Store;

/*
	-------------------------------------formato-------------------------------------------------
	let dataJson = {
		label:'Atividade name',
		dt_i:Date.now(),
		dt_f:moment('2020-10-24 23:59:01'),
		color:'var(--danger)',
		tags:[
			{label:'Tag', color:'var(--primary)'},
			{label:'Topg 2', color:'var(--primary)'},
			{label:'Tmg 4', color:'var(--primary)'},
			{label:'Tmg 4', color:'var(--primary)'},
			{label:'Tmg 4', color:'var(--primary)'},
			{label:'Tmg 4', color:'var(--primary)'},
		],
		avatares:['Rodrigo', 'Paulo', 'Leandro', 'Clara', 'Luiza', 'Clara_', 'Manoel', 'João', 'Luiz', 'Silva', 'Platão'],
	};

	let dataGantt = [
		{
			header:true,
			label:'Backlog',
			data:[dataJson, dataJson]
		},
		{
			header:true,
			label:'A Fazer',
			data:[dataJson, dataJson]
		},
		{
			header:true,
			label:'Fazendo',
			data:[dataJson, dataJson]
		},
		{
			header:true,
			label:'Impeditivo',
			data:[dataJson, dataJson]
		},
		{
			header:true,
			label:'Validação',
			data:[dataJson, dataJson]
		},
		{
			header:true,
			label:'Finalizado',
			data:[dataJson, dataJson]
		},

	]

	-------------------------------table------------------------------------------
	{
		name:'UX/UI',
		color:'red',
		//------subtabela-----
		data:[
			{
				onAction:(e, i) => console.log(e, i),
				id:25,
				title:'Ter avaliação de ao menos 8.0 de satisfação de uso da solução de evoluções',
				predecessors_activitys:[13,22,33,14],
				sponsor:['Carla', 'Luiza', 'Marcos', 'Miguel', 'João'],
				evolution:60,
				planned_start:'23/10/2020',
				realized_start:'23/10/2020',
				planned_end:'23/10/2020',
				realized_end:'23/10/2020',
				planned_cost:13192,
				realized_cost:13192,
				duration:'5 dias',
			},
		]
	}
*/
export default function({data=[], project, dataTable=[], start_gantt, end_gantt}){
	let [open, setOpen] = useState(false);
	let [page, setPage] = useState(0);

	return(
		<div className='mb-3' id='GanttPage'>
			<Header
				btnFilter={[
					'GANTT',
					'TABELA'
				]}
				onChange={(e) => setPage(e)}
			>
				<div className="w-100 d-flex justify-content-between align-items-center">
					<h2 className="title">{`${language.PROJECT} ${project.real_id+' - '+project.name}`}</h2>
					<SwitchIos
						checked={open}
						onChange={(evt) => setOpen(evt.target.checked)}
					/>
				</div>
			</Header>
			{open?
				page===0?(
					<Card>
						<Gantt
							startDate={start_gantt*1000}
							endDate={end_gantt*1000}
                     group={data}
						/>
					</Card>
				):(
					<Table data={dataTable} project={project}/>
				)
			:null}
		</div>
	)
}
