import React, { useState, useCallback, useEffect } from 'react';

import Store from '../../data/Store';
import Button from '../../common/Button';
import Constants from '../../data/Constants';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import Actions from '@data/Actions';

const action = new Actions();

const EventDays = ({ deleteDay, days, language }) => (
  <>
    <hr />
    <CommonTable
      mini
      paper
      col={[
        {
          key: 'day',
          label: language.DATE,
          thConfig: true,
          style: { width: '90%' },
        },
        {
          key: 'delete',
          label: language.DELETE,
          thConfig: true,
          style: { width: '10%' },
        },
      ]}
      data={days.map((day) => ({
        day: moment(day).format('DD/MM/YYYY'),
        delete: (
          <Button
            icon="fas fa-trash"
            label={language.DELETE}
            variant="danger"
            color="danger"
            onClick={() => deleteDay(day)}
          />
        ),
      }))}
      hideFooterToolbar
      language={language}
      pagination={false}
      paginationTop={false}
      searchColumn={false}
    />
  </>
);

const EventCabForm = (props) => {
  const { language } = Store;
  const [day, setDay] = useState('');
  const [days, setDays] = useState([]);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (props.eventCab) {
      action
        .execute('get', `${Constants.APIEndpoints.EVENT_CAB}/${props.eventCab}`, '')
        .then((res) => {
          setValues(res.data);
          if (res.data.ec_days && res.data.ec_days.length) {
            setDays(JSON.parse(res.data.ec_days));
          }
        });
    }
  }, []);

  const saveEvent = (obj) => {
    if (!days.length) {
      Store.toggleAlert(
        true,
        'Adicionar ao menos um dia para o evento',
        'error'
      );
      return;
    }

    obj.ec_days = days;
    const fetchParams = {
      method: 'POST',
      address: Constants.APIEndpoints.EVENT_CAB,
    };

    if (props.eventCab) {
      fetchParams.method = 'PUT';
      fetchParams.address = `${Constants.APIEndpoints.EVENT_CAB}/${props.eventCab}`;
    }

    action
      .execute(fetchParams.method, fetchParams.address, '', obj)
      .then((data) => {
        if ( data.data ) {
          data = data.data;
        }
        if (!data.code) {
          Store.toggleAlert(true, language.EVENT_CAB_ALERT, 'success');
          return props.onClose();
        }
        throw new Error();
      })
      .catch((err) => {
        Store.toggleAlert(true, language.ERROR_CODE['E500'], 'error');
      });
  };

  const onChangeDay = (field, value) => {
    setDay(moment(value).format('YYYY-MM-DD'));
  };

  const addDay = () => {
    if (!days.includes(day)) {
      setDays([...days, day]);
    }
  };

  const deleteDay = (v) => {
    const filterDays = days.filter((d) => d !== v);
    setDays(filterDays);
  };

  return (
    <div className="dialogForm">
      <CommonForm
        sensitive
        values={values}
        fields={[
          {
            col: 4,
            name: 'ec_name',
            label: language.EVENT,
            type: 'text',
            required: true,
          },{
            col: 4,
            type: 'autocomplete',
            name: 'fk_id_company',
            label: language.COMPANY,
            method: 'POST',
            route: `${Constants.APIEndpoints.COMPANY}/filter`,
            fieldquery: 'search',
            textlabel: language.COMPANY,
            textinit: values.name_company ? values.name_company : language.COMPANY,
            fieldvaluedb: 'id_company',
            fieldlabeldb: 'name',
            idinit: values.fk_id_company ? values.fk_id_company : 0,
            routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
            methodGetAll: 'POST',
            routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
            required: true,
            detail : true
          }
        ]}
        onSubmit={saveEvent}
      />
      <hr />
      <CommonForm
        fields={[
          {
            col: 4,
            name: 'day',
            label: language.DATE,
            type: 'date',
          },
          {
            col: 2,
            type: 'element',
            content: (
              <div style={{ paddingTop: 15 }}>
                <Button
                  icon="fas fa-plus"
                  label={language.ADD}
                  variant="normal"
                  color="primary"
                  className="mr-3"
                  onClick={() => addDay()}
                />
              </div>
            ),
          },
        ]}
        onChangeField={onChangeDay}
      />
      <EventDays deleteDay={deleteDay} days={days} language />
    </div>
  );
};

export default EventCabForm;
