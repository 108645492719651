import { useEffect, useState } from "react"
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import CommonTable from '../../common/CommonTable';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
const language = Store.language
const action = new Actions()
const ApprovalCenterHistoric = ({single}) => {
    const [filter, setFilter] = useState({});
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    useEffect(() => {
        callPageSelectFilter();
      }, []);


      const callPageSelectFilter = (page = 0, items = {}, totalPerPage = 75) => {
        setFilter(items);
        let limit = totalPerPage;
        let offset = page * limit;
        items.single = single
        action
          .execute(
            'post',
            `${Constants.APIEndpoints.TICKET}/getallapprovalshistoric/?offset=${offset}&limit=${limit}`,
            '',
            items
          )
          .then((res) => {
            setData(res.data);
            setTotalData(res.data.length > 0 ? res.data[0].total_count : 0);
          });
      };

      const columns = [
        {
          key: 'id',
          label: language.id,
        },
        {
          key: 'type',
          label: language.TYPE,
        },
        {
          key: 'company_name',
          label: language.COMPANY,
        },
        {
          key: 'group_tech_name',
          label: language.GROUP_TECH,
        },
        {
          key: 'dt_cad',
          label: language.DT_CAD,
        },
        {
          key: 'approved',
          label: language.APPROVE,
        },
        {
          key: 'user_approved',
          label: language.USER_APPROVED,
        },
        {
          key: 'justify',
          label: language.JUSTIFICATION,
        },

      ];

      const dataTable = data.map((d) => ({
        id: d.id,
        type: d.type_item,
        company_name: d.company_name,
        group_tech_name: d.group_tech_name,
        dt_cad: d.dt_cad ? moment(d.dt_cad).format('DD/MM/YYYY HH:mm:ss') : '',
        approved: d.approved ? language.YES : language.NOT,
        user_approved : d.user_cad,
        justify : (d.justify || '').replace(/<(?:.|\n)*?>/gm, '')
      }));

    return (
        <div>
        <CommonTable
          col={columns}
          onClearAllFilter={() => {
            callPageSelectFilter();
          }}
          countTotal={totalData}
          paginationTop={false}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          searchColumn
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          data={dataTable}

        />
         </div>
    )
}

export default ApprovalCenterHistoric
