import React, { useState, useEffect, useReducer } from 'react';
import { Card, Grid, Menu, MenuItem } from '@material-ui/core';
import './index.css';
import CommonForm from '@common/CommonForm2';
import List from '@common/List';
import Button from '@common/Button';
import Detail2 from '../ConfigurationDashboard/Detail_2.js';
// ------------------------------icons-----------------------------------------
import SlowIcon from '@images/svg-icon/SlowIcon';
import HightIcon from '@images/svg-icon/HightIcon';
import CriticIcon from '@images/svg-icon/CriticIcon';
import Dialog from '@common/Dialog';
import DialogConfirm from '@common/DialogConfirm';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TicketManagement from '../../TicketManagement';
import DownloadFiles from '../../../util/DownloadFiles';
import Store from '@data/Store';

export default function ({
  users,
  secItem = {},
  form,
  riskType,
  idcompany,
  APIEndpoints,
  ac,
  toggleAlert,
  UserLoged,
}) {
  let [action, setAction] = useState(null);
  let [valor, setValor] = useState([]);
  let [apDados, setapDados] = useState([]);
  let [modalApFile, SetmodalApFile] = useState(false);
  let [modalApMat, SetmodalApMat] = useState(false);
  let [ticketHistory, SetTicketHistory] = useState(false);
  let [modalTicket, setModalTicket] = useState(false);
  const { language } = Store;

  const ondonwFile = (id, file) => {
    DownloadFiles(
      APIEndpoints.REQUESTS + '/downloadrisk/' + id + '&' + file,
      file
    );
  };

  const removeItem = (id) => {
    ac.execute('put', APIEndpoints.RISK + '/historydestroy/', '', id).then(
      (h) => {
        //loadAp(idcompany,riskType);
      }
    );
  };

  const sendAprove = (tipo, idcompany, riskType, item) => {
    let json = {
      id_section: secItem.id,
      fk_id_company: idcompany,
      fk_id_standard: riskType,
      type: tipo,
      status: item == true ? 'reprovar' : 'aprovar',
      fk_id_project: secItem.fk_id_project,
      fk_id_ticket: secItem.fk_id_ticket,
    };
    ac.execute('post', APIEndpoints.RISK + '/riskapprove', 'device', json).then(
      (h) => {
  		setTimeout(()=>{
			loadAp(idcompany, riskType);

		},1000)
      }
    ).catch(e => console.log(e));
  };

  const loadAp = (idcompany, riskType) => {
    let json = {
      id_section: secItem.id,
      fk_id_company: idcompany,
      type: 'search',
    };
    ac.execute('post', APIEndpoints.RISK + '/riskapprove', 'device', json).then(
      (h) => {
        setapDados(h.data);
      }
    );
  };

  useEffect(() => {
    ac.execute(
      'get',
      APIEndpoints.RISK + '/history/' + secItem.idd + '/' + riskType,
      ''
    ).then((h) => {
      setValor(h.data);
    });

    loadAp(idcompany, riskType);
  }, []);

  let dados = [0];
  try {
    dados = users
      .filter((b) => secItem.usera.includes(b.id_user))
      .map((h) => ({
        grid: [
          {
            col: 6,
            className: 'value-table',
            content: h.name,
          },
          {
            col: 3,
            className: 'value-table',
            content:
              apDados.filter(
                (g) => g.fk_id_user == h.id_user && g.tipo == 'mat'
              ).length > 0 ? (
                apDados.filter(
                  (g) =>
                    g.fk_id_user == h.id_user &&
                    g.tipo == 'mat' &&
                    g.approve == true
                ).length > 0 ? (
                  <i
                    style={{
                      color: '#33ABA0',
                      fontSize: '22px',
                      marginLeft: '10px',
                    }}
                    class="far fa-check-circle"
                  ></i>
                ) : (
                  <i
                    style={{
                      color: 'red',
                      fontSize: '22px',
                      marginLeft: '10px',
                    }}
                    class="far fa-check-circle"
                  ></i>
                )
              ) : (
                ''
              ),
          },
          {
            col: 3,
            className: 'value-table',
            content:
              apDados.filter(
                (g) => g.fk_id_user == h.id_user && g.tipo != 'mat'
              ).length > 0 ? (
                <i
                  style={{
                    color: '#33ABA0',
                    fontSize: '22px',
                    marginLeft: '10px',
                  }}
                  class="far fa-check-circle"
                ></i>
              ) : (
                ''
              ),
          },
        ],
      }));
  } catch (e) {}

  const handleClick = (event) => {
    setAction(event.currentTarget);
  };
  
  return (
    <div id="RiskView">
      <header></header>
      <Card className="content">
        <Grid container spacing={16} className="block-1">
          <Grid item xs={4} className="col-b1 col-1-b1">
            <div className="content-medium">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <h3 className="title">
                    <i className="fas fa-users" />
                    {language.INVOLVED_PLURAL} (RACI)
                  </h3>
                  <div className="ml-4">
                    {[
                      {
                        label: language.RESPONSIBLE,
                        value:
                          secItem.userr && secItem.userr.length > 0
                            ? users
                                .filter((b) =>
                                  secItem.userr.includes(b.id_user)
                                )
                                .map((h) => (
                                  <div className="d-flex align-items-center">
                                    {h.name}
                                  </div>
                                ))
                            : '',
                      },
                      {
                        label: language.APPROVER,
                        value:
                          secItem.usera && secItem.usera.length > 0
                            ? users
                                .filter((b) =>
                                  secItem.usera.includes(b.id_user)
                                )
                                .map((h) => (
                                  <div className="d-flex align-items-center">
                                    {h.name}
                                  </div>
                                ))
                            : '',
                      },
                      {
                        label: language.CONSULTED,
                        value:
                          secItem.userc && secItem.userc.length > 0
                            ? users
                                .filter((b) =>
                                  secItem.userc.includes(b.id_user)
                                )
                                .map((h) => (
                                  <div className="d-flex align-items-center">
                                    {h.name}
                                  </div>
                                ))
                            : '',
                      },
                      {
                        label: language.INFORMED,
                        value:
                          secItem.useri && secItem.useri.length > 0
                            ? users
                                .filter((b) =>
                                  secItem.useri.includes(b.id_user)
                                )
                                .map((h) => (
                                  <div className="d-flex align-items-center">
                                    {h.name}
                                  </div>
                                ))
                            : '',
                      },
                    ].map((e, i) => (
                      <div key={i} className="content-items">
                        <span className="label-item">{e.label}</span>
                        <span className="value-item">{e.value}</span>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={4} className="col-b1 col-2-b1">
            <div className="content-medium">
              <Grid container spacing={0}>
                <Grid item xs={8}>
                  <h3 className="title">
                    <i className="ml-3 fas fa-calendar-day" />
                    {language.REQUEST}
                  </h3>
                  <div className="ml-4">
                    {[
                      {
                        label: language.PRIORITY,
                        value: (
                          <div className="d-flex align-items-center">
                            <CriticIcon
                              style={{ fill: 'var(--danger)', width: 10 }}
                              className="mr-1"
                            />
                            {language.TICKET_PRIORITY.CRITICAL}
                          </div>
                        ),
                      },
                      {
                        label: language.REVIEW_PERIOD,
                        value: language.RECURRENCE_TYPE.SEMESTER,
                      },
                      {
                        label: `${language.REVISION_DAY}:`,
                        value: moment(secItem.review_period).format(
                          'DD/MM/YYYY'
                        ),
                      },
                    ].map((e, i) => (
                      <div key={i} className="content-items">
                        <span className="label-item">{e.label}</span>
                        <span className="value-item">{e.value}</span>
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={4} className="content-info">
                  <div className="container-dt-cad mt-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <i class="fas fa-calendar-day" />
                      <span id="dt_cad_title">{language.DEADLINE}</span>
                    </div>
                    <span id="dt_cad">
                      {secItem.execution_date != null &&
                        secItem.execution_date != 'null' &&
                        secItem.execution_date &&
                        secItem.execution_date.substr(3, 2) +
                          '/' +
                          secItem.execution_date.substr(0, 2) +
                          '/' +
                          secItem.execution_date.substr(6, 4)}{' '}
                    </span>
                  </div>
                </Grid>
              </Grid>
              <div className="px-2">
                <h3 className="title">
                  <i className="fas fa-paperclip" />
                  {language.ATTACHMENTS}
                </h3>

                {((form.files || [])[secItem.id] || []).map((h) => (
                  <div className="d-flex ">
                    <i class="far fa-copy"></i>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => ondonwFile('1_' + secItem.id, h.name)}
                    >
                      {h.name}
                    </span>
                    <i
                      style={{
                        color: '#33ABA0',
                        fontSize: '22px',
                        marginLeft: '10px',
                      }}
                      class="far fa-check-circle"
                    ></i>
                  </div>
                ))}
                <div></div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className="col-b1 col-3-b1">
            <div className="content-medium">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <h3 className="title">
                    <i className="fas fa-external-link-square-alt" />
                    {language.ASSOCIATED_ITEMS}
                  </h3>
                  <List
                    GridContainerProps={{ alignItems: 'center' }}
                    scrollProps={{ style: { height: 'auto', maxHeight: 157 } }}
                    items={[
                      {
                        grid: [
                          {
                            col: 6,
                            content: (
                              <span
                                style={{
                                  color: 'var(--success)',
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                Item
                              </span>
                            ),
                          },
                          {
                            col: 6,
                            content: (
                              <span
                                style={{
                                  color: 'var(--success)',
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {language.ASSOCIATE_IN}
                              </span>
                            ),
                          },
                        ],
                      },
                      {
                        grid: [
                          {
                            col: 6,
                            content: (
                              <div>
                                <IconButton
                                  aria-label="More"
                                  aria-owns={action ? 'long-menu' : undefined}
                                  aria-haspopup="true"
                                  onClick={(r) => {
                                    handleClick(r);
                                    SetTicketHistory({
                                      id_tickets: valor.id_tickets,
                                      real_id: valor.name,
                                    });
                                  }}
                                  style={{
                                    transform: 'rotate(90deg)',
                                    padding: 3,
                                  }}
                                >
                                  <MoreVertIcon
                                    style={{ color: 'var(--success)' }}
                                  />
                                </IconButton>
                                <span className="value-table">
                                  {valor.name}
                                </span>
                              </div>
                            ),
                          },
                          {
                            col: 6,
                            className: 'value-table',
                            content:
                              valor.name != null
                                ? moment(secItem.dt_cad).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                  )
                                : '',
                          },
                        ],
                      },
                    ]}
                  />
                  <div className="d-flex align-items-center mt-3"></div>
                  <Menu
                    anchorEl={action}
                    open={!!action}
                    onClose={() => setAction(null)}
                  >
                    {[
                      {
                        icon: 'fas fa-eye',
                        label: language.VISUALIZE,
                        color: 'var(--primary)',
                      },
                      //	{icon:'fas fa-exchange-alt', label:'Substituir', color:'var(--success)'},
                      {
                        icon: 'fas fa-trash-alt',
                        label: language.DELETE,
                        color: 'var(--danger)',
                      },
                    ].map((e, i) => (
                      <MenuItem onClick={() => setAction(null)}>
                        <i className={e.icon} style={{ color: e.color }} />
                        <span
                          style={{ fontSize: 15, color: 'var(--primary)' }}
                          className="ml-2"
                        >
                          {e.label}
                        </span>
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={4} className="col-b1 col-3-b1">
            <div className="content-medium">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <h3 className="title">
                    <i className="fas fa-external-link-square-alt" />
                    {language.APPROVE_EVIDENCE}{' '}
                  </h3>
                  <List
                    GridContainerProps={{ alignItems: 'center' }}
                    scrollProps={{ style: { height: 'auto', maxHeight: 157 } }}
                    items={[
                      {
                        grid: [
                          {
                            col: 6,
                            content: (
                              <span
                                style={{
                                  color: 'var(--success)',
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {language.APPROVERS}
                              </span>
                            ),
                          },
                          {
                            col: 3,
                            content: (
                              <span
                                style={{
                                  color: 'var(--success)',
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {language.MATURITY}
                              </span>
                            ),
                          },
                          {
                            col: 3,
                            content: (
                              <span
                                style={{
                                  color: 'var(--success)',
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                {language.ATTACHMENT}
                              </span>
                            ),
                          },
                        ],
                      },

                      dados[0] || [
                        {
                          grid: [
                            {
                              col: 6,
                              className: 'value-table',
                              content: ' ',
                            },
                            {
                              col: 3,
                              className: 'value-table',
                              content: ' ',
                            },
                            {
                              col: 3,
                              className: 'value-table',
                              content: ' ',
                            },
                          ],
                        },
                      ],
                    ]}
                  />
                  <div className="d-flex  align-items-center mt-3">
                    {apDados.filter((g) => g.tipo == 'mat').length !=
                      (secItem.usera || []).length && (
                      <Button
                        icon="fas fa-external-link-square-alt"
                        label={language.APPROVE_MATURITY}
                        variant="normal"
                        onClick={() => {
                          if (secItem.pending == 'PENDENTE') {
                            toggleAlert(
                              true,
                              language.MATURITY_MUST_CONTAIN_AN_ACTIVITY_OR_TICKET_THAT_IS_100_PERCENT_COMPLETE,
                              'error'
                            );
                          } else if (secItem.usera == undefined) {
                            toggleAlert(true, `${language.INFORM_AN_APPROVER} `, 'error');
                          } else if (
                            secItem.usera.includes(UserLoged.id) == false
                          ) {
                            toggleAlert(
                              true,
                              language.ONLY_APPROVERS_CAN_ACCESS_THIS_OPTION,
                              'error'
                            );
                          } else {
                            SetmodalApMat(true);
                          }
                        }}
                        color="warning"
                      />
                    )}
                    <Button
                      icon="fas fa-external-link-square-alt"
                      label={`${language.APPROVE_ATTACHMENTS}?`}
                      variant="normal"
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        if (secItem.usera == undefined) {
                          toggleAlert(true, `${language.INFORM_AN_APPROVER} `, 'error');
                        } else if (
                          secItem.usera.includes(UserLoged.id) == false
                        ) {
                          toggleAlert(
                            true,
                            language.ONLY_APPROVERS_CAN_ACCESS_THIS_OPTION,
                            'error'
                          );
                        } else {
                          SetmodalApFile(true);
                        }
                      }}
                      color="primary"
                    />
                  </div>
                  <Menu
                    anchorEl={action}
                    open={!!action}
                    onClose={() => setAction(null)}
                  >
                    {[
                      {
                        icon: 'fas fa-eye',
                        label: language.VISUALIZE,
                        color: 'var(--primary)',
                      },
                      /*{icon:'fas fa-exchange-alt', label:'Substituir', color:'var(--success)'},*/
                      {
                        icon: 'fas fa-trash-alt',
                        label: language.DELETE,
                        color: 'var(--danger)',
                      },
                    ].map((e, i) => (
                      <MenuItem
                        onClick={() =>
                          i == 0
                            ? setModalTicket(true)
                            : i == 1
                            ? removeItem(secItem)
                            : setAction(null)
                        }
                      >
                        <i className={e.icon} style={{ color: e.color }} />
                        <span
                          style={{ fontSize: 15, color: 'var(--primary)' }}
                          className="ml-2"
                        >
                          {e.label}
                        </span>
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <hr />
        <Detail2 />
      </Card>

      <Dialog
        title={''}
        open={modalTicket}
        maxWidth="xl"
        onClose={() => setModalTicket(false)}
      >
        <TicketManagement
          detail={true}
          id_ticket_detail={ticketHistory}
          isHistory
        ></TicketManagement>
      </Dialog>

      <DialogConfirm
        open={modalApFile}
        maxWidth="xs"
        onClose={() => SetmodalApFile(false)}
        title={language.CONFIRM}
        message={language.DO_YOU_REALLY_WANT_TO_APPROVE_DOCUMENTATION}
        confirmLabel={`${language.CONFIRM}?`}
        cancelLabel={language.CANCEL}
        cancelProps={{
          color: 'primary',
        }}
        eventClose
        onConfirm={() => sendAprove('anexo', idcompany, riskType)}
        onCancel={() => {}}
      />
      <DialogConfirm
        open={modalApMat}
        maxWidth="xs"
        title={language.CONFIRM}
        message={language.DO_YOU_REALLY_WANT_TO_APPROVE_MATURITY}
        confirmLabel={`${language.CONFIRM}?`}
        cancelLabel={`${language.FAIL}?`}
        cancelProps={{
          color: 'primary',
        }}
        onClose={() => SetmodalApMat(false)}
        eventClose
        onConfirm={() => sendAprove('MT', idcompany, riskType)}
        onCancel={() => sendAprove('MT', idcompany, riskType, true)}
      />
    </div>
  );
}
