import Animate from '@common/Animate';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import '../../../public/css/pages.css';
import CommonDelete from '../../common/CommonDelete';
import CommonPage from '../../common/CommonPage';
import CompanyAddTaskForm from './CompanyAddTaskForm';
import CompanyCabRisk from './CompanyCabRisk';
import CompanyConfigSendMail from './CompanyConfigSendMail';
import CompanyDepartamentForm from './CompanyDepartamentForm';
import CompanyForm from './CompanyForm';
import CompanyList from './CompanyList';
import CompanyLocationForm from './CompanyLocationForm';
import DownloadFiles from '../../util/DownloadFiles';
import CompanyFormsForm from './CompanyFormsForm';
import CompanyGroupingForm from './CompanyGroupingForm';
import CompanyPortalForm from './CompanyPortalForm';
import CustomizePiority from './CustomizePiority';
import WorkflowChange from './WorkflowChange';

const crypto = require('crypto');

@observer
export default class CompanyContainer extends CommonPage {
  constructor() {
    super();

    this.state = {
      index: 0,
      page: 'list',
      openNew: true,
      params: [],
      values: [],
      pageDelete: {
        open: false,
        values: undefined,
      },
      showRelatedWith: false,
      city: null,
      state: null,
      country: null,
      use_resolution_category: false,
      company: [],
      Allcompanies: [],
      totalcompanies: 0,
      currentPage: 0,
      gridFilters: null,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.downloadCompanies = this.downloadCompanies.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.onFormSubmitStatus = this.onFormSubmitStatus.bind(this);
  }

  decryp = (aMsg = '') => {
    try {
      const aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  };

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.COUNTRY, 'countries');
    this.action.execute('get', this.APIEndpoints.LOCATION, 'locations');
    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, '')
      .then((res) => {
        this.setState({ params: res.data });
      });
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id },
      null,
      null,
      null,
    );
  }

  onFormSubmit(values) {
    //se foi setado no state pelo onChangeField, sobreescreve o existente
    values.id_country = this.state.country || values.id_country;
    values.id_state = this.state.state || values.id_state;
    values.id_city = this.state.city || values.id_city;

    values.fk_id_account_manager = this.state.fk_id_account_manager || this.state.values.fk_id_account_manager;
    values.fk_id_service_delivery_manager = this.state.fk_id_service_delivery_manager || this.state.values.fk_id_service_delivery_manager;
    values.fk_id_technical_account_manager = this.state.fk_id_technical_account_manager || this.state.values.fk_id_technical_account_manager;
    values.fk_id_business_line = this.state.fk_id_business_line || this.state.values.fk_id_business_line;

    let method = values.hasOwnProperty('id_company') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_company')
      ? this.APIEndpoints.COMPANY + '/' + values.id_company
      : this.APIEndpoints.COMPANY;
    this.action
      .execute(method, url, 'companies', values, 'id_company')
      .then((v) => {
        this.handlePage('list');
        this.callPageSelectFilter();
      });
  }

  onFormSubmitStatus(values) { 
    const method = 'put';
    const url = this.APIEndpoints.COMPANY + '/status/' + values.id_company
    this.action.execute(method, url, 'companies', values, 'id_company');
  }

  onLoadStates(id_country) {
    this.action.execute(
      'get',
      this.APIEndpoints.COUNTRY + '/' + id_country,
      'states',
    );
  }

  onLoadCities(id_state) {
    this.action.execute(
      'get',
      this.APIEndpoints.STATE + '/' + id_state,
      'cities',
    );
  }

  onChangeField(field, value) {
    if (value) {
      if (field.name === 'id_country') {
        this.onLoadStates(value);
        this.store.cities = [];
        this.setState({ country: value });
        this.setState({ state: null });
        this.setState({ city: null });
      } else if (field.name === 'id_state') {
        this.onLoadCities(value);
        this.setState({ state: value });
        this.setState({ city: null });
      } else if (field.name === 'id_city') {
        this.setState({ city: value });
      } if ([
        'fk_id_account_manager',
        'fk_id_service_delivery_manager',
        'fk_id_technical_account_manager',
        'fk_id_business_line'
      ].includes(field.name)) { 
        this.setState({ [field.name]: value });
      }
    }

    if (field.name == 'use_resolution_category') {
      this.setState({ use_resolution_category: value });
    }

    if (field.name === 'type') {
      this.setState({ showRelatedWith: value === 2 });
    }

    if( field.name === "use_custom_form_for_rfc_cab" ) {
      this.setState({
        values: {
          ...this.state.values,
          use_custom_form_for_rfc_cab: value
        }
      });
    }
  }

  handlePage(page, edit) {
    if (page == 'new/department') {
      this.action
        .execute(
          'get',
          this.APIEndpoints.DEPARTMENT + '/' + edit.id_company,
          '',
        )
        .then((v) => {
          edit.department = v.data;
          edit.sub_dep = [];
          this.setState({ page: page, values: edit });
        });
    } else if (page == 'new/location') {
      this.action
        .execute(
          'get',
          this.APIEndpoints.LOCATION + '/company/' + edit.id_company,
          '',
        )
        .then((v) => {
          edit.location = v.data;
          this.setState({ page: page, values: edit });
        });
    } else if (page == 'new/portal') {
      this.action
        .execute(
          'get',
          this.APIEndpoints.COMPANY + '/' + edit.id_company + '/portal',
          '',
        )
        .then((v) => {
          edit.portal = v.data;
          this.setState({ page: page, values: edit });
        })
        .catch((v) => {
          edit.portal = null;
          this.setState({ page: page, values: edit });
        });
    } else {
      if (edit && edit.use_resolution_category == true) {
        this.setState({
          use_resolution_category: edit.use_resolution_category,
        });
      } else {
        this.setState({ use_resolution_category: false });
      }

      this.setState({ page: page, values: edit });

      this.setState({ showRelatedWith: edit?.type === 2 });

      if (edit?.id_country) this.onLoadStates(edit.id_country);

      if (edit?.id_state) this.onLoadCities(edit.id_state);
    }
  }

  handlePageDelete = (open = false, values) => {
    this.setState({ pageDelete: { open, values } });
  };

  downloadCompanies() {
    this.action
      .execute('post', this.APIEndpoints.COMPANY + '/export')
      .then((res) => {
        DownloadFiles(
          this.APIEndpoints.COMPANY + '/download/' + res.data.file,
          res.data.file,
        );
      });
  }

  onDelete() {
    const { values } = this.state.pageDelete;
    this.action
      .execute(
        'delete',
        `${this.APIEndpoints.COMPANY}/${values.id_company}`,
        'companies',
        null,
        'id_company',
        {
          btn_loading: 'del-company-list-' + values.id_company,
        },
      )
      .then((res) => {
        setTimeout(() => {
          this.setState({ page: 'list' });
          this.callPageSelectFilter();
        }, 2000);
      });
    this.setState({ pageDelete: { open: false, values: undefined } });
  }

  callPageSelectFilter(page = 0, values, totalPerPage = 75) {
    this.setState({ currentPage: page });
    let limit = totalPerPage || 75;
    let offset = page * limit;

    //quando existe filtro global e um reload acontece por atualizar um registro
    //restaura o valor do campo de filtro global, para manter o filtrado
    if (!values && this.state.gridFilters)
      values = { ...this.state.gridFilters };

    this.action
      .execute('post', `${this.APIEndpoints.COMPANY}/filter?limit=${totalPerPage}&offset=${offset}&all=all`, 'companies', values)
      .then((res) => {
        this.setState({
          gridFilters: values || undefined,
          Allcompanies: res.data,
          totalcompanies: parseInt(res.data[0]?.count ?? 0),
        });
      });
  }

  render() {
    let {
      language,
      countries,
      states,
      cities,
      departments,
      locations,
      users,
      categories,
      catalogs,
    } = this.store;
    let { page, values, params } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;

    let PAGE = history.location.pathname;

    let arrayUsers = [];

    users.map((u) => {
      arrayUsers.push({
        value: u.id_user,
        label: `${u.name} - (${u.email})`,
        id_company: u.id_company,
      });
    });

    let { configurationProps } = this.props;
    return (
      <Fragment>
        {page == 'list' && (
          <Animate>
            <CompanyList
              history={history}
              language={language}
              handlePage={this.handlePage}
              companies={this.state.Allcompanies}
              redirect={redirect}
              user={this.store.getUserLoged()}
              values={values}
              APIEndpoints={this.APIEndpoints}
              downloadCompanies={this.downloadCompanies}
              configurationProps={configurationProps}
              usercon_company={this.store.usercon_company}
              handlePageDelete={this.handlePageDelete}
              params={params}
              currentPage={this.state.currentPage}
              countTotal={this.state.totalcompanies}
              callPageSelectFilter={this.callPageSelectFilter}
              onFormSubmitStatus={this.onFormSubmitStatus}
            />
          </Animate>
        )}

        {['new', 'edit'].includes(page) && (
          <Dialog
            title={
              page == 'new'
                ? `${language.TO_REGISTER} ${language.NEW_COMPANY}`
                : language.EDIT_COMPANY
            }
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            monitor_scroll
          >
            <CompanyForm
              values={values}
              cities={cities}
              states={states}
              countries={countries}
              language={language}
              showRelatedWith={this.state.showRelatedWith}
              onChangeField={this.onChangeField}
              params={params}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              redirect={redirect}
              user={this.store.getUserLoged()}
              usercon_company={this.store.usercon_company}
              use_resolution_category={this.state.use_resolution_category}
            />
          </Dialog>
        )}

        {page == 'new/department' && (
          <Dialog
            title={language.ADD_DEPARTMENT}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            contentProps={{
              id: 'nna',
            }}
            PaperProps={{
              id: 'nna2',
            }}
          >
            <CompanyDepartamentForm
              departments={departments}
              values={this.state.values}
              arrayUsers={arrayUsers}
              language={language}
              handlePage={this.handlePage}
              redirect={redirect}
              users={users}
            />
          </Dialog>
        )}

        {page == 'worklowchange' && (
          <Dialog
            title={language.WORKFLOW_OF_CHANGE}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            contentProps={{
              id: 'nna',
            }}
            PaperProps={{
              id: 'nna2',
            }}
          >
            <WorkflowChange
              company={this.state.values}
              language={language}
              params={params}
              Store={this.store}
            />
          </Dialog>
        )}

        {page == 'new/location' && (
          <Dialog
            title={language.ADD_LOCATION}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            contentProps={{
              id: 'nna',
            }}
            PaperProps={{
              id: 'nna2',
            }}
          >
            <CompanyLocationForm
              locations={locations}
              values={this.state.values}
              arrayUsers={arrayUsers}
              language={language}
              handlePage={this.handlePage}
              redirect={redirect}
              users={users}
            />
          </Dialog>
        )}

        {page == 'new/portal' && (
          <Dialog
            title={language.PORTAL_CUSTOMIZATION}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            monitor_scroll
          >
            <CompanyPortalForm
              values={values}
              language={language}
              onClose={() => this.handlePage('list')}
              params={params}
            />
          </Dialog>
        )}

        {page == 'new/grouping' && (
          <Dialog
            title={language.ADD_GROUPING}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            monitor_scroll
          >
            <CompanyGroupingForm
              companyData={values}
              language={language}
              onClose={() => this.handlePage('list')}
              params={params}
            />
          </Dialog>
        )}

        {page == 'new/forms' && (
          <Dialog
            title={language.FORMS}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            monitor_scroll
          >
            <CompanyFormsForm
              companyData={values}
              language={language}
              onClose={() => this.handlePage('list')}
              params={params}
            />
          </Dialog>
        )}

        {page == 'edit/cab_risk' && (
          <Dialog
            title={language.RISK_CHANGE}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            monitor_scroll
          >
            <CompanyCabRisk
              company={values}
              store={this.store}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              onSuccess={() => {
                this.handlePage('list');
              }}
            />
          </Dialog>
        )}

        {page == 'edit/send_mail' && (
          <Dialog
            title={language.CONFIG_SEND_MAIL}
            open={true}
            onClose={() => this.handlePage('list')}
            miniVersion
            monitor_scroll
          >
            <CompanyConfigSendMail
              values={values}
              language={language}
              action={this.action}
              store={this.store}
              onSuccess={() => {
                this.handlePage('list');
              }}
            />
          </Dialog>
        )}

        {page == 'new/task' && (
          <Dialog
            title={language.ADD_ACTIVITY}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
          >
            <CompanyAddTaskForm
              openNew={this.state.openNew}
              onFormSubmit={this.onFormSubmit}
              catalogs={catalogs}
              categories={categories}
              redirect={redirect}
              addDepartmentInCompany={this.addDepartmentInCompany}
              departments={departments}
              values={this.state.values}
              language={language}
              handlePage={this.handlePage}
            />
          </Dialog>
        )}

        {page == 'customize_priority' && (
          <Dialog
            title={language.CUSTOMIZE_PRIORITY}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
          >
            <CustomizePiority
              language={language}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              company={this.state.values}
            />
          </Dialog>
        )}

        {this.state.pageDelete.open && (
          <CommonDelete
            // open={this.state.pageDelete.open}
            // language={language}
            // confirmProps={{ color: 'danger' }}
            onConfirm={this.onDelete}
            // onClose={() => this.handlePageDelete()}

            language={language}
            closeModal={() => this.handlePageDelete()}
            onDelete={this.onDelete}
            message={
              language.WARNING_DELETE_COMPANY +
              this.state.pageDelete.values.name
            }
          />
        )}
      </Fragment>
    );
  }
}
