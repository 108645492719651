import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import BarBlue from '@images/BarBlue.png';
import CommonForm from '@common/CommonForm2';
import { Avatar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toBase64 } from '@common/Func';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Animate from '@common/Animate';
import { Tooltip } from 'react-tippy';
const crypto = require('crypto');
import Step1 from './Step1';
import Step2 from './Step2';
import ChangeRiskCAB from './ChangeRiskCAB';
import Outage from './Outage'
import Store from '@data/Store';
import './index.css';
import { createFilterOptions } from '@material-ui/lab';
import { concatDateHour } from '../../../util/dateTime';

const { language } = Store;

let formstemplateHtml = '';

const createRandomToken = (text) => {
  const aSecret = 'Orch3str0!@forever!';

  try {
    const crypto = require('crypto');

    let cipher, tRet;

    cipher = crypto.createCipher('aes-256-cbc', aSecret);
    tRet = cipher.update(text.toString(), 'utf8', 'base64');
    tRet += cipher.final('base64');
    return tRet;
  } catch (e) {
    return {};
  }
};
const MyStep = ({ activeStep, handleStep,typeTemp,outage }) => (
  <Stepper activeStep={activeStep} alternativeLabel>
    {[{ name: language.GMUD_REGISTRATION,value:0},
    { name:language.OUTAGE,value:1},
    { name: language.RISKS_INVOLVED,value:2},
    { name: language.MILESTONE,value:3},

   ].filter(item=>  (typeTemp  == true || outage!=true)  ? item.name != 'Outage': item.name !=true ).map(
      (obj, i) => (




        <Step
          key={obj.value}
          onClick={() => handleStep(obj.value)}
          style={{ cursor: 'pointer' }}
        >
          <StepLabel
            icon={
              i >= activeStep ? (
                <i
                  className="fas fa-circle"
                  style={{
                    color: obj.value == activeStep ? 'var(--primary)' : '#707070',
                    fontSize: 8,
                    marginTop: 8,
                  }}
                />
              ) : (
                <i
                  className="fas fa-check"
                  style={{ color: 'var(--primary)' }}
                />
              )
            }
          >
            <span style={{ fontSize: 10 }}>{obj.name}</span>
          </StepLabel>
        </Step>
      )
    )}
  </Stepper>
);

export default function ({
  open,
  onClose,
  values,
  change_actions,
  companies,
  action,
  APIEndpoints,
  tickets,
  contracts,
  users,
  devices,
  onFormSubmit,
  changeitem,
  page,
  userl,
  stand,
  ticketparam,
  toggleAlert,
  getData,
  setReload,
  params,
  // language,
  createToTemplate,
  typeTemp,
  resetTemplate,
  createToTemplate_fields,
}) {
  /*#####################################################-STATES-#########################################################################*/
  const reducer = (state, value) => ({ ...state, ...value });
  let [stepIndex, setStepIndex] = useState(0);
  let [subject, setSubject] = useState(undefined);
  let [templateChange, setTemplateChange] = useState(undefined);

  const [modalConfirmBlackout, setModalConfirmBlackout] = useState(false)
  const [modalConfirmOutage, setModalConfirmOutage] = useState(false)
  const [errorToShow, setErrorToShow] = useState('')
  const [valuesToSave, setValuesToSave] = useState([])
  if (stepIndex == 2 && stand == true) {
    stepIndex =3;
  }


  const ondeletefile = (id) => {
    action.execute('get', `${APIEndpoints.CHANGE}/deletefile/${id}`, '');
  };
  const clickStep = (i) => {
    if (page === 'edit') {
      if (i == 0) {
        if (
          userl.id === changeitem.manager ||
          userl.role == 'client' ||
          (changeitem.solicitant && changeitem.solicitant == userl.id) ||
          userl.perfil.gsd_is_change_manager == true
        ) {
          setStepIndex(i);
        } else {
          toggleAlert(
            true,
            language.ONLY_THE_CHANGE_MANAGER_CAN_EDIT,
            `error`
          );
        }
      } else {
        setStepIndex(i);
      }
    } else {
      setStepIndex(i);
    }
  };

  let [changeItem, setchangeItem] = useState(undefined);

  useEffect(() => {
    if (page === 'edit' && changeitem ) {
      setchangeItem(changeitem);
      setSubject(changeitem.reason);
      if (changeitem._status === 'Open' || changeitem._status === 'Create') {
        if (
          typeTemp == true ||
          userl.id === changeitem.manager ||
          createToTemplate_fields == true
        ) {
        } else {
            if(changeitem.outage == true){
              setStepIndex(1);
            }else{
              setStepIndex(2);
            }
        }
      }
    }
  }, [changeitem]);

  let changeActionsOptions = change_actions.map((obj) => ({
    value: obj.item_table,
    label: obj.description,
  }));

  let solicitantOptions = users.map((u) => ({
    value: u.id_user,
    label: u.name,
  }));

  const initialState = {};

  const verifyBlackout = async (values, html) => {
    let black = []
    if (values.devices){
      black = await action
      .execute(
        'post',
        `${APIEndpoints.DEVICE}/getblackoutsfromdevices`,
        '',
        values,
      )
    }
    formstemplateHtml = html;

    if (black.data && black.data.length > 0){
    const errorToShow_ = black.data.map(b => {
      return `IC: ${b.device_name} \n`})

      values.hasBlackout = true
      setValuesToSave(values)
      setModalConfirmBlackout(true)
      setErrorToShow(errorToShow_)
    }else{
      values.hasBlackout = false
      setValuesToSave(values)
      await verifyOutages(values)
    }
  }

  const verifyOutages = async (values) => {
    if (values == undefined){
      values = valuesToSave
    }
    let outages = []
    if (values.devices){
      outages = await action
      .execute(
        'post',
        `${APIEndpoints.DEVICE}/getoutagesfromdevices`,
        '',
        values,
      )
    }

    if (outages.data && outages.data.length > 0){
    const errorToShow_ = outages.data.map(b => {
      return `IC: ${b.device_name} - ${language.GMUD}: #${b.fk_id_change}, \n`})
      values.hasOutage = true
      setValuesToSave(values)

      setModalConfirmOutage(true)
      setErrorToShow(errorToShow_)
    }else{
      values.hasOutage= false
      setValuesToSave(values)
     await  onSave(values)
    }
  }


  const onSave = async (values) => {
    setModalConfirmBlackout(false)
    setModalConfirmOutage(false)
    setErrorToShow('')

    if (values == undefined){
      values = valuesToSave
    }

    if(values.file){
      values.file = values.file.filter(g=>g.size != 0)
    }

    if(values.file&& values.file.length > 0 ){
      let files = await toBase64(values.file);
      let itens = files.map((h) => {
        return {
          file: h.base64,
          namefile: h.file.name,
          type: h.file.type,
        };
      });
      values.file = itens;
    }

    values.start_date =
      values.start_date == undefined
        ? null
        : concatDateHour(values.start_date, values.initial_hour);

    values.end_date =
      values.end_date == undefined
        ? null
        : concatDateHour(values.end_date, values.final_hour);

    ['initial_date', 'initial_hour', 'final_date', 'final_hour'].map(
      (d) => delete values[d]
    );

    values.users = {};

    ['key_users', 'manager', 'outs', 'solicitant', 'techs'].forEach((d) => {
      values.users[d] = values[d];
      delete values[d];
    });

    values.reason = subject;
    if (subject == '' || subject == undefined) {
      toggleAlert(true, language.INFORM_THE_REASON_FOR_THE_GMUD, `error`);
    }  else {
      let fields = [];
      let contador = 0;
      let campossize = false;
      let camposobrigatorios = false;
      let camposobrigatoriosradio = false;
      let camposobrigatorioscheck = false;
      let template = '';
      $('#template').html();
      if ($('#template').html() != undefined) {
        $('#template textarea').each(function () {
          $(this).attr('value', $(this).val());
           $(this).html($(this).val());
          fields.push({ name: $(this).attr('name'), value: $(this).val(), label : $(this).parent()[0].firstChild.textContent });
        });

        $('#template input').each(function () {
          if ($(this).attr('type') == 'radio') {
            if (
              $('input[name=' + $(this).attr('name') + ']:checked').val() ==
              $(this).val()
            ) {
              $(this).attr('checked', true);
              let value = undefined
              try{
                value = $(this).attr('value')
              }catch(e){}
                fields.push({ name: $(this).attr('name'), value: value || $(this).val(), label : $(this).parent().parent().prev()[0].textContent});
            } else {
              $(this).removeAttr('checked');
            }
            let ip = $(this);

            $('#template  label').each(function () {
              var for_ = '';
              try {
                for_ = $(this).attr('for');
              } catch (e) {}

              if (for_ === ip.attr('name')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatoriosradio = true;
                    $('[name=' + for_ + ']').each(function () {
                      try {
                        if ($(this).is(":checked")) {
                          camposobrigatoriosradio = false;
                        }
                      } catch (e) {}
                    });
                    $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'checkbox') {
            if (
              $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
              $(this).attr('id')
            ) {
              $(this).attr('checked', true);
            } else {
              $(this).removeAttr('checked');
            }
            fields.push({
              name: $(this).attr('name'),
              value: $(this).is(':checked'),
              label: $(this).parent().parent().prev()[0].textContent,
            });
            let ip = $(this);
            $('#template  label').each(function () {
              var for_ = ' ';
              try {
                for_ = $(this).attr('for');
              } catch (e) {}

              if (ip.attr('name').indexOf(for_) > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatorioscheck = true;
                    $('#template  input').each(function () {
                      try {
                        if ($(this).attr('name').indexOf(for_) > -1) {
                          try {
                            if ($(this).attr('checked')) {
                              camposobrigatorioscheck = false;
                            }
                          } catch (e) {}
                        }
                      } catch (e) {}
                    });
                    $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'datetime-local') {
            const userLogin = Store.getUserLoged();
            const timezone = userLogin?.timezone?.value;

            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: moment.utc($(this).val()).toISOString(),
            });
            $(this).attr('value', $(this).val());
          } else {
            $(this).attr('value', $(this).val());
            let label = $(this).parent()[0].firstChild.textContent
            if (label.length == 0){
              const id = $(this).attr('id')
                switch (id){
                  case 'companies_var':
                  label = language.COMPANY;
                  break;
                  case 'contracts_var':
                  label = language.CONTRACT;
                  break;
                  case 'allusers_var':
                  label = language.USERS;
                  break;
                  case 'departments_var' || 'departmentall_var':
                  label = language.DEPARTMENT;
                  break;
                  case 'subsdpt_var':
                  label = language.SUBDEPARTMENT;
                  break;
                  case 'chieftdpt_var' || 'chief_var':
                  label = language.DEPARTMENT_BOSS;
                  break;
                  case 'chiefsubdpt_var':
                  label = language.SUBDEPARTMENT_BOSS;
                  break;
                  default :
                  label = id
                }
            }
            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: $(this).attr('value'),
              label : label
            });
            let siz = $(this).attr('max');
            let min = $(this).attr('min');
            if (siz) {
              if (
                parseInt($(this).val().length) > parseInt(siz) ||
                parseInt($(this).val().length) < parseInt(min)
              ) {
                $(this).css('border-color', 'red');
                $(this).before(
                  `<label  id="formtemplabel__" style='color:red'>  ${
                    min || 0
                  } á ${siz || 0} ${language.CHARACTERS}</label>`
                );

                campossize = true;
              }
            }

            let ip = $(this);
            $('#template  label').each(function () {
              if ($(this).attr('for') === ip.attr('id')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    if (ip.attr('value').length == 0 || ip.attr('value') === label) {
                      camposobrigatorios = true;
                      ip.css('border-color', 'red');
                      ip.parent().find('fieldset').css('border-color', 'red');
                    } else {
                      ip.css('border-color', 'var(--primary)');
                      ip.parent().find('fieldset').css('border-color', 'var(--primary)');
                    }
                  }
                }
              }
            });
          }
        });

        $('#template select').each(function () {
          $('#' + $(this).children('option:selected').attr('id')).attr(
            'selected',
            'selected'
          );

          const selectedValues = [];
          $(this).find('option:selected').each(function () {
            selectedValues.push($(this).text());
          });
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: selectedValues.join(),
            label: $(this).parent()[0].firstChild.textContent,
          });

          const label = $(this).parent().find('label');
          if (label.text().split('*')[1] != undefined) {
            if (label.text().split('*')[1].length >= 0) {
              if (!$(this).val() || $(this).val() == "") {
                camposobrigatorios = true;
                $(this).css('border-color', 'red');
              } else {
                $(this).css('border-color', 'var(--primary)');
              }
            }
          }
        });

        $('#template label').each(function () {
          try {
            if ($(this).attr('for').indexOf('textarea') > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  // alert($('#'+$(this).attr('for')).text())

                  let node_ = $(this)
                    .parent()
                    .parent()
                    .nextAll()
                    .slice(2, 3)
                    .children()[0];
                  if (
                    (node_ &&
                      node_.firstChild.tagName == 'P' &&
                      node_.firstChild.textContent.length == 0) ||
                    (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                  ) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          } catch (e) {}
        });

        $('#template label').each(function () {
          try {
            let textArea = $(this)
              .parent()
              .parent()
              .nextAll()
              .slice(2, 3)
              .children()[0];
            if (
              textArea &&
              textArea.firstChild &&
              textArea.firstChild.tagName == 'P' &&
              textArea.firstChild.textContent.length > 0
            ) {
              fields.push({
                name: $(this).attr('for'),
                value: textArea.firstChild.textContent,
              });
            }

            if ($(this).attr('for').indexOf('textarea') > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  // alert($('#'+$(this).attr('for')).text())

                  if ($(this).parent().children().last().text() == '') {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          } catch (e) {
            console.log(e, 'error');
          }
        });
      }
      template = formstemplateHtml;
      
      setTimeout(function () {
        $('#formtemplabel__').fadeOut().empty();
        $('#template .form-control').css('border-color', '#019fc2');
      }, 9000);

      if (
        camposobrigatorios == false &&
        campossize == false &&
        camposobrigatorioscheck == false &&
        camposobrigatoriosradio == false
      ) {
        values.html = template;
        values.html_values = fields;
        values.stand = stand;

        if (createToTemplate == true) {
          values.id_change_template = values.id_change;
          delete values.id_change;
          values.createtotemplate = true;
          resetTemplate();
        }
        setchangeItem([])
        action
          .execute(
            'post',
            `${APIEndpoints.CHANGE}`,
            'change',
            values,
            'id_change'
          )
          .then((h) => {
            getData();
            if (changeItem && changeItem.cretaToTemplate == true) {
              // h.data.id_change = changeItem.id_change;
            }
            setchangeItem(h.data);
            
            if(h.data._status == "Analyze") {
              setStepIndex(3);
            }else if(h.data.outage == 1) {
              setStepIndex(1);
            } else {
              setStepIndex(2);
            }
          });
      } else {
        toggleAlert(
          true,
          language.FORM_FIELDS_CANNOT_BE_BLANK,
          `error`
        );
      }
    }
  };

  var actionsOptions = [
    { icon: 'fas fa-tools', label: language.CORRECTIVE, value: 1 },
    {
      icon: 'fas fa-exclamation-triangle',
      label: language.EMERGENCY,
      value: 2,
    },
    { icon: 'fas fa-chart-line', label: language.NORMAL, value: 3 },
    { icon: 'fas fa-shield-alt', label: language.DEFAULT, value: 4 },
  ];

  if (typeTemp == true && stepIndex == 2 ) {
    stepIndex = 3;
  }

  return (
    <Dialog
      PaperProps={{
        id: 'Complete',
        //style: { maxWidth: 700 }
      }}
      open={open}
      onClose={onClose}
      bar={false}
      maxWidth="xl"
      dialogChildren={
        /*##########################################-CABEÇALHO-##############################################################*/
        <div
          className="d-flex align-items-start justify-content-between bar"
          style={{
            background: `url(${BarBlue})`,
            borderBottom: '3px solid var(--warning)',
          }}
        >
          <div className="pt-3 pb-1 pl-3 w-100">
            <div className="d-flex align-items-start">
              {values && <h4 className="mr-3 id-ticket">25</h4>}
              <div className="w-100" style={{ zoom: 0.8 }} zoom={0.8}>
                {/*------------no step 2 e 3 esse campo abaixo não será mais campo editável, mas sim apenas um texto de apresentação-----------*/}
                {stepIndex == 0 ? (
                  <CommonForm
                    values={{ subject }}
                    fields={[
                      {
                        col: 12,
                        type: 'text',
                        name: 'subject',
                        label: language.TITLE,
                        className: 'subject-ticket',
                        required: true,
                        disabled:
                          changeitem &&
                          changeitem.type_gmud == 2 &&
                          typeTemp == false &&
                          page == 'edit'
                            ? true
                            : false,
                      },
                    ]}
                    onChangeField={(f, v) => {
                      if (f.name == 'subject') {
                        setSubject(v);
                      } else {
                        setTemplateChange(v);
                      }
                    }}
                  />
                ) : (
                  <Tooltip
                    html={<span style={{ color: 'white' }}>{subject}</span>}
                    arrow={true}
                    position="right"
                    theme="dark"
                  >
                    <span className="subject_fixed ellipsis">{subject}</span>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <IconButton
            aria-label={language.CLOSE}
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      }
    >
      {stepIndex > 0 && (
        <MyStep activeStep={stepIndex} typeTemp={typeTemp}   outage={changeItem&&changeItem.outage} handleStep={(i) => i!==3?clickStep(i):null} />
      )}
      {stepIndex == 0 && (
        <Animate anime="bounceInRight">
          <Step1
            companies={companies}
            action={action}
            APIEndpoints={APIEndpoints}
            tickets={tickets}
            createToTemplate={createToTemplate}
            devices={devices}
            createToTemplate_fields={createToTemplate_fields}
            params={params}
            toggleAlert={toggleAlert}
            ticketparam={ticketparam}
            typeTemp={typeTemp}
            ondeletefile={ondeletefile}
            onSaveS={() =>
              setStepIndex(
                2 /*--n tem 3, então o ultimo item ficará como verdadeiro-*/
              )
            }
            users={users}
            page={page}
            contracts={contracts}
            values={changeItem}
            actionsOptions={actionsOptions}
            onSave={onSave}
            stand={stand}
            modalConfirmBlackout = {modalConfirmBlackout}
            setModalConfirmBlackout = {setModalConfirmBlackout}
            errorToShow = {errorToShow}
            verifyBlackout = {verifyBlackout}
            modalConfirmOutage = {modalConfirmOutage}
            setModalConfirmOutage = {setModalConfirmOutage}
            verifyOutages ={verifyOutages}
          />
        </Animate>
      )}

     {stepIndex==1&&(<div>

      <Outage action={action} language={language} all={false}
      setStepIndex={setStepIndex}
       toggleAlert={toggleAlert}  APIEndpoints={APIEndpoints} changeitem={changeItem} ></Outage>


     </div>)}

      {stepIndex == 2 && (
        <Animate anime="bounceInRight">
          <ChangeRiskCAB
            change={changeItem || values || {}}
            onSaveS={() => setStepIndex(3)}
            action={action}
            APIEndpoints={APIEndpoints}
            language={language}
            typeTemp={typeTemp}
            stepIndex={stepIndex}
          />
        </Animate>
      )}
      {stepIndex == 3 && (
        <Animate anime="bounceInRight">
          <Step2
            change={changeItem || values || {}}
            stepIndex={stepIndex}
            companies={companies}
            action={action}
            APIEndpoints={APIEndpoints}
            tickets={tickets}
            devices={devices}
            typeTemp={typeTemp}
            setReload={setReload}
            createToTemplate={createToTemplate}
            users={users}
            language={language}
            contracts={contracts}
            values={values}
            actionsOptions={actionsOptions}
            params = {params}
            onSaveS={() => {
              setTimeout(() => {
                if (stepIndex == 3) {
                  location.reload();
                }
              }, 500);
              setStepIndex(
                4 /*--n tem 3, então o ultimo item ficará como verdadeiro-*/
              );
            }}
          />
        </Animate>
      )}
    </Dialog>
  );
}
