import React from 'react';
import {Card, Grid} from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from './TabelaRisco.graph.js';
import './GeneralInfrastructureRisk.css';
import {flexBasis} from '@common/Func';

export default function({
	language,
	matriz,
	users,
	companies,
	matrizplanning 
   
}){

 
	let sum = matriz.reduce(function (accumulator, currentValue) {
        let posicao = currentValue.probability + currentValue.impact >=5 && 
		            currentValue.probability + currentValue.impact <=6 ? 1
					:currentValue.probability + currentValue.impact >=7&& 
		            currentValue.probability + currentValue.impact <=8 ? 2 :0
 
			 
					accumulator[posicao]++
				 
		  return accumulator
	 
	}, [0,0,0])

  
	let array1=[];
	let array2=[];
	let array3=[];
	 
	let days =matriz.map(c=>moment(c.dt_lanc).format('DD-MM-YY'))

	 
	days.map(b=>{
		let v1=0;
		let v2=0;
		let v3=0;
		 matriz.filter(c=>moment(c.dt_lanc).format('DD-MM-YY') == b).map(c=>{

			let p = c.probability + c.impact >=5 && 
			c.probability + c.impact <=6 ? 1
			:c.probability + c.impact >=7&& 
			c.probability + c.impact <=8 ? 2 :0

          if(p==0){
			 v1++
		   }else if(p==1){
			v2++
		}else if(p==1){ 
			v3++  
			 
		  }
			
		})
		  array1.push(v1)
		  array2.push(v2)
		  array3.push(v3)
	})
 
/*
	{
		name: 'Baixa',
		color:'#33aba0',
		data: [24, 75, 33, 1, 4, 5, 1]
	}, 
	{
		name: 'Média',
		color: '#f0b002',
		data: [10, 54, 32, 98, 102, 12, 19]
	},
	{
		name: 'Alta',
		color:'#f66868',
		data: [12, 34, 44, 33, 13, 65, 24]
	}


    */
	return(
		<div id='GeneralInfrastructureRisk'>
			<Grid container spacing={16}>
				<Grid item xs={8.5} style={{...flexBasis(8.5)}}>
					<Card className='content pt-1 border-radius-10'>
						<h3
							style={{
								color: 'var(--primary)',
								fontSize: 15,
								fontWeight: 'bold'
							}}
						>
							{language.RISK_MONITORING}
						</h3>
						<ReactHighcharts config={ContainerChart.container4({days, language,
						  array1 ,
						  array2 ,
						  array3 
						
						})}/>
					</Card>
				</Grid>
				<Grid item xs={3.5} style={{...flexBasis(3.5)}}>
					<Card className='content pt-1 border-radius-10'>
						<h3
							style={{
								color: 'var(--primary)',
								fontSize: 15,
								fontWeight: 'bold'
							}}
						>
							{language.RISK_MONITORING}
						</h3>
						<Grid container spacing={16}>
							<Grid item xs={9}>
								
					     <ReactHighcharts config={ContainerChart.container5({days, language,
						  array1 ,
						  array2 ,
						  array3 
						  })}/>
							</Grid>
							<Grid item xs={3}>
                                 {sum.map((e,i)=>(
									<Card className='border-radius-10 items-indicator content-min my-3'>
										<span
											style={{
												color:(i==0?'var(--danger)':i==1?'var(--warning)':'var(--primary)')
											}}
										>
											{
												i == 0 ? language.HIGH : i == 1 ? language.MEDIUM : language.LOW
											}
										</span>
										<h3>{e}</h3>
									</Card>
								))}
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
		</div>
	)
}