import React, { useEffect, useReducer, useState } from 'react'
import Store from '@data/Store';
import Constants from '@data/Constants';
import { spFetch, useServicePortalStyles } from '../helpers';
import ServicePortalTableWrapper from './ServicePortalTableWrapper';

export default function ServicePortalTicketRelations({ ticketData }) {

  const { language } = Store;
  const formatDate = 'L LT';
  const [isLoading, setIsLoading] = useReducer(v => !v, false);
  const [rows, setRows] = useState([]);

  const HEAD_COLUMNS = [
    {
      id: 'type',
      label: language.TYPE,
    },
    {
      id: 'id',
      label: language.ID,
    },
    {
      id: 'name',
      label: language.TICKET_SUBJECT,
    },
    {
      id: 'dt_cad',
      label: language.DATE_CAD,
      description: language.DATE_START_RELATED
    },
    {
      id: 'dt_closed',
      label: language.COMPLETION_DATE,
      description: language.DATE_CLOSED_RELATED
    },
    {
      id: 'start_date',
      label: language.INITIAL_DATE,
      description: language.DATE_START_RELATED_GMUD
    },
    {
      id: 'end_date',
      label: language.FINAL_DATE,
      description: language.DATE_END_RELATED_GMUD
    },
  ]

  useEffect(() => {
    setIsLoading(true);

    const url = `${Constants.APIEndpoints.TICKET}/connect/list/${ticketData.id_tickets}`;

    spFetch(url, 'get', null, { responseHeaders: true })
      .then((response) => {

        const data = (response.data ?? []).map((ticket) => ({
          type: ticket.type,
          id: ticket.id,
          name: ticket.name,
          dt_cad: moment(ticket.dt_cad).format(formatDate),
          dt_closed: ticket.dt_closed ? moment(ticket.dt_closed).format(formatDate) : '',
          start_date: ticket.start_date ? moment(ticket.start_date).format(formatDate) : '',
          end_date: ticket.end_date ? moment(ticket.end_date).format(formatDate) : '',
        }))

        setRows(data);
      })
      .finally(() => setIsLoading(false));
  }, [])

  return (
    <div>
      <ServicePortalTableWrapper
        rows={rows}
        columns={HEAD_COLUMNS}
        isLoading={isLoading}
      />
    </div>
  )
}
