import Header from '@common/Header';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useReducer, useState } from 'react';
import { Tooltip } from 'react-tippy';

import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import history2 from '@common/history2';
import Actions from '@data/Actions';
import Store from '@data/Store';
import KnowledgeBase from '@images/svg-icon/KnowledgeBase';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Constants from '../../data/Constants';
import * as Styled from './DashboardMultiCatalog.styles';
import { translateFromPtBR } from '@common/languages/Dictionary';

import './style.css';
const language = Store.language;
export function DashboardMultiCatalog({
  selectedCompany,
  selectedDepartment,
  setSelectedDepartment,
  isFilterTask,
  singleTaskSelected,
  setIsFilterTask,
  setSingleTaskSelected,
  handleFavorite,
  taskFavorite,
  resetDepartment,
  setResetDepartment,
  allCatalog,
  tasksFavorites,
  setOpenMenu,
  generalParams,
  language,
}) {
  const action = new Actions();
  const [openForm, setOpenForm] = useState(false);
  const [openSubcategory, setOpenSubcategory] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [openCategory, setOpenCategory] = useState(true);
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [values, setValues] = useState({});
  const [categories, setCategories] = useState([]);

  const [idCategory, setIdCategory] = useState(false);
  const [idCatalogService, setIdCatalogService] = useState(false);
  const [idCatalogTask, setIdCatalogTask] = useState(false);

  const [categories_, setCategories_] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [openKnowledge, setOpenKnowledge] = useState(false);

  const [allTasks, setAllTasks] = useState([]);
  const [showTasks, setShowTasks] = useState([]);

  const [services, setServices] = useState([]);
  const [delay, setDelay] = useState(false);
  const [tasks_, setTasks_] = useState([]);
  const [nameCategory, setNameCategory] = useState('');
  const [nameService, setNameService] = useState('');
  const [nameTask, setNameTask] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [forms, setForms] = useState(null);
  const [templatesForm, setTemplateForms] = useState([]);

  //Variáveis para o formulário
  const [subdptName, setSubdptName] = useState('');
  const [dptName, setDptName] = useState('');
  const [chieftDptName, setChieftDptName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [allDptName, setAllDptName] = useState('');
  const [allDptName2, setAllDptName2] = useState('');
  const [multiDepartment, setMultiDepartment] = useState([]);
  let [channel, setChannel] = useState([]);
  const [chiefSubDptName, setChiefSubDptName] = useState('');
  const [allChiefName, setAllChieftName] = useState('');

  const [locationOptions, setLocationOptions] = useState([]);

  const [multiTickets, setMultiTickets] = useState([]);
  const [multiTicketsSelected, setMultiTicketsSelected] = useState([]);
  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );

  useEffect(() => {
    if (subdptName.length > 0) {
      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/getDepartmentBySubName/',
          '',
          { name: subdptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setDptName(e.data[0].id_department + ' - ' + e.data[0].name);
          } else {
            setDptName('');
          }
        });
      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/getChiefByDptName/',
          '',
          { name: subdptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setChiefSubDptName(e.data[0].name);
          } else {
            setChiefSubDptName('');
          }
        });
    }
  }, [subdptName]);

  useEffect(() => {
    if (allDptName.length > 0) {
      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/getChiefByDptName/',
          '',
          { name: allDptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setAllChieftName(e.data[0].name);
          } else {
            setAllChieftName('');
          }
        });
    }
  }, [allDptName]);

  useEffect(() => {
    if (allDptName2.length > 0) {
      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/getChiefByDptName/',
          '',
          { name: allDptName2 }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setAllChieftName(e.data[0].name);
          } else {
            setAllChieftName('');
          }
        });
    }
  }, [allDptName2]);

  useEffect(() => {
    if (dptName.length > 0) {
      action
        .execute(
          'post',
          Constants.APIEndpoints.COMPANY + '/getCompanyByDptName/',
          '',
          { name: dptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setCompanyName(e.data[0].name);
          } else {
            setCompanyName('');
          }
        });

      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/getChiefByDptName/',
          '',
          { name: dptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setChieftDptName(e.data[0].name);
          } else {
            setChieftDptName('');
          }
        });
    }
  }, [dptName]);

  useEffect(async () => {
    action
      .execute(
        'get',
        Constants.APIEndpoints.TABLE_STRUCTURED + '/120',
        'channel'
      )
      .then((item) => {
        setChannel(item.data);
      });

    let id_catalog_task = selectedTask
      ? selectedTask.id_catalog_task
      : taskFavorite
        ? taskFavorite.id_catalog_task
        : 0;
    let template = [];
    if (id_catalog_task) {
      template = await action.execute(
        'get',
        Constants.APIEndpoints.TASK + '/getformticket/' + id_catalog_task,
        ''
      );
      template = template.data;
      setTemplateForms(template);
    }
    let c = 0;

    let item = template.map((obj) => {
      c = c + 1;
      let id = 'ID' + c;
      return (
        <div className="contain">
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={id}>
              {formshtml(decryp(obj.html))}
            </p>
          </div>
          <div style={{ marginTop: '80px' }} />
          <div>
            {obj.user_up != null
              ? `${language.LAST_UPDATE}: ` +
              obj.user_up +
              '-' +
              moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
              : null}
          </div>
          <hr />
        </div>
      );
    });
    try {
      setForms(item[0]);
    } catch (e) { }

    if (selectedCompany?.id_company && generalParams.enable_location_in_opening_ticket) {
      const url = `${Constants.APIEndpoints.LOCATION}/company/${selectedCompany.id_company}`;
      action
        .execute('get', url)
        .then((result) => {
          if (result.data.length > 0) {
            setLocationOptions(result.data.map(item => ({
              label: item.name,
              value: item.id_location
            })));
          } else {
            setLocationOptions([]);
          }
        });
    }
  }, [
    dptName,
    chieftDptName,
    subdptName,
    companyName,
    allChiefName,
    subdptName,
    chiefSubDptName,
  ]);

  history2.listen((location, action) => {
    window.localStorage.setItem('previous_url', location.pathname);
    window.scrollTo(0, 0);
  });

  function createRandomToken(text) {
    const aSecret = 'Orch3str0!@forever!';
    try {
      const crypto = require('crypto');

      let cipher, tRet;

      cipher = crypto.createCipher('aes-256-cbc', aSecret);
      tRet = cipher.update(text.toString(), 'utf8', 'base64');
      tRet += cipher.final('base64');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  function decryp(aMsg) {
    const crypto = require('crypto');
    var aSecret = 'Orch3str0!@forever!';

    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');

    return tRet;
  }

  const [catalog, setCatalog] = useState([]);
  useEffect(() => {
    if (selectedDepartment.id_department) {
      action
        .execute(
          'get',
          Constants.APIEndpoints.CATALOG +
          '/company/' +
          selectedCompany.id_company +
          '/department/' +
          selectedDepartment.id_department,
          ''
        )
        .then((b) => {
          setCategories(b.data);
        });
    }
  }, [selectedDepartment, resetDepartment]);

  useEffect(() => {
    if (resetDepartment == true) {
      closeAllCategory('categories', null);
      setNameCategory('');
      setNameService('');

      if (isFilterTask) {
        setOpenCategory(false);
        setOpenSubcategory(false);
        setOpenTask(false);
        setOpenForm(false);
        setSelectedCategory([]);
        setSelectedSubcategory([]);
        setSelectedTask([]);
      } else {
        setCatalog([]);
        setServices([]);
        setTasks_([]);
        setCategories_([]);
      }

      if (taskFavorite && taskFavorite.id_catalog_task) {
        setSelectedCategory([]);
        setSelectedSubcategory([]);
        setSelectedTask([]);
        setSelectedDepartment([]);
      }

      setResetDepartment(false);
    }
  }, [resetDepartment]);

  useEffect(() => {
    let cat = [...categories];
    setCategories_(cat);
  }, [categories]);

  useEffect(() => {
    if (selectedCategory.catalog) {
      let sub = [...selectedCategory.catalog];
      setSubcategories(sub);
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubcategory.task) {
      let tasks = [...selectedSubcategory.task];
      setTasks(tasks);
    }
  }, [selectedSubcategory]);

  let fields = [
    {
      col: 8,
      type: 'text',
      name: 'subject',
      label: language.REQUEST_TITLE,
      required: true,
    },
    {
      col: 12,
      type: generalParams?.integration ? 'simplesTextEditor' : 'textEditor',
      name: 'description',
      label: language.REQUEST_DESCRIPTION,
      required: true,
    },
    {
      col: 12,
      type: 'select',
      name: 'fk_id_urgency',
      label: language.URGENCY,
      options: [
        { value: 24, label: language.LOW },
        { value: 25, label: language.MEDIUM },
        { value: 26, label: language.HIGH },
        { value: 27, label: language.TICKET_PRIORITY.CRITICAL },
      ],
      visible: generalParams.allow_customize_priority ? true : false,
    },
    {
      col: 8,
      type: 'autocomplete',
      name: 'devices',
      label: language.ASSET_INVOLVED,
      textlabel: language.ASSET_INVOLVED,
      method: 'POST',
      route: `${Constants.APIEndpoints.DEVICE}/filter/`,
      routeGetCount: `${Constants.APIEndpoints.DEVICE}/filter?count=0`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_device',
      fieldlabeldb: 'name',
      filterAll: { filterByUser: true },
      idinit: '',
      routeAll: `${Constants.APIEndpoints.DEVICE}/filter?`,
      methodGetAll: 'POST',
      detail: true,
      isMulti: true,
      visible: generalParams.enable_asset_involved_ticket_creation ? true : false
    },
    {
      col: 4,
      type: 'file',
      name: 'file',
      label: language.ATTACHMENTS,
      customPortalCss: true,
    },
    {
      col: 4,
      type: 'select',
      name: 'fk_id_location',
      label: language.LOCATION_TITLE,

      options: locationOptions || [],
      required: false,
      visible: generalParams.enable_location_in_opening_ticket ?? false,
    },
  ];

  const onSubmit = () => {
    setDelay(true);
    setTimeout(() => {
      setDelay(false);
    }, 5000);

    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    let campossize = false;
    let v_ = values;
    if (!v_.description) {
      return Store.toggleAlert(
        true,
        language.DESCRIPTION_CANNOT_BE_BLANK,
        'error'
      );
    }

    const keysTemplate = Object.keys(json_template_solicitation);
    let formsComplete = true;
    keysTemplate.map((k) => {
      k == 'chieft_var' &&
        (json_template_solicitation[k] == '' ||
          json_template_solicitation[k] == 'Chefe')
        ? (formsComplete = false)
        : k == 'chiefsubdpt_var' &&
          (json_template_solicitation[k] == '' ||
            json_template_solicitation[k] == 'Chefe de Subdepartamento')
          ? (formsComplete = false)
          : k == 'chieftdpt_var' &&
            (json_template_solicitation[k] == '' ||
              json_template_solicitation[k] == 'Chefe de Departamento')
            ? (formsComplete = false)
            : k == 'companies_var' &&
              (json_template_solicitation[k] == '' ||
                json_template_solicitation[k] == 'Empresa')
              ? (formsComplete = false)
              : k == 'departmentall_var' &&
                (json_template_solicitation[k] == '' ||
                  json_template_solicitation[k] == 'Departamento')
                ? (formsComplete = false)
                : k == 'departments_var' &&
                  (json_template_solicitation[k] == '' ||
                    json_template_solicitation[k] == 'Departamentos')
                  ? (formsComplete = false)
                  : k == 'subdepartments_var' &&
                    (json_template_solicitation[k] == 0 ||
                      json_template_solicitation[k] == 'Subdepartamento')
                    ? (formsComplete = false)
                    : '';
    });

    if (!formsComplete) {
      return Store.toggleAlert(
        true,
        language.FORM_FIELDS_CANNOT_BE_BLANK,
        'error'
      );
    }

    v_.fk_id_category = selectedCategory.id_category
      ? selectedCategory.id_category
      : idCategory;
    v_.fk_id_catalog_service = selectedSubcategory.id_catalog_service
      ? selectedSubcategory.id_catalog_service
      : idCatalogService;
    v_.fk_id_catalog_task = selectedTask.id_catalog_task
      ? selectedTask.id_catalog_task
      : idCatalogTask;
    if (!isFilterTask) {
      v_.fk_id_company = selectedDepartment.fk_id_company
        ? selectedDepartment.fk_id_company
        : selectedCompany.id_company;
      v_.fk_id_department = selectedDepartment.id_department;
    }
    if (chieftDptName.length > 0) {
      v_.approver_name = chieftDptName;
    } else if (chiefSubDptName.length > 0) {
      v_.approver_name = chiefSubDptName;
    } else if (allChiefName.length > 0) {
      v_.approver_name = allChiefName;
    }
    if (subdptName.length > 0) {
      v_.departament_name = subdptName;
    } else if (dptName.length > 0) {
      v_.departament_name = dptName;
    } else if (allDptName.length > 0) {
      v_.departament_name = allDptName;

    } else if (allDptName2.length > 0) {
      v_.departament_name = allDptName2;
    }
    let filesSend = [];
    let cont = 0;
    let fields = [];
    if ($('#template').html() != undefined) {
      $('#template textarea').each(function () {
        $(this).attr('value', $(this).val());
        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#template input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            let value = undefined;
            try {
              value = $(this).parent().children()[1].textContent;
            } catch (e) { }
            fields.push({
              name: $(this).attr('name'),
              value: value || $(this).val(),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#template  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).is(":checked")) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) { }
                  });
                  $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({ name: $(this).attr('name'), value: $(this).val() });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).is(":checked")) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) { }
                      }
                    } catch (e) { }
                  });
                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });

          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#template select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );
        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (!$(this).val() || $(this).val() == "") {
              camposobrigatorios = true;
              $(this).css('border-color', 'red');
            } else {
              $(this).css('border-color', 'var(--primary)');
            }
          }
        }
      });

      $('#template label').each(function () {
        try {
          let textArea = $(this)
            .parent()
            .parent()
            .nextAll()
            .slice(2, 3)
            .children()[0];
          if (
            textArea &&
            textArea.firstChild &&
            textArea.firstChild.tagName == 'P' &&
            textArea.firstChild.textContent.length > 0
          ) {
            fields.push({
              name: $(this).attr('for'),
              value: textArea.firstChild.textContent,
            });
          }

          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())
                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) {
          console.log('erro no label', e);
        }
      });
      // v_.template = createRandomToken($('#template').html());
      v_.template = templatesForm[0].html;
      v_.fields = fields;
    }
    v_.multi_tickets = multiTicketsSelected;

    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      if (v_.file && v_.file.length > 0) {
        let total = Array.from(v_.file).length;
        Array.from(v_.file).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
              filesSend = [];
              cont = 0;
              setTimeout(
                function () {
                  this.redirect('/dash/gsd');
                }.bind(this),
                1000
              );
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              let host = Constants.APIRoot;
              v_.file = filesSend;

              action
                .execute( 'post', `${host}/api/request/`, 'requests', v_)
                .then((results) => {
                  setTimeout(
                    function () {
                      this.redirect('/dash/gsd');
                    }.bind(this),
                    1000
                  );
                  history2.push('/dash/gsd');
                });
            }
          });
        });
      } else {
        action
          .execute(
            'post',
            Constants.APIEndpoints.REQUEST,
            'requests',
            v_,
            'id_request'
          )
          .then((e) => {
            history2.push('/dash/gsd');
          });
      }
    } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        Store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');

      if (campossize)
        Store.toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
    }
  };
  const handleForm = async (task) => {
    setSelectedTask(task);
    setIdCatalogTask(task.id_catalog_task);
    setOpenForm(true);
    setOpenTask(false);
    let template = await action.execute(
      'get',
      Constants.APIEndpoints.TASK + '/getformticket/' + task.id_catalog_task,
      ''
    );
    template = template.data;
    setTemplateForms(template);
    let c = 0;
    let item = template.map((obj) => {
      c = c + 1;
      let id = 'ID' + c;

      return (
        <div className="contain">
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={id}>
              {formshtml(decryp(obj.html))}
            </p>
          </div>
          <div style={{ marginTop: '30px' }} />
          <div>
            {obj.user_up != null
              ? language.LAST_UPDATE + ' ' +
              obj.user_up +
              '-' +
              moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
              : null}
          </div>
          <hr />
        </div>
      );
    });
    try {
      setForms(item[0]);
    } catch (e) {
      console.log(e);
    }

    await action
      .execute('post', Constants.APIEndpoints.TASK + '/getallmultiticket', '', {
        task: task.id_catalog_task,
      })
      .then((res) => {
        setMultiTickets(res.data);
      });
  };

  const formshtml = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    /**
     * Added the timezone of the user who created the ticket.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];

    t.split('<div').map((i, key) => {
      const required = (i || '').indexOf('formbuilder-required') > -1;
      const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
      const label = labelMatch ? labelMatch[1] : undefined;
      const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;

      if( addEmptyOption && i.indexOf('<select') > -1 ) {
        i.replace(/>Selecione</, function(match) {
          let originalText = match.slice(1, -1);
          let newText = translateFromPtBR(originalText, language);
          return `>${newText}<`;
        });
      }

      if (i.indexOf('companies_var') > -1) {
        data.push(
          <div id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.COMPANY,
                  textinit: companyName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: companyName,
                  showEmptyFilter: addEmptyOption,
                  routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('allusers_var') > -1) {
        data.push(
          <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.USER,
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Usuário',
                  showEmptyFilter: addEmptyOption,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        );
      } else
        if (i.indexOf('hours_var') > -1) {
          let nameField = 'hours_var'
          try {
            const regex = /<p(.*?)<\/p>/;
            const textWithoutTags = i.match(regex)
            nameField = textWithoutTags[1].split('|')[1]
          } catch (e) { }
          data.push(
            <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ hours: value });
                }}
                fields={[
                  {
                    col: 12,
                    name: nameField,
                    label: label || language.HOURS,
                    type: 'time',
                    format: '##:##',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('hidden_var') > -1) {
          let value_field = '';
          let name_field = '';
          try {
            const regex = /<p(.*?)<\/p>/;
            const textWithoutTags = i.match(regex);
            name_field = textWithoutTags[1].split('|')[1];
            value_field = textWithoutTags[1].split('|')[2];
          } catch (e) { }
          const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`;
          data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
        } else if (i.indexOf('departments_var') > -1) {
          data.push(
            <div id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ departments_var: value });
                  setDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'departments',
                    label: '',
                    textlabel: label || language.DEPARTMENT,
                    method: 'POST',
                    showEmptyFilter: addEmptyOption,
                    route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                    filterAll: { subdepartment: false },
                    fieldquery: 'search',
                    textinit: dptName,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: dptName,
                    routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('subsdpt_var') > -1) {
          data.push(
            <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ subdepartments_var: value });
                  setSubdptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'subdepartments',
                    label: '',
                    textlabel: label || language.SUBDEPARTMENT,
                    method: 'POST',
                    showEmptyFilter: addEmptyOption,
                    route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                    filterAll: { subdepartment: true },
                    fieldquery: 'search',
                    textinit: '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('contracts_var') > -1) {
          data.push(
            <div id="contracts_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ contracts_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'contracts_var',
                    method: 'POST',
                    showEmptyFilter: addEmptyOption,
                    route: `${Constants.APIEndpoints.CONTRACT}/filter`,
                    fieldquery: 'search',
                    textlabel: label || language.CONTRACT,
                    textinit: '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
                    methodGetAll: 'POST',
                    routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
                    disablePortal: true,
                    required,
                    showId: true,
                    idFieldToShow: 'id_contract',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('chieftdpt_var') > -1) {
          data.push(
            <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ chieftdpt_var: value });
                  setChieftDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'boss_dpt',
                    label: '',
                    textlabel: label || language.DEPARTMENT_BOSS,
                    method: 'POST',
                    filter: { fk_id_company: 1 },
                    filterAll: { chief: true },
                    route: `${Constants.APIEndpoints.USER}/filter`,
                    routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                    fieldquery: 'search',
                    textinit: chieftDptName,
                    showEmptyFilter: addEmptyOption,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: chieftDptName,
                    routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                    methodGetAll: 'POST',
                    detail: true,
                    visible: true,
                    required,
                    disablePortal: true,
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('chiefsubdpt_var') > -1) {
          data.push(
            <div id="chiefsubdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ chiefsubdpt_var: value });
                  setChiefSubDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'boss_sub_dpt',
                    label: '',
                    textlabel: label || language.SUBDEPARTMENT_BOSS,
                    method: 'POST',
                    filter: { fk_id_company: 1 },
                    filterAll: { chiefsub: true },
                    route: `${Constants.APIEndpoints.USER}/filter`,
                    routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                    fieldquery: 'search',
                    textinit: chiefSubDptName,
                    showEmptyFilter: addEmptyOption,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: chiefSubDptName,
                    routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('chief_var') > -1) {
          data.push(
            <div id="chief_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ chief_var: value });
                  setAllChieftName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'all_boss_dpt',
                    label: '',
                    textlabel: label || language.DEPARTMENT_BOSS,
                    method: 'POST',
                    filter: { fk_id_company: 1 },
                    filterAll: { all_chief: true },
                    route: `${Constants.APIEndpoints.USER}/filter`,
                    routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                    showEmptyFilter: addEmptyOption,
                    fieldquery: 'search',
                    textinit: allChiefName,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: allChiefName,
                    routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('departmentall_var') > -1) {
          data.push(
            <div id="departmentall_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ departmentall_var: value });
                  setAllDptName(value);
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'all_departments',
                    label: '',
                    textlabel: label || language.DEPARTMENT,
                    method: 'POST',
                    route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                    showEmptyFilter: addEmptyOption,
                    fieldquery: 'search',
                    textinit: allDptName,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: allDptName,
                    routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else if (i.indexOf('Multidepartment_var') > -1) {
          let nameField = ''
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex)
          nameField = 'Multidepartment_var' + textWithoutTags[1].split('|')[1]
          nameField = nameField.split('<')[0]
          data.push(
            <div id={nameField} style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ departmentall_var: value });
                  let MD = multiDepartment
                  MD.push({ index: nameField, value: value })
                  setMultiDepartment(MD)
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: `${nameField}`,
                    label: '',
                    textlabel: label || language.DEPARTMENTS,
                    method: 'POST',
                    route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                    showEmptyFilter: addEmptyOption,
                    fieldquery: 'search',
                    textinit: multiDepartment.find(M => M.index == nameField)?.value,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: multiDepartment.find(M => M.index == nameField)?.value,
                    routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
        } else {
          if (i.length > 0) {
            let g = '';
            if (i.indexOf('ql-tooltip ql-hidden') > -1) {
              g = `<div style = "display : none" ${i}`;
            } else if (
              i.indexOf('ql-preview') > -1 ||
              i.indexOf('ql-clipboard') > -1 ||
              i.indexOf('ql-snow') > -1
            ) {
              g = `<div  ${i}`;
            } else if (i.indexOf('ql-editor') > -1) {
              g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
            } else {
              g = `<div class = "template-dinamic" ${i}`;
            }
            data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
          }
        }
    });
    return data;
  };

  const handleSubcategory = (category) => {
    setSelectedCategory(category);
    setOpenSubcategory(true);
    setOpenCategory(false);
    setIsFilterTask(false);
    setIdCategory(category.id_category);
  };

  const handleTask = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setOpenTask(true);
    setOpenSubcategory(false);
    setIdCatalogService(subcategory.id_catalog_service);
  };

  const closeAllCategory = (type, id) => {
    if (isSearch) {
      setIsFilterTask(true);
      setOpenForm(false);
      setIsSearch(false);
      setNameCategory('');
      setNameService('');
    }
    if (type == 'company') {
      setSelectedCategory([]);
      setSelectedSubcategory([]);
      setTasks([]);
      setOpenCategory(true);
      setOpenSubcategory(false);
      setOpenTask(false);
      setOpenForm(false);
    } else if (type == 'categories') {
      setSelectedCategory([]);
      setSelectedSubcategory([]);
      setOpenCategory(true);
    } else if (type == 'subcategories') {
      setSelectedSubcategory([]);
      setOpenSubcategory(true);
    }
  };

  useEffect(async () => {
    if (taskFavorite.id_category && !isFilterTask) {
      handleSelectedSingleTask(
        taskFavorite.id_category,
        taskFavorite.id_catalog_service,
        taskFavorite.id_catalog_task
      );
      setNameCategory(taskFavorite.name_category);
      setNameService(taskFavorite.name_catalog_service);
      setNameTask(taskFavorite.name);
      setSelectedDepartment([]);
      setSelectedTask([]);

      let template = await action.execute(
        'get',
        Constants.APIEndpoints.TASK +
        '/getformticket/' +
        taskFavorite.id_catalog_task,
        ''
      );
      template = template.data;
      setTemplateForms(template);

      let c = 0;

      let item = template.map((obj) => {
        c = c + 1;
        let id = 'ID' + c;
        return (
          <div className="contain">
            <div className="itemTicketDetail ">
              <p style={{ color: '#888080' }} id={id}>
                {formshtml(decryp(obj.html))}
              </p>
            </div>
            <div style={{ marginTop: '80px' }} />
            <div>
              {obj.user_up != null
                ? language.LAST_UPDATE + ' ' +
                obj.user_up +
                '-' +
                moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
                : null}
            </div>
            <hr />
          </div>
        );
      });
      try {
        setForms(item[0]);
      } catch (e) { }
    }
  }, [taskFavorite, resetDepartment]);

  const handleSelectedSingleTask = async (
    id_category,
    id_catalog_service,
    id_catalog_task
  ) => {
    setIdCategory(id_category);
    setIdCatalogService(id_catalog_service);
    setIdCatalogTask(id_catalog_task);
    setOpenForm(true);
    setOpenTask(false);
    setOpenCategory(false);
    setOpenSubcategory(false);
    setIsFilterTask(false);
    let template = await action.execute(
      'get',
      Constants.APIEndpoints.TASK + '/getformticket/' + id_catalog_task,
      ''
    );
    template = template.data;
    setTemplateForms(template);

    let c = 0;

    let item = template.map((obj) => {
      c = c + 1;
      let id = 'ID' + c;
      return (
        <div className="contain">
          <div className="itemTicketDetail ">
            <p style={{ color: '#888080' }} id={id}>
              {formshtml(decryp(obj.html))}
            </p>
          </div>
          <div style={{ marginTop: '80px' }} />
          <div>
            {obj.user_up != null
              ? `${language.LAST_UPDATE}: ` +
              obj.user_up +
              '-' +
              moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
              : null}
          </div>
          <hr />
        </div>
      );
    });
    try {
      setForms(item[0]);
    } catch (e) { }
  };

  useEffect(() => {
    let cat = [];
    let single = singleTaskSelected.categories
      ? singleTaskSelected.categories
      : [];
    single.map((category) => {
      category.catalog.map((catalog) => {
        cat.push({
          id_category: category.id_category,
          name_category: category.name,
          icon_category: category.icon,
          icon_category: category.iconchange,
          favorite_category: category.is_favorite,

          id_catalog_service: catalog.id_catalog_service,
          name_catalog_service: catalog.name,
          icon_catalog_service: catalog.icon,
          icon_catalog_service: catalog.iconchange,
          favorite_catalog_service: catalog.is_favorite,

          id_catalog_task: singleTaskSelected.id_catalog_task,
          name_catalog_task: singleTaskSelected.name,
          icon_catalog_task: singleTaskSelected.icon,
          icon_catalog_task: singleTaskSelected.iconchange,
          favorite_catalog_task: singleTaskSelected.is_favorite,
        });
      });
    });
    setAllTasks(cat);
    setShowTasks(cat);
  }, [singleTaskSelected]);
  useEffect(() => {
    let cat_ = [];
    let servicesComplete = [];
    let servicesNotComplete = [];
    let tasksComplete = [];
    let tasksNotComplete = [];
    let categoriesComplete = [];
    let categoriesNotComplete = [];

    if (allCatalog.categories) {
      allCatalog.categories.map((category) => {
        category.type = 'category';
        category.priority == 1 || category.priority == 2
          ? categoriesComplete.push(category)
          : categoriesNotComplete.push(category);
      });
    }
    if (allCatalog.services) {
      allCatalog.services.map((service) => {
        (service.categories ? service.categories : []).map((category) => {
          let service_ = {
            id_category: category.id_category,
            name_category: category.name,
            id_catalog_service: service.id_catalog_service,
            name_catalog_service: service.name,
            icon_catalog_service: service.icon,
            icon_catalog_service: service.iconchange,
            tasks: service.tasks,
          };
          service_.type = 'service';
          service.priority == 1 || service.priority == 2
            ? servicesComplete.push(service_)
            : servicesNotComplete.push(service_);
        });
      });
      let allServices = [...servicesComplete, ...servicesNotComplete];
      setServices(allServices);
    }
    if (allCatalog.tasks) {
      allCatalog.tasks.map((task) => {
        (task.categories ? task.categories : []).map((category) => {
          (category.catalog ? category.catalog : []).map((catalog) => {
            let task_ = {
              id_category: category.id_category,
              name_category: category.name,

              id_catalog_service: catalog.id_catalog_service,
              name_catalog_service: catalog.name,

              id_catalog_task: task.id_catalog_task,
              name: task.name,
              icon: task.icon,
              iconchange: task.iconchange,
            };
            task_.type = 'task';
            task.priority == 1 || task.priority == 2
              ? tasksComplete.push(task_)
              : tasksNotComplete.push(task_);
          });
        });
      });
      let allTasks = [...tasksComplete, ...tasksNotComplete];
      setTasks_(allTasks);
    }
    cat_.push(...categoriesComplete);
    cat_.push(...servicesComplete);
    cat_.push(...tasksComplete);
    cat_.push(...categoriesNotComplete);
    cat_.push(...servicesNotComplete);
    cat_.push(...tasksNotComplete);
    setCatalog(cat_);
  }, [allCatalog]);

  const isFavorite = () => {
    let is_ = false;
    tasksFavorites.map((t) => {
      if (
        t.id_category == idCategory &&
        t.id_catalog_service == idCatalogService &&
        t.id_catalog_task == idCatalogTask
      ) {
        is_ = true;
      }
    });
    return is_;
  };

  const hasDisableFields = !selectedTask?.disable_fields;

  return (
    <div style={{ flex: 1, backgroundColor: 'white' }}>
      <Header className="justify-content-between">
        <div>
          <Button
            variant="normal"
            icon={<KnowledgeBase style={{ fill: 'white', width: 20 }} />}
            label={language.KNOWLEDGE_BASE}
            color="warning"
            fluid
            onClick={() => setOpenKnowledge(true)}
            disabled={!selectedSubcategory?.name}
          />
        </div>
      </Header>
      {isFilterTask ? (
        <div
          style={{
            maxHeight: 'auto',
            overflowY: 'auto',
          }}
        >
          <div className="divListTasksFilter">
            {catalog.map((service) =>
              service.type == 'service' ? (
                <div className="divSelectedSubcategoryTask">
                  {service.iconchange ? (
                    <img
                      style={{
                        color: 'orange',
                        marginRight: 10,
                        width: '1.5rem',
                        height: '1.5rem',
                        filter:
                          'invert(83%) sepia(38%) saturate(6346%) hue-rotate(359deg) brightness(100%) contrast(106%)',
                      }}
                      src={service.iconchange}
                    />
                  ) : (
                    <Icon style={{ color: 'orange', marginBottom: 20 }}>
                      {service.icon_catalog_service}
                    </Icon>
                  )}

                  <div>
                    <p style={{ cursor: 'default' }}>{service.name_category}</p>
                    <label style={{ cursor: 'default' }}>
                      {service.name_catalog_service}
                    </label>
                    <p style={{ display: 'flex', color: '#b9b6b6' }}>
                      {language.ACTIVITIES}:{' '}
                    </p>
                    <div style={{ display: 'flex' }}>
                      {(service.tasks ? service.tasks : []).map((t) => (
                        <p
                          style={{ padding: 5 }}
                          onClick={() => {
                            handleSelectedSingleTask(
                              service.id_category,
                              service.id_catalog_service,
                              t.id_catalog_task
                            );
                            setNameCategory(service.name_category);
                            setNameService(service.name_catalog_service);
                            setSelectedTask(t);
                            setIsSearch(true);
                            setOpenMenu(false);
                          }}
                        >
                          {t.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              ) : service.type == 'task' ? (
                <div
                  className="divSelectedSubcategoryTask"
                  onClick={() => {
                    handleForm(service)
                    handleSelectedSingleTask(
                      service.id_category,
                      service.id_catalog_service,
                      service.id_catalog_task
                    );
                    setNameCategory(service.name_category);
                    setNameService(service.name_catalog_service);
                    setIsSearch(true);
                    setSelectedTask(service);
                    setOpenMenu(false);
                  }}
                >
                  {service.iconchange ? (
                    <img
                      style={{
                        color: 'orange',
                        marginRight: 10,
                        width: '1.5rem',
                        height: '1.5rem',
                        filter:
                          'invert(83%) sepia(38%) saturate(6346%) hue-rotate(359deg) brightness(100%) contrast(106%)',
                      }}
                      src={service.iconchange}
                    />
                  ) : (
                    <Icon style={{ color: 'orange', marginBottom: 20 }}>
                      {service.icon}
                    </Icon>
                  )}

                  <div>
                    <p style={{ display: 'flex', color: '#b9b6b6' }}>
                      {language.CATEGORY}:{' '}
                      <p
                        style={{ color: 'black', marginLeft: 5 }}
                      >{` ${service.name_category}`}</p>
                    </p>
                    <p style={{ display: 'flex', color: '#b9b6b6' }}>
                      {language.SUBCATEGORY}:{' '}
                      <p style={{ color: 'black', marginLeft: 5 }}>
                        {` ${service.name_catalog_service}`}
                      </p>
                    </p>
                    <label>{service.name}</label>
                  </div>
                </div>
              ) : (
                <div
                  className="divSelectedSubcategoryTask"
                  onClick={() => {
                    setIsSearch(true);
                    handleSubcategory(service);
                    setOpenMenu(false);
                  }}
                >
                  {service.iconchange ? (
                    <img
                      style={{
                        color: 'orange',
                        marginRight: 10,
                        width: '1.5rem',
                        height: '1.5rem',
                        filter:
                          'invert(83%) sepia(38%) saturate(6346%) hue-rotate(359deg) brightness(100%) contrast(106%)',
                      }}
                      src={service.iconchange}
                    />
                  ) : (
                    <Icon style={{ color: 'orange', marginBottom: 20 }}>
                      {service.icon}
                    </Icon>
                  )}

                  <label>{service.name}</label>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <div className="divSelectedSubcategoryCatalog">
          {selectedDepartment.name ? (
            <div
              className="divSingleTitleCatalog"
              onClick={() => closeAllCategory('company', selectedCompany)}
            >
              <div>
                <p>{language.DEPARTMENT}</p>
                <label>{selectedDepartment.name}</label>
              </div>
            </div>
          ) : null}

          {selectedDepartment.name && selectedCategory.name ? (
            <Icon className="iconArrowForward">arrow_forward</Icon>
          ) : null}
          <div
            className="divSingleTitleCatalog"
            onClick={() => closeAllCategory('categories', null)}
          >
            <div>
              <p>
                {selectedCategory.name || nameCategory.length > 0
                  ? 'Categoria'
                  : ''}
              </p>
              <label>{selectedCategory.name || nameCategory}</label>
            </div>
          </div>
          {selectedCategory.name && selectedSubcategory.name ? (
            <Icon className="iconArrowForward">arrow_forward</Icon>
          ) : null}
          <div
            className="divSingleTitleCatalog"
            onClick={() => closeAllCategory('subcategories', null)}
          >
            <div>
              <p>
                {selectedSubcategory.name || nameService.length > 0
                  ? 'Subcategoria'
                  : ''}
              </p>
              <label>{selectedSubcategory.name || nameService}</label>
            </div>
          </div>
        </div>
      )}

      <Divider />
      {openCategory ? (
        <div style={{ marginTop: 10 }}>
          <label className="labelTitleContainer">
            {selectedDepartment.id_department ? language.CATEGORIES : ''}
          </label>
          <label style={{ color: 'orange', fontSize: 12 }}>
            {selectedDepartment.id_department
              ? `(${language.SELECT_ONE_BELOW})`
              : ''}
          </label>

          <div className="divListTasks">
            {categories_
              .sort(function (a, b) {
                if (a.name.normalize('NFD') > b.name.normalize('NFD')) return 1;
                if (a.name.normalize('NFD') < b.name.normalize('NFD'))
                  return -1;
                return 0;
              })
              .map((cat) => (
                <div
                  className="divSelectedSubcategoryTaskNoFilter"
                  onClick={() => handleSubcategory(cat)}
                >
                  {cat.iconchange ? (
                    <img
                      style={{
                        color: 'orange',
                        marginRight: 10,
                        width: '1.5rem',
                        height: '1.5rem',
                        filter:
                          'invert(83%) sepia(38%) saturate(6346%) hue-rotate(359deg) brightness(100%) contrast(106%)',
                      }}
                      src={cat.iconchange}
                    />
                  ) : (
                    <Icon style={{ color: 'orange', marginRight: 10 }}>
                      {cat.icon}
                    </Icon>
                  )}

                  <label>{cat.name}</label>
                  <Tooltip
                    html={
                      <div>
                        {cat?.description}
                      </div>
                    }
                    interactive
                    theme="dark"
                    arrow
                    className='fas fa-eye btn-view-detail mr-2'
                    style={{ fontSize: 12, display: 'inline-block', color: 'orange', padding: 10 }}
                  ></Tooltip>
                </div>
              ))}
          </div>
        </div>
      ) : openSubcategory ? (
        <div style={{ marginTop: 10 }}>
          <label className="labelTitleContainer">
            {language.SUBCATEGORIES}
          </label>
          <label style={{ color: 'orange', fontSize: 12 }}>
            {`(${language.SELECT_ONE_BELOW})`}
          </label>

          <div className="divListTasks">
            {subcategories
              .sort(function (a, b) {
                if (a.name.normalize('NFD') > b.name.normalize('NFD')) return 1;
                if (a.name.normalize('NFD') < b.name.normalize('NFD'))
                  return -1;
                return 0;
              })
              .map((cat) => (
                <div
                  className="divSelectedSubcategoryTaskNoFilter"
                  onClick={() => handleTask(cat)}
                >
                  {cat.iconchange ? (
                    <img
                      style={{
                        color: 'orange',
                        marginRight: 10,
                        width: '1.5rem',
                        height: '1.5rem',
                        filter:
                          'invert(83%) sepia(38%) saturate(6346%) hue-rotate(359deg) brightness(100%) contrast(106%)',
                      }}
                      src={cat.iconchange}
                    />
                  ) : (
                    <Icon style={{ color: 'orange', marginRight: 10 }}>
                      {cat.icon}
                    </Icon>
                  )}

                  <label>{cat.name}</label>
                  <Tooltip
                    html={
                      <div>
                        {cat?.description}
                      </div>
                    }
                    interactive
                    theme="dark"
                    arrow
                    className='fas fa-eye btn-view-detail mr-2'
                    style={{ fontSize: 12, display: 'inline-block', color: 'orange', padding: 10 }}
                  ></Tooltip>
                </div>
              ))}
          </div>
        </div>
      ) : openTask ? (
        <div style={{ marginTop: 10 }}>
          <label className="labelTitleContainer">
            {language.THIRD_LEVEL_CATEGORIES}
          </label>
          <label style={{ color: 'orange', fontSize: 12 }}>
            {`(${language.SELECT_ONE_BELOW})`}
          </label>
          <div className="divListTasks">
            {tasks
              .sort(function (a, b) {
                if (a.name.normalize('NFD') > b.name.normalize('NFD')) return 1;
                if (a.name.normalize('NFD') < b.name.normalize('NFD'))
                  return -1;
                return 0;
              })
              .map((task) => (
                <div
                  className="divSelectedSubcategoryTaskNoFilter"
                  onClick={() => handleForm(task)}

                >
                  {task.iconchange ? (
                    <img
                      style={{
                        color: 'orange',
                        marginRight: 10,
                        width: '1.5rem',
                        height: '1.5rem',
                        filter:
                          'invert(83%) sepia(38%) saturate(6346%) hue-rotate(359deg) brightness(100%) contrast(106%)',
                      }}
                      src={task.iconchange}
                    />
                  ) : (
                    <Icon style={{ color: 'orange', marginRight: 10 }}>
                      {task.icon}
                    </Icon>
                  )
                  }

                  <label>{task.name}</label>
                  <Tooltip
                    html={
                      <div>
                        {task?.description}
                      </div>
                    }
                    interactive
                    theme="dark"
                    arrow
                    className='fas fa-eye btn-view-detail mr-2'
                    style={{ fontSize: 12, display: 'inline-block', color: 'orange', padding: 10 }}
                  ></Tooltip>
                </div>
              ))}
          </div>
        </div>
      ) : openForm ? (
        <div
          style={{
            padding: 20,
            maxHeight: '60vh',
            overflowY: 'auto',
          }}
        >
          <IconButton
            size="small"
            aria-label="Menu"
            onClick={() => {
              if (selectedDepartment && selectedDepartment.id_department) {
                setOpenTask(true);
              } else {
                setIsFilterTask(true);
                setOpenForm(false);
              }
            }}
            style={{
              height: 30,
              color: '#F09726',
              borderRadius: 0,
              marginRight: 10,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <label className="labelTitleSelected">
            {selectedTask.name || nameTask}
          </label>
          <IconButton
            size="small"
            aria-label="Menu"
            style={{ color: 'orange', marginBottom: 5, marginLeft: 5 }}
            onClick={() =>
              handleFavorite(
                'catalog_task',
                idCatalogTask,
                idCatalogService,
                idCategory
              )
            }
          >
            {isFavorite() ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>

          {hasDisableFields && (
            <>
              <div className="divCommonForm">
              <CommonForm
                fields={fields}
                values={{
                  ...values,
                  ...{ fk_id_urgency: selectedTask.id_urgency },
                }}
                onChangeField={(f, v) => {
                  let v_ = values;
                  v_[f.name] = v;
                  setValues(v_);
                }}
              />
              </div>
              {multiTickets.length > 0 && (
              <div style={{ marginTop: 10 }}>
                <CommonForm
                  fields={[
                    {
                      col: 12,
                      type: 'listCheckbox',
                      required: true,
                      name: 'multi_tickets',
                      label: language.MULTI_TICKETS,
                      options: multiTickets.map((t) => ({
                        value: t.id_rel_task_multi_ticket,
                        label: t.name,
                      })),
                    },
                  ]}
                  onChangeField={(f, v) => {
                    setMultiTicketsSelected(v);
                  }}
                />
              </div>
              )}
            </>
          )}

          {forms != null ? (
            <div
              id="template"
              style={{ marginTop: '10px', position: 'relative' }}
              className="template"
            >
              {forms}
            </div>
          ) : null}

          {hasDisableFields && (
            <>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  variant="normal"
                  icon="fas fa-save"
                  color="success"
                  onClick={() => (delay == false ? onSubmit() : null)}
                  label={language.SAVE}
                  style={{ marginBottom: 50, marginTop: 40 }}
                />
              </div>
              <Divider />
            </>
          )}

        </div>
      ) : null}
      <Styled.Dialog
        title={language.KNOWLEDGE_BASE}
        open={openKnowledge}
        onClose={() => setOpenKnowledge(false)}
      >
        <Styled.SolutionContainer
          company={selectedCompany.id_company}
          subcategory={selectedSubcategory.id_catalog_service}
        />
      </Styled.Dialog>
    </div>
  );
}
