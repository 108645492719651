import React, { useEffect, useState } from 'react';
import { Card, Grid, Paper } from '@material-ui/core';
import {
  Slider,
  Typography,
  Button as ButtonUI,
  Divider,
  InputBase,
  Select as MuiSelect,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import lodash from 'lodash';
import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import Header from '@common/Header';
import AppBar from '@common/AppBar2';
import Dialog from '@common/Dialog';
import moment from 'moment';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';
import CommonTable from '@common/CommonTable';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';
import Button from '@common/Button';
import TicketManagement from '../TicketManagement';
import Badge from '@material-ui/core/Badge';
import { set } from 'nprogress';
import randomcolor from 'randomcolor';
import {
  MuiThemeProvider,
  createTheme,
  makeStyles,
  withStyles,
  styled,
} from '@material-ui/core/styles';
const crypto = require('crypto');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #DEDEDE',
    height: '100%',
  },
  textSubTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#a2a2a2',
    marginBottom: 25,
  },
  textTitle: {
    color: '#33ACA0',
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    color: '#707070',
    fontSize: 12,
  },
  spacing: {
    padding: 5,
  },
  icon: {
    color: (props) => props.color || '#019FC2',
  },
  text: {
    color: '#707070',
    textAlign: 'justify',
    marginLeft: 45,
  },
  fullHeightCard: {
    height: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemTask: {
    padding: 13,
    border: `1px solid ${randomcolor()}`,
  },
  textDescription: {
    fontWeight: 700,
    color: 'var(--success)',
    fontSize: 16,
  },
  subtitle_solicitation: {
    color: '#019FC2',
    fontWeight: 'bold',
  },
  button: {
    color: '#FFF',
    background: '#58A9A0',
    padding: '5px 73px',
    float: 'right',
    '&:hover:': {
      background: '#58A9A0',
    },
  },
  atend: {
    display: 'flex',
  },
}));

export function ApproveTask() {
  let { language, host } = Store;

  const [taskApprove, setTaskApprove] = useState([]);
  const [countTaskApprove, setCountTaskApprove] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [countTotal, setCounTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState({});
  const [ticketObj, setTicketObj] = useState(null);
  const [taskPredecessor, setTaskPredecessor] = useState([]);
  const [openPredecessor, setOpenPredecessor] = useState(false);
  const [openStatusChange, setOpenStatusChange] = useState(false);
  const classes = useStyles();

  const formshtml = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    /**
     * Added the timezone of the user who created the ticket.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];
    t.split('<div').map((i, key) => {
      if (i.indexOf('companies_var') > -1) {
        data.push(
          data.push(
            <div id="companies_var" style={{ margin: '10px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ companies_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'companies_var',
                    label: '',
                    method: 'POST',
                    route: `${APIEndpoints.COMPANY}/filter`,
                    fieldquery: 'search',
                    textlabel: ``,
                    textinit: language.COMPANY,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${APIEndpoints.COMPANY}/all?`,
                    methodGetAll: 'POST',
                    routeGetCount: `${APIEndpoints.COMPANY}/count`,
                    detail: true,
                    visible: true,
                    required: true,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_company',
                  },
                ]}
              />
            </div>
          )
        );
      }else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var'
        try{
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i.match(regex)
        nameField = textWithoutTags[1].split('|')[1]
        }catch(e){}
        data.push(
          <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: language.HOURS,
                  type: 'time',
                  format: '##:##',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = ''
        let name_field = ''
        try{
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i.match(regex)
        name_field = textWithoutTags[1].split('|')[1]
        value_field = textWithoutTags[1].split('|')[2]
      }catch(e){}
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`
        data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('subsdpt_var') > -1) {
        data.push(
          <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: language.SUB_DEPARTMENT,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('departments_var') > -1) {
        data.push(
          <div id="departments_var" style={{ margin: '10px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: language.DEPARTMENTS,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('contracts_var') > -1) {
        data.push(
          <div>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: language.CONTRACTS,
                  method: 'POST',
                  route: `${APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: language.CONTRACT,
                  fieldvaluedb: 'id_contract',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                  textlabel: language.CONTRACTS,
                  showId: true,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chieftdpt_var') > -1) {
        data.push(
          <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss',
                  label: '',
                  textlabel: ``,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: language.DEPARTMENT_BOSS,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: language.DEPARTMENT_BOSS,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        );
      } else {
        if (i.length > 0) {
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }

          data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
        }
      }
    });
    return data;
  };

  function decryp(aMsg) {
    var aSecret = 'Orch3str0!@forever!';
    try {
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  const callPageSelectFilter = (page = 0, values, totalPerPage = 75) => {
    setCurrentPage(page);
    let limit = totalPerPage || 75;
    let pageCount = page + 1;
    let offset = page * limit;

    const action = new Actions();
    action
      .execute('post', `${Constants.APIEndpoints.TASKS}/tasksapprove?limit=${totalPerPage}&offset=${offset}&all=true`, '', values)
      .then((res) => {
        setTaskApprove(res.data);
        setCountTaskApprove(parseInt(res.data[0].total_count));
        let cont = 0;
        try {
          cont = res.data[0].total_count;
        } catch (e) {}
        setCountTaskApprove(cont);
      });
  };

  const getBackStatus = (status) => {
    if (status == 'AGUARDANDO') {
      return 'rgb(240 31 31 / 19%)';
    }
    if (status == 'INICIADO') {
      return '#f0971f30';
    }

    if (status == 'ANDAMENTO') {
      return '#f0971f30';
    } else {
      return 'rgb(52, 172, 160, 0.35)';
    }
  };

  const getColorStatus = (status) => {
    if (status == 'AGUARDANDO') {
      return 'rgb(240 31 31)';
    }
    if (status == 'INICIADO') {
      return 'rgb(240, 151, 31)';
    }

    if (status == 'ANDAMENTO') {
      return 'rgb(240, 151, 31)';
    } else {
      return 'rgba(52, 172, 160)';
    }
  };

  const approve = (v) => {
    const action = new Actions();
    action
      .execute('post', Constants.APIEndpoints.TASKS + '/approvetask', '', {
        ...v,
        ...obj,
      })
      .then((e) => {
        //  return {code:3030}
        if (e.data.code == '3030') {
          Store.toggleAlert(
            true,
            language.USER_IS_NOT_PART_OF_THE_APPROVER_GROUP,
            'error'
          );
        }
        if (e.data.code == '3031') {
          Store.toggleAlert(true, language.USER_HAS_ALREADY_MADE_APPROVAL, 'error');
        }
        if (e.data.code == 'ok') {
          Store.toggleAlert(true, language.APPROVAL_CARRIED_OUT_SUCCESSFULLY, 'success');
        }

        setTimeout(() => {
          callPageSelectFilter();
        }, 2000);
        setOpen(false);
      });
  };

  useEffect(() => {
    callPageSelectFilter();
  }, []);

  const openPredecessorTask = (id_task) => {
    const action = new Actions();
    action
      .execute('get', Constants.APIEndpoints.TASKS + '/' + id_task, '')
      .then((res) => {
        setTaskPredecessor(res.data.tasks[0]);
        setOpenPredecessor(true);
      });
  };

  function handleOpenStatus(task) {
    setOpenStatusChange(true);
  }

  let data = taskApprove.map((obj) => ({
    id: obj.id_tasks,
    name: obj.name,
    description: (
      <p
        style={{ color: '#888080' }}
        dangerouslySetInnerHTML={{
          __html: obj.description,
        }}
      ></p>
    ),

    dt_cad: moment(obj.dt_cad).format('DD/MM/YY HH:mm'),
    predecessor:
      obj.predecessor > 0 ? (
        <div
          onClick={() => {
            openPredecessorTask(obj.predecessor);
          }}
          className="detailIcon eye"
          style={{ width: 25, height: 25 }}
        >
          {' '}
        </div>
      ) : null,
    ticketOrreuest: (
      <div className="d-flex align-items-center justify-content-between">
        <div
          onClick={() => {
            setOpen(false);
            setTicketObj({ id_tickets: obj.id_tickets, real_id: obj.real_id });
          }}
          className="detailIcon pointer"
          style={{ width: 25, height: 25 }}
        >
          {' '}
        </div>
        {obj.real_id || 'R' + obj.id_request}
      </div>
    ),
    group_users: obj.group_users,
    type_approve: obj.type_approve == 'all' ? 'Todos Usuários Grupo' : '',
    status: (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          variant="normal"
          label={obj.count_total}
          color={'warning'}
          tooltipLabel={language.TOTAL_APPROVERS}
          size={0.7}
          style={{
            color: 'white',
          }}
        />
        <Button
          variant="normal"
          label={obj.count_approved}
          color={'success'}
          size={0.7}
          tooltipLabel={language.TOTAL_APPROVED}
          style={{
            color: 'white',
          }}
        />
        <Button
          variant="normal"
          label={obj.count_reject}
          color={'danger'}
          size={0.7}
          tooltipLabel={language.TOTAL_REJECTED}
          style={{
            color: 'white',
          }}
        />
      </div>
    ),

    actions:
      (obj.removebutton !== true && obj.type_approve == 'all') ||
      obj.count_total == 0 ? (

         obj.status_ticket !== 'Resolved' &&  obj.status_ticket!== 'Closed' ?
        <div className="d-flex align-items-center justify-content-between">
          <Button
            icon=""
            variant="normal"
            color="success"
            label={language.APPROVE}
            size={0.9}
            onClick={() => {
              setOpen(true);
              setTicketObj(null);
              setObj({ ...obj, ...{ approve: true } });
            }}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 10 }}
          />

          <Button
            icon=""
            variant="normal"
            color="danger"
            label={language.REJECT}
            size={0.9}
            onClick={() => {
              setOpen(true);
              setTicketObj(null);
              setObj({ ...obj, ...{ aṕprove: false } });
            }}
            zoom={0.9}
            style={{ fontSize: 14, marginLeft: 10 }}
          />
        </div>
        :
        <div className="d-flex align-items-center justify-content-between">
        <Button
          icon=""
          variant="normal"
          color="warning"
          label={language.FINISHED_CALL}
          size={0.9}
           zoom={0.9}
          style={{ fontSize: 14, marginLeft: 10 }}
        />

      </div>
      ) : (
        <i></i>
      ),
  }));

  let col = [
    {
      key: 'id',
      label: language.ID,
      style: { width: 10, textAlign: 'center' },
    },
    { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
    {
      key: 'description',
      label: language.DESCRIPTION,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'dt_cad',
      label: language.DT_CAD,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'predecessor',
      label: language.ACTIVITY_PRD,
      style: { width: 100, textAlign: 'center' },
    },
    {
      key: 'ticketOrreuest',
      label: 'Ticket',
      style: { width: 100, textAlign: 'center' },
    },
    {
      key: 'group_users',
      label: language.APPROVING_GROUP,
      style: { width: 200, textAlign: 'center' },
    },
    {
      key: 'type_approve',
      label: language.TYPE_OF_APPROVAL,
      style: { width: 100, textAlign: 'center' },
    },
    {
      key: 'status',
      label: language.STATE,
      style: { width: 100, textAlign: 'center' },
      noOrder: true,
      search: false,
    },
    {
      key: 'actions',
      label: language.ACTIONS,
      style: { width: 40, textAlign: 'center' },
      noOrder: true,
      search: false,
    },
  ];

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.APPROVALS}
        subTitle={language.TASK_MANAGEMENT + '/'}
      >
        <Dialog
          onClose={() => setOpenStatusChange(false)}
          open={openStatusChange}
          maxWidth="lg"
          title={language.TASK_FORM}
        >
          {taskPredecessor ? (
            <div className="template template-dinamic">
              <p style={{ color: '#888080',pointerEvents: 'none' }} id={'formatasktemplate'}>
                {taskPredecessor.html
                  ? formshtml(decryp(taskPredecessor.html))
                  : ''}
              </p>
            </div>
          ) : null}
          <br></br>
        </Dialog>

        <Dialog
          PaperProps={{
            style: {
              width: '25%',
              alignItems : 'center'
            },
          }}
          open={openPredecessor}
          title={language.PREDECESSOR_TASK}
          maxWidth="xs"
          onClose={() => setOpenPredecessor(false)}
        >
          <Grid
            xs={3}
            spacing={1}
            key={Math.random()}
            style={{
              marginRight: 10,
              maxHeight: 320,
              height: 320,
              minWidth: 275,
              overflowY: 'auto',
            }}
          >
            <Paper
              className={classes.itemTask}
              style={{
                borderColor: randomcolor({
                  luminosity: 'dark',
                }),
                height: 320,
                minWidth: 275,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ color: '#B5B5B5', fontSize: 12 }}>
                  {taskPredecessor.name}
                </Typography>
              </div>
              <ButtonUI
                style={{
                  borderRadius: 10,
                  background: getBackStatus(taskPredecessor.status),
                  color: getColorStatus(taskPredecessor.status),
                }}
                onClick = {() => handleOpenStatus(taskPredecessor)}
              >
                  <span>{taskPredecessor.status}</span>
              </ButtonUI>
              <br />
              <br />

              <Divider />

              <Typography
                className={classes.textDescription}
                style={{ marginTop: 17, fontSize: 12 }}
              >
                {language.DESCRIPTION}
              </Typography>
              <div
                style={{
                  color: '#B5B5B5',
                  fontWeight: 400,
                  fontSize: 12,
                }}
              >
                {taskPredecessor.description && taskPredecessor.description.length > 200
                  ? taskPredecessor.description
                      .replace(/<[^>]*>?/gm, '')
                      .toString()
                      .substring(0, 200) + '...'
                  : taskPredecessor.description && taskPredecessor.description.replace(/<[^>]*>?/gm, '')}
              </div>
              <br />

              <Divider />
              <Typography
                className={classes.textDescription}
                style={{ marginTop: 17, fontSize: 12 }}
              >
                {taskPredecessor.fk_id_template_task > 0
                  ? language.RESPONSIBLE_GROUP
                  : language.RESPONSIBLES}
              </Typography>
              <div style={{ display: 'flex', fontSize: 12 }}>
                {taskPredecessor.fk_id_template_task > 0
                  ? taskPredecessor.grupo_tech
                  : taskPredecessor.responsible && taskPredecessor.responsible.map((user) => (
                      <Avatar
                        style={{ marginLeft: 10 }}
                        alt={user.name}
                        src={`${Constants.APIEndpoints.REQUESTS}/public/users/${user.id_user}/profileLogo.png`}
                      />
                    ))}
              </div>
              <br />
              <Divider />
              <Typography style={{ fontSize: 12 }}>
                {language.START_OF_ACTIVITY}:{' '}
                {moment(taskPredecessor.dt_start).format('DD/MM/YYYY')}{' '}
              </Typography>
            </Paper>
          </Grid>
        </Dialog>

        <Dialog
          PaperProps={{
            style: {
              height: '70%',
            },
          }}
          open={open}
          title={'Tickets'}
          maxWidth="md"
        >
          <div>
            <CommonForm
              language={language}
              fields={[
                {
                  name: 'justify',
                  label: language.REASON,
                  type: 'textEditor',
                  required: true,
                },
              ]}
              onSubmit={(v) => {
                approve(v);
              }}
              button={{
                md: 3,
                offset: { md: 9 },
                label: language.SAVE,
                primary: true,
                style: { margin: 5 },
              }}
            />
          </div>
        </Dialog>
        <Header />
        <Card className="content-min px-3">
            <Dialog title={''} open={ticketObj} onClose = {() => setTicketObj(null)} maxWidth="xl">
              <TicketManagement
                detail={true}
                id_ticket_detail={ticketObj}
                noBack
              ></TicketManagement>
            </Dialog>
          <CommonTable
            title={''}
            language={language}
            columns={col}
            data={data}
            index="TaskList"
            showHeaderToolbar
            orderColumn
            searchColumnEvent={(v) => callPageSelectFilter(0, {ticketOrreuest :v.search}, 75)}
            countTotal={countTaskApprove}
            isAutomaticPagination={false}
            beginWithNoFilter={true}
            paginationTop={false}
            searchColumn={true}
            loadSearch={true}
            currentPage={currentPage}
            callPageSelectFilter={(page, values, rowsPerPage, type) =>
              callPageSelectFilter(page, values, rowsPerPage, type)
            }
          />
        </Card>
      </PageLayout>
    </CommonPage>
  );
}
