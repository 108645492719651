import React from 'react';
import Button from '@common/Button';
import './ControllerTicket.css';

const BCT = ({label, active, onClick,id,closeDetail,refreshbt, controlButtonTheme}) => (
	<div id='ControllerTicket' onClick={onClick} className={"content-button-control-ticket "+(active?'active':'')}>
		{id != 0?<i onClick={()=>closeDetail(id)} className='fas fa-times'/>:null}
		<Button
			label={label}
			variant='normal'
			color='#01C1B4'
			onClick={refreshbt}
			style={{
				backgroundColor:active ? controlButtonTheme.active : controlButtonTheme.normal,
				boxShadow:'none'

			}}
			className='button-control-ticket'
		/>
	</div>
)
export default function({
	handleDetail,abas ,closeDetail,setLocalFiles,controlButtonTheme
}){
	 
	return(
		<div style={{overflow: 'auto', marginBottom: '0px'}} className='d-flex align-items-end'>
		    {abas&& abas.map((e,i)=>(
				<BCT key={i} closeDetail={closeDetail} controlButtonTheme={controlButtonTheme} refreshbt={()=>{handleDetail(e.id);setLocalFiles(undefined)}} id={e.id} label={e.label} active={e.active}/>
		    ))}	
		</div>
	)
}
 
 
 



