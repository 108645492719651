import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import Help from '../../common/components/Help';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ReactTable from 'react-table';
import CommonTable from '@common/CommonTable';
import { CSVLink, CSVDownload } from 'react-csv';
import Header from '@common/Header';
import CommonPage from '@common/CommonPage';
import ListColumn from '@common/ListColumn';
import './InventoryListSoftware.css'
@observer
export default class InventoryListSoftware extends CommonPage {
  constructor() {
    super();
    this.convertDate = this.convertDate.bind(this);
    this.state = {
      nome: '',
      distro: '',
      versao: '',
      kernel: '',
      hist_: [],
      page: 0
    }

    this.getHistoric = this.getHistoric.bind(this);
  }
  getHistoric(device, e) {


    this.action.execute('get', this.APIEndpoints.INVENTORY + `hist?id=${device.id_device}`, '').then(vv => {

      this.setState({ hist_: vv.data, page: 1 })

    }).catch(e => {
      this.setState({ hist_: vv.data, page: 1 })
    })



  }
  componentDidMount() {
    let { values } = this.props;

    if (values) {
      this.props.action.execute(
        'get',
        `${this.props.APIEndpoints.INVENTORY}/${values.id_inventory_collector}/mac`,
        'softwares'
      );
    }
  }

  convertDate(value) {
    var dateTemp = value + '';
    //console.log(dateTemp);
    var date =
      dateTemp.substr(6, 2) +
      '-' +
      dateTemp.substr(4, 2) +
      '-' +
      dateTemp.substr(0, 4);
    return date;
  }

  handleSocket = event => value => {
    const { socketEvents, device } = this.props;

    const target = Reflect.get(socketEvents, event);

    if (target) {
      return target(value, device.id_device);
    }
  };

  uninstallSoftware = this.handleSocket('uninstallSoftware');


  render() {
    const inv = (v) => {

      try {


        return v.substr(6, 2) + '-' + v.substr(4, 2) + '-' + v.substr(0, 4)
      } catch (e) {
        return v
      }
    }
    let { handlePage, language, device } = this.props;
    let { values } = this.props;
    let { softwares } = this.store;
    // console.log("Id do device" + this.props.device.id_device)
    // console.log(softwares);

    let columns = [
      {
        label: language.NAME,
        key: 'name', // String-based value accessors!,
        thConfig: true,
        style: { width: 600 }
      },
      {
        label: this.state.page == 0 ? language.INSTALATION_DATE : language.DATE_REMOVED,
        key: 'install_date',
        thConfig: true,
        style: { width: 300 }
      },

      {
        label: language.VERSION,
        key: 'version',
        thConfig: true,
        style: { width: 300 }
      }
      /* {
        label: language.ACTION,
        key: 'pschildname',
        thConfig:true,
        style:{width:100}
      }*/
    ];
    let data = [];
    let csvData = [];
    csvData.push(['Nome', 'Data', 'Versão']);
    if (this.state.page == 0) {
      softwares
        .filter(t => t.command == 'software')
        .map(e => {
          JSON.parse(e.value).map(e => {
            if (e.DisplayName != '' && e.DisplayName != null)
              if (data.filter(g => g.name == e.DisplayName).length == 0) {
                data.push({
                  name: e.DisplayName,
                  install_date: inv(e.InstallDate),
                  version: e.DisplayVersion
                });
              }
          });
        });

      data.map(i => {
        csvData.push([i.name, i.install_date, i.version]);
      });
    } else {

      this.state.hist_
        .map(b => {
          b.value.map(e => {
            try {

              if (e.DisplayName != '' && e.DisplayName != null)
                if (data.filter(g => g.name == e.DisplayName).length == 0) {
                  data.push({
                    name: e.DisplayName,
                    install_date: moment(b.dt_cad).format("DD-MM-YYYY"),
                    version: e.DisplayVersion
                  });
                }

            } catch (e) { }
          });
        })


    }

    this.store.softwares
      .filter(h => h.command != 'software')
      .map(g => {
        if (g.command === 'osInfo') {
          this.state.nome = g.value.hostname;
          this.state.distro = g.value.distro + ' - ' + g.value.codename;
          this.state.versao = g.value.release;
          this.state.kernel = g.value.kernel;
        }
        if (g.command === 'os') {
          if (g.value.csname != undefined) {
            this.state.nome = g.value.csname;
            this.state.distro = g.value.caption;
            this.state.versao = g.value.version;
            this.state.kernel = '';
          }
        }
      });






    return (
      <PageLayout
        icon={
          <div className="inventaryIcon" style={{ width: 40, height: 40 }} />
        }
        title={language.INVENTORY}
        subtitle={language.SOFTWARE_LIST}
        rightElements={[
          <CSVLink
            style={{ textDecoration: 'none' }}
            separator={';'}
            filename={
              'softwares-' +
              values.name +
              ' ' +
              moment()
                .format('MMDDYY')
                .toString() +
              '.csv'
            }
            enclosingCharacter={`'`}
            data={csvData}
          >
            {' '}
            <RaisedButton
              circleButton
              icon="fas fa-file-export"
              label={'Export Excel'}
              primary
              style={{
                fontSize: 15,
                paddingLeft: 4,
                marginRight: 5
              }}
            />
          </CSVLink>
        ]}
      >

        <Header>
          <div className="btnFilterHeader">
            <span className={this.state.page == 0 ? 'spanFilter_' : 'spanFilter'} onClick={() => this.setState({ page: 0 })}>
              {language.SOFTWARE_LIST}
            </span>
            <span className={this.state.page == 0 ? 'spanFilter' : 'spanFilter_'} onClick={() => this.getHistoric(device, 1)}>
              {language.CHANGE_HISTORY}
            </span>
          </div>
        </Header>

        <Card className="content">
          <ListColumn
            col={3}
            items={[
              {
                col: 4,
                label: language.COMPUTER_NAME,
                value: this.state.nome //osInfo.hostname
              },
              {
                col: 4,
                label: language.DISTRO,
                value: this.state.distro //osInfo.distro
              },
              {
                col: 2,
                label: language.VERSION,
                value: this.state.versao // osInfo.release
              },
              {
                col: 2,
                label: 'Kernel',
                value: this.state.kernel //osInfo.kernel
              }
            ]}
          />
        </Card>
        <br />

        <CommonTable
          data={data}
          columns={columns}
          index="InventoryListSoftware"
          rowsPerPageOptions={[100, 250]}
          searchColumn
          orderColumn
        />

      </PageLayout>
    );
  }
}
