import React, { useCallback, useEffect, useState } from 'react';
import AttendanceGADGrid from './AttendanceGADGrid';
import AttendanceGADCard from './AttendanceGADCard';
import classnames from 'classnames';
import Dialog from '@common/Dialog';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import InfiniteLoader from 'react-window-infinite-loader';
import Actions from '@data/Actions';
import Store from '../../../data/Store';
import DetailAttendance from '../common/DetailAttendance';

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <PerfectScrollbar
      ref={refSetter}
      style={{ ...style, overflow: 'hidden' }}
      className="pb-2"
      onScroll={onScroll}
      options={{
        minScrollbarLength: 30,
      }}
    >
      {children}
    </PerfectScrollbar>
  );
};

function CheckTypeDate(t) {
  return t.status == 'Closed' || t.status == 'Resolved' ? 'dt_up' : 'dt_cad';
}
const action = new Actions();
export default function (props) {
  const {
    index = null,
    title,
    language,
    user,
    GSD_THEME,
    status,
    APIEndpoints,
    tokenApi,
    filter,
    getDashboard,
  } = props;

  const [orderIndex, setOrderIndex] = useState(null);
  const [orderPosition, setOrderPosition] = useState(null);
  const [totalAttendances, setTotalAttendances] = useState(0);
  const [attendances, setAttendances] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortByDesc, setSortByDesc] = useState(true);
  const [reload, setReload] = useState(null);
  const [singleAttendance, setSingleAttendance] = useState([]);

  useEffect(() => {
    setSortBy(['grid_id', 'grid_date'][orderIndex]);
    setSortByDesc(orderPosition);
  }, [orderIndex, orderPosition]);

  useEffect(() => {
    setReload(filter?.reload);
    getAttendances(filter);
  }, [sortBy, sortByDesc, reload, filter]);

  async function getAttendances(filter = {}, endIndex = 20) {
    try {
      let payload = {
        statusDash: status,
        sortBy,
        sortByDesc,
        language: language.LOCATION_LANGUAGE,
        filterFind: filter.search ? filter.search : '',
        fk_id_theme: filter.fk_id_theme ? filter.fk_id_theme : 0,
        fk_id_topic: filter.fk_id_topic ? filter.fk_id_topic : 0,
        fk_id_subject: filter.fk_id_subject ? filter.fk_id_subject : 0,
        dtinicial: filter.dtinicial ? filter.dtinicial : 0,
        dtfinal: filter.dtfinal ? filter.dtfinal : 0,
        fk_id_group_attend: filter.fk_id_group_tech
          ? filter.fk_id_group_tech
          : 0,
        fk_id_user_filter: filter.user_start ? filter.user_start : 0,
        id_user: Store.getUserLoged().id,
      };

      const response = await action.execute( 'post', `${APIEndpoints.SAC_ATTENDANCE}/getAllAttendance?limit=${endIndex+1}&offset=${0}`, '', payload);

      const data = await response.data.json();
      setTotalAttendances(response.totalCount);
      setAttendances([...data]);
    } catch (error) {
      setAttendances([]);
      setTotalAttendances(0);
    }
  }

  function isItemLoaded(index) {
    return index < totalAttendances && !!attendances[index];
  }

  return (
    <div
      style={{ width: '24rem' }}
      className={classnames('pl-2 pr-2 pb-1', {
        'pl-1': index == 0,
        'pr-1': index == 7,
      })}
    >
      <AttendanceGADGrid
        title={title}
        attendances={attendances}
        totalAttendances={totalAttendances}
        DASHBOARD_THEME={GSD_THEME.columns}
        orderIndex={orderIndex}
        setOrderIndex={setOrderIndex}
        orderPosition={orderPosition}
        setOrderPosition={setOrderPosition}
      >
        {attendances.length > 0 ? (
          <AutoSizer style={{ height: 'auto', width: 'auto' }}>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalAttendances}
                loadMoreItems={getAttendances}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    itemCount={attendances.length}
                    itemSize={120}
                    width={width}
                    outerElementType={CustomScrollbarsVirtualList}
                  >
                    {({ index, style, data }) => {
                      return attendances.length > 0 ? (
                        <div
                          style={style}
                          className="content-medium py-0 d-flex align-items-center justify-content-center"
                        >
                          <AttendanceGADCard
                            user={user}
                            onClick={() => {
                              action
                                .execute(
                                  'get',
                                  APIEndpoints.SAC_ATTENDANCE +
                                    '/' +
                                    attendances[index].id_attendance_sac
                                )
                                .then((res) => {
                                  setSingleAttendance(res.data[0]);
                                });
                            }}
                            src={
                              undefined /*--passa a imagem do usuário aqui se não tiver deixa a props como undefined--*/
                            }
                            current={attendances[index].progress}
                            title={attendances[index].name_subject}
                            attendance={attendances[index]}
                            name_user={attendances[index].name_user}
                            date={moment(
                              attendances[index][
                                CheckTypeDate(attendances[index])
                              ]
                            ).format('L')}
                            version={attendances[index].id_attendance_sac}
                            progressColor={
                              attendances[index].progress > 66
                                ? 'var(--success-light)'
                                : attendances[index].progress > 33
                                ? 'var(--warning)'
                                : 'var(--danger)'
                            }
                          />
                        </div>
                      ) : (
                        <span>Carregando...</span>
                      );
                    }}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        ) : (
          <h3
            style={{
              color: 'white',
              fontWeight: 100,
              fontSize: 16,
              textAlign: 'center',
            }}
            className="mt-2"
          >
            {language.NO_ATTENDANCE}
          </h3>
        )}
      </AttendanceGADGrid>
      {singleAttendance.length === 0 ? (
        false
      ) : (
        <Dialog
          title={''}
          open={singleAttendance.length == 0 ? false : true}
          maxWidth="xl"
          onClose={() => {
            getDashboard();
            setSingleAttendance([]);
          }}
        >
          <DetailAttendance
            Attendance={singleAttendance}
            language={Store.language}
            APIEndpoints={APIEndpoints}
            Store={Store}
            setSingleAttendance={setSingleAttendance}
            listAttendace={false}
          />
        </Dialog>
      )}
    </div>
  );
}
