import React, { useState, useReducer, Fragment, useEffect, useCallback } from 'react';
import AppBar from '@common/AppBar2';
import './index.css';
import Actions from '@data/Actions';
const actions = new Actions();
import Constants from '@data/Constants';


import { Tooltip } from 'react-tippy';
/*-----------------------------common--------------------------*/
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import Animate from '@common/Animate';
import { flexBasis } from '@common/Func';
/*--------------------------material-ui--------------------------*/
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/*--------------------------pages--------------------------*/
import TagCarousel from './TagCarousel';
import Store from '@data/Store'
// import Options from './options';


const MyHeader = ({
  title,
  subTitle,
  className
}) => (
  <AppBar
    contentProps={{
      className: 'pl-0 ' + className
    }}
    titleProps={{
      style: { color: 'var(--primary)' }
    }}
    title={title}
    subTitle={subTitle}
  />
)

export default function ({
  open,
  onClose,
  options,
  values: _values = {},
  project,
  idProject,
  onSubmit,
  onDownloadFile,
  ondeletefile,
  isDuplicate = false,
  action,
  APIEndpoints
}) {
  let [form, setForm] = useReducer((state, v) => [...state, v], []);
  let [tagOptions, setTagOptions] = useState([]);
  let [tagValues, setTagValues] = useState([]);
  let [formPrimary, setFormPrimary] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [projects, setProjects] = useState([]);

  const { language } = Store;

  let values = {};
  if (!!Object.keys(_values).length) {
    values = Object.assign(
      {},
      _values,
      { _hours: _values.hours },
      { hours: `${(_values.hours || 0).toString().padStart(2, 0)}:${(_values.minutes || 0).toString().padStart(2, 0)}` },
      { activityprd: (_values.activityprd || []).map(e => e.id_project_activity) },
      { tags: (_values.tagac || []).map(e => e.id_project_tag) },
      {
        files: !_values.file ? [] : _values.file.map(f => ({
          name: f.file,
          size: f.size,
          type: f.type,
          id_gp_file: f.id_gp_file,
          id_project_activity: f.id_project_activity
        }))
      }
    );
  }

  useEffect(() => {
    let evt = window.$eventBus.on('close-modal-form-activity-project', onClose)
    if ((values.tagac || []).length) {

      setTagValues((values.tagac || []).map(e => e.id_project_tag));
    }
    setTagOptions((project.tagpj || []).map(e => ({ label: e.name, color: e.color, value: e.id_project_tag })));

    return function () {
      evt.unSubscribe();
    }
  }, []);

  useEffect(() => {
    if (!!isDuplicate) {
      actions.execute('get', Constants.APIRoot + '/api/projects', 'projects')
        .then(response => setProjects(response.data))
        .catch(error => console.log('error', error));
    }
  }, []);

  useEffect(() => {
    if (!!isDuplicate) {
      action.execute(
        'get',
        `${APIEndpoints.PROJECT}/activity/subactivity?fk_id_activity=${values.id_project_activity}`,
        'subActivities'
      );
    }

  }, []);

  const handleSubmit = (v) => {
    let dup = false;
    if (isDuplicate) {
      v.fk_id_project = projectId > 0 ? projectId : v.fk_id_project;
      delete v.id_project_activity;
      dup = true;
    }
    let dtafinal = moment(v.dt_end).format('YYMMDD')
    let dtpjinicial = moment(project.start_date).format('YYMMDD')
    let dtpjfinal = moment(project.end_date).format('YYMMDD');
    if (dtafinal < dtpjinicial || dtafinal > dtpjfinal) {
      Store.toggleAlert(true, 'Atividade fora do período do projeto ' +
        moment(project.start_date).format('DD/MM/YY') + ' à ' +
        moment(project.end_date).format('DD/MM/YY')
        , 'error')
    } else {

      onSubmit({ ...v, tags: tagOptions.filter(t => (v.tags || []).includes(t.value)) }, dup);
    }
  }

  let projectSelect = projects.length > 0 ?
    projects.map(pro => ({ value: pro.id_project, label: pro.name })) : [];

  return (
    <Dialog
      PaperProps={{
        id: 'DialogActivityForm',
      }}
      contentTitle={{
        style: { width: '100%' }
      }}
      titleContentProps={{
        style: {
          width: 'calc(100% - 60px)'
        }
      }}
      open={open}
      onClose={onClose}
      title={<div className="w-100 bar" style={{ zoom: 0.8 }} zoom={0.8}>

        <CommonForm
          individual
          values={{
            name: values.name,
          }}
          alert={false}
          onRef={r => setForm(r)}
          fields={[
            {
              type: 'text',
              name: 'name',
              label: language.ACTIVITY_NAME,
              className: 'subject-ticket',
              required: true,
            },
          ]}

          onChangeField={(f, v) => {
            formPrimary.handleValue(v, f.name)
          }}
        />

      </div>}
      maxWidth="lg"

      contentProps={{
        style: {
          background: 'white',
        },
      }}
    >

      <CommonForm
        childFields={form.flatMap(e => e.getFields().map(e => ({ name: e.name, type: e.type, required: e.required, toBase64: e.type == 'file' })))}
        onRef={r => setFormPrimary(r)}
        fixedValue={{
          fk_id_project: idProject,
        }}
        values={values}
        fields={[
          /*#######################################-Informações básicas-#############################################################3*/
          {
            type: 'element',
            content: (v, h) => (
              <div className='wrap mb-3'>
                <AppBar title={language.BASIC_INFO} />
                <CommonForm
                  onRef={r => setForm(r)}
                  values={values}
                  fields={[
                    {
                      col: 12,
                      beforeContent: (
                        <MyHeader
                          title={language.DESCRIPTION.toLocaleUpperCase()}
                          subTitle={language.LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT}
                        />
                      ),
                      type: 'textEditor',
                      name: 'description',
                      required: true
                    },
                  ]}
                  onChangeField={(f, v) => {
                    h(v, f.name);
                  }}
                />
              </div>
            )
          },
          /*#######################################-Período-#############################################################3*/
          {
            type: 'element',
            content: (v, h) => (
              <div className='wrap mb-3'>
                <AppBar title={language.PERIOD} />
                <div className='content pt-0'>
                  <CommonForm
                    onRef={r => setForm(r)}
                    values={values}
                    fields={[
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.INITIAL_DATE}
                            subTitle={language.INFORM_WHAT_WILL_BE_START_DATE_CHANGE}
                          />
                        ),
                        type: 'date',
                        name: 'dt_start',
                        label: language.INITIAL_DATE,
                        required: true
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.FINAL_DATE}
                            subTitle={language.ENTER_END_DATE_CHANGE}
                          />
                        ),
                        type: 'date',
                        name: 'dt_end',
                        label: language.FINAL_DATE,
                        required: true
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.EFFORT}
                            subTitle={language.INFORM_WHAT_WILL_BE_EFFORT_HOURCS_CARRIED}
                          />
                        ),
                        type: 'text',
                        name: 'hours',
                        label: language.EFFORT,
                        format: '##:##',
                        placeholder: 'xx:xx',
                        change(v) {
                          return v.formattedValue;
                        },
                        required: true
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.PRIORIZATION}
                            subTitle={language.SELECT_PRIORIZATION_MAKING_CHANGE}
                          />
                        ),
                        type: 'element',
                        name: 'priorization',
                        required: true,
                        content: (v, h) => (
                          <div className='d-flex aling-items-center content-stars'>
                            {[1, 2, 3].map(e => (
                              <i key={e} onClick={() => h(e, 'priorization')} className={(e <= v ? 'fas fa-star' : 'far fa-star') + ' star-btn mr-2'} />
                            ))}
                          </div>
                        )
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.WEIGHT.toLocaleUpperCase()}
                            subTitle={language.SELECT_WEIGHT_ACTIVITY}
                          />
                        ),
                        type: 'element',
                        name: 'weight',
                        required: true,
                        content: (v, h) => (
                          <div className='d-flex aling-items-center content-stars'>
                            {[1, 2, 3, 4, 5].map(e => (
                              <i key={e} onClick={() => h(e, 'weight')} className={(e <= v ? 'selected' : '') + ' fas fa-weight-hanging weight-btn mr-2'} />
                            ))}
                          </div>
                        )
                      },
                    ]}
                    onChangeField={(f, v) => {
                      h(v, f.name);
                    }}
                  />
                </div>
              </div>
            )
          },
          /*#######################################-Período-#############################################################3*/
          {
            type: 'element',
            content: (v, h) => (
              <div className='wrap mb-3'>
                <AppBar title={language.COMPL_INFO.toLocaleUpperCase()} />
                <div className='content pt-0'>
                  <CommonForm
                    onRef={r => setForm(r)}
                    values={values}
                    fields={[

                      {
                        col: 12,
                        beforeContent: (
                          <MyHeader
                            title={language.ACTIVITY_TAGS}
                            subTitle={language.CREATE_OR_ADD_TAGS_ACTIVITY}
                          />
                        ),
                        type: 'element',
                        name: 'tags',
                        required: true,
                        content: (v, h) => (
                          <TagCarousel
                            h={h}
                            onRef={r => setForm(r)}
                            tagOptions={tagOptions}
                            setTagOptions={setTagOptions}
                            tagValues={tagValues}
                            setTagValues={setTagValues}
                          />
                        )
                      }
                    ]}
                    onChangeField={(f, v) => {
                      h(v, f.name);
                    }}
                  />
                </div>
              </div>
            )
          },

        ]}
        beforeButtonElement={
          <Button
            icon='fas fa-times'
            label={language.CANCEL}
            variant='normal'
            color='danger'
            className='mr-3'
            onClick={onClose}
          />
        }


        _onSubmit={() => {
          form.forEach(e => {
            e.submit();
          })
        }}
        loading='load_activity'
        button={{ label: isDuplicate ? language.DUPLICATE : language.SAVE }}
        onSubmit={v => handleSubmit(v)}
      />
    </Dialog>
  );
}
