import React, { useCallback, useEffect, useState } from 'react';
import GSDGrid from './GSDGrid';
import GSDCard from './GSDCard';
import classnames from 'classnames';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import InfiniteLoader from 'react-window-infinite-loader';

const action = new Actions();

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <PerfectScrollbar
      ref={refSetter}
      style={{ ...style, overflow: 'hidden' }}
      className="pb-2"
      onScroll={onScroll}
      options={{
        minScrollbarLength: 30,
      }}
    >
      {children}
    </PerfectScrollbar>
  );
};

let _orderIndex = {
  0: 'id_tickets',
  1: 'dt_start_by_status',
  null: 'id_tickets',
};
function CheckTypeDate(t) {
  return t.status == 'Closed' || t.status == 'Resolved' ? 'dt_up' : 'dt_cad';
}
export default function (props) {
  const {
    index = null,
    title,
    language,
    getDashboard,
    onAdd,
    redirect,
    TicketManagement,
    RequestContainer,
    msn,
    socket,
    user,
    GSD_THEME,
    clearticket,
    priorities,
    status,
    APIEndpoints,
    tokenApi,
    filter,
    filterUserGroup,
  } = props;

  const [idTicket, setIdTicket] = useState(0);
  const [orderIndex, setOrderIndex] = useState(null);
  const [orderPosition, setOrderPosition] = useState(null);
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortByDesc, setSortByDesc] = useState(true);
  const [groupTech, setGroupTech] = useState(filter?.groupTech ?? '');
  const [filterFind, setFilterFind] = useState(filter?.search ?? '');
  const [dataInicial, setDataInicial] = useState(filter?.dtinicial ?? null);
  const [dataFinal, setDataFinal] = useState(filter?.dtfinal ?? null);
  const [reload, setReload] = useState(filter?.reload ?? null);
  const [departament, setDepartament] = useState(filter?.departament ?? null);
  const [userTech, setUserTech] = useState(filter?.userTech ?? null);
  const [serviceCatalog, setServiceCatalog] = useState(filter?.serviceCatalog ?? null);
  const [category, setCategory] = useState(filter?.category ?? null);
  const [id_complexity, setComplexity] = useState(filter?.id_complexity ?? null);

  useEffect(() => {
    setFilterFind(filter?.search);
    setGroupTech(filter?.groupTech);
    setDataInicial(filter?.dtinicial);
    setDataFinal(filter?.dtfinal);
    setReload(filter?.reload);
    setDepartament(filter?.departament);
    setUserTech(filter?.userTech);
    setServiceCatalog(filter?.serviceCatalog);
    setCategory(filter?.category);
    setComplexity(filter?.id_complexity);
  }, [filter]);

  useEffect(() => {
    getTickets();
  }, [
    sortBy,
    sortByDesc,
    filterFind,
    groupTech,
    dataInicial,
    dataFinal,
    reload,
    departament,
    userTech,
    serviceCatalog,
    category,
    id_complexity
  ]);

  useEffect(() => {
    setSortBy(['id_tickets', 'dt_cad'][orderIndex]);
    setSortByDesc(orderPosition);
  }, [orderIndex, orderPosition]);

  async function getTickets(startIndex = 0, endIndex = 20) {
    const payload = {
      filter_user_group: filterUserGroup,
      statusFilter: [status],
      sortBy,
      sortByDesc,
      language: language.LOCATION_LANGUAGE,
      filterFind,
      groupTech,
      dataInicial,
      dataFinal,
      departament,
      userTech,
      serviceCatalog,
      category,
      id_complexity
    }

    action
      .execute('post', `${APIEndpoints.TICKET}/ticketFindDashboard?limit=${endIndex + 1}&offset=${0}`, '', payload)
      .then((res) => {
        setTickets([...res.data]);
        setTotalTickets(res.totalCount);
      })
      .catch((error) => {
        console.error(error);
        setTickets([]);
        setTotalTickets(0);
      })
  }

  function isItemLoaded(index) {
    return index < totalTickets && !!tickets[index];
  }


  return (
    <div
      style={{ width: '300px' }}
      className={classnames('pl-2 pr-2 pb-1', {
        'pl-1': index == 0,
        'pr-1': index == 7,
      })}
    >
      <GSDGrid
        title={title}
        onAdd={onAdd}
        tickets={tickets}
        totalTickets={totalTickets}
        DASHBOARD_THEME={GSD_THEME.columns}
        orderIndex={orderIndex}
        setOrderIndex={setOrderIndex}
        orderPosition={orderPosition}
        setOrderPosition={setOrderPosition}
      >
        {tickets.length ? (
          <AutoSizer style={{ height: 'auto', width: 'auto' }}>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalTickets}
                loadMoreItems={getTickets}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    itemCount={tickets.length}
                    itemSize={120}
                    width={width}
                    outerElementType={CustomScrollbarsVirtualList}
                  >
                    {({ index, style, data }) =>
                      tickets.length > index ? (
                        <div
                          style={style}
                          className="content-medium py-0 d-flex align-items-center justify-content-center"
                        >
                          <GSDCard
                            msn={msn}
                            socket={socket}
                            user={user}
                            onClick={() => {
                              tickets[index].id_request
                                ? setIdTicket({
                                  id_request: tickets[index].id_request,
                                })
                                : setIdTicket({
                                  id_tickets: tickets[index].id_tickets,
                                  real_id: tickets[index].real_id,
                                });
                            }}
                            src={
                              undefined /*--passa a imagem do usuário aqui se não tiver deixa a props como undefined--*/
                            }
                            current={tickets[index].progress}
                            title={tickets[index].subject}
                            indicator={
                              tickets[index].fk_id_priority ==
                                (!!priorities.length ? priorities[2].id_table : 0)
                                ? 1
                                : tickets[index].fk_id_priority ==
                                  (!!priorities.length
                                    ? priorities[1].id_table
                                    : 0)
                                  ? 2
                                  : tickets[index].fk_id_priority ==
                                    (!!priorities.length
                                      ? priorities[0].id_table
                                      : 0)
                                    ? 3
                                    : 4
                            }
                            ticket={tickets[index]}
                            name_user={tickets[index].nametech}
                            date={moment(
                              tickets[index][CheckTypeDate(tickets[index])]
                            ).format('L')}
                            version={tickets[index].real_id}
                            progressColor={
                              tickets[index].progress > 66
                                ? 'var(--success-light)'
                                : tickets[index].progress > 33
                                  ? 'var(--warning)'
                                  : 'var(--danger)'
                            }
                          />
                        </div>
                      ) : (
                        <span>{language.LOAD}</span>
                      )
                    }
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        ) : (
          <h3
            style={{
              color: 'white',
              fontWeight: 100,
              fontSize: 16,
              textAlign: 'center',
            }}
            className="mt-2"
          >
            {language.NO_TICKETS}
          </h3>
        )}
      </GSDGrid>
      {idTicket == 0 ? (
        false
      ) : (
        <Dialog
          title={''}
          open={idTicket == 0 ? false : true}
          maxWidth="xl"
          onClose={() => {
            setIdTicket(0);
            getDashboard({
              dtfinal: dataFinal,
              dtinicial: dataInicial,
              groupTech,
              search: filterFind,
              departament,
              userTech,
              serviceCatalog,
              category,
              id_complexity
            });
            clearticket();
          }}
        >
          {' '}
          {idTicket.id_request ? (
            <RequestContainer
              id_request={idTicket.id_request}
              version={idTicket.real_id}
            ></RequestContainer>
          ) : (
            <TicketManagement
              detail={true}
              id_ticket_detail={idTicket}
            ></TicketManagement>
          )}
        </Dialog>
      )}
    </div>
  );
}
