import React, { useEffect, useState } from 'react';
import { Card, Grid, Button, Divider } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import Header from '@common/Header';
import AppBar from '@common/AppBar2';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CssBaseline, TextField, Typography } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DevicesIcon from '@material-ui/icons/Devices';
import SubjectIcon from '@material-ui/icons/Subject';
import LinearProgress from '@material-ui/core/LinearProgress';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import { Tooltip } from 'react-tippy';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import RaisedButton from '@common/RaisedButton';

import Store from '@data/Store';
import Constants from '../../../data/Constants';

import './styles.css';
import { NewTask } from '../Calendar/NewTask';
import Sla from '../../TicketManagement/Sla';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#F66868',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #DEDEDE',
    height: '100%',
  },
  textSubTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#a2a2a2',
    marginBottom: 25,
  },
  textTitle: {
    color: '#33ACA0',
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    color: '#707070',
    fontSize: 12,
  },
  spacing: {
    padding: 5,
  },
  icon: {
    color: (props) => props.color || '#019FC2',
  },
  text: {
    color: '#707070',
    textAlign: 'justify',
    marginLeft: 45,
  },
  fullHeightCard: {
    height: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemTask: {
    padding: 13,
    border: '1px solid #f3a622',
  },
  textDescription: {
    fontWeight: 'bold',
    color: '#a2a2a5',
    fontSize: 16,
  },
  subtitle_solicitation: {
    color: '#019FC2',
    fontWeight: 'bold',
  },
  button: {
    color: '#FFF',
    background: '#58A9A0',
    padding: '5px 73px',
    float: 'right',
    '&:hover:': {
      background: '#58A9A0',
    },
  },
  atend: {
    display: 'flex',
  },
}));

export function TicketTask({ match }) {
  const [ticket, setTicket] = useState(null);
  let [statusAwaiting, setStatusAwaiting] = useState([]);
  let [stopSlaParams, setstopSlaParams] = useState(false);

  let { language, host, getUserLoged, users, action, APIEndpoints } = Store;
  const classes = useStyles();
  const { id } = match.params;
  let waitingTickets = [];
  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.TICKET}/${id}`, '')
      .then((res) => {
        setTicket(res.data);
      });
  }, []);

  const reload = (dateFromChild) => {};

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.TICKET_MANAGEMENT}
        subTitle={`${language.TICKET_TITLE} / `}
        rightElements={[]}
      >
        <Header
          btnFilterProps={{
            style: { width: 150 },
          }}
          btnFilter={['DETALHES', 'HISTÓRICO DE CHAMADO']}
          //  onChange={(index) => {
          //    setPage(index);
          //  }}
          //  active={page}
        ></Header>

        {ticket && (
          <>
            <Card className="content-min px-3">
              <div>
                <Typography variant="h4" className={classes.textSubTitle}>
                  {language.BASIC_TICKET_INFORMATION}
                </Typography>
              </div>
              <div className={classes.root}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="stretch"
                >
                  <Grid item xs={6} sm={4}>
                    <Paper
                      className={classes.fullHeightCard}
                      className={classes.paper}
                    >
                      <div className="icon_title">
                        <BusinessCenterIcon
                          style={{ color: '#F09702' }}
                          className={classes.icon}
                        />
                        <Typography
                          variant="h4"
                          className={classes.textSubTitle}
                        >
                          Sensr. IT
                        </Typography>
                      </div>

                      <div className="left-45">
                        <Typography variant="h6" className={classes.textTitle}>
                          {language._CONTRACT}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.config[0].contractname}
                        </Typography>
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.DEPARTMENT}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.config[0].name_depart_}
                        </Typography>
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.REQUESTER}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.user_solicit[0].name_person}
                        </Typography>
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.CONTACT}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.user_solicit[0].phone}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Paper
                      className={classes.fullHeightCard}
                      className={classes.paper}
                    >
                      <div className="icon_title">
                        <h3 className="">
                          {ticket.type_tickets == 'Solicitation' ? (
                            <i
                              className="fas fa-user-plus"
                              style={{ color: 'var(--primary)', fontSize: 18 }}
                            />
                          ) : ticket.type_tickets == 'Problem' ? (
                            <i
                              className="fas fa-exclamation-triangle"
                              style={{ color: ' var(--danger)', fontSize: 18 }}
                            />
                          ) : (
                            <i
                              className="fas fa-bell"
                              style={{ color: 'var(--warning)', fontSize: 18 }}
                            />
                          )}
                        </h3>
                        <Typography
                          variant="h4"
                          className={classes.textSubTitle}
                        >
                          {ticket.type_tickets == 'Incident' &&
                            language.TICKET_TYPES.INCIDENT}
                          {ticket.type_tickets == 'Problem' &&
                            language.TICKET_TYPES.PROBLEM}
                          {ticket.type_tickets == 'Solicitation' &&
                            language.TICKET_TYPES.SOLICITATION}
                        </Typography>
                      </div>

                      <div className="left-45">
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.PRIORITY}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.priority}
                        </Typography>
                        <div className={classes.spacing}></div>
                        {ticket.config[0].time_total && (
                          <>
                            <Typography
                              variant="h6"
                              className={classes.textTitle}
                            >
                              {language.SERVICE_TIME}
                            </Typography>
                            <Typography
                              variant="h6"
                              className={classes.description}
                            >
                              <span>{ticket.config[0].time_total}</span>
                            </Typography>
                          </>
                        )}

                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.PROGRESS}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.config[0].progress}%{' '}
                          <BorderLinearProgress
                            variant="determinate"
                            value={ticket.config[0].progress}
                          />
                        </Typography>
                        <div className={classes.spacing}></div>

                        {ticket.status != 'Resolved' &&
                        ticket.status != 'Closed' ? (
                          <>
                            <Typography
                              variant="h6"
                              className={classes.textTitle}
                            >
                              {language.TICKET_STATUS.IN_PROGRESS}
                            </Typography>
                            <div className="container-dt-cad mt-2">
                              <span style={{ fontSize: '15px' }} id="dt_cad">
                                {ticket.config[0].stop_ticket == 0 && (
                                  <div className={classes.atend}>
                                    <PauseCircleFilledRoundedIcon
                                      style={{ color: '#33ACA0' }}
                                    />
                                    <PlayCircleOutlineRoundedIcon
                                      style={{ color: '#33ACA0' }}
                                    />
                                    <Sla
                                      not
                                      statusAwaiting={
                                        statusAwaiting[
                                          ticket.config[0].stop_ticket
                                        ]
                                          ? statusAwaiting[
                                              ticket.config[0].stop_ticket
                                            ]
                                          : undefined
                                      }
                                      t={ticket}
                                      stopSlaParams={stopSlaParams}
                                      horas={undefined}
                                      req={[
                                        {
                                          dt_up: ticket.config[0].dt_cad,
                                          dt_cad: ticket.config[0].dt_up,
                                          id_request:
                                            ticket.config[0].id_request,
                                        },
                                      ]}
                                      language={language}
                                    />
                                  </div>
                                )}
                                {ticket.status == 'Resolved' ||
                                ticket.status == 'Closed' ? (
                                  <div className="container-in-call">
                                    <Tooltip
                                      interactive
                                      html={
                                        language.TOTAL_TICKET_TIME_BETWEEN_OPENING_AND_RESOLUTION
                                      }
                                      arrow={true}
                                      position="left"
                                      theme="light"
                                    >
                                      {' '}
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          color: 'var(--success)',
                                          fontWeight: 600,
                                        }}
                                      >
                                        {' '}
                                        {language.TOTAL_TICKET_TIME}
                                      </span>
                                    </Tooltip>
                                    <span>{ticket.config[0].time_total}</span>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </span>
                            </div>
                          </>
                        ) : null}
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.REGISTRATION_DATE}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {moment(
                            ticket.config[0].dt_cad || ticket.dt_cad
                          ).format('DD/MM/YYYY HH:mm:ss')}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Paper
                      className={classes.fullHeightCard}
                      className={classes.paper}
                    >
                      <div className="icon_title">
                        <DevicesIcon
                          style={{ color: '#F09702' }}
                          className={classes.icon}
                        />
                        <Typography
                          variant="h4"
                          className={classes.textSubTitle}
                        >
                          {language.DEVICE}
                        </Typography>
                      </div>

                      <div className="left-45">
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.CATEGORY}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.name_cat}
                        </Typography>
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.CATALOG}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.name_service}
                        </Typography>
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.CATALOG_TASK}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          {ticket.config[0].name_catalog_task}
                        </Typography>
                        <div className={classes.spacing}></div>
                        <Typography variant="h6" className={classes.textTitle}>
                          {language.CONTACT}
                        </Typography>
                        <Typography
                          variant="h6"
                          className={classes.description}
                        >
                          +55 (11) 2222-2222
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.spacing}></div>
              <div className={classes.spacing}></div>
              <div className={classes.root}>
                <Grid container item spacing={3}>
                  <Grid item xs={8}>
                    <Paper className={classes.paper}>
                      <div className="icon_title">
                        <SubjectIcon
                          style={{ color: '#F09702' }}
                          className={classes.icon}
                        />
                        <Typography
                          variant="h4"
                          className={classes.textSubTitle}
                        >
                          {language.DESCRIPTION}
                        </Typography>
                      </div>
                      <Typography variant="body2" className={classes.text}>
                        <p
                          style={{ color: '#888080' }}
                          dangerouslySetInnerHTML={{
                            __html: ticket.description,
                          }}
                        />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper className={classes.paper}>
                      <div className="icon_title">
                        <DevicesIcon className={classes.icon} />
                        <Typography
                          variant="h4"
                          className={classes.textSubTitle}
                        >
                          {language.RESPONSIBLE}
                        </Typography>
                      </div>

                      <Typography variant="h6" className={classes.textTitle}>
                        {language.TECH_GROUP}
                      </Typography>
                      <Typography variant="h6" className={classes.description}>
                        {ticket.config[0].grouptech}
                        {ticket.status != 'Resolved' &&
                        ticket.status != 'Closed' &&
                        getUserLoged().role != 'solicitant' ? (
                          <RaisedButton
                            circleButton
                            color="primaryGradient"
                            label={language.CHANGE_TECH_GROUP}
                            //  onClick={() => this.modalOpen()}
                            icon="far fa-edit"
                            style={{
                              marginLeft: '10px',
                              fontSize: 14,
                              width: 25,
                              height: 25,
                            }}
                          />
                        ) : null}
                      </Typography>
                      <div className={classes.spacing}></div>
                      <Typography variant="h6" className={classes.textTitle}>
                        {language.TECH}
                      </Typography>
                      <Typography variant="h6" className={classes.description}>
                        <div>
                          {ticket.status != '' ? ticket.tech_start : null}
                          {ticket.status != 'Resolved' &&
                          ticket.status != 'Closed' &&
                          getUserLoged().role != 'solicitant' ? (
                            <Button
                              variant="circle"
                              icon="fas fa-pencil-alt"
                              color="success"
                              outlined
                              label={language.CHANGE_TECH}
                              //   onClick={() => setModaltech(true)}
                              style={{ border: 'none', fontSize: 14 }}
                            />
                          ) : null}
                        </div>
                      </Typography>
                      <div className={classes.spacing}></div>
                      <Typography variant="h6" className={classes.textTitle}>
                        {language.STATUS}
                      </Typography>
                      <Typography variant="h6" className={classes.description}>
                        {ticket.status == 'In Progress'
                          ? language.TICKET_STATUS.IN_PROGRESS
                          : ticket.status == 'Resolved'
                          ? language.TICKET_STATUS.RESOLVED
                          : ticket.status == 'Reopened'
                          ? language.TICKET_STATUS.REOPENED
                          : ticket.status == 'Approve'
                          ? language.TICKET_STATUS.IN_APPROVAL
                          : ticket.status == 'Approved'
                          ? language.TICKET_STATUS.APPROVE
                          : ticket.status == 'Open'
                          ? language.TICKET_STATUS.OPEN
                          : ticket.status == 'Closed'
                          ? language.TICKET_STATUS.CLOSED
                          : ticket.status == 'Cancelled'
                          ? language.TICKET_STATUS.CANCELLED
                          : ''}
                      </Typography>
                      <div className={classes.spacing}></div>
                      <Typography variant="h6" className={classes.textTitle}>
                        {['Closed', 'Resolved'].includes(ticket.status) == true
                          ? ''
                          : language.WAITING}
                      </Typography>
                      <Typography variant="h6" className={classes.description}>
                        <div>
                          {['Closed', 'Resolved'].includes(ticket.status) ==
                          true
                            ? ''
                            : (waitingTickets[ticket.config[0].stop_ticket] ||
                                '') +
                              ' ' +
                              (ticket.config[0].stop_ticket == 2
                                ? ticket.config[0].nameprovider
                                  ? ticket.config[0].nameprovider
                                  : ''
                                : '')}
                          {getUserLoged().role != 'solicitant' &&
                          ticket.status != 'Resolved' &&
                          ticket.status != 'Closed' ? (
                            <Button
                              variant="circle"
                              icon="fas fa-pencil-alt"
                              color="success"
                              outlined
                              onClick={(evt) => setModalWaiting(true)}
                              style={{ border: 'none', fontSize: 14 }}
                            />
                          ) : null}
                        </div>
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Card>

            <Card className="content-min px-3">
              <div className={classes.flex}>
                <Typography variant="h4" className={classes.textSubTitle}>
                  {language.TICKET_TASKS}
                </Typography>

                <NewTask taskTicket reload={reload} language={language} />
              </div>

              <Grid xs={3}>
                <Paper className={classes.itemTask}>
                  <Typography variant="h6" style={{ color: '#707070' }}>
                    {language.TASK_NAME}
                  </Typography>
                  <Button
                    style={{
                      borderRadius: 10,
                      background: '#f0971f30',
                      color: '#f0971f',
                    }}
                  >
                    {language.TYPE_SLA_STATUS_IN_PROGRESS}
                  </Button>
                  <br />
                  <br />

                  <Typography variant="h6" className={classes.textDescription}>
                    {language.DESCRIPTION}
                  </Typography>
                  <Typography variant="body1">
                    {
                      language.PERFORM_AUDIT_OF_EQUIPMENT_FOR_REPLACEMENT_UNDER_WARRANTY
                    }
                  </Typography>
                  <br />
                  <Typography
                    variant="body2"
                    className={classes.textDescription}
                  >
                    {language.DEPARTMENT}
                  </Typography>

                  <Typography
                    variant="body2"
                    className={classes.textDescription}
                  >
                    {language.RESPONSIBLES}
                  </Typography>

                  <Typography variant="body1">
                    {language.CREATED_IN}:
                  </Typography>
                </Paper>
              </Grid>
            </Card>

            <Card className="content-min px-3">
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <div className={classes.flex}>
                    <Typography variant="h4" className={classes.textSubTitle}>
                      {language.CHAT}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.flex}>
                    <Typography variant="h4" className={classes.textSubTitle}>
                      {language.SERVICE_SLA}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Card>
            <Divider />
            <Card className="content-min px-3">
              <div className={classes.flex}>
                <Typography variant="h4" className={classes.textSubTitle}>
                  {language.REQUEST_RESOLUTION}
                </Typography>
              </div>
              <Grid container>
                <Grid xs={4}>
                  <div>
                    <span className={classes.subtitle_solicitation}>
                      {language.EVOLUTION}
                    </span>
                  </div>
                </Grid>
                <Grid xs={4}>
                  <div>
                    <span className={classes.subtitle_solicitation}>
                      {language.TIME_CONSUMED}
                    </span>
                  </div>
                </Grid>
                <Grid xs={4}>
                  <div>
                    <span className={classes.subtitle_solicitation}>
                      {language.AUTOMATIC_ANSWER}
                    </span>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <span className={classes.subtitle_solicitation}>
                    {language.DESCRIPTION}
                  </span>
                </Grid>
              </Grid>
              <Button className={classes.button}>{language.SAVE}</Button>
            </Card>
          </>
        )}
      </PageLayout>
    </CommonPage>
  );
}
