import React from 'react';
import './index.css';
import { observer } from 'mobx-react';
// ----------------------------common--------------------------------------------
import CommonPage from '@common/CommonPage';
import CommonForm from '@common/CommonForm2';
import DialogDetail_ from '../../../projectmanagement/ProjectManagementPage/DialogDetail';
// ----------------------------material-ui--------------------------------------------
// ----------------------------components--------------------------------------------
import Dialog from '@common/Dialog';
import CP from '../CP';
import Button from '@common/Button';
import Block_1 from './Block_1';
import Block_2 from './Block_2';
import TicketManagement from '../../../TicketManagement/index';
import sprint from '../../Sprint/DialogForm';
import DownloadFiles from '../../../../util/DownloadFiles';
import Store from '@data/Store';

const { language } = Store;
 
// ----------------------------pages--------------------------------------------


@observer
export default class extends CommonPage {
   constructor(props) {
      super(props);
      this.state = {
         modalId: 0,
         activity: null,
         modalAc: false,
         project: null,
         modalTicket: false,
         idTicket: null,
         v: false,
         sprintkaban: undefined,
         sprintpj: [],
         sprintpjs: [],
         burn: [],
         effort_value: []
      };
      this.onDropitem = this.onDropitem.bind(this);
      this.onDrop = this.onDrop.bind(this);
      this.onAdd_ = this.onAdd_.bind(this);
      this.ondeletefile = this.ondeletefile.bind(this);
      this.UpKaban = this.UpKaban.bind(this);
      this.onFormSubmitActivityStatus = this.onFormSubmitActivityStatus.bind(
         this
      );
      this.UpdateFiles = this.UpdateFiles.bind(this);
      this.ExportSprint = this.ExportSprint.bind(this);
   }
   handleModalId = (v = 0) => {
      this.setState({ modalId: v });
   };
   onAdd_ = (type, activity, project) => {
      if (type === 1) {
         this.setState({
            modalAc: true,
            activity: activity,
            project: project,
            modalTicket: false
         });
      } else {
         this.setState({
            modalTicket: true,
            idTicket: activity,
            project: null,
            modalAc: false
         });
      }
   };
   onFormSubmitActivityStatus(valueStatus) {
      this.action
         .execute(
            'post',
            this.APIEndpoints.PROJECT + '/activityStatus',
            'milestone',
            valueStatus,
            'id_project'
         )
         .then(e => {
            this.redirect(
               `/dash/projectmanagement/sprint/${this.props.match.params.id}/kanban`
            );
         })
         .catch(err => {
            if (err.data.code == 'E019') {
               this.store.toggleAlert(
                  true,
                  language.CONCLUDES_THE_PREVIOUS_ACTIVITIES_TO_THIS,
                  'error'
               );
            }
         });
   }
   activityEdit(activity) {
      this.setState({ activity: activity, modalAc: true });
   }

   ondeletefile(id) {
      this.action.execute(
         'get',
         this.APIEndpoints.REQUESTS +
         '/deletefileproject/' +
         id.id_project_activity +
         '&' +
         id.id_gp_file +
         '',
         ''
      );
   }
   UpdateFiles(ac) {
      Array.from(ac).forEach(file => {
         const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
               if (!!reader.result) {
                  resolve(reader.result);
               } else {
                  reject(Error('Failed converting to base64'));
               }
            };
         });
         promise.then(
            result => {
               let upload = {
                  id_project_activity: ac.id_project_activity,
                  deleted: false,
                  file: result,
                  type: file.type,
                  namefile: file.name,
                  fk_id_company: 1
               };
               this.action.execute(
                  'post',
                  this.APIEndpoints.PROJECT + '/upload',
                  '',
                  upload
               );

               this.state.files = [];
               //setTimeout(function() {   this.redirect('/tech/tickets/detail', { ticket: this.props.location.state.ticket}) }.bind(this), 1000);
            },
            err => { }
         );
      });
   }

   ondonwFile(id, file) {
      DownloadFiles(this.APIEndpoints.REQUESTS + '/downloadgp/' + id + '&' + file, file)
   }
   
   handleDialog() { }
   
   onFormSubmitComments(values) {
      this.action
         .execute(
            'post',
            this.APIEndpoints.PROJECT + '/ReplyProject',
            '',
            values,
            'id_project'
         )
         .then(e => {
            this.action.execute(
               'post',
               this.APIEndpoints.PROJECT + '/projectlist',
               'pmo',
               { idproject: values.fk_id_project },
               null,
               null
            );
         });
   }

   onDropitem(e, type, id_project, gestor) {

      let ac = JSON.parse(e.milestone);
      id_project = ac.id_project;
      gestor = ac.fk_id_sponsor;

      let cond;
      if (type == 'DONE') {
         if (parseInt(ac.percent || ac.progress) < 100) {
            this.store.toggleAlert(true, language.FINISH_THE_TASK_BEFORE_MOVING_IT_TO_THE_FINISHED_COLUMN, 'error');
            return false;
         }
      }

      if (ac.usersid) {
         cond = ac.usersid.includes(this.store.getUserLoged().id);
      } else {
         cond = ac.fk_id_tech = this.store.getUserLoged().id;
      }
      if (
         cond || parseInt(this.store.getUserLoged().id) == parseInt(gestor)
      ) {

         if (parseInt(ac.percent || ac.progress) < 100) this.onDrop(ac, type);
      } else {
         this.store.toggleAlert(
            true,
            language.USER_IS_NOT_PART_OF_THE_ACTIVITY,
            'error'
         );
      }
   }

   onDrop(data, type) {
      var { pmo } = this.store;


      if (data.id_project_activity) {
         let values = {
            fk_id_milestone: data.id_project_activity,
            status: type,
            description: '',
            id_project: data.pj.id_project
         };
         this.action.execute('post', this.APIEndpoints.PROJECT + '/createStMil', 'project', values).then(e => {
            this.action.execute('post', this.APIEndpoints.PROJECT + '/sprint/kaban', '', {
               id_project_sprint: this.props.match.params.id
            }, null, null, false)
               .then(v => {
                  this.setState({ sprintkaban: [v.data] });
               });
         })
      } else {

      }




      /* this.action
         .execute(
            'post',
            this.APIEndpoints.PROJECT + '/createStMil',
            '',
            values,
            'id_project'
         )
         .then(e => {
            //this.action.execute('post', this.APIEndpoints.PROJECT + '/projectlist', 'pmo', { idproject: project }, null,
            // null);
         }); */
   }
   
   ExportSprint(id){
       console.log(id)
      this.action
      .execute('get', this.APIEndpoints.PROJECT + '/sprint/export/'+id, '', { }, null, null, false)
      .then(v => {
           console.log
         window.open(
            this.APIEndpoints.REQUESTS + '/downloadticketSprint/'+v.data.file + '/'+this.store.getUserLoged().id,
            '_blank'
          ); 

          
      });
   }

   componentWillMount() {
      /**  this.action.execute(
        'post',
        this.APIEndpoints.USER + '/techcontracts',
        'usercon_company',
        { id_user: this.store.getUserLoged().id },null, null, null
     ) */

      this.action
         .execute('post', this.APIEndpoints.PROJECT + '/sprint/sprintburn', '', {
            id_project_sprint: this.props.match.params.id
         }, null, null, false)
         .then(v => {
            this.setState({ burn: v.data });
         });


         this.action.execute(
            'get',
            this.APIEndpoints.TABLE_STRUCTURED + '/60',
            'priorities'
          );
      
          this.action.execute('get', this.APIEndpoints.GROUP, 'groups');

          this.action.execute(
            'get',
            this.APIEndpoints.USER + '/techgroups',
            'groupstech'
          );
          this.action.execute(
            'get',
            this.APIEndpoints.TABLE_STRUCTURED + '/20',
            'type_solution'
          );
          
          this.action.execute('get', this.APIEndpoints.TICKET + '/params', 'params');
            

      this.action.execute('get', this.APIEndpoints.EFFORT_VALUE, 'effort_value').then(res => {
         this.setState({ effort_value: res.data })
      });



      this.action
         .execute('get', this.APIEndpoints.PROJECT + '/sprintpjs')
         .then(v => {
            this.setState({ sprintpjs: [v.data] });
         });
      this.action
         .execute('post', this.APIEndpoints.PROJECT + '/sprinttp', ' ', {
            id_project_sprint: this.props.match.params.id
         }, null, null, false)
         .then(g => {
            this.setState({ sprintpj: [g.data] });
         });
      this.action
         .execute('post', this.APIEndpoints.PROJECT + '/sprint/kaban', '', {
            id_project_sprint: this.props.match.params.id
         }, null, null, false)
         .then(v => {
            this.setState({ sprintkaban: [v.data] });
         });






      setTimeout(() => {
         this.setState({ v: true });
      }, 4000);
      this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
   }
   UpKaban() {
      this.setState({ modalAc: false, modalTicket: false });

      this.action
         .execute('post', this.APIEndpoints.PROJECT + '/sprint/kaban', '', {
            id_project_sprint: this.props.match.params.id
         }, null, null, false)
         .then(v => {
            this.setState({ sprintkaban: [v.data] });
         });
   }

   render() {
      let { language, users, companies } = this.store;
      let { modalId, sprintkaban, sprintpj, sprintpjs } = this.state;
      let { SPRINT } = this.store.theme;
      //sprint


      window._sprintkaban = sprintkaban;
      return (
         <CP
            title={language.ACTIVITIES}
            subTitle={language.PROJECT_MANAGEMENT_SPRINT}
            rightElements={[
               <div style={{ minWidth: 500 }}>
            
               </div>
            ]}
         >
            {sprintkaban != undefined ? (
               <div id="Kanban" style={{ '--column': SPRINT.KANBAN.column }}>

                  {/*<ViewActivity open={modalId} onClose={()=>this.handleModalId()}/>*/}
                  <Block_1 language={language} sprintkaban={sprintkaban}
                     users={this.store.users}
                     burn={this.state.burn}
                     effort_value={this.state.effort_value}
                     themes={SPRINT.KANBAN.column}
                  />
                  <Block_2
                     export_={ 
                        
                     <Button
                        color="warning"
                        label={language.EXPORT}
                       style={{ marginTop: 15,marginLeft:20 ,marginBottom:10 }}
                       onClick={() => this.ExportSprint(sprintkaban[0].sprint.id_project_sprint)}
                       />}
                     redirect={this.redirect}
                     redirect2={this.redirect2}
                     sprintpjs={sprintpjs}
                     users={users}
                     companies={companies}
                     users={this.store.users}
                     onAdd_={this.onAdd_}
                     sprintpj={sprintpj}
                     action={this.action}
                     APIEndpoints={this.APIEndpoints}
                     onDropitem={this.onDropitem}
                     sprintkaban={sprintkaban}
                     handleModalId={this.handleModalId}
                     language={language}
                     themes={SPRINT.KANBAN.column}
                     setSprintKanban={v => { this.setState(v) }}
                     id_project_sprint={this.props.match.params.id}
                  />

                  {(this.state.modalAc &&
                     moment().format('YYMMDD') <=
                     moment(sprintkaban[0].sprint.dt_end).format('YYMMDD')
                  ) ?
                     (
                        <DialogDetail_
                           toggleAlert={this.store.toggleAlert}
                           subActivities={[]}
                           users={this.store.users}
                           language={language}
                           action={this.action}
                           APIEndpoints={this.APIEndpoints}
                           ondonwFile={this.ondonwFile}
                           UpdateFiles={this.UpdateFiles}
                           onFormSubmitActivityStatus={this.onFormSubmitActivityStatus}
                           project={this.state.project}
                           onFormSubmitComments={this.onFormSubmitComments}
                           ondeletefile={this.ondeletefile}
                           open={true}
                           onClose={() => {
                              this.UpKaban();
                           }}
                           activity={this.state.activity}
                        />
                     ) : null

                  }
                  {(this.state.modalTicket &&
                     moment().format('YYMMDD') <=
                     moment(sprintkaban[0].sprint.dt_end).format('YYMMDD'))
                     ? (
                        <Dialog
                           title={language.TICKET_VIEW}
                           open={true}
                           maxWidth="xl"
                           onClose={() => {
                              this.UpKaban();
                           }}
                        >
                           <TicketManagement
                              detail={true}
                              id_ticket_detail={this.state.idTicket}
                           ></TicketManagement>
                        </Dialog>
                     ) : null}
               </div>
            ) : null}
         </CP>
      );
   }
}
