import React, { useEffect, useReducer, useState } from 'react'
import Store from '@data/Store';
import Constants from '@data/Constants';
import { spFetch, useServicePortalStyles } from '../helpers';
import ServicePortalTableWrapper from './ServicePortalTableWrapper';

export default function ServicePortalTicketICS({ ticketData }) {

  const { language } = Store;
  const formatDate = 'L LT';
  const [isLoading, setIsLoading] = useReducer(v => !v, false);

  const [rows, setRows] = useState([]);

  const HEAD_COLUMNS = [
    {
      id: 'name',
      label: language.NAME,
    },
    {
      id: 'type',
      label: language.TYPE,
    },
    {
      id: 'dt_cad',
      label: language.DATE_CAD,
    },
  ]

  useEffect(() => {
    setIsLoading(true);

    const url = `${Constants.APIEndpoints.TICKET}/${ticketData.id_tickets}/ics`;

    spFetch(url, 'get', null, { responseHeaders: true })
      .then((response) => {

        const data = (response.data ?? []).map((item) => ({
          name: item.name,
          type: item.type,
          dt_cad: moment(item.dt_cad).format(formatDate),
        }))

        setRows(data);
      })
      .finally(() => setIsLoading(false));
  }, [])

  return (
    <div>
      <ServicePortalTableWrapper
        rows={rows}
        columns={HEAD_COLUMNS}
        isLoading={isLoading}
      />
    </div>
  )
}
