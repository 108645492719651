import React, { Component } from 'react';
import RaisedButton from '@common/RaisedButton';
import Dialog from '@common/Dialog';

export default class CommonDelete extends Component {
  render() {
    let { language, closeModal, onDelete, message, confirm } = this.props;

    return (
      <Dialog
        miniVersion
        modal={false}
        open={(this.props.open != undefined) ? this.props.open : true}
        title={confirm || language.CONFIRM_DELETE}
        onClose={() => closeModal()}
      >
        <h2 className='titlePattern color-lead'>{message}</h2>

        <div style={{ float: 'right' }}>
          <RaisedButton
            primary={true}
            circleButton
            icon='fas fa-trash-alt'
            label={language.DELETE}
            onClick={onDelete}
            color='danger'
            style={{ fontSize: 15 }}
          />
        </div>
      </Dialog>
    );
  }
}
