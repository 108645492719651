import { turquoise } from 'color-name';
import { observer } from 'mobx-react';
import React from 'react';

// ------------------------------------------------------------

import './index.css';
import Tech from '../reports/reportpages/gcf/Tech';
import DialogForm from '../dashboards/GSDContainer/DialogForm';
import View1 from './View1';
import View2 from './View2';

import Animate from '@common/Animate';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import CommonPage from '@common/CommonPage';
import Dialog from '@common/Dialog';
import Header from '@common/Header';
import PageLayout from '@common/PageLayout';
import Constants from '@data/Constants';

// ------------------------------------------------------------

@observer
export default class extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      day: moment().format('DD'),
      month: moment().format('MM'),
      year: moment().year(),
      values: [],
      tickets: [],
      general_parameters: [],
      dt_ini: undefined,
      dt_end: undefined,
      openRel: false,
      dialog: false,
      ds: true,
    };

    this.onFormSubmitItem = this.onFormSubmitItem.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
  }

  componentWillMount() {
    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, '')
      .then((res) => {
        this.setState({ general_parameters: res.data });
      });
    //  this.action.execute('get', this.APIEndpoints.REQUEST, 'requests');
  }

  onFormSubmitItem(values) {
    this.store.loading = true;
    this.setState({ sendFile: true });
    let filesSend = [];
    let cont = 0;

    if (values.file && values.file.length > 0) {
      let total = Array.from(values.file).length;
      Array.from(values.file).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });

        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
            this.setState({ sendFile: false });
            filesSend = [];
            this.state.files = [];
            cont = 0;
            setTimeout(
              function () {
                //8this.redirect('/dash/gsd');
              }.bind(this),
              1000
            );
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            let host = Constants.APIRoot;
            values.file = filesSend;
            let tokenApi = this.store.getToken();

            this.action
              .execute('post', `${host}/api/request/`, '', values)
              .then((results) => {
                setTimeout(
                  function () {
                    this.redirect('/dash/gsd');
                  }.bind(this),
                  1000
                );
              })
              .then((data) => {
                setTimeout(
                  function () {
                    this.redirect('/dash/gsd');
                  }.bind(this),
                  1000
                );
              });
          }
        });
      });
    } else {
      this.action
        .execute(
          'post',
          this.APIEndpoints.REQUEST,
          'requests',
          values,
          'id_request'
        )
        .then((e) => this.redirect('/dash/gsd'));

      this.setState({ values: '' });
    }

    // this.redirect('/dash/request');
  }

  createRandomToken(text) {
    const aSecret = 'Orch3str0!@forever!';
    const crypto = require('crypto');

    let cipher, tRet;

    cipher = crypto.createCipher('aes-256-cbc', aSecret);
    tRet = cipher.update(text.toString(), 'utf8', 'base64');
    tRet += cipher.final('base64');
    return tRet;
  }

  onFormSubmit(type, values, general_parameters) {
    let contador = 0;
    let camposobrigatorios = false;
    this.store.loading = true;
    let camposobrigatorioscheck = false;
    let camposobrigatoriosradio = false;
    if (
      type == '' &&
      ![true].includes(general_parameters.ticket_simplified) &&
      (values.fk_id_category == undefined ||
        values.fk_id_catalog_service == undefined ||
        values.fk_id_catalog_task == undefined)
    ) {
      this.store.toggleAlert(
        true,
        language.INFORM_ALL_FIELDS,
        'error'
      );
    } else {
      if (type === 'ticket') {
        if (values.ticketArray != undefined && values.ticketArray.length > 1) {
          values.ticketArray.forEach((num) => {
            contador++;

            let item = values.req.filter((x) => x.id_request === num)[0];

            values.fk_id_request = num;
            values.description = item.description;
            values.subject = item.subject;

            this.action
              .execute('post', this.APIEndpoints.TICKET, 'requests', values)
              .then((e) => {
                if (contador == values.ticketArray.length) {
                  this.redirect('/dash/request');
                }
              });
          });
        } else {
          if (
            values.fk_id_user_sponsor == null ||
            values.fk_id_user_sponsor <= 0
          ) {
            this.store.toggleAlert(
              true,
              language.RESPONSIBLE_NOT_REGISTERED,
              'error'
            );
          }

          this.action
            .execute('post', this.APIEndpoints.TICKET, 'requests', values)
            .then((e) => this.redirect('/dash/request'));

          //setTimeout(function () { this.setState({ page, values }) }.bind(this), 1000);
        }

        // this.redirect('/dash/request');
      } else if (type === 'reject') {
        values.values.action = 'approval';
        values.values.status = 'Rejected';

        let contador = 0;

        if (values.id_request.length > 1) {
          values.id_request.forEach((num) => {
            contador++;
            this.store.loading++;

            this.action
              .execute(
                'post',
                this.APIEndpoints.REQUEST + '/' + num,
                'requests',
                values.values
              )
              .then((e) => {
                if (contador == values.id_request.length) {
                  this.redirect('/dash/request');
                }
              });
            this.store.loading = false;
          });
        } else {
          this.action
            .execute(
              'post',
              this.APIEndpoints.REQUEST + '/' + values.id_request,
              'requests',
              values.values
            )
            .then((e) => this.redirect('/dash/request'));
        }
      } else {
        let campossize = false;
        let fields = [];
        if ($('#template').html() != undefined) {
          $('#template textarea').each(function () {
            $(this).attr('value', $(this).val());

            $(this).html($(this).val());
            fields.push({ name: $(this).attr('name'), value: $(this).val() });
          });

          $('#template input').each(function () {
            if ($(this).attr('type') == 'radio') {
              if (
                $('input[name=' + $(this).attr('name') + ']:checked').val() ==
                $(this).val()
              ) {
                $(this).attr('checked', true);
                let value = undefined;
                try {
                  value = $(this).parent().children()[1].textContent;
                } catch (e) {}
                fields.push({
                  name: $(this).attr('name'),
                  value: value || $(this).val(),
                });
              } else {
                $(this).removeAttr('checked');
              }
              let ip = $(this);

              $('#template  label').each(function () {
                var for_ = '';
                try {
                  for_ = $(this).attr('for');
                } catch (e) {}

                if (for_ === ip.attr('name')) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      camposobrigatoriosradio = true;
                      $('[name=' + for_ + ']').each(function () {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatoriosradio = false;
                          }
                        } catch (e) {}
                      });
                    }
                  }
                }
              });
            } else if ($(this).attr('type') == 'checkbox') {
              if (
                $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
                $(this).attr('id')
              ) {
                $(this).attr('checked', true);
              } else {
                $(this).removeAttr('checked');
              }
              let ip = $(this);
              $('#template  label').each(function () {
                var for_ = ' ';
                try {
                  for_ = $(this).attr('for');
                } catch (e) {}

                if (ip.attr('name').indexOf(for_) > -1) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      camposobrigatorioscheck = true;
                      $('#template  input').each(function () {
                        try {
                          if ($(this).attr('name').indexOf(for_) > -1) {
                            try {
                              if ($(this).attr('checked')) {
                                camposobrigatorioscheck = false;
                              }
                            } catch (e) {}
                          }
                        } catch (e) {}
                      });
                    }
                  }
                }
              });
            } else if ($(this).attr('type') == 'datetime-local') {
              const userLogin = Store.getUserLoged();
              const timezone = userLogin?.timezone?.value;

              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: moment($(this).val()).tz(timezone).utc().toISOString(),
              });
              $(this).attr('value', $(this).val());
            } else {
              $(this).attr('value', $(this).val());
              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: $(this).attr('value'),
              });

              let siz = $(this).attr('max');
              let min = $(this).attr('min');
              if (siz) {
                if (
                  parseInt($(this).val().length) > parseInt(siz) ||
                  parseInt($(this).val().length) < parseInt(min)
                ) {
                  $(this).css('border-color', 'red');
                  $(this).before(
                    `<label  id="formtemplabel__" style='color:red'>  ${
                      min || 0
                    } á ${siz || 0} caracteres</label>`
                  );

                  campossize = true;
                }
              }

              let ip = $(this);
              $('#template  label').each(function () {
                if ($(this).attr('for') === ip.attr('id')) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      if (ip.attr('value').length == 0) {
                        camposobrigatorios = true;
                      }
                    }
                  }
                }
              });
            }
          });

          $('#template select').each(function () {
            $('#' + $(this).children('option:selected').attr('id')).attr(
              'selected',
              'selected'
            );
            fields.push({ name: $(this).attr('name'), value: $(this).val() });
          });

          $('#template label').each(function () {
            try {
              if ($(this).attr('for').indexOf('textarea') > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    // alert($('#'+$(this).attr('for')).text())

                    let node_ = $(this)
                      .parent()
                      .parent()
                      .nextAll()
                      .slice(2, 3)
                      .children()[0];
                    if (
                      (node_ &&
                        node_.firstChild.tagName == 'P' &&
                        node_.firstChild.textContent.length == 0) ||
                      (!node_ &&
                        $('#' + $(this).attr('for')).text().length == 0)
                    ) {
                      camposobrigatorios = true;
                    }
                  }
                }
              }
            } catch (e) {}
          });

          values.template = this.createRandomToken($('#template').html());
          values.fields = fields;
        }

        setTimeout(function () {
          $('#formtemplabel__').fadeOut().empty();
          $('#template .form-control').css('border-color', '#019fc2');
        }, 9000);

        if (
          camposobrigatorios == false &&
          campossize == false &&
          camposobrigatorioscheck == false &&
          camposobrigatoriosradio == false
        ) {
          setTimeout(
            function () {
              this.onFormSubmitItem(values);
            }.bind(this),
            1000
          );
        } else {
          if (
            camposobrigatorios ||
            camposobrigatoriosradio ||
            camposobrigatorioscheck
          )
            this.store.toggleAlert(
              true,
              language.INFORM_REQUIRED_FIELDS,
              'error'
            );

          if (campossize)
            this.store.toggleAlert(
              true,
              language.CHECK_FIELD_SIZES,
              'error'
            );
        }
      }
    }
  }

  handleDialog = (n = false) => {
    this.action.execute('get', this.APIEndpoints.TASK, 'tasks');
    this.action.execute(
      'get',
      this.APIEndpoints.TASK + '/getforms',
      'formstemplate'
    );
    // this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');

    //this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');

    this.setState({ dialog: n });
  };

  handleNewRequest = () => {
    //console.log('----', this.state.general_parameters)
    if (this.state.general_parameters.request_by_portal) {
      this.redirect2('/dash/portal');
    } else {
      this.handleDialog(1);
    }
  };

  render() {
    let {
      language,
      tickets,
      projects,
      calls,
      nps,
      users,
      gmuds,
      os,
      risks,
      priorities,
      requests,
      matrizplanning,
    } = this.store;
    let { page, values } = this.state;

    const filterData = (datas) => {
      //let osfilter =tickets.filter((order)=> moment(ticket.dt_cad).format('YYYY-MM-DD') >= moment(this.state.values.dt_ini).format('YYYY-MM-DD') && moment(ticket.dt_end).format('YYYY-MM-DD') <= moment(this.state.values.dt_end).format('YYYY-MM-DD'))
      let dt_ini = moment(datas.dt_ini).format('YYYY-MM-DD');
      let dt_end = moment(datas.dt_end).format('YYYY-MM-DD');
      this.action.execute(
        'get',
        `${this.APIRoot}/api/home/tickets?dt_ini=${dt_ini}&dt_end=${dt_end}`,
        'tickets'
      );
      this.action.execute(
        'get',
        `${this.APIRoot}/api/home/tickets/priorities?dt_ini=${dt_ini}&dt_end=${dt_end}`,
        'priorities'
      );
      this.action.execute(
        'get',
        `${this.APIRoot}/api/home/projects?dt_ini=${dt_ini}&dt_end=${dt_end}`,
        'projects'
      );
      this.action.execute(
        'get',
        `${this.APIRoot}/api/home/gmud?dt_ini=${dt_ini}&dt_end=${dt_end}`,
        'gmuds'
      );
      this.action.execute(
        'get',
        `${this.APIRoot}/api/home/os?dt_ini=${dt_ini}&dt_end=${dt_end}`,
        'os'
      );
    };

    // --------------------------------------------------------------------------------------------------------------------------------
    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        {this.state.openRel == true ? (
          <Dialog
            title={''}
            open={turquoise}
            maxWidth="1000px"
            onClose={() => this.setState({ openRel: false })}
          >
            <Tech
              contracts={[]}
              value={[]}
              handleChange={() => {}}
              users={users}
              id_user={this.store.getUserLoged().id}
              companies={[]}
              language={language}
              handlePage={() => {}}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            ></Tech>
          </Dialog>
        ) : null}

        <PageLayout
          title={language.MY_EXPERIENCE}
          rightElements={[
            page != 1 ? (
              <div style={{ width: 400, marginTop: '1rem', zoom: 0.8 }}>
                <CommonForm
                  individual
                  hiddenSubmit
                  onSubmit={(e) => {
                    filterData(e);
                  }}
                  values={values}
                  fields={[
                    {
                      col: 5,
                      type: 'date',
                      name: 'dt_ini',
                      label: language.INITIAL_DATE,
                    },
                    {
                      col: 5,
                      type: 'date',
                      name: 'dt_end',
                      label: language.END_DATE,
                    },
                    {
                      col: 1,
                      type: 'element',
                      content: (v, h, submit) => (
                        <Button
                          icon="fas fa-eraser"
                          variant="circle"
                          color="primary"
                          label={language.CLEAR}
                          onClick={() => window.location.reload()}
                          style={{ marginTop: 15 }}
                        />
                      ),
                    },
                    {
                      col: 1,
                      type: 'element',
                      content: (v, h, submit) => (
                        <Button
                          icon="fas fa-search"
                          variant="circle"
                          color="success"
                          label={language.SEARCH_FOR}
                          style={{ marginTop: 15 }}
                          onClick={() => submit()}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            ) : null,
          ].filter(Boolean)}
        >
          <Animate>
            <div id="Home-Sensrit">
              <div id="header">
                <Header
                  btnFilterProps={{
                    style: { width: 217 },
                  }}
                  btnFilter={[
                    language.DASHBOARD_G,
                    language.DASHBOARD,
                    language.VALIDATIONS_APPROVALS,
                  ]}
                  onChange={(e) => {
                    this.setState({ page: e + 1 });
                  }}
                >
                  <div style={{ display: `flex` }}>
                    {this.store.getUserLoged().role != 'solicitant' ? (
                      <Button
                        color="primary"
                        label={language.TECHNICAL_REPORT}
                        onClick={() => this.setState({ openRel: true })}
                        style={{ marginTop: 15 }}
                      />
                    ) : null}

                    <Button
                      onClick={() => this.handleNewRequest()}
                      color="warning"
                      label={language.NEW_REQUEST}
                      style={{
                        fontSize: 18,
                        marginLeft: `10px`,
                        marginTop: `13px`,
                      }}
                    />
                  </div>
                </Header>
              </div>
              {page == 1 ? (
                <View1
                  language={language}
                  execute={this.action.execute}
                  APIRoot={this.APIRoot}
                  APIEndpoints={this.APIEndpoints}
                  tickets={tickets}
                  projects={projects}
                  calls={calls}
                  nps={nps}
                  requests={requests}
                  risks={risks}
                  users={users}
                  redirect={this.redirect2}
                  user={this.store.getUserLoged()}
                />
              ) : (
                <View2
                  page={page}
                  execute={this.action.execute}
                  APIRoot={this.APIRoot}
                  APIEndpoints={this.APIEndpoints}
                  tickets={tickets}
                  projects={projects}
                  gmuds={gmuds}
                  user={this.store.getUserLoged()}
                  os={os}
                  users={users}
                  action={this.action}
                  priorities={priorities}
                  day={this.state.day}
                  month={this.state.month}
                  year={this.state.year}
                  redirect={this.redirect2}
                  matrizplanning={matrizplanning}
                  requests={requests}
                  risks={risks}
                  dt_ini={this.state.dt_ini}
                  dt_end={this.state.dt_end}
                />
              )}

              {this.state.dialog == 1 ? (
                <DialogForm
                  className="block"
                  onFormSubmit={this.onFormSubmit}
                  redirect={this.store.redirect}
                  language={language}
                  categories={this.store.categories}
                  catalogs={this.store.catalogs}
                  tasks={this.store.tasks}
                  companiess={this.store.companiess}
                  formstemplate={this.store.formstemplate}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  store={this.store}
                  open={this.state.dialog == 1}
                  onClose={() => this.handleDialog()}
                  ds={this.state.ds}
                  setDs={(state) => this.setState({ ds: state })}
                />
              ) : null}
            </div>
          </Animate>
        </PageLayout>
      </CommonPage>
    );
  }
}
