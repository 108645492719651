import React, { useState, useEffect, useReducer } from 'react';
import Header from '@common/Header';
import SearchInput from '@common/SearchInput';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './style.css';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';
import Knowledge from './Knowledge';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';
import KnowledgeBase from '@images/svg-icon/KnowledgeBase';
import SolutionContainer from '../solution/SolutionContainer';
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import history2 from '@common/history2';
import { translateFromPtBR } from '@common/languages/Dictionary';

export function Dashboard({
  selectedSubcategory,
  selectedCategory,
  setSelectedCategory,
  setSelectedSubcategory,
  handleChange,
  resetCatalog,
  setResetCatalog,
  language,
  generalParams
}) {
  const action = new Actions();
  const [openForm, setOpenForm] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [values, setValues] = useState({});
  const [tasks, setTasks] = useState([]);
  const [tasks_, setTasks_] = useState([]);
  const [delay, setDelay] = useState(false);

  const [openKnowledge, setOpenKnowledge] = useState(false);
  const [templatesForm, setTemplateForms] = useState([]);


//Variáveis para o formulário
const [forms, setForms] = useState(null);

const [subdptName, setSubdptName] = useState('Subdepartamento');
const [dptName, setDptName] = useState('Departamento');
const [chieftDptName, setChieftDptName] = useState('Chefe de Departamento');
const [companyName, setCompanyName] = useState('Empresa');
let [channel, setChannel] = useState([]);
const [allDptName, setAllDptName] = useState('Departamento')
const [chiefSubDptName, setChiefSubDptName] = useState('Chefe de Subdepartamento')
const [allChiefName, setAllChieftName] = useState('Chefe de Departamento')

const initialState = {};
const [json_template_solicitation, updateState] = useReducer(
  (state, updates) => ({
    ...state,
    ...updates,
  }),
  initialState
);

  useEffect(() => {
    if (dptName != 'Departamento') {
      action
        .execute(
          'post',
          Constants.APIEndpoints.COMPANY + '/getCompanyByDptName/',
          '',
          { name: dptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setCompanyName(e.data[0].name);
            updateState({ companies_var: e.data[0].name });
          } else {
            setCompanyName('Empresa');
            updateState({ companies_var: '' });
          }
        });

      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/getChiefByDptName/',
          '',
          { name: dptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setChieftDptName(e.data[0].name);
            updateState({ chieftdpt_var: e.data[0].name });
          } else {
            setChieftDptName('Chefe de Departamento');
            updateState({ chieftdpt_var: '' });
          }
        });
    }
  }, [dptName]);


  useEffect(async () => {
    let id_catalog_task = selectedTask
      ? selectedTask.id_catalog_task
      : taskFavorite
      ? taskFavorite.id_catalog_task
      : 0;

      let template = []
      if (id_catalog_task){
       template =  await action
        .execute(
          'get',
          Constants.APIEndpoints.TASK + '/getformticket/' + id_catalog_task,
          ''
        )
        template = template.data;
        setTemplateForms(template.data);

      }
      let c = 0;

    let item = template.map((obj) => {
      c = c + 1;
      let id = 'ID' + c;
      return (
        <div className="contain">
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={id}>
              {formshtml(decryp(obj.html))}
            </p>
          </div>
          <div style={{ marginTop: '80px' }} />
          <div>
            {obj.user_up != null
              ? `${language.LAST_UPDATE}: ` +
                obj.user_up +
                '-' +
                moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
              : null}
          </div>
          <hr />
        </div>
      );
    });
    try {
      setForms(item);
    } catch (e) {}
  }, [dptName, chieftDptName, subdptName, companyName]);

  history2.listen((location, action) => {
    window.localStorage.setItem('previous_url', location.pathname);
    window.scrollTo(0, 0);
  });

  function createRandomToken(text) {
    const aSecret = 'Orch3str0!@forever!';
    try {
      const crypto = require('crypto');

      let cipher, tRet;

      cipher = crypto.createCipher('aes-256-cbc', aSecret);
      tRet = cipher.update(text.toString(), 'utf8', 'base64');
      tRet += cipher.final('base64');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  function decryp(aMsg) {
    const crypto = require('crypto');
    var aSecret = 'Orch3str0!@forever!';

    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');

    return tRet;
  }


  useEffect(() => {
    action
    .execute('get', Constants.APIEndpoints.TABLE_STRUCTURED + '/120', 'channel')
    .then((item) => {
      setChannel(item.data);
    });

  }, []);
  useEffect(() => {
    if (selectedSubcategory.task) {
      let tasks = [...selectedSubcategory.task];
      setTasks_(tasks);
    }
    setTasks(selectedSubcategory.task);
  }, [selectedSubcategory]);

  let fields = [
    {
      col: 8,
      type: 'text',
      name: 'subject',
      label: language.REQUEST_TITLE,
      required: true,
    },
    {
      col: 12,
      type: generalParams?.integration ? 'simplesTextEditor':'textEditor',
      name: 'description',
      label: language.REQUEST_DESCRIPTION,
      required : true
    },
    {
      col: 8,
      type: 'autocomplete',
      name: 'fk_id_device',
      label: language.ASSET_INVOLVED,
      textlabel: language.ASSET_INVOLVED,
      method: 'POST',
      route: `${Constants.APIEndpoints.DEVICE}/filter/`,
      routeGetCount: `${Constants.APIEndpoints.DEVICE}/filter?count=0`,
      fieldquery: 'search',
      textinit:  '',
      fieldvaluedb: 'id_device',
      fieldlabeldb: 'name',
      idinit:  '',
      routeAll: `${Constants.APIEndpoints.DEVICE}/filter?`,
      methodGetAll: 'POST',
      detail : true,
      visible: generalParams.enable_asset_involved_ticket_creation == true ? true : false
    },
    {
      col: 4,
      type: 'file',
      name: 'file',
      label: language.ATTACHMENTS,
      customPortalCss: true,
    },
  ];

  const onSubmit = () => {
    setDelay(true)
    setTimeout(() => {

      setDelay(false)

    }, 5000);
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    let campossize = false;
    let v_ = values;
    if (!v_.description) {
      return Store.toggleAlert(
        true,
        language.DESCRIPTION_CANNOT_BE_BLANK,
        'error'
      );
    }
    v_.fk_id_category = selectedSubcategory.fk_id_category
      ? selectedSubcategory.fk_id_category
      : selectedCategory.id_category;
    v_.fk_id_catalog_service = selectedSubcategory.id_catalog_service;
    v_.fk_id_catalog_task = selectedTask.id_catalog_task;
    let filesSend = [];
    let cont = 0;
    let fields=[];
    if ($('#template').html() != undefined) {
      $('#template textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({name: $(this).attr('name') ,
        value:  $(this).val() })
      });

      $('#template input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            fields.push({name: $(this).attr('name') ,
            value:  $(this).val() })

          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#template  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).is(":checked")) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) {}
                  });
                  $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({name: $(this).attr('name') ,
            value:  $(this).val() })

          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).is(":checked")) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) {}
                      }
                    } catch (e) {}
                  });
                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({name:($(this).attr('name')||$(this).attr('id')),value:$(this).attr('value')})

          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${
                  min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#template select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );

        fields.push({name:($(this).attr('name')||$(this).attr('id')),value:
        $(this).find(":selected").text()})

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (!$(this).val() || $(this).val() == "") {
              camposobrigatorios = true;
              $(this).css('border-color', 'red');
            } else {
              $(this).css('border-color', 'var(--primary)');
            }
          }
        }
      });

      $('#template label').each(function () {
        try {
          let textArea = $(this).parent().parent().nextAll().slice(2,3).children()[0]
          if (textArea && textArea.firstChild && textArea.firstChild.tagName == "P" && textArea.firstChild.textContent.length > 0){
            fields.push({name : $(this).attr('for'), value : textArea.firstChild.textContent})
          }

          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())
                let node_ = $(this).parent().parent().nextAll().slice(2,3).children()[0]
                if ((node_ && node_.firstChild.tagName == "P" && node_.firstChild.textContent.length == 0) || (!node_ && $('#' + $(this).attr('for')).text().length == 0)) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) {console.log('erro no label', e)}
      });
      // v_.template = createRandomToken($('#template').html());
      v_.template = templatesForm[0].html;
      v_.fields = fields;
    }

    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {

    if (v_.file && v_.file.length > 0) {
      let total = Array.from(v_.file).length;
      Array.from(v_.file).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });

        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
            filesSend = [];
            cont = 0;
            setTimeout(
              function () {
                //8this.redirect('/dash/gsd');
              }.bind(this),
              1000
            );
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            let host = Constants.APIRoot;
            v_.file = filesSend;

            action
              .execute('post', `${host}/api/request/`, 'requests', v_)
              .then((results) => {
                setTimeout(
                  function () {
                    //this.redirect('/dash/gsd');
                  }.bind(this),
                  1000
                );
              })
              .then((data) => {
                setTimeout(
                  function () {
                    history2.push('/dash/gsd');
                  }.bind(this),
                  1000
                );
              });
          }
        });
      });
    } else {

     action
     .execute(
       'post',
       Constants.APIEndpoints.REQUEST,
       'requests',
       v_,
       'id_request'
     )
     .then((e) => {
         history2.push('/dash/gsd');
     });

    }
  } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        Store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');

      if (campossize)
        Store.toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
      }
  };

  useEffect(() => {
    if (resetCatalog) {
      setOpenForm(false);
      setResetCatalog(false);
    }
  }, [resetCatalog]);

  const handleForm = async (task) => {
    setSelectedTask(task);
    setOpenForm(true);
    let template = await action
    .execute(
      'get',
      Constants.APIEndpoints.TASK + '/getformticket/' + task.id_catalog_task,
      ''
    )
    template = template.data
    setTemplateForms(template);

    let c = 0;
    let item = template.map((obj) => {
      c = c + 1;
      let id = 'ID' + c;

      return (
        <div className="contain">
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={id}>
              {formshtml(decryp(obj.html))}
            </p>
          </div>
          <div style={{ marginTop: '30px' }} />
          <div>
            {obj.user_up != null
              ? `${language.LAST_UPDATE}: ` +
                obj.user_up +
                '-' +
                moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
              : null}
          </div>
          <hr />
        </div>
      );
    });
    try {
      setForms(item);
    } catch (e) {
      console.log(e);
    }
  };

  const formshtml = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    /**
     * Added the timezone of the user who created the ticket.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];
    t.split('<div').map((i, key) => {
      const required = (i || '').indexOf('formbuilder-required') > -1;
      const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
      const label = labelMatch ? labelMatch[1] : undefined;
      const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;

      if( addEmptyOption && i.indexOf('<select') > -1 ) {
        i.replace(/>Selecione</, function(match) {
          let originalText = match.slice(1, -1);
          let newText = translateFromPtBR(originalText, language);
          return `>${newText}<`;
        });
      }

      if (i.indexOf('companies_var') > -1) {
        data.push(
          <div id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.COMPANY,
                  textinit: companyName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: companyName,
                  showEmptyFilter: addEmptyOption,
                  routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('allusers_var') > -1) {
        data.push(
          <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.USER,
                  textinit: '',
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Usuário',
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        );
      }else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var'
        try{
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i.match(regex)
        nameField = textWithoutTags[1].split('|')[1]
        }catch(e){}
        data.push(
          <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: label || language.HOURS,
                  type: 'time',
                  format: '##:##',
                },
              ]}
            />
          </div>
        );
      }  else if (i.indexOf('hidden_var') > -1) {
        let value_field = ''
        let name_field = ''
        try{
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i.match(regex)
        console.log('textwithoutags, t', textWithoutTags)
        name_field = textWithoutTags[1].split('|')[1]
        value_field = textWithoutTags[1].split('|')[2]
      }catch(e){}
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`
        data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('departments_var') > -1) {
        data.push(
          <div id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
                setDptName(value)
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: dptName,
                  textlabel: label || language.DEPARTMENTS,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  showEmptyFilter: addEmptyOption,
                  idinit: dptName,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('subsdpt_var') > -1) {
        data.push(
          <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
                setSubdptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: '',
                  textlabel: label || language.SUBDEPARTMENT,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: '',
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('contracts_var') > -1) {
        data.push(
          <div id="contracts_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.CONTRACT,
                  textinit: '',
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
                  disablePortal: true,
                  required,
                  showId: true,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chieftdpt_var') > -1) {
        data.push(
          <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value)
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss',
                  label: '',
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll : { chief : true},
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: chieftDptName,
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chieftDptName,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chiefsubdpt_var') > -1) {
        data.push(
          <div id="chiefsubdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chiefsubdpt_var: value });
                setChiefSubDptName(value)
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_dpt',
                  label: '',
                  textlabel: label || language.SUBDEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll : { chiefsub : true},
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: chiefSubDptName,
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chiefSubDptName,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chief_var') > -1) {
        data.push(
          <div id="chief_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
                setDptName(value)
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: dptName,
                  showEmptyFilter: addEmptyOption,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: dptName,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('departamentall_var') > -1) {
          data.push(
            <div id="departamentall_var" style={{ margin: '15px 0px 30px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ departments_var: value });
                  setDptName(value)
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'departments',
                    label: '',
                    textlabel: label || language.DEPARTMENT,
                    method: 'POST',
                    route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                    routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                    fieldquery: 'search',
                    textinit: dptName,
                    showEmptyFilter: addEmptyOption,
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: dptName,
                    routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                    methodGetAll: 'POST',
                    required,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_department',
                  },
                ]}
              />
            </div>
          );
      } else {
        if (i.length > 0) {
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }

          data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
        }
      }
    });
    return data;
  };


  const closeAll = (id_category) => {
    handleChange(id_category)(null, false);
    setSelectedCategory([]);
    setSelectedSubcategory([]);
    setTasks_([]);
  };

  const handleSearch = (search) => {
    let tasks_ = [...tasks];
    tasks_ = tasks_.filter(
      (c) => c.name.toUpperCase().indexOf(search.toUpperCase()) != -1
    );
    setTasks_(tasks_);
  };

  return (
    <div style={{ flex: 1, backgroundColor: 'white' }}>
      <Header className="justify-content-between">
        <div>
          <SearchInput
            onChange={(evt) => {
              handleSearch(evt.target.value);
            }}
          />
        </div>
        <div>
          <Button
            variant="normal"
            icon={<KnowledgeBase style={{ fill: 'white', width: 20 }} />}
            label={language.KNOWLEDGE_BASE}
            color="warning"
            fluid
            onClick={
              () => setOpenKnowledge(true) /*redirect('/dash/solution')*/
            }
          />
        </div>
      </Header>
      {Object.keys(selectedSubcategory).length > 0 && (
        <div
          className="divSingleTitleCatalog"
          onClick={() => closeAll(selectedCategory.id_category)}
          style={{ marginLeft: 5, padding: 16 }}
        >
          <div>
            <p>{language.SUBCATEGORY}</p>
            <label>{selectedSubcategory.name}</label>
          </div>
        </div>
      )}
      <Divider />
      {!openForm ? (
        <div style={{ marginTop: 10 }}>
          <label className="labelTitleContainer">
            {selectedSubcategory.id_catalog_service
              ? language.THIRD_LEVEL_CATEGORIES
              : ''}
          </label>
          <label style={{ color: 'orange', fontSize: 12 }}>
            {selectedSubcategory.id_catalog_service
              ? language.SELECT_ONE_BELOW
              : ''}
          </label>

          <div className="divListTasks">
            {tasks_.map((task) => (
              <div
                className="divSelectedSubcategoryTaskNoMultiCatalog"
                onClick={() => handleForm(task)}
              >
                <Icon style={{ color: '#F09726', fontSize: 28 }}>
                  {task.icon}
                </Icon>
                <label>{task.name}</label>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: 20,
            padding: 10,
            height: '55vh',
            maxHeight: '55vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <label className="labelTitleSelected">{selectedTask.name}</label>
          <div className="divCommonForm">
            <CommonForm fields={fields} values={values}
               onChangeField={(f, v) => {
                let v_ = values;
                v_[f.name] = v;
                setValues(v_);
              }}/>
          </div>
          {forms != null ? (
            <div
              id="template"
              style={{ marginTop: '10px' }}
              className="template"
            >
              {forms}
            </div>
          ) : null}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="normal"
              icon="fas fa-save"
              color="success"
              onClick={() => delay == false ? onSubmit():  null}
              label="Salvar"
              style={{ marginBottom: 30 }}
            />
          </div>
          <Divider />
        </div>
      )}
      <Dialog
        title={language.KNOWLEDGE_BASE}
        open={openKnowledge}
        maxWidth="lg"
        contentProps={{
          style: { marginLeft: -50, marginRight: -20 },
        }}
        onClose={() => setOpenKnowledge(false)}
      >
        <SolutionContainer company={values.fk_id_company} subcategory={values.fk_id_catalog_service} />
      </Dialog>
    </div>
  );
}
