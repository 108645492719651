import './Form.css';

import React, { useState, Fragment } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, IconButton, Stepper, Step, StepLabel } from '@material-ui/core';

import Dialog from '../../common/Dialog';
import Constants from '../../data/Constants';
import CommonForm from '../../common/CommonForm2';

import BarBlue from '@images/BarBlue.png';

import options from './options';

const MyStep = ({ activeStep, handleStep }) => (
  <Stepper
    activeStep={activeStep}
    alternativeLabel
    style={{ backgroundColor: 'transparent' }}
  >
    {['Objetivo Corporativo', 'Objetivo da TI', 'Indicador'].map((label, i) => (
      <Step
        key={label}
        onClick={() => handleStep(i)}
        style={{ cursor: 'pointer' }}
      >
        <StepLabel
          icon={
            i >= activeStep ? (
              <i
                className="fas fa-circle"
                style={{
                  color: i == activeStep ? 'var(--primary)' : '#707070',
                  fontSize: 8,
                  marginTop: 8,
                }}
              />
            ) : (
              <i className="fas fa-check" style={{ color: 'var(--primary)' }} />
            )
          }
        >
          <span style={{ fontSize: 10 }}>{label}</span>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default function ({
  open,
  onClose = () => null,
  bsc,
  onFormSubmit,
  values,
  edit,
  stepIndexProps,
  stepIndex,
  setStepIndex,
  bsctialls,
  projects,
  role,
  departments,
	language
}) {
  return (
    <Dialog open={open} onClose={onClose} title={language.ADD}>
      {stepIndex > 0 && stepIndex < 3 ? (
        <Fragment>
          <MyStep activeStep={stepIndex} handleStep={(i) => setStepIndex(i)} />
          <hr className="mb-4" />
        </Fragment>
      ) : stepIndex == 3 ? (
        <h2 style={{ color: 'var(--success)', textAlign: 'center' }}>
          {language.ALERT_INSERT_SUCCESS}
        </h2>
      ) : null}
      <CommonForm
        fixedValue={undefined}
        values={{
          id_bsc_goal_ti: values.id_bsc_goal_ti,
          ...(edit ? values : {}),
        }}
        fields={
          stepIndex == 0
            ? [
                {
                  type: 'btnGroup',
                  name: 'fk_id_bsc',
                  label: language.PERSPECTIVE,
                  options: options.perspective(language),
                },
                {
                  type: 'element',
                  content: <hr />,
                },
                {
                  type: 'name',
                  name: 'name',
                  label: language.BSC_OB,
                },
                {
                  type: 'element',
                  content: <hr className="mt-0" />,
                },
              ]
            : stepIndex == 1
            ? [
                {
                  type: 'name',
                  name: 'name_OTI',
                  label: language.BSC_TI,
                },
                {
                  name: 'fk_id_department',
                  textlabel: language.DEPARTMENT,
                  type: 'autocomplete',
                  name: 'area',
                  label: language.DEPARTMENT,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/count?`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'id_department',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/list?`,
                  methodGetAll: 'POST',
                  detail: true,
                  required: true,
                },
                {
                  type: 'element',
                  content: <hr className="mt-0" />,
                },
                {
                  type: 'date',
                  name: 'dt_meta',
                  label: language.GOAL,
                },
                {
                  type: 'element',
                  content: <hr className="mt-0" />,
                },
                {
                  type: 'element',
                  content: (
                    <Grid container spacing={16}>
                      {[
                        {
                          col: 12,
                          label: language.PERSPECTIVE,
                          value: (
                            options
                              .perspective(language)
                              .find((e) => e.value == values.fk_id_bsc) || {}
                          ).label,
                        },
                        {
                          col: 12,
                          label: language.BSC_TI,
                          value: values.name,
                        },
                      ].map((e, i) => (
                        <Grid
                          key={i}
                          item
                          xs={e.col || 3}
                          className="list-items-fields-content"
                        >
                          <span><strong>{e.label}:</strong> {e.value}</span>
                        </Grid>
                      ))}
                    </Grid>
                  ),
                },
                {
                  type: 'element',
                  content: <hr />,
                },
              ]
            : stepIndex == 2
            ? [
                {
                  type: 'select',
                  name: 'id_bsc_goal_ti',
                  label: language.BSC_TI,
                  options: options.bscGoalTi(values.fk_id_bsc, bsctialls),
                },
                {
                  type: 'element',
                  content: <hr className="mt-0" />,
                },
                {
                  type: 'btnGroup',
                  name: 'indicators',
                  label: language.INDICATORS,
                  options: [
                    { value: '1', label: language.PROJECT },
                    { value: '2', label: language.AVERAGE_SERVICE_TIME },
                    { value: '3', label: language.CATEGORIZATION_TIME },
                  ],
                },
                {
                  type: 'element',
                  content: <hr />,
                },
                {
                  type: 'element',
                  content: (
                    <Grid container spacing={16}>
                      {[
                        {
                          col: 12,
                          label: language.PERSPECTIVE,
                          value: (
                            options
                              .perspective(language)
                              .find((e) => e.value == values.fk_id_bsc) || {}
                          ).label,
                        },
                        {
                          col: 12,
                          label: language.BSC_OB,
                          value: values.name,
                        },
                        {
                          col: 12,
                          label: language.BSC_OB,
                          value: moment(values.dt_meta).format('L'),
                        },
                        {
                          col: 12,
                          label: language.BSC_TI,
                          value: values.name_OTI,
                        },
                      ].map((e, i) => (
                        <Grid
                          key={i}
                          item
                          xs={e.col || 3}
                          className="list-items-fields-content"
                        >
                          <span>{e.label}</span>
                          <span>{e.value}</span>
                        </Grid>
                      ))}
                    </Grid>
                  ),
                },
                {
                  type: 'element',
                  content: <hr />,
                },
                {
                  type: 'select',
                  name: 'fk_id_project',
                  label: language.SELECT_PROJECT,
                  options: options.projects(projects),
                },
                {
                  type: 'element',
                  content: <hr className="mt-0" />,
                },
              ]
            : []
        }
        onSubmit={(v) => onFormSubmit(v)}
        button={{
          fluid: true,
          style: { height: 40 },
          className: '-mt-3',
          icon: stepIndex == 3 ? 'fas fa-thumbs-up' : 'fas fa-save',
          label: stepIndex == 3 ? language.OK : language.SAVE,
        }}
      />
    </Dialog>
  );
}
