import React from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../../../common/CommonForm2';
import { formshtml } from '../../utils/formshtml';
import decrypt from '../../../../util/decrypt';
import crypt from '../../../../util/crypt';

const FinishAttendance = ({
  APIEndpoints,
  setOpenFinishDialog,
  action,
  Attendance,
  Store,
  setSingleAttendance,
  updateState,
  language,
  stepToShow,
}) => {
  const finishAttendance = (values) => {
    values.user = values.user = Store.getUserLoged();
    values.id_attendance_sac = Attendance.id_attendance_sac;
    values.place = 'sac_final_attendance';
    values.progress = 100;
    action
      .execute('post', APIEndpoints.SAC_ATTENDANCE + '/progress', '', values)
      .then((res) => {
        Store.toggleAlert(true, 'Dados salvos com sucesso', 'success');
        setOpenFinishDialog(false);
        setSingleAttendance(res.data[0]);
      });
  };

  const finishAttendance_ = (values) => {
    let fields = [];
    let campossize = false;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    $('#template_final').html();
    if ($('#template_final').html() != undefined) {
      $('#template_final textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#template_final input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#template_final  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).attr('checked')) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) {}
                  });
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template_final  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template_final  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) {}
                      }
                    } catch (e) {}
                  });
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${
                  min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template_final  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#template_final select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });
      });

      $('#template_final label').each(function () {
        try {
          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())

                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) {}
      });
    }
    setTimeout(function () {
      $('#formtemplabel__').fadeOut().empty();
      $('#template .form-control').css('border-color', '#019fc2');
    }, 9000);
    if ($('#template_final').html()) {
      values.template_final = crypt($('#template_final').html());
      values.html_fields_final = fields;
    }
    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      values.user = Store.getUserLoged();
      values.id_sac_steps = stepToShow.id_sac_task;
      values.place = 'sac_final_attendance';
      let files = [];
      let cont = 0;

      if (values.files && values.files.length > 0) {
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });

          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
              filesSend = [];
              cont = 0;
              setTimeout(function () {}.bind(this), 1000);
            }
            files.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              values.files = files;
              finishAttendance(values);
            }
          });
        });
      } else {
        finishAttendance(values);
      }
    } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        Store.toggleAlert(true, 'Informe dos campos obrigatórios', 'error');

      if (campossize)
        Store.toggleAlert(true, 'Verifique o tamanho dos campos', 'error');
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => setOpenFinishDialog(false)}
      title="Finalizar Atendimento"
      maxWidth="xl"
    >
      {Attendance.template_final && (
        <div className="contain">
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={'template_final'}>
              {formshtml(decrypt(Attendance.template_final), updateState)}
            </p>
          </div>
          <div style={{ marginTop: '80px' }} />
          <hr />
        </div>
      )}
      <CommonForm2
        fields={[
          {
            type: 'textEditor',
            col: 12,
            required: true,
            name: 'solution',
            label: 'Solução',
          },
          {
            type: 'select',
            col: 12,
            name: 'satisfation',
            label: 'Satisfação do Cliente',
            options: [
              { value: 'sim', label: language.YES },
              { value: 'nao', label: language.NO },
              { value: 'n/a', label: 'N/A - Cliente não avaliou' },
            ],
          },
          {
            type: 'textEditor',
            col: 12,
            required: true,
            label: 'Parecer',
            name: 'opinion',
          },
          {
            col: 12,
            type: 'file',
            name: 'files',
            label: language.ATTACH_FILE,
          },
        ]}
        onSubmit={finishAttendance_}
      />
    </Dialog>
  );
};

export default FinishAttendance;
