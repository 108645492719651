import React from 'react';
import CommonPage from '@common/CommonPage';
import List from './List';
import Detail from './Detail';
import TicketUtils from '../../util/TicketUtils';
import { observer } from 'mobx-react';
import DownloadFiles from '../../util/DownloadFiles';
import Store from '@data/Store';

const { language } = Store;
@observer
export default class Index extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      detail: 0,
      abas: [],
      tickets: [],
      requests: 0,
      loadTickets: false,
      autoloadTickets: false,
      filterStatus: [{ label: language.ALL, value: 'Todos' }],
    };
    this.openTicket = this.openTicket.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.closeDetail = this.closeDetail.bind(this);
    this.onCommentTicket = this.onCommentTicket.bind(this);
    this.createCommentTicket = this.createCommentTicket.bind(this);
    this.onNotesTicketTab = this.onNotesTicketTab.bind(this);
    this.onChangeStatus_Tab = this.onChangeStatus_Tab.bind(this);
    this.sendConnectTicketsNew = this.sendConnectTicketsNew.bind(this);
    this.updateTicketTech = this.updateTicketTech.bind(this);
    this.handleAwaitingTicket = this.handleAwaitingTicket.bind(this);
    this.onNotesTicket_1 = this.onNotesTicket_1.bind(this);
    this.onResolutionTab = this.onResolutionTab.bind(this);

    this.finishAssessment = this.finishAssessment.bind(this);
    this.UpdateFiles = this.UpdateFiles.bind(this);
    this.ondeletefile = this.ondeletefile.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.onChangeStatusJust = this.onChangeStatusJust.bind(this);
    this.onFormSubmitTab = this.onFormSubmitTab.bind(this);
    this.onFormSubmitItem = this.onFormSubmitItem.bind(this);
    this.onupdateTicket = this.onupdateTicket.bind(this);
    this.onUpdateTemplateHtml = this.onUpdateTemplateHtml.bind(this);
  }

  onChangeTicket = (v) => {
    this.action
      .execute('put', `${this.APIEndpoints.TICKET}/update/${v.id_tickets}`, '', v)
      .then((res) => {
        this.store.ticket = res.data;
      })
      .catch((error) => {
        console.error({ error });
      });
  };

  onFormSubmitItem(values, afterSave) {
    let filesSend = [];
    let cont = 0;
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }

    let total = values.files.length;
    Array.from(values.files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then((result) => {
        if (file.size >= 40000000) {
          alert(language.FILE + file.name + language.MORE_THAN_40MB);
          this.setState({ sendFile: false });
          filesSend = [];
          //this.state.files = [];
          cont = 0;
        }
        filesSend.push({
          file: result,
          type: file.type,
          namefile: file.name,
        });
        cont = cont + 1;
        if (total === cont) {
          values.file = filesSend;
          let tokenApi = this.store.getToken();

          this.action
            .execute('post', `${this.APIEndpoints.TICKET}`, '', values)
            .then((res) => {
              this.closeDetail(values.id_tickets);
              afterSave && afterSave();
            })
            .catch((error) => {
              console.error({ error });
            });
        }
      });
    });

    // this.redirect('/dash/request');
  }

  ondonwFile(id, file, type) {
    if (type == 'request') {
      DownloadFiles(
        this.APIEndpoints.REQUESTS + '/api/downloadrequest/' + id + '&' + file,
        file
      );
    } else {
      DownloadFiles(
        this.APIEndpoints.REQUESTS + '/api/downloadticket/' + id + '&' + file,
        file
      );
    }
  }

  ondeletefile(id, ticket, type) {
    if (type === 'ticket') {
      if (ticket.status != 'Resolved' && ticket.status != 'Closed') {
        this.action.execute(
          'get',
          this.APIEndpoints.REQUESTS +
            '/api/deletefileticket/' +
            id.id_tickets +
            '&' +
            id.id_tickets_file +
            '&' +
            this.store.getUserLoged().id +
            '',
          'ticket'
        )
        .catch((error) => {
          console.error({ error });
        });
      }
    } else {
      this.action
        .execute(
          'get',
          this.APIEndpoints.REQUESTS +
            '/api/deletefilerequest/' +
            0 +
            '&' +
            id.id_tickets_file,
          '',
          'filedelete'
        )
        .catch((error) => {
          console.error({ error });
        });
    }
  }

  async onChangeStatus_Tab(id_tickets, status, ticket) {
    let { language, spinner, toggleAlert } = this.store;
    if (status.action == 'resolve') {
      spinner = true;
      this.action
        .execute('post', `${this.APIEndpoints.TICKET + '/' + id_tickets}`, '', { action: 'resolve' })
        .then((res) => {
          this.store.ticket.config[0].analyze_percent =
            res.data.analyze_percent;
          this.store.ticket.config[0].problem_identification =
            res.data.problem_identification;
          this.store.ticket.config[0].solution_development =
            res.data.solution_development;
          this.store.ticket.config[0].solution_development_percent =
            res.data.solution_development_percent;
          this.store.ticket.config[0].test_percent = res.data.test_percent;
          this.store.ticket.config[0].time_analyze = res.data.time_analyze;
          this.store.ticket.config[0].problem_identification_percent =
            res.data.problem_identification_percent;
          this.store.ticket.config[0].time_problem_identification =
            res.data.time_problem_identification;
          this.store.ticket.config[0].time_solution_development =
            res.data.time_solution_development;
          this.store.ticket.config[0].time_test = res.data.time_test;
          this.store.ticket.config[0].time_total_request =
            res.data.time_total_request;
          this.store.ticket.config[0].dt_start_sheet = res.data.dt_start_sheet;
          this.store.ticket.config[0].dt_stop_sheet = res.data.dt_stop_sheet;
          this.store.ticket.config[0].enable_time_sheet =
            res.data.enable_time_sheet;
          this.store.ticket.config[0].time_sheet = res.data.time_sheet;
          this.store.ticket.config[0].progress = res.data.progress;
          this.store.ticket.config[0].fk_id_tech = res.data.fk_id_tech;
          this.store.ticket.tech_start = res.data.tech_start;
          this.store.ticket.notes = res.data.notes;
          this.store.ticket.status = res.data.status;

          this.store.toggleAlert(
            true,
            language.DATA_SENT_SUCCESSFULLY,
            'success',
          );
          spinner = false;
        })
        .catch((error) => {

          let messageError = language.ERROR_REQUEST;

          if( error.data?.id_error === "e39cf79e-0629-4160-941e-697d98b99a09" ) {
            messageError = language.USER_DOES_NOT_BELONG_TO_THE_TECHNICAL_GROUP;
          }

          spinner = false;
          toggleAlert(true, messageError, 'error');
        });
    } else {
      spinner = true;
      this.action
        .execute(
          'post',
          this.APIEndpoints.TICKET + '/' + id_tickets,
          'ticket',
          status,
        )
        .then(() => {
          this.handleDetail(id_tickets);
          spinner = false;
        })
        .catch(() => {
          spinner = false;
          toggleAlert(true, language.ERROR_REQUEST, 'error');
        });
    }
  }

  onChangeStatusJust(id_tickets, status, just, refresh) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }

    this.action
      .execute('post', this.APIEndpoints.TICKET + '/' + id_tickets, 'ticket', {
        action: status,
        justify: just,
      })
      .then((g) => {
        this.handleDetail(id_tickets);
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  sendConnectTicketsNew = (values, id_ticket) => {
    this.action
      .execute(
        'post',
        `${this.APIEndpoints.TICKET}/connect/${id_ticket}`,
        'ticket',
        values
      )
      .then((h) => {
        this.handleDetail(id_ticket);
      })
      .catch((error) => {
        console.error({ error });
      });
  };

  onNotesTicket_1(id_ticket, values, index, time) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    values.action = 'notes';
    if (index == 1) {
      values.type = 'v1';
    } else if (index == 2) {
      values.type = 'v2';
    } else if (index == 3) {
      values.type = 'v3';
    } else if (index == 4) {
      values.type = 'v4';
    }
    if (time && time != '00:00:00') {
      let h = time.split(':');
      values.time_problem_identification = moment().set({
        hour: h[0],
        minutes: h[1],
      });
      values.time_analyze = moment().set({ hour: h[0], minutes: h[1] });
      values.time_solution_development = moment().set({
        hour: h[0],
        minutes: h[1],
      });
      values.time_test = moment().set({ hour: h[0], minutes: h[1] });
    }

    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_ticket,
        'ticket',
        values
      )
      .then((h) => {
        this.handleDetail(id_ticket);
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  openTicket(id, reload, setDetail) {
    let abas = this.state.abas;
    setTimeout(() => {
      $('#descriptionTicket___ .ql-editor p ').html('');
    }, 3000);

    if (reload == undefined) {
      abas.map((g) => {
        g.active = false;
        return g;
      });
      if (abas.filter((g) => g.id == id.id_tickets).length == 0) {
        abas.push({
          id: id.id_tickets,
          label: `${id.real_id}`,
          active: true,
        });
      } else {
        abas.map((g) => {
          if (g.id == id.id_tickets) {
            g.active = true;
            return g;
          } else {
            g.active = false;
            return g;
          }
        });
      }

      if (id.id_tickets > 0) {
        this.action
          .execute(
            'get',
            this.APIEndpoints.TICKET +
              (id.historico == true ? '/history/' : '/') +
              id.id_tickets,
            'ticket'
          )
          .then((h) => {
            if (!id.real_id && this.props.id_ticket_detail) {
              abas[0].label = `${h.data.config[0].real_id}`;
            }

            if (setDetail) {
              abas[1].label = `${h.data.config[0].real_id}`;
              this.setState({ abas, detail: 1 });
              this.handleDetail(id.id_tickets);
            } else {
              this.setState({ abas, detail: 1 });
            }
          })
          .catch((error) => {
            console.error({ error });
          });
      }
    } else {
      if (abas.filter((g) => g.id == id.id_tickets).length == 0) {
        abas.push({
          id: id.id_tickets,
          label: `${id.real_id}`,
          active: false,
        });
      } else {
        abas.map((g) => {
          if (g.id == 1) {
            g.active = true;
            return g;
          } else {
            g.active = false;
            return g;
          }
        });
      }
      this.setState({ abas, detail: 2 });
    }
  }

  updateRecorrence = (t) => {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    let value = { id_tickets: t.id_tickets };
    this.action
      .execute(
        'get',
        `${this.APIEndpoints.TICKET}/updateRec/${t.id_tickets}`,
        'tickets'
      )
      .then((g) => {
        this.redirect('/dash/ticketmanagement');
      })
      .catch((error) => {
        console.error({ error });
      });
  };

  createCommentTicket(id_ticket, values, type, massSelect = false) {
    this.action
    .execute('get', this.APIEndpoints.TICKET + '/' + id_ticket)
    .then((res) => {
      const ticket = res.data;
      values.action = 'reply';
      values.id_ticket = id_ticket;
      values.id_user_sponsor = ticket.fk_id_user_sponsor;
      if (type == 'tech') {
        values.id_user = ticket.config[0].fk_id_tech;
      } else {
        values.id_user = ticket.fk_id_user;
      }
      if (type == 'tech') {
        values.action = 'reply';
        values.type = 'tech';
        this.action
          .execute(
            'post',
            this.APIEndpoints.TICKET + '/' + id_ticket,
            'id_tickets',
            values
          )
          .then((h) => {
            if (!massSelect) {
              ticket.comments.push(h.data);
              this.handleDetail(id_ticket);
            }
          })
          .catch((error) => {
            console.error({ error });
          });
      } else {
        //sou o solicitante do chamado
        if (this.store.getUserLoged().id == ticket.fk_id_user) {
          values.destino = ticket.config[0].fk_id_tech;
        } else {
          values.destino = ticket.fk_id_user;
        }

        this.store
          .$emit(
            'chat-ticket-' + this.store.getTenant,
            null,
            { ...values, type },
            'id_tickets',
            false,
            {
              btn_loading: ['ticket-chat'],
            }
          )
          .then((data) => {
            if (!massSelect) {
              if (data) {
                ticket.comments.push(data);
                this.handleDetail(id_ticket);
              }
              tickets_chat_opens.map((e) => {
                if (e.id_tickets == data.fk_id_tickets) {
                  e.comments.push(data);
                }
                return e;
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  async onCommentTicket(id_ticket, values, type) {
    if (Array.isArray(id_ticket)) {
      if (id_ticket.length > 0) {
        id_ticket.map(async (ticketId) => {
          this.createCommentTicket(ticketId, values, type, true)
        })
      }
    } else {
      this.createCommentTicket(id_ticket, values, type)
    }
  }

  onNotesTicketTab(id_ticket, _values, url, type_tickets, time, ticketarray) {
    var values = Object.assign({}, _values);

    if (ticketarray) {
      values.action = 'arraytickets';
      values.tickets = ticketarray;
      values.url = url;
      return this.action
        .execute(
          'post',
          this.APIEndpoints.TICKET + '/' + id_ticket,
          'ticket',
          values
        )
        .catch((error) => {
          console.error({ error });
        });
    } else if (type_tickets == 'comments') {
      values.action = 'notes';
      values.savecomments = true;
      this.action
        .execute(
          'post',
          this.APIEndpoints.TICKET + '/' + id_ticket,
          'ticket',
          values
        )
        .then((h) => {
          //this.handleDetail(id_ticket);
        })
        .catch((error) => {
          console.error({ error });
        });

      // this.state.md = true;
    } else if (values.fk_id_teck_new != undefined) {
      values.action = 'notes';
      this.action
        .execute('post', this.APIEndpoints.TICKET + '/' + id_ticket, '', values)
        .then((item) => {
          this.store.ticket = item.data;
        })
        .catch((error) => {
          console.error({ error });
        });

      // this.state.md = true;
    } else {
      let timeout = 0;
      if (values.aguardando && values.progress == 100) {
        timeout = 3000;
        let array = { stop_ticket: 0, action: 'notes' };
        this.action
          .execute(
            'post',
            this.APIEndpoints.TICKET + '/' + id_ticket,
            '',
            array
          )
          .then((item) => {
            if (item.data.Subtickets == true) {
              this.store.toggleAlert(
                true,
                language.FIRST_NEED_FINALIZE_SUBCALLS,
                'error'
              );
            } else {
              this.store.ticket = item.data;
            }
          })
          .catch((error) => {
            console.error({ error });
          });
      }

      setTimeout(() => {
        values.notes = values.notes;
        values.action = 'notes';
        values.url = url;
        this.action
          .execute(
            'post',
            this.APIEndpoints.TICKET + '/' + id_ticket,
            '',
            values
          )
          .then((item) => {
            if (item.data.Subtickets == true) {
              this.store.toggleAlert(
                true,
                language.FIRST_NEED_FINALIZE_SUBCALLS,
                'error'
              );
            } else {
              this.store.ticket = item.data;
            }
          })
          .catch((error) => {
            console.error({ error });
          });

        // this.setState({ needReportTime: true });
        if (values.progress == 100) {
        } else {
          values.notes = '';
          values.time = '';
        }
      }, timeout);
    }
  }

  onUpdateTemplateHtml(values) {
    const data = {
      id_tickets: values.id_tickets,
      // fk_id_catalog_task: values.fk_id_catalog_task,
      // fk_id_request: values.fk_id_request,
      // fk_id_user: user.id_user,
      fields: values.fields,
      template: values.template,
    }

    this.action
      .execute('put', this.APIEndpoints.TICKET + '/template/html', 'ticket', data)
      .catch((error) => {
        console.error({ error });
      });
  }

  onFormSubmitTab(values, afterSave) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    if (values.files && values.files.length > 0) {
      this.onFormSubmitItem(values, afterSave);
    } else {
      this.action
        .execute('post', this.APIEndpoints.TICKET, 'ticket', values)
        .then((h) => {
          if (values.update == true) {
          } else {
            afterSave && afterSave();
            this.closeDetail(values.id_tickets);
          }
        })
        .catch((error) => {
          console.error({ error });
        });
    }
  }

  componentWillMount() {
    let abas = this.state.abas;
    this.store.loading = undefined;
    let history = this.history2;
    let { pathname, state: state2 } = history.location;
    this.setState({ loadTickets: true });
    let state = this.props.location ? this.props.location.state : {};

    let { id_ticket_detail } = this.props;
    this.action
      .execute('get', this.APIEndpoints.TICKET + '/params', 'params')
      .catch((error) => {
        console.error({ error });
      });

    if (!id_ticket_detail) {
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/20',
        'type_solution'
      )
      .catch((error) => {
        console.error({ error });
      });

      this.action
        .execute('get', this.APIEndpoints.ANSWER, 'answer')
        .catch((error) => {
          console.error({ error });
        });

      this.action
        .execute('get', this.APIEndpoints.TABLE_STRUCTURED + '/60', 'priorities')
        .then((v) => {
          this.setState({ priorities: v.data });
        })
        .catch((error) => {
          console.error({ error });
        });

      this.action
        .execute('get', this.APIEndpoints.REQUEST + '/count/all', 're')
        .then((res) => {
          this.setState({ requests: res.data });
        })
        .catch((error) => {
          console.error({ error });
        });
    }

    if (!id_ticket_detail) {
      this.action
        .execute('get', this.APIEndpoints.OCCUPATION, 'occupations')
        .catch((error) => {
          console.error({ error });
        });
    }

    if (id_ticket_detail) {
      this.openTicket(id_ticket_detail);
    } else {
      abas.push({ id: 0, label: language.TICKET_MANAGEMENT, active: true });
      this.setState({ abas });
    }

    try {
      if (state.ticket.id_tickets) {
        this.openTicket(
          { id_tickets: state.ticket.id_tickets },
          undefined,
          true
        );
      }
    } catch (e) {}
    try {
      if (state.abas) {
        this.setState({ abas: state.abas });
        setTimeout(() => {
          this.openTicket(
            { id_tickets: state.id_tickets, real_id: state.real_id },
            undefined,
            true
          );
        }, 2000);
      }
    } catch (e) {}
    setTimeout(() => {
      this.setState({ loadTickets: false });
    }, 3000);
  }

  onResolutionTab(ticket, values) {
    if (values.aguardando) {
      let array = { stop_ticket: 0, action: 'notes' };
      this.action.execute(
        'post',
        this.APIEndpoints.TICKET + '/' + ticket.id_tickets,
        'ticket',
        array
      )
      .then((res)=>{})
      .catch((error) => {
        console.error({ error });
      });
    }

    values.id_tickets = ticket.id_tickets;
    values.fk_id_category = ticket.fk_id_category;
    values.time_resolution = values.time_resolution;
    values.fk_id_catalog_service = ticket.fk_id_catalog_service;
    values.subject = ticket.subject;
    values.description = '';
    values.type = undefined;
    values.type_origin = 2
    this.action
      .execute('post', this.APIEndpoints.SOLUTION, 'ticket', values)
      .then((g) => {
        //this.handleDetail(ticket.id_tickets)
      })
      .catch((error) => {
        console.error({ error });
      });
    setTimeout(
      function () {
        // this.handleDetail(ticket.id_tickets);
      }.bind(this),
      2000
    );
  }

  handleDetail = (detail, tab) => {
    setTimeout(() => {
      $('#descriptionTicket___ .ql-editor p ').html('');
    }, 3000);
    let abas = tab || this.state.abas;
    abas.map((g) => {
      if (g.id == detail) {
        g.active = true;
        return g;
      } else {
        g.active = false;
        return g;
      }
    });

    if (detail != 0) {
      this.action
        .execute('get', this.APIEndpoints.TICKET + '/' + detail, 'ticket')
        .then((e) => this.setState({ abas, detail: detail == 0 ? 0 : 1 }))
        .catch((error) => {
          console.error({ error });
        });
    }

    if (detail == 0) {
      this.setState({ abas, detail: detail == 0 ? 0 : 1 });
    }
  };

  UpdateFiles = (files, tickets) => {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }

    Array.from(files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });

      promise.then(
        (result) => {
          let upload = {
            id_tickets: tickets.id_tickets,
            deleted: false,
            file: result,
            type: file.type,
            namefile: file.name,
            fk_id_company: tickets.id_company,
          };
          this.action.execute(
            'post',
            this.APIEndpoints.TICKET + '/upload',
            'ticket',
            upload
          )
          .catch((error) => {
            console.error({ error });
          });;
          this.state.files = [];
        },
        (err) => {}
      );
    });
  };

  updateTicketTech(values, ticket) {
    let req = [];
    if (ticket.config != undefined) {
      if (ticket.config[0].dt_cad != null) {
        req = [
          {
            dt_up: ticket.config[0].dt_up,
            dt_cad: ticket.config[0].dt_cad,
            id_request: ticket.config[0].id_request,
          },
        ];
      }
    }
    values.sla_time = TicketUtils.calcSla(
      ticket,
      'ticket',
      this.props.language,
      req,
      ticket.sla_task,
      null
    ).time;
    values.action = 'notes';

    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/' + values.id_tickets,
        'ticket',
        values
      )
      .then((h) => {
        //  this.handleDetail(values.id_tickets);
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  finishAssessment(contract, note, tickets) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    screenfull.exit();
    let { assessment } = this.store;
    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT,
      'assessment',
      { tickets: tickets, path: assessment[0].path, note: note },
      null,
      'Finished the remote connection'
    )
      .catch((error) => {
        console.error({ error });
      });
    this.setState({ openassessment: false });
    //this.redirect('/dash/tickets/detail', { ticket: this.props.location.state.ticket })
  }

  closeDetail = (detail) => {
    let _abas = this.state.abas;
    let abas = _abas.filter((g) => g.id != detail);
    let abaActive = _abas.find((e) => e.active);
    if (abaActive.id == detail) {
      this.handleDetail(0, abas);
    }
    this.setState({ abas });
  };

  onupdateTicket(values) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/templateupdate',
        'ticket',
        values
      )
      .then((h) => {
        this.handleDetail(values.ticket.id_tickets);
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  handleAwaitingTicket(value, ticket, fk_id_status_kanban, time_pause_restart) {
    let array = {
      time_pause_restart: time_pause_restart ? moment(time_pause_restart).format('YYYY-MM-DD HH:mm:ss') : time_pause_restart,
      stop_ticket: value.id_waiting,
      fk_id_company_provider: value.fk_id_company_provider,
      chamado: value.chamado,
      action: 'notes',
      reason: value.reason
    };
    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/' + ticket.id_tickets,
        'ticket',
        array
      )
      .then((h) => {
        //this.handleDetail(ticket.id_tickets);
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  render() {
    let {
      language,
      ticket,
      groupstech,
      groups,
      type_solution,
      categories,
      solutions,
      redirect,
      params,
      general_parameters,
    } = this.store;
    let { TICKETS } = this.store.theme;
    let { abas } = this.state;
    let { detail, isHistory } = this.props;
    let { id_ticket_detail } = this.props;
    let integration = true;

    if( id_ticket_detail && ticket && id_ticket_detail?.id_tickets !== ticket?.id_tickets ) {
      ticket = null;
    }

    let PageDetail = (
      <Detail
        onActiveRefresh={this.props.onActiveRefresh}
        controlButtonTheme={TICKETS.controlButton}
        store={this.store}
        historico={id_ticket_detail && id_ticket_detail.historico}
        groupstech={groupstech}
        groupcheck={this.props.groupcheck}
        updateRecorrence={this.updateRecorrence}
        categories={categories}
        UpdateFiles={this.UpdateFiles}
        solutions={solutions}
        general_parameters={general_parameters}
        handleAwaitingTicket={this.handleAwaitingTicket}
        groups={groups}
        onChangeStatus_Tab={this.onChangeStatus_Tab}
        onNotesTicketTab={this.onNotesTicketTab}
        updateTicketTech={this.updateTicketTech}
        sendConnectTicketsNew={this.sendConnectTicketsNew}
        onNotesTicket_1={this.onNotesTicket_1}
        onResolutionTab={this.onResolutionTab}
        onCommentTicket={this.onCommentTicket}
        language={language}
        detail={detail}
        user={this.store.getUserLoged()}
        ticket={ticket}
        closeDetail={this.closeDetail}
        onupdateTicket={this.onupdateTicket}
        abas={abas}
        openTicket={this.openTicket}
        answer={this.store.answer}
        handleDetail={this.handleDetail}
        type_solution={type_solution}
        redirect={this.redirect}
        redirect2={this.redirect2}
        toggleAlert={this.store.toggleAlert}
        onChangeStatusJust={this.onChangeStatusJust}
        onFormSubmitTab={this.onFormSubmitTab}
        onUpdateTemplateHtml={this.onUpdateTemplateHtml}
        action={this.action}
        startAssessment={this.startAssessment}
        finishAssessment={this.finishAssessment}
        ondeletefile={this.ondeletefile}
        ondonwFile={this.ondonwFile}
        APIEndpoints={this.APIEndpoints}
        isSeeHistory={isHistory}
        noBack={this.props.noBack}
        onChangeTicket={this.onChangeTicket}
        params={params}
      />
    );

    return !id_ticket_detail ? (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect2('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        {this.state.detail == 1 && ticket ? (
          PageDetail
        ) : (
          <List
            TICKETS_THEME={TICKETS}
            abas={abas}
            onFormSubmitTab={this.onFormSubmitTab}
            toggleAlert={this.store.toggleAlert}
            redirect={this.redirect}
            onNotesTicketTab={this.onNotesTicketTab}
            openTicket={this.openTicket}
            answer={this.store.answer}
            user={this.store.getUserLoged()}
            language={language}
            closeDetail={this.closeDetail}
            handleDetail={this.handleDetail}
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            store={this.store}
            requests={this.state.requests}
            prioritiesIndex={this.state.priorities}
            integration={integration}
            params={params}
            tickets={this.state.tickets}
            setTickets={(data) => this.setState({ tickets: data })}
            loadTickets={this.state.loadTickets}
            filterStatus={this.state.filterStatus}
            setFilterStatus={(data) => this.setState({ filterStatus: data })}
            onChangeStatus_Tab={this.onChangeStatus_Tab}
            autoloadTickets={this.state.autoloadTickets}
            onCommentTicket={this.onCommentTicket}
            setAutoloadTickets={(data) =>
              this.setState({ autoloadTickets: data })
            }
          />
        )}
      </CommonPage>
    ) : (
      ticket && PageDetail
    );
  }
}
