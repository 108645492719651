import React, { Fragment, memo } from 'react';
import './index.css';
//--------------------------common----------------------------
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Progress from '@common/Progress';
import Button from '@common/Button';
//--------------------------material-ui----------------------------
import { Checkbox } from '@material-ui/core';
import Store from '@data/Store';

const { language } = Store;

export default memo(({ setViewProjectsId, viewProjectsId, onClose, itens, addItem, isBacklog }) => {
	//------ os dados abaixo devem ser buscando de acordo com o id do projeto (viewProjectsId)
	//------ por favor, fazer todas as requisições nessa página


	let data = [];

	itens.actitivies && !isBacklog && itens.actitivies.filter(b => b.id_project === viewProjectsId)
		.map(g => {
			data.push({
				check: <Checkbox onChange={() => addItem(g)} />,
				id: g.id_project_activity,
				activity: g.name,
				sponsor: g.envolvedac,
				progress: (
					<div className='ml-3 d-flex align-items-center content-progress-table'>
						<Progress background='var(--warning)' current={g.percent} hiddenText style={{ height: 7, width: '100%' }} />
						<span>{g.percent}%</span>
					</div>
				),
				status: <div className='status-indicator'>{g.stage} </div>,
				tags: <p style={{ maxWidth: 200 }}
					dangerouslySetInnerHTML={{ __html: g.namegroup }}
				/>,
				goals: moment(g.dt_start).format('DD-MM-YY')
			})
		})

	itens.actitivies && isBacklog && itens.actitivies.filter(b => [null, 'BACKLOG'].includes(b.stage))
		.map(g => {
			data.push({
				check: <Checkbox onChange={() => addItem(g)} />,
				id: g.id_project_activity,
				activity: g.name,
				sponsor: g.envolvedac,
				progress: (
					<div className='ml-3 d-flex align-items-center content-progress-table'>
						<Progress background='var(--warning)' current={g.percent} hiddenText style={{ height: 7, width: '100%' }} />
						<span>{g.percent}%</span>
					</div>
				),
				status: <div className='status-indicator'>BACKLOG</div>,

				tags: <p style={{ maxWidth: 200 }}
					dangerouslySetInnerHTML={{ __html: g.namegroup }}
				/>,
				goals: moment(g.dt_start).format('DD-MM-YY')
			})
		})



	return (
		<CommonTable
			className='AssociateActivityTable'
			maxHeight={500}
			searchColumn
			orderColumn
			paginationTop={false}
			beforeBar={
				<Button
					icon='fas fa-arrow-left'
					variant='circle'
					color='light'
					outlined
					style={{
						border: 'none'
					}}
					className='mr-3'
					onClick={() => { setViewProjectsId(0); setIsBacklog(false) }}
				/>
			}
			afterBar2={
				<Button
					label={language.CLOSE}
					icon='fas fa-times'
					color='#ffffff'
					variant='circle'
					outlined
					style={{
						border: 'none',
						color: '#ffffff'
					}}
					onClick={onClose}
				/>
			}
			AppBarProps={{
				style: {
					borderRadius: '10px 10px 0 0'
				}
			}}
			col={[
				{ key: 'check', label: '' },
				{ key: 'id', label: language.ID },
				{ key: 'activity', label: language.ACTIVITIES },
				{ key: 'sponsor', label: language.RESPONSIBLE },
				{ key: 'progress', label: language.PROGRESS_ },
				{ key: 'status', label: language.STATE },
				{ key: 'tags', label: language.TAG },
				{ key: 'goals', label: language.GOAL },
			]}
			data={data}
			footer={
				isBacklog ? (
					<Fragment>
						<hr />
						<div className='d-flex align-items-center justify-content-end pb-3 pr-3'>
							<Button
								label={language.CANCEL}
								color='danger'
								variant='normal'
								className='mr-3'
								onClick={onClose}
							/>
							<Button
								label={language.SAVE}
								color='success'
								variant='normal'
								onClick={onClose}
							/>
						</div>
					</Fragment>
				) : null
			}
		/>
	)
})
