import React, { useEffect, useReducer, useState } from 'react'
import Store from '@data/Store';
import Constants from '@data/Constants';
import { spFetch, useServicePortalStyles } from '../helpers';
import ServicePortalTableWrapper from './ServicePortalTableWrapper';

export default function ServicePortalTicketSlaHistory({ ticketData }) {

  const { language } = Store;
  const formatDate = 'L LT';
  const [isLoading, setIsLoading] = useReducer(v => !v, false);

  const [rows, setRows] = useState([]);

  const HEAD_COLUMNS = [
    { id: 'sh_active', label: language.CMDB.ASSET },
    { id: 'sla_name', label: language.SLA_NAME },
    { id: 'sh_condition', label: language.CONDITION },
    { id: 'sh_prev', label: language.ENTRY_DATE },
    { id: 'sh_stopped', label: language.STOP_DATE },
    { id: 'sh_next', label: language.EXPIRY_DATE },
    { id: 'sh_ended', label: language.COMPLETION_DATE },
    { id: 'sh_broken', label: language.SLA_BROKEN_OUT },
  ];

  useEffect(() => {
    setIsLoading(true);

    const urlTicketSla = `${Constants.APIEndpoints.TICKET}/sla/${ticketData.id_tickets}`;

    spFetch(urlTicketSla, 'GET', null, { responseHeaders: true })
      .then((response) => {

        const data = (response.data ?? []).map(item => ({
          sh_active: item.sh_active ? language.YES : language.NOT,
          sla_name: item.sla_name,
          sh_condition: getSlaType(item.sh_condition, ticketData.status),
          sh_prev: moment(item.sh_prev).format(formatDate),
          sh_stopped: item.sh_stopped ? moment(item.sh_stopped).format(formatDate) : '',
          sh_next: moment(item.sh_next).format(formatDate),
          sh_ended: item.sh_ended ? moment(item.sh_ended).format(formatDate) : '',
          sh_broken: item.sh_broken ? language.YES : language.NOT,
        }));

        setRows(data);
      })
      .finally(() => setIsLoading(false));
  }, [])


  const getSlaType = (cond, status) => {
    let st = cond;
    if (cond == 'start') {
      st = status === 'In Progress' ? language.TYPE_SLA_STATUS_IN_PROGRESS : language.TYPE_SLA_STATUS_START;
    } else if (cond == 'pause') {
      st = language.TYPE_SLA_STATUS_PAUSE;
    } else if (cond == 'restart') {
      st = language.TYPE_SLA_STATUS_IN_PROGRESS;
    } else if (cond == 'stop') {
      st = language.TYPE_SLA_STATUS_CONCLUDED;
    } else if (cond == 'cancel') {
      st = language.TYPE_SLA_STATUS_CANCELED;
    }

    return st;
  };

  return (
    <div>
      <ServicePortalTableWrapper
        rows={rows}
        columns={HEAD_COLUMNS}
        isLoading={isLoading}
      />
    </div>
  )
}
