import React, {Fragment, memo, useEffect, useState} from 'react';
import './index.css';
//--------------------------common----------------------------
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Progress from '@common/Progress';
import Button from '@common/Button';
import Store from '@data/Store';
//--------------------------material-ui----------------------------
import {Checkbox} from '@material-ui/core';

const language = Store.language;

export default memo(({setViewProjectsId, viewProjectsId, onClose,onSave, action, APIEndpoints, users})=>{
	//------ os dados abaixo devem ser buscando de acordo com o id do projeto (viewProjectsId)
	//------ por favor, fazer todas as requisições nessa página
	 const [activities, setActivity] = useState([]);
	 const [id, setId] = useState(0);
	 useEffect(() => {
		if (viewProjectsId > 0) {
			action.execute('get', APIEndpoints.PROJECT + `/activityallPro/${viewProjectsId}`)
				.then(ac => {
					setActivity(ac.data)
				})
		}
	 }, [viewProjectsId]);

	 let data=[];

	 activities.length && activities.filter(x => !x.deleted && parseInt(x.percent) < 100).map(g=>{
		let sponsor = ''
		try {
			sponsor = users.find(x => x.value == g.id_user_activity).label
		} catch (error) {

		}

		data.push({
			check:
			<Checkbox
				onChange={()=> setId(prev => prev === g.id_project_activity ? 0 : g.id_project_activity)}
				checked={id === g.id_project_activity}
			/>,
			id: g.id_project_activity,
			activity: g.name,
			sponsor:sponsor,
			progress:(
				<div className='ml-3 d-flex align-items-center content-progress-table'>
					<Progress background='var(--warning)' current={g.percent} hiddenText style={{height:7, width:'100%'}}/>
					<span>{g.percent}%</span>
				</div>
			),
			 status:<div className='status-indicator'>{(g.stage=="0" || g.stage==null)?"TODO":g.stage} </div>,
			 goals:moment(g.dt_start).format('DD-MM-YY')
		})})


	return(
		<CommonTable
			className='AssociateActivityTable'
			maxHeight={500}
			paginationTop={false}
			beforeBar={
				<Button
					icon='fas fa-arrow-left'
					variant='circle'
					color='light'
					outlined
					style={{
						border:'none'
					}}
					className='mr-3'
					onClick={()=>setViewProjectsId(0)}
				/>
			}
			afterBar2={
				<Button
					label={language.CLOSE}
					icon='fas fa-times'
					color='#ffffff'
					variant='circle'
					outlined
					style={{
						border:'none',
						color:'#ffffff'
					}}
					onClick={onClose}
				/>
			}
			AppBarProps={{
				style:{
					borderRadius:'10px 10px 0 0'
				}
			}}
			col={[
				{key:'check', label:''},
				{key:'id', label:language.ID},
                {key:'activity', label:language.ACTIVITY},
				{key:'sponsor', label:language.RESPONSIBLE},
				{key:'progress', label:language._PROGRESS},
				{key:'status', label:language.STATE},
				 {key:'goals', label:language.GOAL},
			]}
			data={data}
			footer={
				<Fragment>
					<hr/>
					<div className='d-flex align-items-center justify-content-end pb-3 pr-3'>
						<Button
							label={language.CANCEL}
							color='danger'
							variant='normal'
							className='mr-3'
							onClick={onClose}
						/>
						<Button
							label={language.SAVE}
							color='success'
							variant='normal'
							onClick={() => onSave({
								value: id,
								type: 'activity'
							})}
							disabled={id === 0}
						/>
					</div>
				</Fragment>
			}
		/>
	)
})
