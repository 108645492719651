import React, { useState } from 'react';
/*-------------------------------common--------------------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import PieChart from '@common/PieChart';
import { Card, Chip } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import Progress from '@common/Progress';

import TicketManagement from '@components/TicketManagement';

export default function ({
  language,
  slaok,
  total,
  percent,
  monthPosition,
  tickets,
  companies,
  users,
}) {
  let month = language.DATE_LANG.monthLongList;
  let [slaok_modal, setSlaok_modal] = useState(false);
  let [out_modal, setOut_modal] = useState(false);
  let [ticket_modal, setTicketModal] = useState(false);
  let [ticket_id, setTicket_id] = useState([]);
  let [showPercent, setShowPercent] = useState(false);

  let tickets_ok = tickets.filter(
    (ticket) => ticket.sla == 'ok' || ticket.sla == 'out'
  );
  let tickets_out = tickets.filter((ticket) => ticket.sla == 'un');
  const closeModal = () => {
    setSlaok_modal(false);
    setOut_modal(false);
  };

  const getCompany = (id) => {
    let company = companies.filter((c) => c.id_company == id);

    return company.length > 0 ? company[0].name : '';
  };
  const getSponsor = (id) => {
    try {
      let user = users.filter((c) => c.id_user == id);
      return user[0].name;
    } catch (e) {
      return '';
    }
  };

  const openTicket = (idTicket, realId) => {
    setTicket_id({ id_tickets: idTicket, real_id: realId });

    setTicketModal(true);
  };

  let col = [
    {
      key: 'actions',
      label: language.VISUALIZE,
      thConfig: true,
      style: { width: 1 },
    },
    {
      key: 'id_ticket',
      label: language.ID,
      thConfig: true,
      style: { width: 1, textAlign: 'center', paddingRight: 0 },
    },

    {
      key: 'company',
      label: language.COMPANY,
      thConfig: true,
      style: { width: 140 },
    },

    {
      key: 'progress',
      label: language.PROGRESS_,
      thConfig: true,
      style: { width: 140 },
    },
    {
      key: 'sponsor',
      label: language.RESPONSIBLE,
      thConfig: true,
      style: { width: 140 },
    },
    {
      key: 'subject',
      label: language.SUBJECT,
      thConfig: true,
      style: { width: 140 },
    },

    {
      key: 'created',
      label: language.ACTIVITY_DATE,
      thConfig: true,
      style: { width: 140 },
    },
  ];

  let data_ok = (tickets_ok || []).map((obj, index) => {
    return {
      action: (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <RaisedButton
                  circleButton
                  icon="far fa-eye"
                  label="Visualizar"
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => openTicket(obj.id_tickets, obj.realid)}
                />
              </div>
            }
          />
        </React.Fragment>
      ),
      id_ticket: obj.id_tickets,
      //      description: obj.description,

      company: getCompany(obj.fk_id_company),
      progress: (
        <div className="d-flex align-items-center" style={{ width: 100 }}>
          <Progress
            style={{ width: '100%', height: 5 }}
            hiddenText
            current={obj.progress}
            background="var(--primary)"
          />
          <span
            style={{ fontSize: 10, color: 'var(--primary)' }}
            className="ml-1"
          >
            {obj.progress}%
          </span>
        </div>
      ),
      sponsor: getSponsor(obj.fk_id_user_sponsor),

      subject: obj.subject,
      created: moment(obj.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
    };
  });

  let data_out = (tickets_out || []).map((obj, index) => {
    return {
      action: (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <RaisedButton
                  circleButton
                  icon="far fa-eye"
                  label={language.VISUALIZE}
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => openTicket(obj.id_tickets, obj.realid)}
                />
              </div>
            }
          />
        </React.Fragment>
      ),
      id_ticket: obj.id_tickets,

      company: getCompany(obj.fk_id_company),
      progress: (
        <div className="d-flex align-items-center" style={{ width: 100 }}>
          <Progress
            style={{ width: '100%', height: 5 }}
            hiddenText
            current={obj.progress}
            background="var(--primary)"
          />
          <span
            style={{ fontSize: 10, color: 'var(--primary)' }}
            className="ml-1"
          >
            {obj.progress}%
          </span>
        </div>
      ),
      sponsor: getSponsor(obj.fk_id_user_sponsor),
      subject: obj.subject,
      created: moment(obj.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
    };
  });

  return (
    <Card id="SlaAnalysis" className="h-100">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
              ({language.OPENED}, {language.IN_ATTENDANCE}, {language.WAITING}, {language.RESOLVED_AND_FINISHED})
            </div>
          }
          theme="dark"
        >
          {' '}
          <AppBar
            title={language.FINANCIAL_ANALYSIS_OF_CONTRACT}
            subTitle={`(${language.MONTH_SELECTED})`}
          />{' '}
        </Tooltip>

        <RaisedButton
          circleButton
          icon="fa fa-retweet"
          label={language.CHANGE_VISUALIZATION}
          color="sucess"
          style={{ fontSize: 13, width: 21, height: 21 }}
          onClick={() => setShowPercent(!showPercent)}
        />
      </div>
      <div className="content-medium pt-0">
        <span className="current-month-label">{language.MONTH}: </span>
        <span className="current-month-value">{month[monthPosition]}</span>
        <div className="content-chart mt-3">
          <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 15 }}>
                <div>
                  <strong>{language.TOTAL_TICKETS_ON_TIME} : </strong>{' '}
                  <span>{slaok}</span>
                </div>
                <div>
                  <strong>{language.TOTAL_OUT_OF_DATE_TICKETS} : </strong>{' '}
                  <span>{total - slaok}</span>
                </div>
              </div>
            }
            theme="dark"
          >
            {showPercent ? (
              <PieChart
                percent={percent}
                color="success"
                strokeWidth={4.3}
                bgStrokeWidth={4}
                strokeColor="var(--danger)"
              />
            ) : (
              <PieChart
                slaok={slaok}
                total={total}
                color="success"
                strokeWidth={4.3}
                bgStrokeWidth={4}
                strokeColor="var(--danger)"
              />
            )}
          </Tooltip>
        </div>
        <div className="content-legend mt-3">
          <div
            className="mb-2"
            onClick={() => setSlaok_modal(true)}
            style={{ cursor: 'pointer' }}
          >
            <div style={{ backgroundColor: 'var(--danger)' }}></div>
            <span style={{ color: 'var(--danger)' }}>
              {language.TOTAL_OUT_OF_DATE_TICKETS}
            </span>
          </div>

          <div onClick={() => setOut_modal(true)} style={{ cursor: 'pointer' }}>
            <div style={{ backgroundColor: 'var(--success)' }}></div>
            <span style={{ color: 'var(--success)' }}>
              {language.TOTAL_TICKETS_ON_TIME}
            </span>
          </div>
        </div>
      </div>

      <Dialog
        open={slaok_modal}
        title={language.TOTAL_OUT_OF_DATE_TICKETS}
        onClose={() => closeModal()}
        maxWidth="lg"
      >
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data_ok}
          paginationTop={false}
          searchColumn={true}
        />
      </Dialog>

      <Dialog
        open={out_modal}
        title={language.TOTAL_TICKETS_ON_TIME}
        onClose={() => closeModal()}
        maxWidth="lg"
      >
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data_out}
          paginationTop={false}
          searchColumn={true}
        />
      </Dialog>

      <Dialog
        open={ticket_modal}
        title="Ticket"
        onClose={() => setTicketModal(false)}
        maxWidth="100%"
      >
        <TicketManagement
          detail={true}
          id_ticket_detail={ticket_id}
        ></TicketManagement>
      </Dialog>
    </Card>
  );
}
