import React, {Fragment, memo} from 'react';
import './index.css';
//--------------------------common---------------------------- 
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import Button from '@common/Button';
//--------------------------material-ui---------------------------- 
import {Checkbox} from '@material-ui/core';
//--------------------------icons---------------------------- 
import BuildIcon from '@material-ui/icons/Build';
import ExploreIcon from '@material-ui/icons/Explore';
import GavelIcon from '@material-ui/icons/Gavel';
import TimelineIcon from '@material-ui/icons/Timeline';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import Store from '@data/Store';

const { language } = Store;

const styleIcons = [
	{fontSize:11, color:'var(--primary)'},
	{fontSize:11, color:'#0F9FC2'},
	{fontSize:11, color:'var(--danger)'},
	{fontSize:11, color:'var(--warning)'},
	{fontSize:11, color:'var(--purple)'},
]
const icons = [
	<BuildIcon style={styleIcons[0]}/>,
	<ExploreIcon style={styleIcons[1]}/>,
	<GavelIcon style={styleIcons[2]}/>,
	<TimelineIcon style={styleIcons[3]}/>,
	<FitnessCenterIcon style={styleIcons[4]}/>
]
export default memo(({
	setViewProjectsId,
	onClose,
	itens
})=>{
	//----- as requisições para esses dados serão feitas nesse componente

	var resArr = [];
	itens&& itens.actitivies&&	itens.actitivies.filter(function(item){
		var i = resArr.findIndex(x => (x.id_project == item.id_project ));
		if(i <= -1){
			
			resArr.push(item);
		}
		 
	  });

 
 

	let data= resArr.length>0&&resArr.map(g=>{ 
	 
		try{
			u =users.filter(v=>v.id_user  == g.fk_id_tech)[0].name
		  }catch(e){}
		return{  check: (
				<Button
					icon='fas fa-eye'
					label={language.SEE_ACTIVITIES}
					variant='normal'
					color='success'
					size={.8}
					onClick={()=>setViewProjectsId(g.id_project)} //--id do projeto
				/>
			),
			 
			id:g.id_project,
			subject:g.project,
			dt:moment(g.start_date).format('DD-MM-YY'),
			hour:moment(g.start_date).format('HH:mm'),
			
			sponsor:g.sponsor
			 
		}
	})





	 
	return(
		<CommonTable
			className='AssociateActivityTable'
			searchColumn
			orderColumn
			maxHeight={500}
			paginationTop={false}
			afterBar2={
				<Button
					label='Fechar'
					icon='fas fa-times'
					color='#ffffff'
					variant='circle'
					outlined
					style={{
						border:'none',
						color:'#ffffff'
					}}

					onClick={onClose}
				/>
			}
			AppBarProps={{
				style:{
					borderRadius:'10px 10px 0 0'
				}
			}}
			col={[
				{key:'action', label:language.ACTION},
			  {key:'id', label:'ID'},
				{key:'subject', label:language.PROJECT_NAME},
				{key:'dt', label:language.DATE},
				{key:'hour', label:language.HOUR},
				{key:'sponsor', label:language.RESPONSIBLE},
				 
			]}
			data={data}
			footer={
				<Fragment>
					<hr/>
					<div className='d-flex align-items-center justify-content-end pb-3 pr-3'>
						<Button
							label={language.CANCEL}
							color='danger'
							variant='normal'
							className='mr-3'
							onClick={onClose}
						/>
						<Button
							label={language.SAVE}
							color='success'
							variant='normal'
							onClick={onClose}
						/>
					</div>
				</Fragment>
			}
		/>
	)
})