import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonPage from '../../../../common/CommonPage';
import Pickers from '@common/Pickers';
import {

  Dialog,

  SelectField,

  RadioButton,
  CircularProgress
} from '@material-ui/core';

import BarTableGroupTech from './componentsgsd/BarTableGroupTech';
import BarTableAvaliation from './componentsgsd/BarTableAvaliation';
import BarTableService from './componentsgsd/BarTableService';
import BarTableDevice from './componentsgsd/BarTableDevice';
import BarTableDepartment from './componentsgsd/BarTableDepartment';
import BarTableTech from './componentsgsd/BarTableTech';
import NumberOfCalls from './componentsgsd/NumberOfCalls';
import ResolutionReports from './componentsgsd/ResolutionReports';
import { Card, TextField, MenuItem, Paper, Grid } from '@material-ui/core';
import AppBar from '@common/AppBar';
const styleH2 = {
  textAlign: 'left'
};
@observer
export default class AnalystReportCalls extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      valueCompanie: '',

      showGraph: false,
      loadingGraph: false,
      inputLinkClicked: false,
      date: {
        ini: moment().date(1),
        end: moment()
      }
    };

    this.generateReportBtn = this.generateReportBtn.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.CONTRACT + 'back', 'contracts');
    this.action.execute('get', this.APIEndpoints.COMPANY + 'back', 'companies');
    //this.action.execute('post', this.APIEndpoints.REPORT + '/12', 'reportCall');
  }



  handleChange = (evt) => {
    this.setState({ valueCompanie: evt.target.value });
    this.resultCompanie = evt.target.value;
    this.contractItem = this.store.contracts.filter(c => c.fk_id_out_company == evt.target.value && !c.deleted).map(contr => {
      return (
        <MenuItem
          key={contr.id_contract}
          value={contr.id_contract}
          primaryText={contr.name}
        >
          {contr.name}
        </MenuItem>
      );
    });
  };

  handleChangeContract = (evt) => {




    this.resultContract = evt.target.value;
    this.setState({ value: evt.target.value });
  };


  changeDate = (type, value) => {


    var date = this.state.date;
    date[type] = value;

    this.setState({ date });

  }

  generateReportBtn = () => {
    let values = {
      dataini: moment(this.state.date.ini).format('YYYY-MM-DD'),
      dataend: moment(this.state.date.end).format('YYYY-MM-DD')
    }

    values.id_company = this.state.valueCompanie;
    values.contract_id = this.state.value
    this.action.execute('post', this.APIEndpoints.REPORT + '/12', 'reportCall', values);

    this.setState({ loadingGraph: true });
    setTimeout(
      function () {
        this.setState({
          showGraph: true,
          loadingGraph: false,
          inputLinkClicked: true
        });
      }.bind(this),
      5000
    );
  };

  render() {
    let { language, contracts, companies, reportCall } = this.store;
    let { page, values, value } = this.state;
    let { handlePage } = this.props;
    this.companyItem = companies.map(co => (
      <MenuItem
        key={co.id_company}
        value={co.id_company}
        primaryText={co.name}
      >
        {co.name}
      </MenuItem>
    ));

    return (
      <PageLayout
        icon={
          <div className="imgGsd" style={{ width: '44px', height: '44px' }} />
        }
        back={()=>   handlePage('dash')}
        title={language.CALL_REPORTING}
        subtitle={language.REPORTING_CALL_ANALYSIS}
        rightElements={[]}
      >
        <div>
          <div>
            <Paper className='pb-2'>
              <AppBar light>
                <span className='font titlePattern'>{language.COMPANY_FILTER_CONTRACT}</span>
              </AppBar>
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <div>

                      <h3 className='titlePattern' style={{ color: '#616161cf', textAlign: 'left' }}>
                        {language.SELECT_COMPANY_CONTRACT}
                      </h3>
                    </div>
                  </Col>
                </Row>

                <div className='d-flex align-items-center'>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label={language.COMPANY}
                    style={{ minWidth: 200 }}
                    value={this.state.valueCompanie}
                    onChange={this.handleChange}
                    margin="dense"
                    variant="outlined"
                  >
                    {this.companyItem}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label={language._CONTRACT}
                    style={{ minWidth: 200 }}
                    value={this.state.value}
                    onChange={this.handleChangeContract}
                    margin="dense"
                    variant="outlined"
                    className='mx-3'
                  >
                    {this.contractItem}
                  </TextField>
                  <Pickers
                    type='DatePicker'
                    onChange={(e) => this.changeDate('ini', e)}
                    value={this.state.date.ini}
                    label="Data Inicial"
                    variant='outlined'
                    margin='dense'


                  />,
                  <Pickers
                    type='DatePicker'
                    onChange={(e) => this.changeDate('end', e)}
                    value={this.state.date.end}
                    label="Data Final"
                    variant='outlined'
                    margin='dense'
                    className='mx-3'


                  />
                  <div>
                    <RaisedButton
                      circleButton
                      icon='fas fa-file-signature'
                      color='primaryGradient'
                      onClick={this.generateReportBtn}
                      label="Gerar Relatório"
                      style={{ fontSize: 15, paddingLeft: 3.5 }}
                      primary={true}
                    />
                  </div>

                </div>
              </Container>
            </Paper>

            {this.state.showGraph ? (
              <div>
                <Grid container spacing={3} className='my-2'>
                  <Grid item xs={12}>
                    <Card className='pt-3' style={{ height: '100%' }}>

                      <NumberOfCalls
                        title={language.CALL_QUANTITY_GRAPH}
                        subTitle={language.SEE_PERCENTAGE_COLOR}
                        reportCall={reportCall}
                        resultContract={this.resultContract}
                        language={language}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '5px' }}>
                    <Card className='pt-3' style={{ height: '100%' }}>

                      <ResolutionReports
                        reportCall={reportCall}
                        resultContract={this.resultContract}
                        language={language}
                      />
                    </Card>
                  </Grid>
                </Grid>

                <BarTableTech
                  reportCall={reportCall}
                  inputLinkClicked={this.state.inputLinkClicked}
                  resultContract={this.resultContract}
                  language={language}
                />

                <BarTableService
                  reportCall={reportCall}
                  inputLinkClicked={this.state.inputLinkClicked}
                  resultContract={this.resultContract}
                  language={language}
                />

                <BarTableDevice
                  reportCall={reportCall}
                  inputLinkClicked={this.state.inputLinkClicked}
                  resultContract={this.resultContract}
                  language={language}
                />

                <BarTableDepartment
                  reportCall={reportCall}
                  inputLinkClicked={this.state.inputLinkClicked}
                  resultContract={this.resultContract}
                  language={language}
                />
                <BarTableGroupTech
                  reportCall={reportCall}
                  inputLinkClicked={this.state.inputLinkClicked}
                  resultContract={this.resultContract}
                  language={language}
                />
                <BarTableAvaliation
                  reportCall={reportCall}
                  inputLinkClicked={this.state.inputLinkClicked}
                  resultContract={this.resultContract}
                  language={language}
                />

              </div>
            ) : (
              <div>
                {' '}
                <br /> <br />{' '}
              </div>
            )}

            {this.state.loadingGraph ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={60} thickness={7} />
              </div>
            ) : (
              <div>
                {' '}
                <br /> <br />{' '}
              </div>
            )}
          </div>
        </div>

        <br />
        <br />
      </PageLayout>
    );
  }
}
