import React from 'react';
import ServicePortalTicketSubTitle from './ServicePortalTicketSubTitle';
import { Avatar, Button, Chip, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { useServicePortalStyles } from '../helpers';
import { GridLine, GridLineItem } from './GridUtils';
import { EmptyResult } from './EmptyState';
import { Done, Block, ErrorOutline } from '@material-ui/icons';
import LinearProgressWithLabel from './LinearProgressWithLabel';

export default function ServicePortalTicketTasks({ language, ticketData }) {
    const styles = useServicePortalStyles();

    const getBackStatus = (status) => {
        if (status == 'AGUARDANDO') {
            return 'rgb(240 31 31 / 19%)';
        }
        if (status == 'INICIADO') {
            return '#f0971f30';
        }

        if (status == 'ANDAMENTO') {
            return '#f0971f30';
        } else {
            return 'rgb(52, 172, 160, 0.35)';
        }
    };

    const getIconByStatus = (status) => {

        if (['APROVADO', 'CONCLUIDO'].includes(status))
            return <Done />;

        if (['CANCELADO'].includes(status))
            return <Block />;

        if (['FALHA'].includes(status))
            return <ErrorOutline />;

        return <></>
    }

    const taskLabelId = (id, type) => {
        const labels = {
            Incident: 'INCTASK',
            Problem: 'PRBTASK',
            Solicitation: 'SOLTASK',
        };
        return `${labels[type]}${id}`;
    };

    const getColorStatus = (status) => {
        if (status == 'AGUARDANDO') {
            return 'rgb(240 31 31)';
        }
        if (status == 'INICIADO') {
            return 'rgb(240, 151, 31)';
        }

        if (status == 'ANDAMENTO') {
            return 'rgb(240, 151, 31)';
        } else {
            return 'rgba(52, 172, 160)';
        }
    };

    const getLabelByStatus = (status, ticketTaskItem) => {
        if (status === 'PENDENTE APROVAÇÃO') {
            return 'PENDENTE APROVAÇÃO';
        } else if (status === 'REJEITADO') {
            return 'REJEITADO';
        } else if (ticketTaskItem.existOpenPredecessors) {
            return language.WAITING_FOR_PREDECESSORS;
        } else {
            return status === 'CONCLUIDO' && ticketTaskItem.tasks[0]?.approved
                ? 'CONCLUIDO'
                : status;
        }







    }

    const hasData = Array.isArray(ticketData._ticketTasks) && ticketData._ticketTasks.length > 0;
    const noData = Array.isArray(ticketData._ticketTasks) && ticketData._ticketTasks.length === 0;
    let stylesContainer = { minHeight: '18rem' };
    if (noData)
        stylesContainer.height = '18rem';

    return (
        <div className=''>
            <ServicePortalTicketSubTitle label={language.TICKET_TASKS} iconText='fa-tasks' />
            <div className='mb-2 tw-border tw-rounded tw-p-2' style={stylesContainer}>

                {hasData && (
                    <>
                        <div className='tw-bg-gray-100 tw-py-2 tw-px-3 tw-mb-2 tw-rounded'>
                            <Grid container spacing={1}>
                                <Grid container item sm={3}>
                                    <GridLineItem
                                        label={language.PROGRESS_}
                                        content={(
                                            <div style={{ width: 150 }}>
                                                <LinearProgressWithLabel variant="determinate" value={ticketData._tasksProgress} />
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Grid xs={12} className='tw-flex tw-gap-4 tw-overflow-x-scroll'>
                            {ticketData._ticketTasks.map(
                                (ticketTaskItem, index) => (
                                    <Grid
                                        item
                                        xs={3}
                                        lg={2}
                                        spacing={1}
                                        key={Math.random()}
                                        style={{
                                            maxHeight: 320,
                                            height: 320,
                                            minWidth: 275,
                                            overflowY: 'auto',
                                            pointerEvents:
                                                index == 0 ||
                                                    ticketTaskItem.existOpenPredecessors == false
                                                    ? 'auto' : 'none',
                                            opacity:
                                                ticketTaskItem.existOpenPredecessors == false
                                                    ? '1' : '0.3',

                                        }}
                                    >
                                        <Paper
                                            style={{
                                                minHeight: 320,
                                                minWidth: 275,
                                                padding: 12,
                                                border: `1px solid rgba(229,231,235,1)`,
                                                backgroundColor: 'rgba(243,244,246,1)'
                                            }}
                                        >
                                            <Grid container spacing={1}>
                                                <ServicePortalTicketSubTitle label={ticketTaskItem.tasks[0].name} iconText='' />

                                                <Grid item sm={12}></Grid>
                                                <GridLineItem
                                                    label={language.STATUS}
                                                    content={
                                                        <Chip
                                                            size="small"
                                                            clickable={false}
                                                            classes={{ root: styles.chipStatus }}
                                                            label={getLabelByStatus(ticketTaskItem.tasks[0].status, ticketTaskItem)}
                                                            style={{
                                                                background: getBackStatus(ticketTaskItem.tasks[0].status),
                                                                color: getColorStatus(ticketTaskItem.tasks[0].status),
                                                            }}
                                                            onDelete={() => { }}
                                                            deleteIcon={getIconByStatus(ticketTaskItem.tasks[0].status)}
                                                        />
                                                    }
                                                />

                                                <Grid item sm={12}><Divider /></Grid>
                                                <GridLineItem
                                                    label={language.ID}
                                                    text={taskLabelId(
                                                        ticketTaskItem.tasks[0].id_tasks,
                                                        ticketData.type_tickets
                                                    )}
                                                />

                                                <Grid item sm={12}><Divider /></Grid>
                                                <GridLineItem
                                                    label={
                                                        ticketTaskItem.tasks[0].fk_id_template_task > 0
                                                            ? language.RESPONSIBLE_GROUP
                                                            : language.RESPONSIBLES
                                                    }
                                                    text={
                                                        ticketTaskItem.tasks[0].fk_id_template_task > 0
                                                            ? ticketTaskItem.tasks[0].grupo_tech
                                                            : (ticketTaskItem.responsible || []).map(
                                                                (user) => (
                                                                    <Avatar
                                                                        style={{ marginLeft: 10 }}
                                                                        alt={user.name}
                                                                        src={`${APIEndpoints.REQUESTS}/public/users/${user.id_user}/profileLogo.png`}
                                                                    />
                                                                )
                                                            )
                                                    }
                                                />


                                                {ticketTaskItem.tasks[0].tech && (
                                                    <>
                                                        <Grid item sm={12}><Divider /></Grid>

                                                        <GridLineItem
                                                            label={language.DESIGNATED_ANALYST}
                                                            text={ticketTaskItem.tasks[0].tech}
                                                        />

                                                    </>
                                                )}

                                                {ticketTaskItem.tasks[0].deadline && (
                                                    <>
                                                        <Grid item sm={12}><Divider /></Grid>

                                                        <GridLineItem
                                                            label={language.TERM}
                                                            text={moment(ticketTaskItem.tasks[0].deadline).format('L LT')}
                                                        />
                                                    </>
                                                )}

                                                {ticketTaskItem.tasks[0].files && (
                                                    <>
                                                        <Grid item sm={12}><Divider /></Grid>

                                                        <GridLineItem
                                                            label={language.FILES}
                                                            content={(ticketTaskItem.tasks[0].files || []).map(
                                                                (item) => (
                                                                    <div key={item.id_tasks_files}>
                                                                        <Chip
                                                                            size="small"
                                                                            label={item.namefile?.length > 16 ? item.namefile.substr(0, 6) + '...' + item.namefile.substr(-7) : item.namefile}
                                                                            avatar={<Avatar>
                                                                                <i className='far fa-file tw-w-auto tw-h-auto' />
                                                                            </Avatar>}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        />
                                                    </>
                                                )}

                                                <Grid item sm={12}><Divider /></Grid>

                                                <GridLineItem
                                                    label={language.START_OF_ACTIVITY}
                                                    text={moment(ticketTaskItem.tasks[0].dt_start).format('L')}
                                                />

                                                <Grid item sm={12}><Divider /></Grid>
                                                <GridLineItem
                                                    isLongText={true}
                                                    label={language.DESCRIPTION}
                                                    text={ticketTaskItem.tasks[0].description.length > 200
                                                        ? ticketTaskItem.tasks[0].description.replace(/<[^>]*>?/gm, '').toString().substring(0, 200) + '...'
                                                        : ticketTaskItem.tasks[0].description.replace(/<[^>]*>?/gm, '')}
                                                />
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </>
                )}

                {noData && (
                    <EmptyResult language={language} />
                )}
            </div>
        </div>
    )
}
