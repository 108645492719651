import React, { useState, useEffect } from 'react';
import { Card, Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles';

import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import Header from '@common/Header';
import AppBar from '@common/AppBar2';
import ButtonCommon from '@common/Button';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

import Store from '@data/Store';
import { DayTask } from './DayTask';
import { WeekTask } from './WeekTask';
import { MonthTask } from './MonthTask';
import { NewTask } from './NewTask';
import { FilterTaskCalendar } from './FilterTaskCalendar';

import './styles.css';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: '#039FC1',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
  },
}))(Button);

export function TaskCalendar() {
  let { language } = Store;
  const action = new Actions();
  let APIEndpoints = Constants.APIEndpoints;
  const [page, setPage] = useState(0);
  let [calendar, setCalendar] = useState([]);
  const [eventTask, setEventTask] = useState(null);
  const [newTask, setNewTask] = useState(false);
  const [totalTasks, setTotalTasks] = useState(0);

  const [dateFilter, setDateFilter] = useState({});
  useEffect(() => {
    action.execute('post', APIEndpoints.CALENDAR, 'calendar').then((h) => {
      setCalendar(h.data);
    });

    action
      .execute('get', `${APIEndpoints.TASKS}/month`, '')
      .then((res) => {
        setTotalTasks(res.data.length);
      })
      .catch((err) => console.log('Error', err));
  }, []);

  let ticketTotalMonth = 0;
  let riskTotalMonth = 0;
  let activityTotalMonth = 0;
  let total = 0;

  let events = [];
  let regex = /(<([^>]+)>)/gi;
  ticketTotalMonth = calendar && calendar.tickets && calendar.tickets.length;
  riskTotalMonth = calendar && calendar.risk && calendar.risk.length;
  activityTotalMonth =
    calendar && calendar.activityProject && calendar.activityProject.length;
  total = ticketTotalMonth + riskTotalMonth + activityTotalMonth;

  const tasksFilter = (dateFromChild) => {
    setPage(2);
    setEventTask(dateFromChild);
    //  setNewTask(true);
  };

  const reload = (dateFromChild) => {
    setNewTask(dateFromChild);
    setPage(2);
  };

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.CALENDAR}
        subTitle={language.TASK_MANAGEMENT + '/'}
        rightElements={[
          <FilterTaskCalendar
            tasksFilter={tasksFilter}
            setDateFilter={setDateFilter}
            setPage={setPage}
          />,
        ]}
      >
        <Header
          btnFilterProps={{
            style: { width: 150 },
          }}
          btnFilter={[language.DAY, language.WEEK, language.MONTH]}
          onChange={(index) => {
            setPage(index);
            setNewTask(false);
          }}
          active={page}
        >
          <NewTask reload={reload} language={language} />
        </Header>
        <Card className="content-min px-3">
          <div id="dashboard-task" className="mb-2">
            <Grid container spacing={3}>
              <Grid item xs={3} className="content-card">
                <Card className="card">
                  <span className="title">{language.DEMAND_MANAGEMENT}</span>
                  <span className="subtitle">
                    {language.NUMBER_OF_OPEN_ACTIVITIES} ({language.MONTH})
                  </span>
                  <span className="total">{ticketTotalMonth || 0}</span>
                </Card>
              </Grid>
              <Grid item xs={3} className="content-card">
                <Card className="card">
                  <span className="title">{language.PROJECT_MANAGEMENT}</span>

                  <span className="subtitle">
                    {language.NUMBER_OF_OPEN_ACTIVITIES} ({language.MONTH})
                  </span>

                  <span className="total">{activityTotalMonth || 0}</span>
                </Card>
              </Grid>
              <Grid item xs={3} className="content-card">
                <Card className="card">
                  <span className="title">{language.RISKS_MANAGEMENT}</span>

                  <span className="subtitle">
                    {language.NUMBER_OF_OPEN_ACTIVITIES} ({language.MONTH})
                  </span>

                  <span className="total">{riskTotalMonth || 0}</span>
                </Card>
              </Grid>
              <Grid item xs={3} className="content-card">
                <Card className="card">
                  <span className="title">{language.TASK_MANAGEMENT}</span>

                  <span className="subtitle">
                    {language.NUMBER_OF_OPEN_TASKS} ({language.MONTH})
                  </span>

                  <span className="total">{totalTasks || 0}</span>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Card>
        {page == 0 && (
          <DayTask
            type="timeGridDay"
            eventTask={eventTask}
            reload={newTask}
            dateFilter={dateFilter}
          />
        )}
        {page == 1 && (
          <WeekTask
            type="timeGridWeek"
            eventTask={eventTask}
            reload={newTask}
            dateFilter={dateFilter}
          />
        )}
        {page == 2 && (
          <MonthTask
            type="dayGridMonth"
            eventTask={eventTask}
            reload={newTask}
            dateFilter={dateFilter}
          />
        )}
      </PageLayout>
    </CommonPage>
  );
}
