import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import { MaskMoney } from '@common/Mask';

import DownloadFiles from '../../util/DownloadFiles';

@observer
export default class DeviceForm extends Component {
  constructor() {
    super();

    this.state = {
      type: 'server',
      connection: '',
      company: 0,
      user: '',
      fk_id_manufacture: '',
      departmentOptions:[],
      files: [],
      listDevicesICOptions: [],
      params: []
    };
    this.onChangeField = this.onChangeField.bind(this);
    this.handleValues = this.handleValues.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDownFile = this.onDownFile.bind(this);
    this.DepartmentList =this.DepartmentList.bind(this);
  }

  handleValues(value, type) {
    let obj = {};
    obj[type] = value;
    this.setState(obj);
  }

  componentWillMount() {

    if (this.props && this.props.values && this.props.values.fk_id_company > 0){
      this.setState({
        company: this.props.values.fk_id_company
      });

      this.DepartmentList(this.props.values.fk_id_company)

    }
    this.props.action.execute('get', this.props.APIEndpoints.GENERAL_PARAMETERS, '').then(res => {
      this.setState({ params: res.data })
    })
  }

  onChangeField(field, value) {

    if (field.name === 'connection') {
      this.setState({
        connection: value
      });
    } else if (field.name === 'fk_id_company') {

      this.setState({
        company: value
      });

      this.DepartmentList(value)
    }
    else if (field.name == 'fk_id_manufacture') {
      this.setState({ fk_id_manufacture: value })

    }


  }

  onDownFile(file) {
    setTimeout(() => {
      DownloadFiles(
        this.props.APIEndpoints.REQUESTS + '/downloadfiledevice/' + this.props.values.id_device + '/' + file.name,
        file.name
      );
    }, 2000);
  }

  onDownFileTable(file) {

    setTimeout(() => {
      if(file.db == 'filedb'){
       DownloadFiles(this.props.APIEndpoints.REQUESTS + '/api/downloadfiledevice/' + file.id_device_file + '&' + file.name, file.name)
       }else{
        DownloadFiles(
          this.props.APIEndpoints.REQUESTS + '/downloadfiledevice/' + this.props.values.id_device + '/' + file.name,
          file.name
        );
       }
    }, 2000);
  }

  DepartmentList(id) {
    if( id !== 0 ) {
      this.props.action.execute('get',  this.props.APIEndpoints.DEPARTMENT+'/'+ id , '').then(v=>{
          let listDepartments=v.data.map(c=>({
          value: parseInt(c.id_department),
          label: c.name,
         }))
         this.setState({departmentOptions: listDepartments});
        })
    } else {
      this.setState({departmentOptions: []});
    }

  }

  onFormSubmit(values) {

    if (values.files) {
      values.files = values.files.map(f => (
        {
          name: f.split('|')[0] + " - " + moment(f.split('|')[3]).format('L'),
          size: '',
          type: '',
          nn: f.split('|')[0],
          user_cad: '',
          id_user: ''
        }))
    }

    let method = values.hasOwnProperty('id_device') ? 'post' : 'post';
    let url = this.props.APIEndpoints.DEVICE;
    this.props.action.execute(method, url, 'devices', values, 'id_device');
    this.props.handlePage('list')

  }

  render() {
    let {
      language,
      handlePage,
      values,
      onFormSubmit,
      companies,
      users,
      redirect,
      devicetype,
      clone,
      inv,
      usercon_company,
      user,

      contracts

    } = this.props;

    let {departmentOptions} = this.state;
    clone = false;
    let company = 0;
    if (this.state.company > 0) {
      company = this.state.company;
      if (values) {
        values.fk_id_company = company;
      }
    } else {
      if (values) {
        company = values.fk_id_company

      }
    }


    var manufacturesOptions = [];
    var brandsOptions = [];
    if (this.props.snmp == true) {

      manufacturesOptions = this.props.manufactures.map(e => ({ value: e.id, label: e.name }));
      brandsOptions = this.props.brands.filter(e => e.tb_manufacturer_id == this.state.fk_id_manufacture).map(e => ({
        value: e.id_brand,
        label: e.name
      }))

    }

    let companiesOptions = companies

      .map(obj => ({ value: obj.id_company, label: obj.name }));
    let contractOptions = [];

    if (company > 0) {

      contractOptions = contracts && contracts
        .filter(g =>
          (company == 1 ? g.fk_id_company : g.fk_id_out_company == company)
          ||
          (g.global == true)
        )
        .map(c => ({
          value: c.id_contract,
          label: c.company + ' - ' + c.name
        }));
    }



    try {
      if (user.role == 'tech') {
        try {
          devices = devices.filter(p => usercon_company[0] && usercon_company[0].company.includes(p.fk_id_company))
        } catch (g) {

        }
        companiesOptions = companies
          .filter(c => c.type !== 2 &&
            usercon_company[0].company.includes(c.id_company)
          )
          .map(obj => ({ value: obj.id_company, label: obj.name }));

      }
    } catch (r) { }




    let typeOptions = devicetype && devicetype.map(u => ({
      value: u.id_device_type,
      label: u.name
    }));

    let usersOptions = [];

    if (company > 0) {
     /* try {
        companies
          .filter(c => c.id_company == company)[0]
          .department.map(obj => {
            departmentOptions.push({
              value: obj.id_department,
              label: obj.name,
              user: obj.user_cad
            });
            if (obj.sub_dep.length) {
              obj.sub_dep.map(sd => {
                departmentOptions.push({
                  value: sd.id_department,
                  label: sd.name
                });
              });
            }
          });
      } catch (r) { } */

      usersOptions = users && users

        .filter(f => (f.id_company === company && f.fk_id_department && f.deleted == false && f.status == true) ||
         (f.companysolicitant && f.companysolicitant.includes(company) && f.fk_id_department && f.deleted == false && f.status == true)
           )
        .map(u => ({ value: u.id_user, label: u.name }));

      /*try {
        values.user_cad = parseInt(values.user_cad);

        if (isNaN(values.user_cad)) {
        }
      } catch (t) {} */
    }
    let usersOptionsTech = users
      .filter(f =>(  f.id_company === 1 && f.role == 'tech' && f.deleted == false && f.status == true))
      .map(u => ({ value: u.id_user, label: u.name }));
    //let user = !!values ? values.user_cad : this.state.user;

    // let type = !!values ? values.type : this.state.type;

    if (this.state.connection != '') {
      try {
        values.connection = this.state.connection
      } catch (e) { }
    }

    let connectionOptions = [
      { value: 'rdp', label: language.RDP },
      { value: 'ssh', label: language.SSH },
      { value: 'srd', label: language.SRD }
    ];

    let connectionDesktopOptions = [
      { value: 'rdp', label: language.RDP },
      { value: 'ssh', label: language.SSH },
      { value: 'srd', label: language.SRD }
    ];

    let fields = [];
    let tipo = '';

    if (this.props.snmp == true) {
      fields = [
        {
          col: 3,
          name: 'id_device_type',
          label: language.TYPE,
          type: 'select',
          required: true,
          options: typeOptions

        },
        {
          col: 3,
          name: 'name',
          label: language.NAME,
          type: 'text',
          required: true
        },
        {
          col: 3,
          name: 'fk_id_company',
          label: language.COMPANY,
          type: 'select',
          required: true,
          options: companiesOptions

        },
        {
          col: 3,
          name: 'fk_id_department',
          label: language.DEPARTMENT,
          required: true,
          type: 'select',
          options: departmentOptions,
          disabled: (departmentOptions.length === 0)
        },


        {
          col: 6,
          name: 'user_tech',
          label: language.TECH,
          type: 'listCheckbox',
          required: false,
          options: usersOptionsTech

        },
        {
          col: 3,
          name: 'contracts',
          label: language.CONTRACTS,
          type: 'listCheckbox',
          required: false,
          options: contractOptions

        },

        {
          col: 3,
          name: 'connection',
          label: language.CONNECTION,
          type: 'select',
          options: connectionDesktopOptions
        },
        { col: 3, name: 'patrimony', label: language.PATRIMONY, type: 'text' },
        {
          col: 3,
          name: 'cost',
          label: language.COST,
          type: 'text',
          InputProps: {

            inputComponent: MaskMoney
          },
        },
        {
          col: 3, name: 'fk_id_manufacture', label: language.MANUFACTURER, type: 'select',
          options: manufacturesOptions
        },
        {
          col: 3, name: 'fk_id_brands', label: language.MODEL, type: 'select',
          options: brandsOptions,

        },
        { col: 3, name: 'vendor', label: language.VENDOR, type: 'text' },
        { col: 3, name: 'warranty', label: language.WARRANTY, type: 'text' },
        { col: 3, name: 'invoice', label: language.INVOICE, type: 'text' },
        { col: 3, name: 'serial_number', label: language.SERIAL, type: 'text' },
        { col: 3, name: 'serial_os', label: language.SERIAL_SO, type: 'text' },
        { col: 3, name: 'ip', label: language.IP, type: 'text' },
        { col: 3, name: 'port', label: language.PORT, type: 'number', inputProps: { min: 0 } },
        { col: 3, name: 'username', label: language.USERNAME, type: 'text' },
        { col: 3, name: 'password', label: language.PASSWORD, type: 'password' },
        { col: 3, name: 'domain', label: language.DOMAIN, type: 'text' },
        { col: 3, name: 'community', label: language.COMMUNITY, type: 'text' },
        { col: 3, name: 'url', label: language.URL, type: 'text' },
        { col: 3, name: 'address', label: language.ADDRESS, type: 'text' },
        { col: 3, name: 'macadress', label: language.MACADRESS, type: 'text' },
        { col: 3, name: 'alias', label: language.ALIAS, type: 'text' },
        { col: 3, name: 'provider', label: language.PROVIDER2, type: 'text' },

        {
          col: 12,
          name: 'ssh_key',
          label: language.SSH_KEY,
          type: 'text',
          multiLine: true
        },

        {
          col: 12,
          name: 'description',
          label: language.DESCRIPTION,
          type: 'text',
          multiLine: true
        },
        {
          col: 12,
          name: 'files',
          label: language.ATTACHMENTS,
          type: 'file',
        }
      ];
    } else {
      fields = [
        {
          col: 3,
          name: 'id_device_type',
          label: language.TYPE,
          type: 'select',
          required: true,
          options: typeOptions

        },
        {
          col: 3,
          name: 'name',
          label: language.NAME,
          type: 'text',
          required: true
        },
        {
          col: 3,
          // name: 'fk_id_company',
          // label: language.COMPANY,
          // type: 'select',
          // required: true,
          // options: companiesOptions

          type: 'autocomplete',
          name: 'fk_id_company',
          label: language.COMPANY,
          textlabel: language.COMPANY,
          className: 'tech-group-ticket',
          method: 'POST',
          route: `${this.props.APIEndpoints.COMPANY}/filter`,
          routeGetCount: `${this.props.APIEndpoints.COMPANY}/count`,
          fieldquery: 'search',
          textinit:  values ? values.namecompany : language.COMPANY,
          fieldvaluedb: 'id_company',
          fieldlabeldb: 'name',
          idinit: values ? values.fk_id_company : 0,
          routeAll:`${this.props.APIEndpoints.COMPANY}/all?`,
          filterAll : {fk_id_company : company || 0},
          methodGetAll: 'POST',
          detail: true,
          required: true,
          visible: true
        },
        {
          col: 3,
          name: 'fk_id_department',
          label: language.DEPARTMENT,
          type: 'select',
          required: true,
          options: departmentOptions
        },

        {
          col: 6,
          // name: 'user_cad',
          // label: language.USER,
          // type: 'listCheckbox',
          // required: true,
          // options: usersOptions

          type: 'autocomplete',
          name: 'user_cad',
          label: language.USER,
          textlabel: language.USER,
          className: 'tech-group-ticket',
          method: "POST",
          route: `${this.props.APIEndpoints.USER}/filter`,
          routeGetCount: `${this.props.APIEndpoints.USER}/active/count/all`,
          filterAll:{ fk_id_company : company, deleted : false, status : true, current : true},
          fieldquery: 'search',
          textinit:  values ? values.user_cad_name : language.USER,
          fieldvaluedb: 'id_user',
          fieldlabeldb: 'name',
          idinit: values ? values.user_cad : 0,
          routeAll: `${this.props.APIEndpoints.USER}/filter/all?`,
          detail: true,
          methodGetAll : 'POST',
          isMulti: true

        },
        {
          col: 6,
          // name: 'user_tech',
          // label: language.TECH,
          // type: 'listCheckbox',
          // required: false,
          //  options: usersOptionsTech

          type: 'autocomplete',
          name: 'user_tech',
          label: language.TECH,
          textlabel: language.TECH,
          className: 'tech-group-ticket',
          required: false,
          method: "POST",
          route: `${this.props.APIEndpoints.USER}/filter`,
          routeGetCount: `${this.props.APIEndpoints.USER}/active/count/tech`,
          filterAll:{ role:`tech`, all_fk_id_company : company, deleted : false, status : true, current : true},
          fieldquery: 'search',
          textinit: values ? values.user_tech_name : language.TECHS,
          fieldvaluedb: 'id_user',
          fieldlabeldb: 'name',
          idinit: values ? values.user_tech : 0,
          routeAll: `${this.props.APIEndpoints.USER}/filter/tech?`,
          detail: true,
          methodGetAll : 'POST',
          // showEmail : true,
        },
        {
          col: 6,
          type: 'autocomplete',
          name: 'fk_id_device_ic',
          label: language.DEVICE_IC,
          textlabel: language.DEVICE_IC,
          className: 'tech-group-ticket',
          method: 'POST',
          route: `${this.props.APIEndpoints.DEVICE}/filter/`,
          routeGetCount: `${this.props.APIEndpoints.DEVICE}/filter?offset=0&limit=1`,
          fieldquery: 'search',
          textinit:  values ? values.name_device_ic : language.DEVICE_IC,
          fieldvaluedb: 'id_device',
          fieldlabeldb: 'name',
          idinit: values ? values.fk_id_device_ic : [],
          routeAll: `${this.props.APIEndpoints.DEVICE}/filter?`,
          filterAll : this.state.params?.enabled_show_all_company ?
                      { fk_id_company : 0 } : { fk_id_company : company || 0 },
          methodGetAll: 'POST',
          detail: true,
          visible: true,
          isMulti : true,
          customFields : [
            {value : 'ci_status', label: language.CMDB_CI_STATUS },
            {value : 'ci_base_oper_status', label: language.CMDB_CI_BASE_OPER_STATUS},
            {value : 'ci_base_fqdn', label: language.CMDB_CI_BASE_FQDN},
          ]
        },
        {
          col: 6,
          type: 'autocomplete',
          name: 'fk_type_device_ic',
          label: language.DEVICE_TYPE_IC,
          textlabel: language.DEVICE_TYPE_IC,
          className: 'tech-group-ticket',
          required: false,
          method: "POST",
          route: `${this.props.APIEndpoints.DEVICEIC}/list?`,
          routeGetCount: `${this.props.APIEndpoints.DEVICEIC}/list?`,
          filterAll:{ role:`tech`, all_fk_id_company : company, deleted : false, status : true, current : true},
          fieldquery: 'search',
          textinit: values ? values.type_deviceic_name : language.DEVICE_TYPE_IC,
          fieldvaluedb: 'id_device_ic',
          fieldlabeldb: 'name',
          idinit: values ? values.fk_type_device_ic : 0,
          idinit: 0,
          routeAll: `${this.props.APIEndpoints.DEVICEIC}/list?`,
          detail: true,
          methodGetAll : 'POST',
        },
        {
          col: 6,
          name: 'type',
          label: language.TYPE_OF_MONITORING,
          type: 'listCheckboxSingle',
          required: true,
          options: [{ value: 'link', label: language.LINK },
          { value: 'desktop', label: language.DESKTOP },
          { value: 'server', label: language.SERVER },
          { value: '-', label: language.NONE }]

        }, {
           col: 6,
          name: 'contracts',
          label: language.CONTRACTS,
          type: 'listCheckbox',
          required: true,
          options: contractOptions

        // todo fazer rotas de contrato para implemtação de autocomplete
        // type: 'autocomplete',
        // name: 'contracts',
        // textlabel: language.CONTRACTS,
        // label: language.CONTRACTS,
        // className: 'tech-group-ticket',
        // required: true,
        // method: "POST",
        // route: `${this.props.APIEndpoints.USER}/filter`,
        // routeGetCount: `${this.props.APIEndpoints.USER}/active/count/all`,
        // filterAll: (company == 1 ? {fk_id_company : company || g.global == true} : {fk_id_out_company : company || g.global == true}),
        // fieldquery: 'search',
        // textinit:  values ? values.contracts_name : "Contratos",
        // fieldvaluedb: 'id_contract',
        // fieldlabeldb: 'name',
        // idinit: values ? values.contracts : 0,
        // routeAll: `${this.props.APIEndpoints.USER}/filter/all?`,
        // detail: true,
        // methodGetAll : 'POST',
        // isMulti: true

        },
        {
          col: 3,
          name: 'connection',
          label: language.CONNECTION,
          type: 'select',
          options: connectionDesktopOptions
        },
        { col: 3, name: 'patrimony', label: language.PATRIMONY, type: 'text' },
        {
          col: 3,
          name: 'cost',
          label: language.COST,
          type: 'text',
          InputProps: {

            inputComponent: MaskMoney
          },
        },
        { col: 3, name: 'model', label: language.MODEL, type: 'text' },
        { col: 3, name: 'vendor', label: language.VENDOR, type: 'text' },
        { col: 3, name: 'warranty', label: language.WARRANTY, type: 'text' },
        { col: 3, name: 'invoice', label: language.INVOICE, type: 'text' },
        { col: 3, name: 'serial_number', label: language.SERIAL, type: 'text' },
        { col: 3, name: 'serial_os', label: language.SERIAL_SO, type: 'text' },
        { col: 3, name: 'ip', label: language.IP, type: 'text' },
        { col: 3, name: 'port', label: language.PORT, type: 'number', inputProps: { min: 0 } },
        { col: 3, name: 'username', label: language.USERNAME, type: 'text' },
        { col: 3, name: 'password', label: language.PASSWORD, type: 'password' },
        { col: 3, name: 'domain', label: language.DOMAIN, type: 'text' },
        { col: 3, name: 'community', label: language.COMMUNITY, type: 'text' },
        { col: 3, name: 'url', label: language.URL, type: 'text' },
        { col: 3, name: 'address', label: language.ADDRESS, type: 'text' },
        { col: 3, name: 'macadress', label: language.MACADRESS, type: 'text' },
        { col: 3, name: 'alias', label: language.ALIAS, type: 'text' },
        { col: 3, name: 'provider', label: language.PROVIDER2, type: 'text' },

        {
          col: 9,
          name: 'ssh_key',
          label: language.SSH_KEY,
          type: 'text',
          multiLine: true
        },
        { col: 3, name: 'is_acn', label: language.ACN, type: 'switch' },

        {
          col: 12,
          name: 'description',
          label: language.DESCRIPTION,
          type: 'text',
          multiLine: true
        },
        {
          col: 12,
          name: 'files',
          label: language.ATTACHMENTS,
          type: 'file',
          onClick: (f) =>this.onDownFileTable(f)
        },

      ];
    }

    let button = {
      md: 2,
      offset: { md: 10 },
      label: clone ? language.CLONE : language.SAVE,
      primary: true,
      icon: 'fas fa-' + (clone ? 'clone' : 'save'),
      style: { float: 'right', fontSize: 15 }
    };

    return (

      <Card className='content'>
        <CommonForm

          fields={fields}
          onSubmit={(values) => clone ? this.onFormSubmit : onFormSubmit(values, inv)}
          onChangeField={this.onChangeField}
          button={button}
          values={values}
        />
        {/* {values && values.files.length > 0 && (
          <div>
            <Typography style ={{marginBottom : 20}}
            >
              Download dos arquivos
            </Typography>
            <CommonForm

              onChangeField={this.onChangeField}
              values={values}
              fields={[{
                col: 12, type: 'file', name: 'files',
                label: 'Anexos Solicitação', btn: true,
                onClick: f => this.onDownFile(f)
                //   ondonwFile(f.fk_id_request, f.nn)
              }]
              }
            />
          </div>
        )} */}

      </Card>

    );
  }
}
