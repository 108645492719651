import React, { useState, useEffect, memo } from 'react';
import './index.css';
/*------------------------------common----------------------------------*/
import { flexBasis } from '@common/Func';
/*------------------------------material-ui----------------------------------*/
import { Grid } from '@material-ui/core';
/*------------------------------pages----------------------------------*/
import MediumTime from './MediumTime';
import TicketEvolution from './TicketEvolution';
import TicketQuantity from './TicketQuantity';
import Priority from './Priority';

export default memo(
  ({
    avTicket,
    avRequest,
    avCon,
    graphModulesCO,
    progressmes,
    delayTicket,
    graphDtq,
    priority,
    openModalDetail,
    ticketsGSDTop,
    setLoad,
    language,
  }) => {
    const [avHourTicket, setAvHourTicket] = useState(0);
    const [avHourRequest, setAvHourRequest] = useState(0);
    const [avConsumed, setavConsumed] = useState(0);
    const [priorityDash, setPriority] = useState([
      {
        label: language.TICKET_PRIORITY.SMALL,
        value: 0,
        color: 'var(--danger)',
      },
      {
        label: language.TICKET_PRIORITY.MEDIUM,
        value: 0,
        color: 'var(--warning)',
      },
      {
        label: language.TICKET_PRIORITY.HIGH,
        value: 0,
        color: 'var(--success)',
      },
    ]);

    useEffect(() => {
      if (avTicket) {
        setAvHourTicket(avTicket);
      }
      if (avCon) {
        setavConsumed(avCon);
      }

      if (avRequest) {
        setAvHourRequest(avRequest);
      }
    }, [avRequest, avTicket]);

    useEffect(() => {
      if (priority) {
        let priorities = [];
        priorities.push({
          label: language.TICKET_PRIORITY.SMALL,
          color: 'var(--danger)',
          value: priority.find((p) => p.name == 'Baixa')
            ? priority.find((p) => p.name == 'Baixa').y
            : 0,
          fk_id_priority: priority.find((p) => p.name == 'Baixa')
            ? priority.find((p) => p.name == 'Baixa').fk_id_priority
            : 0,
        });

        priorities.push({
          label: language.TICKET_PRIORITY.MEDIUM,
          color: 'var(--warning)',
          value: priority.find((p) => p.name == 'Medio')
            ? priority.find((p) => p.name == 'Medio').y
            : 0,
          fk_id_priority: priority.find((p) => p.name == 'Medio')
            ? priority.find((p) => p.name == 'Medio').fk_id_priority
            : 0,
        });
        priorities.push({
          label: language.TICKET_PRIORITY.HIGH,
          color: 'var(--success)',
          value: priority.find((p) => p.name == 'Alto')
            ? priority.find((p) => p.name == 'Alto').y
            : 0,
          fk_id_priority: priority.find((p) => p.name == 'Alto')
            ? priority.find((p) => p.name == 'Alto').fk_id_priority
            : 0,
        });
        /*
			for (let i = 0; i<3; i++){
				let label = i == 0 ? 'Baixa' : i==1 ? 'Medio' : "Alto"
				let color = i == 0 ? 'var(--primary)' : "var(--success)"
				priorities.push({
					label : priority[i] ? priority[i].name : label,
					value : priority[i] ? priority[i].y : 0,
					color : priority[i] ? priority[i].color : color,
					fk_id_priority : priority[i] ? priority[i].fk_id_priority : 0
				})
			}*/
        setPriority(priorities);
      }

      setTimeout(() => {
        setLoad(true);
      }, 3000);
    }, [priority]);

    /*--------------------MediumTime1---------------------------*/
    let MediumTime1Props = {
      data: [
        {
          color: '#F66868',
          y: avHourTicket.max / 3,
        },
        {
          color: '#F0B002',
          y: avHourTicket.max / 3,
        },
        {
          color: 'var(--success-light)',
          y: avHourTicket.max / 3,
        },
      ],
      index: 0,
      y: avHourTicket.hour,
      hour: avHourTicket.hour,
      minute: avHourTicket.minutes,
      language,
    };
    /*--------------------MediumTime2---------------------------*/
    let MediumTime2Props = {
      data: [
        {
          color: '#F66868',
          y: avHourRequest.max / 3,
        },
        {
          color: '#F0B002',
          y: avHourRequest.max / 3,
        },
        {
          color: 'var(--success-light)',
          y: avHourRequest.max / 3,
        },
      ],
      index: 1,
      y: avHourRequest.hour,
      hour: avHourRequest.hour,
      minute: avHourRequest.minutes,
      language,
    };
    let MediumTime3Props = {
      data: [
        {
          color: '#F66868',
          y: avConsumed.max / 3,
        },
        {
          color: '#F0B002',
          y: avConsumed.max / 3,
        },
        {
          color: 'var(--success-light)',
          y: avConsumed.max / 3,
        },
      ],
      index: 2,
      y: avConsumed.hour,
      hour: avConsumed.hour,
      minute: avConsumed.minutes,
      language,
    };
    console.log("opennnnnnnn");
    console.log(progressmes.openv_)
    /*--------------------TicketEvolution---------------------------*/
    let TicketEvolutionProps = {
      data: [
        
        {
          name: language.TICKET_STATUS.OPENS,
          zIndex: 2,
          color: '#019FC2',
          data: Array.from(new Array(moment().daysInMonth()), (_, i) =>
            progressmes ? progressmes.openv_[i] : 0
          ),
        },
        {
          name: language.TICKET_STATUS.IN_PROGRESS,
          zIndex: 2,
          color: '#F0B002',
          data: Array.from(new Array(moment().daysInMonth()), (_, i) =>
            progressmes ? progressmes.PROGRESS_[i] : 0
          ),
        },
        {
          name: language.TICKET_STATUS.RESOLVED,
          zIndex: 2,
          color: '#33ACA0',
          data: Array.from(new Array(moment().daysInMonth()), (_, i) =>
            progressmes ? progressmes.RESOLVED_[i] : 0
          ),
        },
        {
          name: language.TICKET_STATUS.CLOSED,
          zIndex: 1,
          color: '#9E24F0',
          data: Array.from(new Array(moment().daysInMonth()), (_, i) =>
            progressmes ? progressmes.CLOSED_[i] : 0
          ),
        },
      ],
      language,
    };
    /*--------------------TicketQuantity---------------------------*/
    let TicketQuantityProps = {
      news: {
        generated: graphModulesCO ? graphModulesCO.RequestO : 0,
        late: delayTicket ? delayTicket.delayInRquest : 0,
      },
      open: {
        generated: graphDtq ? graphDtq.Open : 0,
        late: delayTicket ? delayTicket.delayOpen : 0,
      },
      attendance: {
        generated: graphDtq ? graphDtq.InProgress : 0,
        late: delayTicket ? delayTicket.delayInprogress : 0,
      },
      resolved: {
        generated: graphDtq ? graphDtq.Resolved : 0,
        late: delayTicket ? delayTicket.delayInResolved : 0,
      },
      closed: {
        generated: graphDtq ? graphDtq.Closed : 0,
        late: delayTicket ? delayTicket.delayInClosed : 0,
      },
      reject: {
        generated: graphDtq ? graphDtq.Reject : 0,
        late: delayTicket ? delayTicket.delayInReject : 0,
      },
      language,
    };
    /*--------------------Priority---------------------------*/
    let PriorityProps = {
      data: priorityDash,
      language,
    };
    return (
      <div id="dashboard-block-3" className="mb-2">
        <Grid container spacing={1}>
          {/*--------------------MediumTime---------------------------*/}
          <Grid item xs={1.2} style={{ ...flexBasis(1.2) }}>
            <MediumTime {...MediumTime1Props} />
          </Grid>
          <Grid item xs={1.2} style={{ ...flexBasis(1.2) }}>
            <MediumTime {...MediumTime2Props} />
          </Grid>
          <Grid item xs={1.2} style={{ ...flexBasis(1.2) }}>
            <MediumTime {...MediumTime3Props} />
          </Grid>
          {/*------------------TicketEvolution-------------------------------*/}

          <Grid item xs={4.6} style={{ ...flexBasis(4.6) }}>
            <TicketEvolution {...TicketEvolutionProps} />
          </Grid>

          <Grid item xs={3.8} style={{ ...flexBasis(3.8) }}>
            {/*---------------TicketQuantity-------------------------------*/}
            <TicketQuantity {...TicketQuantityProps} />
            {/*-------------------Priority-------------------------------*/}
            <Priority
              ticketsGSDTop={ticketsGSDTop}
              openModalDetail={openModalDetail}
              {...PriorityProps}
            />
          </Grid>
        </Grid>
      </div>
    );
  },
  function (prev, next) {
    return _.isEqual(
      [
        prev.avTicket,
        prev.avRequest,
        prev.avCon,
        prev.graphModulesCO,
        prev.progressmes,
        prev.delayTicket,
        prev.graphDtq,
        prev.priority,
        prev.ticketsGSDTop,
      ],
      [
        next.avTicket,
        next.avRequest,
        next.avCon,
        next.graphModulesCO,
        next.progressmes,
        next.delayTicket,
        next.graphDtq,
        next.priority,
        next.ticketsGSDTop,
      ]
    );
  }
);
