import { useState, useEffect } from 'react';
import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import Header from '@common/Header';
import CommonTable from '../../common/CommonTable';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import TicketManagement from '@components/TicketManagement'
import RequestView from '@components/request/RequestView';
import {
  TextField,
  FormControlLabel,
  Grid,
  Checkbox,
  Typography,
} from '@material-ui/core';
import history2 from '@common/history2';

import './ApprovalCenterContainer.css';
import DownloadFiles from '../../util/DownloadFiles';
const action = new Actions();
const language = Store.language;
history2.listen((location, action) => {
  window.localStorage.setItem('previous_url', location.pathname);
  window.scrollTo(0, 0);
});
const ApprovalCenterContainer = ({}) => {
  const [filterTypeSelected, setFilterTypeSelected] = useState([]);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [selectedItens, setSelectedItens] = useState([]);
  const [singleItem, setSingleItem] = useState([]);
  const [singleRejectDialog, setSingleRejectDialog] = useState(false);
  const [multiRejectDialog, setMultiRejectDialog] = useState(false);
  const [singleApproveDialog, setSingleApproveDialog] = useState(false);
  const [multiApproveDialog, setMultiApproveDialog] = useState(false);
  const [showTicketDialog, setShowTicketDialog] = useState(false)
  const [ticketToShow, setTicketToShow] = useState([]);
  const [requestToShow, setRequestToShow] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [itemView, setItemView] = useState(false);
  const filterType = [
    { label: language.CHANGE, value: 'Mudança' },
    { label: language.TASKS, value: 'Tarefa' },
    { label: language.TICKET, value: 'Ticket' },
  ];

  useEffect(() => {
    callPageSelectFilter();
  }, []);

  useEffect(() => {
    let itens = filter;
    itens.statusFilter = filterTypeSelected;
    callPageSelectFilter(0, itens, 75);
  }, [filterTypeSelected]);

  const callPageSelectFilter = (page = 0, items = {}, totalPerPage = 75) => {
    setFilter(items);
    let limit = totalPerPage;
    let offset = page * limit;
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.TICKET}/getallapprovals/?offset=${offset}&limit=${limit}`,
        '',
        items
      )
      .then((res) => {
        setData(res.data);
        setTotalData(res.data.length > 0 ? res.data[0].total_count : 0);
      });
  };

  const handleCheck = (item, checked) => {
    if (checked == true) {
      let items = selectedItens;
      items.push(item);
      setSelectedItens([...items]);
    } else {
      // selectedItens.filter((selected) => {
      //   if( i.id !=== item.id || i.id === item.id && i.fk_id_group_tech !=== item.fk_id_group_tech )
      // })
      // let items = selectedItens.filter((i) => ( i.id !== item.id || i.id === item.id && i.fk_id_group_tech !== item.fk_id_group_tech ));
      let items = selectedItens.filter((i) => ( i !== item ));
      setSelectedItens([...items]);
    }
  };

  const seeItem = (item) => {
    if (item.type == 'Ticket' && item.ticket_real_id.length == 0) {
      action
        .execute(
          'get',
          Constants.APIEndpoints.REQUEST + 'one' + '/' + item.id,
          'request'
        ).then(res => {
          let data = res.data
          data[0].typeItem = 'Ticket'
          setSingleItem({...data[0], ...item});
          setItemView(true);
        })
    } else if (item.type == 'Ticket' && item.ticket_real_id.length > 0) {
      action
      .execute('get', Constants.APIEndpoints.TICKET + '/' + item.id, '')
      .then((res) => {
        action
          .execute('post', Constants.APIEndpoints.TICKET + '/detail', '', {
            id_tickets: item.id,
          })
          .then((desc) => {
            let data = res.data;
            data.typeItem = 'Ticket';
            data.description = desc.data.description;
            setSingleItem({ ...data, ...item });
            setItemView(true);
          });
      });
    }
    else if (item.type == 'Tarefa') {
      action
        .execute('get', Constants.APIEndpoints.TASKS + '/' + item.id, '')
        .then((res) => {
          let data = res.data.tasks[0];
          data.typeItem = 'Tarefa';
          setSingleItem({ ...data, ...item });
          setItemView(true);
        });
    } else {
      action
        .execute('get', Constants.APIEndpoints.CHANGE + '/' + item.id, '')
        .then((res) => {
          let data = res.data;
          data.typeItem = 'Mudança';
          setSingleItem({ ...data, ...item });
          setItemView(true);
        });
    }
  };

  const clearScreen = () => {
    callPageSelectFilter(0, filter, 75);
    setSingleItem([]);
    setSelectedItens([]);
    setSingleRejectDialog(false);
    setMultiRejectDialog(false);
    setSingleApproveDialog(false);
    setMultiApproveDialog(false);
    setItemView(false);
  };

  const createLog = (values, reason, approve) => {
    values.approve = approve;
    values.justify = reason;
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.TICKET}/createlogapproval`,
        '',
        values
      )
      .catch((error) => {
        console.error({ error });
      });
  };

  const approveMulti = (values) => {
    const tickets = selectedItens.filter((s) => s.type == 'Ticket');
    const tasks = selectedItens.filter((s) => s.type == 'Tarefa');
    const changes = selectedItens.filter((s) => s.type == 'Mudança');

    tickets.map((ticket) => {
      approveSingle('Ticket', values.reason, ticket);
    });

    tasks.map((task) => {
      task.id_tasks = task.id;
      approveSingle('Tarefa', values.reason, task);
    });

    changes.map((change) => {
      change.id_change = change.id;
      approveSingle('Mudança', values.reason, change);
    });
  };

  const rejectMulti = (values_) => {
    const tickets = selectedItens.filter((s) => s.type == 'Ticket');
    const tasks = selectedItens.filter((s) => s.type == 'Tarefa');
    const changes = selectedItens.filter((s) => s.type == 'Mudança');

    tickets.map((ticket) => {
      rejectSingle(values_, 'Ticket', ticket);
    });

    tasks.map((task) => {
      task.id_tasks = task.id;
      rejectSingle(values_, 'Tarefa', task);
    });

    changes.map((change) => {
      change.id_change = change.id;
      rejectSingle(values_, 'Mudança', change);
    });
  };

  const getRequestByTicket = () =>{
    action
    .execute(
      'get',
      Constants.APIEndpoints.REQUEST + 'one' + '/' + singleItem.id_request,
      'request'
    ).then(res => {
      setRequestToShow(res.data[0])
      setShowRequest(true)

    })
  }


  const approveSingle = (type, reason, data) => {
    let values = singleItem;
    if (data) {
      values = data;
    }

    if (type == 'Ticket') {
      values.action = 'approval';
      values.reason = reason;
      values.status = 'Approve';
      action
        .execute(
          'post',
          Constants.APIEndpoints.REQUEST + '/' + values.id_request,
          '',
          values
        )
        .then((res) => {
          clearScreen();
          createLog(values, reason, true);
        });
    } else if (type == 'Tarefa') {
      action
        .execute('post', Constants.APIEndpoints.TASKS + '/approvetask', '', {
          ...{ justify: reason, approve: true },
          ...values,
        })
        .then((e) => {
          if (e.data.code == '3030') {
            Store.toggleAlert(
              true,
              language.USER_IS_NOT_PART_OF_THE_APPROVAL_GROUP,
              'error'
            );
          }
          if (e.data.code == '3031') {
            Store.toggleAlert(true, language.USER_HAS_ALREADY_APPROVED, 'error');
          }
          if (e.data.code == 'ok') {
            Store.toggleAlert(
              true,
              language.APPROVAL_SUCCESSFULLY_COMPLETED,
              'success'
            );
          }
          clearScreen();
          createLog(values, reason, true);
        });
    } else {
      values.fk_id_change = values.id_change;
      values.approved = true;
      values.reason = reason;
      action
        .execute(
          'post',
          `${Constants.APIEndpoints.CHANGE}/revision`,
          'Mudança',
          values,
          'id_change'
        )
        .then((e) => {
          if (e.data.erro == 20) {
            Store.toggleAlert(
              true,
              language.USER_DOES_NOT_HAVE_PERMISSION,
              'error'
            );
          } else if (e.data.erro == 30) {
            Store.toggleAlert(true, language.USER_HAS_ALREADY_PERFORMED_THIS_ACTION, 'error');
          } else {
            clearScreen();
            createLog(values, reason, true);
          }
        });
    }
  };

  const rejectSingle = (values_, type, data) => {
    let values = singleItem;
    if (data) {
      values = data;
      values.typeItem = type;
    }
    if (values.typeItem == 'Ticket') {
      values.action = 'approval';
      values.reason = values_.reason;
      values.status = 'Rejected';
      if (values_.files && values_.files.length > 0) {
        let filesSend = [];
        let cont = 0;
        values.files = values_.files;
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
              filesSend = [];
              cont = 0;
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              let host = Constants.APIRoot;
              values.file = filesSend;

              action
                .execute('post', `${host}/api/request/${values.id_request}`, '', values)
                .then((data) => {
                  clearScreen();
                  createLog(values, values_.reason, false);
                });
            }
          });
        });
      } else {
        action
          .execute(
            'post',
            Constants.APIEndpoints.REQUEST + '/' + values.id_request,
            '',
            values
          )
          .then((res) => {
            clearScreen();
            createLog(values, values_.reason, false);
          });
      }
    } else if (values.typeItem == 'Tarefa') {
      values.files = values_.files;
      values.justify = values_.reason;
      if (values_.files && values_.files.length > 0) {
        let filesSend = [];
        let cont = 0;
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
              filesSend = [];
              cont = 0;
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              let host = Constants.APIRoot;
              values.file = filesSend;

              action
                .execute('post', `${host}/api/tasks/approvetask`, '', values)
                .then((data) => {
                  clearScreen();
                  createLog(values, values_.reason, false);
                });
            }
          });
        });
      } else {
        action
          .execute(
            'post',
            Constants.APIEndpoints.TASKS + '/approvetask',
            '',
            values
          )
          .then((res) => {
            clearScreen();
            createLog(values, values_.reason, false);
          });
      }
    } else {
      values.fk_id_change = values.id_change;
      values.approved = false;
      if (values_.files && values_.files.length > 0) {
        let filesSend = [];
        let cont = 0;
        values.files = values_.files;
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
              filesSend = [];
              cont = 0;
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              let host = Constants.APIRoot;
              values.file = filesSend;

              action
                .execute('post', `${Constants.APIEndpoints.CHANGE}/revision`, '', values)
                .then((data) => {
                  clearScreen();
                  createLog(values, values_.reason, false);
                });
            }
          });
        });
      } else {
        action
          .execute(
            'post',
            `${Constants.APIEndpoints.CHANGE}/revision`,
            '',
            values
          )
          .then((res) => {
            clearScreen();
            createLog(values, values_.reason, false);
          });
      }
    }
  };

  const downFileTicket = (item) => {
    DownloadFiles(
      Constants.APIEndpoints.REQUESTS +
        '/api/downloadticket/' +
        item.id_tickets_file +
        '&' +
        item.file,
      item.file
    );
  };
  const downFileRequest = (item) => {
    DownloadFiles(
      Constants.APIEndpoints.REQUESTS +
        '/api/downloadrequest/' +
        item.id_request_file +
        '&' +
        item.file,
      item.file
    );
  };
  const downFileTask = (item) => {
    DownloadFiles(
      Constants.APIEndpoints.REQUESTS +
        '/api/downloadTasks/' +
        item.id_tasks_files +
        '&' +
        item.namefile,
      item.namefile
    );
  };
  const downFileChange = (item) => {
    DownloadFiles(
      Constants.APIEndpoints.REQUESTS +
        '/api/downloadchange/' +
        item.id_change_file +
        '&' +
        item.file,
      item.file
    );
  };
  const columns = [
    {
      key: 'check',
      label: '',
    },
    {
      key: 'see',
      label: '',
    },
    {
      key: 'id_',
      label: language.ID,
    },
    {
      key: 'ticket_real_id',
      label: language.ID_TICKET,
    },

    {
      key: 'type',
      label: language.TYPE,
    },
    {
      key: 'company_name',
      label: language.COMPANY,
    },
    {
      key: 'group_tech_name',
      label: language.GROUP_TECH,
    },
    {
      key: 'dt_cad',
      label: language.DT_CAD,
    },
    {
      key: 'start_date',
      label: language.START_DATE,
    },
    {
      key: 'end_date',
      label: language.END_DATE,
    },
    {
      key: 'sol',
      label: language.SOLICITANT,
    },
    {
      key: 'creator',
      label: language.CREATOR,
    },
  ];
  const dataTable = data.map((d) => ({
    check: (
      <Checkbox
        checked={!!selectedItens.find((e) => (e === d))}
        onChange={(evt) => handleCheck(d, evt.target.checked)}
        style={{ margin: 'auto', display: 'flex' }}
      />
    ),
    see: (
      <i
        class="fas fa-eye"
        style={{
          width: 'auto',
          display: 'flex',
          justifyContent: 'center',
          fontSize: 16,
          height: 10,
          cursor: 'pointer',
        }}
        onClick={() => seeItem(d)}
      ></i>
    ),
    id_: d.id_,
    ticket_real_id : d.ticket_real_id,
    type: d.type,
    company_name: d.company_name,
    group_tech_name: d.group_tech_name,
    dt_cad: d.dt_cad ? moment(d.dt_cad).format('DD/MM/YYYY HH:mm:ss') : '',
    start_date: d.start_date
      ? moment(d.start_date).format('DD/MM/YYYY HH:mm:ss')
      : '',
    end_date: d.end_date
      ? moment(d.end_date).format('DD/MM/YYYY HH:mm:ss')
      : '',
    sol: d.sol,
    creator: d.creator,
  }));
  let button = null;
  if (selectedItens.length > 0) {
    button = (
      <div className="d-flex align-items-center">
        <Button
          variant="normal"
          label={language.APPROVE}
          color="warning"
          style={{ marginRight: 10, padding: 10 }}
          onClick={() => setMultiApproveDialog(true)}
          icon="fas fa-check"
        />
        <Button
          variant="normal"
          label={language.REJECT}
          color="danger"
          style={{ marginRight: 10, padding: 10 }}
          onClick={() => setMultiRejectDialog(true)}
          icon="fas fa-trash-alt"
        />
      </div>
    );
  }

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.APPROVAL_CENTER}
        subTitle=""
      ></PageLayout>
      <div id="ApprovalCenter" style={{ marginTop: '-10px' }}>
        <CommonTable
          col={columns}
          onClearAllFilter={() => {
            callPageSelectFilter();
            setSingleItem([]);
            setFilterTypeSelected([]);
          }}
          countTotal={totalData}
          paginationTop={false}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          searchColumn
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          maxHeight={500}
          data={dataTable}
          beforeBar={button}
          beforeBar2={
            <div className="content-item-control d-flex align-items-center">
              {filterType.map((filter, i) => (
                <a
                  href="#"
                  style={{
                    backgroundColor: filterTypeSelected.find(
                      (item) => item.value === filter.value
                    )
                      ? 'var(--warning)'
                      : 'transparent',
                  }}
                  onClick={() => {
                    setSingleItem([])
                    if (
                      filterTypeSelected.find(
                        (item) => item.value === filter.value
                      )
                    ) {
                      setFilterTypeSelected([
                        ...filterTypeSelected.filter(
                          (item) => item.value !== filter.value
                        ),
                      ]);
                    } else {
                      setFilterTypeSelected([...filterTypeSelected, filter]);
                    }
                  }}
                >
                  <span>{filter.label}</span>
                </a>
              ))}
            </div>
          }
        />
        <div style={{ height: 20 }}></div>
        <Dialog
          open={singleRejectDialog}
          title={language.REJECT}
          onClose={() => setSingleRejectDialog(false)}
          maxWidth="xl"
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'reason',
                label: language.JUSTIFICATION,
                type: 'textEditor',
                required: true,
              },
              {
                col: 4,
                name: 'files',
                label: language.ATTACH,
                type: 'file',
                required: false,
                customPortalCss: true,
              },
            ]}
            onSubmit={rejectSingle}
            button={{ label: language.SAVE }}
          />
        </Dialog>
        <Dialog
          open={multiRejectDialog}
          title={language.REJECT}
          onClose={() => setMultiRejectDialog(false)}
          maxWidth="xl"
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'reason',
                label: language.JUSTIFICATION,
                type: 'textEditor',
                required: true,
              },
              {
                col: 4,
                name: 'files',
                label: language.ATTACH,
                type: 'file',
                required: false,
                customPortalCss: true,
              },
            ]}
            onSubmit={rejectMulti}
            button={{ label: language.SAVE }}
          />
        </Dialog>

        <Dialog
          open={singleApproveDialog}
          title={language.APPROVE}
          onClose={() => setSingleApproveDialog(false)}
          maxWidth="xl"
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'reason',
                label: language.JUSTIFICATION,
                type: 'textEditor',
                required: true,
              },
            ]}
            onSubmit={(values) =>
              approveSingle(singleItem.typeItem, values.reason)
            }
            button={{ label: language.SAVE }}
          />
        </Dialog>
        <Dialog
          open={multiApproveDialog}
          title={language.APPROVE}
          onClose={() => setMultiApproveDialog(false)}
          maxWidth="xl"
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'reason',
                label: language.JUSTIFICATION,
                type: 'textEditor',
                required: true,
              },
            ]}
            onSubmit={approveMulti}
            button={{ label: language.SAVE }}
          />
        </Dialog>

            <Dialog
            open = {showTicketDialog}
            onClose = {() => setShowTicketDialog(false)}

            >
            <TicketManagement
                detail={true}
                id_ticket_detail={ticketToShow}
              ></TicketManagement>
            </Dialog>
            {showRequest ? (
          <RequestView
            language={language}
            solicit={

              Store.getUserLoged().role == 'solicitant' ? true : false
            }
            onClose={() => setShowRequest(false)}
            store={Store}
            request={requestToShow}
            redirect2={(route, state) =>  history2.push(route, state)}
            ondonwFile={downFileRequest}
          />
        ) : null}

        <Dialog
          open={itemView}
          title={language.APPROVAL}
          onClose={() => setItemView(false)}
          maxWidth="xl"
        >
          <Grid
            container
            alignItems="flex-start"
            spacing={4}
            style={{
              marginTop: 20,
              height: 200,
              width: '100%',
              margin: 'auto',
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            {Object.keys(singleItem).length > 0 && (
              <div style={{display : 'contents'}}>
                <Grid item xs={5} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography className="title">{language.DESCRIPTION}</Typography>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: singleItem.description ?? singleItem.benefits,
                    }}
                    className="label"
                  ></Typography>
                </Grid>
                <Grid item xs={1} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography className="title">ID</Typography>
                  <Typography>
                  {singleItem.id_ ?? singleItem.id_tasks ?? singleItem.id_change}
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography className="title">{language.SUBJECT}</Typography>
                  <Typography>
                    {singleItem.subject ?? singleItem.reason ?? singleItem.name}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography className="title">{language.FILES}</Typography>
                  {singleItem.typeItem == 'Ticket' ? (
                    <div>
                      {(singleItem.config ? singleItem.config[0].files : [] ).map((file) => (
                        <p className="file" onClick={() => downFileTicket(file)}>
                          {file.file}
                        </p>
                      ))}
                      { (singleItem.config ?singleItem.config[0].filesrequest
                      : Array.isArray(singleItem.files) ? singleItem.files : []).map((file) => (
                        <p className="file" onClick={() => downFileRequest(file)}>
                          {file.file}
                        </p>
                      ))}
                    </div>
                  ) : null}
                  {singleItem.typeItem == 'Tarefa'
                    ? (singleItem.files || []).map((file) => (
                        <p className="file" onClick={() => downFileTask(file)}>
                          {file.namefile}
                        </p>
                      ))
                    : null}
                  {singleItem.typeItem == 'Mudança'
                    ? (singleItem.file || []).map((file) => (
                        <p className="file" onClick={() => downFileChange(file)}>
                          {file.file}
                        </p>
                      ))
                    : null}
                </Grid>
                <Grid item xs={1} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography className="title">{language.ACTIONS}</Typography>
                  <div style={{ display: 'grid' }}>
                    <Button
                      variant="normal"
                      label={language.APPROVE}
                      color="success"
                      style={{ marginBottom: 10, marginRight: 10, padding: 10 }}
                      onClick={() => setSingleApproveDialog(true)}
                      icon="fas fa-check"
                    />{' '}
                    <Button
                      variant="normal"
                      label={language.REJECT}
                      color="danger"
                      style={{ marginBottom: 10, marginRight: 10, padding: 10 }}
                      onClick={() => setSingleRejectDialog(true)}
                      icon="fas fa-trash-alt"
                    />
                  {singleItem.typeItem.toLowerCase() !== 'mudança' && (
                    <Button
                      variant="normal"
                      label={language.VISUALIZE}
                      color="warning"
                      style={{ marginBottom: 10, marginRight: 10, padding: 10 }}
                      onClick={() => {
                        if (singleItem.type.toLowerCase() == 'tarefa'){
                          const ticket = {
                            id_tickets : singleItem.ticket_id,
                            real_id : singleItem.ticket_real_id
                          }
                          setTicketToShow(ticket)
                          setShowTicketDialog(true)
                        }else {
                          if (singleItem.ticket_real_id.length == 0){
                            setShowRequest(true)
                            setRequestToShow(singleItem)
                          }else{
                            getRequestByTicket()
                          }
                        }
                      }}
                      icon="fas fa-eye"
                    /> )}
                  </div>

                </Grid>
              </div>
            )}
          </Grid>
        </Dialog>
      </div>
    </CommonPage>
  );
};

export default ApprovalCenterContainer;
