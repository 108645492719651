import Store from '@data/Store';

/**
 * This method get the timezone of the last user who updated the form using the hidden field.
 * @param {*} html - Html content
 * @returns - The timezone
 */
export const getTimezoneOfLastUserWhoUpdatedForm = (html) => {
  const tzLastUserUpdateMatch = String(html).match(/<input.*?(?:id=\"timezone-last-update-user\".*?value=\"([^"]+)|value=\"([^"]+).*?id=\"timezone-last-update-user\")[^>]*>/);
  const tzLastUserUpdateTicket = tzLastUserUpdateMatch && tzLastUserUpdateMatch[1] ? tzLastUserUpdateMatch[1] : '';
  return tzLastUserUpdateTicket;
}

/**
 * This method applies the timezone to the field type datetime. 
 * The logic uses a hidden field that was previously added to form on the edition. 
 * This field has the timezone of the last user who updated the form.
 * The timezone is necessary to calculate the new time based on the timezone from the session user.
 * @param {*} html - Form html decrypted 
 * @param {*} htmlValues - Values from the html
 * @returns - The HTML with the timezone of the session user applied on the time fields.
 */

export const applyTimezoneOnHTML = (html, htmlValues) => {
  /**
   * This is used by the integration with servicenow.
   */
  const tzHtmlValues = (htmlValues || []).find((h) => h.name === 'timezone-last-update-user')?.value;

  /**
   * Finding the hidden field with the time zone of the user who created the ticket.
   */
  const tzLastUserUpdateForm = getTimezoneOfLastUserWhoUpdatedForm(html);
  const tzLastUserUpdateTicket = tzLastUserUpdateForm || tzHtmlValues;

  if (!tzLastUserUpdateTicket) {
    return html;
  }

  const userLogin = Store.getUserLoged();
  const tzCurrentUser = userLogin?.timezone?.value;

  // if (tzLastUserUpdateTicket === tzCurrentUser) {
  //   return html;
  // }

  html.split('<div').forEach((i) => {
    /**
     * Finding the datetime field on the HTML. 
     */
    const currentFieldMatch = i.match(/<input.*?(?:type=\"datetime-local\".*?value=\"([^"]+)|value=\"([^"]+).*?type=\"datetime-local\")[^>]*>/);
    const isDateTimeField = currentFieldMatch && !!currentFieldMatch[1];

    if (isDateTimeField) {
      const currentFieldValue = currentFieldMatch[1];
      if (!currentFieldValue) {
        return html;
      }

      /**
       * Applying the timezone
       */
      const tzTimeCurrentUser = moment.tz(currentFieldValue, tzLastUserUpdateTicket).clone().tz(tzCurrentUser).format('YYYY-MM-DD[T]HH:mm');
      html = html.replace(currentFieldValue, tzTimeCurrentUser);
    }
  });
  return html;
}

window.applyTimezoneOnHTML = applyTimezoneOnHTML;
window.getTimezoneOfLastUserWhoUpdatedForm = getTimezoneOfLastUserWhoUpdatedForm;
window.Store = Store;