import React from 'react';
import './index.css';
import { observer } from 'mobx-react';

// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import Header from '@common/Header';
import Button from '@common/Button';
import SearchInput from '@common/SearchInput';
import Animate from '@common/Animate';
import EventBus from '@common/EventBus';
// --------------------------------material-ui----------------------------------------------------
import { Card } from '@material-ui/core';
// --------------------------------pages----------------------------------------------------
import Kanban from './Kanban';
import Gantt from './Gantt';
import BarRight from './BarRight';
import {
  jsonFormatKanban,
  jsonFormatGantt,
  jsonFormatGanttTable,
} from './jsonFormat';
// ----------------------------------------------<----------------------------------------------------------------------------------------------------
import Options from './options';

// @observer
export default class extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      rd: 4,
      search: null,
      local: null,
      usersid: null,
      dt_start: null,
      dt_end: null,
      formRef: null,
      project: [],
      clear_autocomplete: false,
    };
    this.idProject = parseInt(this.props.match.params.id); //---esse é o id do projeto, vindo da url;
    this.updateProject = this.updateProject.bind(this);
  }

  componentWillMount() {
    this.store.project = {};
    this.action
      .execute(
        'get',
        `${this.APIRoot}/api/projects/${this.idProject}`,
        'project'
      )
      .then((res) => {
        this.setState({ project: res.data });
      });

  }

  updateProject() {
    this.action
      .execute(
        'get',
        `${this.APIRoot}/api/projects/${this.idProject}`,
        'project'
      )
      .then((res) => {
        this.setState({ project: res.data });
      });
  }

  onSubmit = (v, isDuplicate = false) => {
    if (!v.hours_in_days) {
      v.hours = v.hours.substring(0, 2) + ':' + v.hours.substring(2, 4);
    }
    v.isDuplicate = isDuplicate;
    if (v.files && v.files.length) {
      v.files = v.files.map((e) => ({
        id_project_activity: v.id_project_activity,
        file: e.base64,
        name: e.file.name,
        size: e.file.size,
        type: e.file.type,
      }));
    }

    this.action
      .execute(
        'put',
        `${this.APIRoot}/api/project/activity`,
        'project',
        v,
        'id_project',
        { btn_loading: 'load_activity' }
      )
      .then((e) => {
        window.$eventBus.emit('close-modal-form-activity-project', 1);
        if (!!isDuplicate) {
          //duplicar sub atividades

          this.store.subActivities.map((sub, i) => {
            const data = {
              name: sub.name,
              fk_id_responsible: sub.fk_id_responsible,
              dt_end: sub.dt_end,
              fk_id_project: v.fk_id_project,
              fk_id_activity: parseInt(e.data.newActivityId),
              neworder: i + 1,
            };

            this.action
              .execute(
                'post',
                this.APIEndpoints.PROJECT + '/activity/subactivity',
                'subActivities',
                data,
                null,
                { btn_loading: 'subactivities' }
              )
              .then((e) => {
                (_) => console.log('save');
              });
          });
        }
        window.$eventBus.emit('evtUpdateStatus', {
          fk_id_project_activity: v.id_project_activity,
          //pj: e.data,
        });
      })
      .catch((e) => {
        if (e.data.code == 'E501') {
          this.store.toggleAlert(
            true,
            'Valor digitado no esforço é inválido, por favor corrigir.',
            'error'
          );
        }
      });
    //  window.location.reload();
    setTimeout(() => {
      this.action
        .execute(
          'get',
          `${this.APIRoot}/api/projects/${this.idProject}`,
          'project'
        )
        .then((res) => {
          this.setState({ project: res.data });
          window.$eventBus.emit('evtUpdateStatus', {
            fk_id_project_activity: res.data.id_project_activity,
            pj: res.data,
          });
        });
    }, 1000);
  };
  onDelete = (id) => {
    this.action
      .execute(
        'put',
        `${this.APIEndpoints.PROJECT}/deleteactivity/${this.idProject}/${id}`,
        'project',
        null,
        'id_project'
      )
      .then((res) => {
        this.setState({ project: res.data });
      });
  };

  filter = (e) => {
    let { search, local, usersid, dt_start, dt_end } = this.state;

    let errors = [];

    if (dt_start) {
      /*  errors.push(
        moment(e.dt_start).isBetween(
          dt_start || Date.now() / 1000,
          dt_end || Date.now() * 1000,
          undefined,
          '[]'
        )
      );*/

      errors.push(moment(e.dt_start).isSameOrAfter(moment(dt_start), 'day'));
    }

    if (dt_end) {
      /*  errors.push(
        moment(e.dt_end).isBetween(
          dt_start || Date.now() / 1000,
          dt_end || Date.now() * 1000,
          undefined,
          '[]'
        )
      );*/
      errors.push(moment(e.dt_end).isSameOrBefore(moment(dt_end), 'day'));
    }

    if (search) {
      errors.push(
        `${e.name} ${(e.tagac || []).map((e) => e.name).join(' ')}`
          .toLowerCase()
          .indexOf(search.toLowerCase()) != -1
      );
    }
    if (local) {
      errors.push((e.local || []).includes(local));
    }

    if (usersid) {
      errors.push((e.usersid || []).includes(usersid));
    }
    return errors.every(Boolean);
  };
  render() {
    let {
      language,
      users,
      companies,
      FilesD,
      contracts,
      tickets,
      departments,
      subActivities,
    } = this.store;
    let { PROJECT } = this.store.theme;
    let { state } = this.history2.location;
    var ticket = undefined;
    let { column, barRight } = PROJECT;
    let { search, local, usersid, dt_start, dt_end, formRef, project } =
      this.state;
    if (state && state.ticket != undefined) {
      ticket = state.ticket;
      //   this.getTickets(ticket.fk_id_contract)
    }

    let utils = project.utils || {};

    let options = Options({
      users,
      // activities_predecessor:(utils.TODO||[]),
      activities_predecessor: [
        ...(utils.TODO || []),
        ...(utils.BACKLOG || []),
        ...(utils.DOING || []),
        ...(utils.STOPPED || []),
        ...(utils.VALIDATION || []),
      ],
      companies,
    });

    // ###############################################-KANBAN-#####################################################

    let data = [
      {
        title: language.BACKLOG,
        data: (utils.BACKLOG || [])
          .filter(this.filter)
          .map((e) => jsonFormatKanban(e, users)),
      },
      {
        title: language.TO_DO,
        data: (utils.TODO || [])
          .filter(this.filter)
          .map((e) => jsonFormatKanban(e, users)),
      },
      {
        title: language.DOING,
        data: (utils.DOING || [])
          .filter(this.filter)
          .map((e) => jsonFormatKanban(e, users)),
      },
      {
        title: language.IMPEDITIVE,
        data: (utils.STOPPED || [])
          .filter(this.filter)
          .map((e) => jsonFormatKanban(e, users)),
      },
      {
        title: language.VALIDATION,
        data: (utils.VALIDATION || [])
          .filter(this.filter)
          .map((e) => jsonFormatKanban(e, users)),
      },
      {
        title: language.FINISHED,
        data: (utils.DONE || [])
          .filter(this.filter)
          .map((e) => jsonFormatKanban(e, users)),
      },
    ];
    // ###############################################-GANTT-#####################################################
    //--------------------------------------------------view gantt------------------------------------------------
    let dataGantt = [
      {
        label: language.BACKLOG,
        data: (utils.BACKLOG || [])
          .filter(this.filter)
          .map((e) => jsonFormatGantt(e, users)),
      },
      {
        label: language.TO_DO,
        data: (utils.TODO || [])
          .filter(this.filter)
          .map((e) => jsonFormatGantt(e, users)),
      },
      {
        label: language.DOING,
        data: (utils.DOING || [])
          .filter(this.filter)
          .map((e) => jsonFormatGantt(e, users)),
      },
      {
        label: language.IMPEDITIVE,
        data: (utils.STOPPED || [])
          .filter(this.filter)
          .map((e) => jsonFormatGantt(e, users)),
      },
      {
        label: language.VALIDATION,
        data: (utils.VALIDATION || [])
          .filter(this.filter)
          .map((e) => jsonFormatGantt(e, users)),
      },
      {
        label: language.FINISHED,
        data: (utils.DONE || [])
          .filter(this.filter)
          .map((e) => jsonFormatGantt(e, users)),
      },
    ];
    //--------------------------------------------------view table------------------------------------------------

    let dataTable = [
      ...(utils.BACKLOG || []).filter(this.filter),
      ...(utils.TODO || []).filter(this.filter),
      ...(utils.DOING || []).filter(this.filter),
      ...(utils.STOPPED || []).filter(this.filter),
      ...(utils.VALIDATION || []).filter(this.filter),
      ...(utils.DONE || []).filter(this.filter),
    ]
      .flatMap((e) => e)
      .reduce(
        (obj, a) => {
          (a.tagac || []).forEach((tag) => {
            let index = obj.findIndex(
              (t) => t.id_project_tag == tag.id_project_tag
            );

            if (index != -1) {
              obj[index].data.push(jsonFormatGanttTable(a, users));
            }
          });

          return obj;
        },
        (project.tagpj || [])
          .filter(
            (e) =>
              e.name
                .toLowerCase()
                .indexOf((this.state.search || '').toLowerCase()) != -1
          )
          .map((e) => ({
            id_project_tag: e.id_project_tag,
            name: e.name,
            color: e.color,
            data: [],
          }))
      );

    // --------------------------------------------------------------------------------------------------------------------------------
    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        <PageLayout
          title={language.PROJECT_PROGRESS}
          subTitle={`${language.PROJECT_MANAGEMENT} / ${language.PROJECTS}`}
          rightElements={[
            <div
              style={{ width: 1200, zoom: 0.8, marginTop: '1rem' }}
              zoom={0.8}
              className="mr-5"
            >
              <CommonForm
                individual
                onRef={(formRef) => {
                  this.setState({ formRef });
                }}
                fields={[
                  {
                    col: 2.5,
                    type: 'search',
                    name: 'search',
                    label: language.SEARCH_FOR,
                    startIcon: 'fas fa-search',
                  },
                  {
                    col: 2,
                    type: 'select',
                    name: 'local',
                    label: language.LOCATION,
                    options: options.local(),
                  },
                  {
                    col: 2,
                    name: 'usersid',
                    label: language.ACTIVITY_PER_USER,
                    type: 'autocomplete',
                    textlabel: language.ACTIVITY_PER_USER,
                    method: 'POST',
                    route: `${this.APIEndpoints.USER}/filter/all?`,
                    routeGetCount: `${this.APIEndpoints.USER}/active/count/all`,
                    textinit: language.USERS,
                    idinit: 0,
                    filterAll: { fk_id_project: this.idProject },
                    fieldquery: 'search',
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    routeAll: `${this.APIEndpoints.USER}/filter/all?`,
                    detail: true,
                    methodGetAll: 'POST',
                    cleanValue: this.state.clear_autocomplete,
                  },
                  {
                    col: 2,
                    type: 'date',
                    name: 'dt_start',
                    label: language.INITIAL_DATE,
                  },
                  {
                    col: 2,
                    type: 'date',
                    name: 'dt_end',
                    label: language.FINAL_DATE,
                  },
                  {
                    col: 1.5,
                    type: 'element',
                    content: (
                      <Button
                        icon="fas fa-eraser"
                        label={language.RESET}
                        variant="normal"
                        color="primary"
                        className="mt-3"
                        onClick={() => {
                          this.setState({
                            search: null,
                            local: null,
                            usersid: null,
                            dt_start: null,
                            dt_end: null,
                            clear_autocomplete: true,
                          });
                          setTimeout(() => {
                            this.setState({ clear_autocomplete: false });
                          }, 100);
                          formRef.clean();
                        }}
                      />
                    ),
                  },
                ]}
                onChangeField={(f, v) => {
                  this.setState({ [f.name]: v });
                }}
              />
            </div>,
          ]}
        >
          <Animate>
            <div id="ProjectItem" className="pr-5 -mt-3">
              <Gantt
                data={dataGantt}
                dataTable={dataTable}
                start_gantt={utils.start_gantt}
                end_gantt={utils.end_gantt}
                project={project}
              />
              <Kanban
                idProject={this.idProject}
                updateProject={this.updateProject}
                onSubmit={this.onSubmit}
                ticket={ticket}
                data={data}
                clearHistory={this.clearHistory}
                options={options}
                project={project}
                onDelete={this.onDelete}
                users={users}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                subActivities={subActivities}
                columnTheme={column}
                state={state}
              />
            </div>
            <BarRight
              idProject={this.idProject}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              barRightTheme={barRight}
            />
          </Animate>
        </PageLayout>
      </CommonPage>
    );
  }
}
