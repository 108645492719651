import React, {memo} from 'react';
import {Card} from '@material-ui/core';
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import {BURNDOWN} from './Graph';

export default memo(({
	language,

	ac,
	tickets,
	sprintkaban
	,
	burn
})=>{
  
	 let pac=[];
	 let ptic=[];
	 let evolution=[];
	 let desired_maturity=[];

	 try{
		ptic= sprintkaban[0].sprint.tickets_unplanned 
		
	 }catch(r){}
	 try{
		pac= sprintkaban[0].sprint.project_sprints_activities_unplaned 
	}catch(r){}
 
        
	 let atividades=[];
	 let tickets_=[];
 
	 ac.map(g=>{
       
 let item=sprintkaban[0].assoac.filter(b=>b.fk_id_activity == g.id_project_activity)[0]
    g.dt_ac= item.dt_cad
       
    atividades.push(g)
	 })

let quantidade=	 sprintkaban[0].assotickets.length+sprintkaban[0].assoac.length
	 tickets.map(g=>{
       
		let item=sprintkaban[0].assotickets.filter(b=>b.fk_id_tickets
			 == g.id_tickets)[0]
		   g.dt_ac= item.dt_cad
			  
		   tickets_.push(g)
			})
	   
 
   
	let days = moment(sprintkaban[0].sprint.dt_end).diff(
	  moment(sprintkaban[0].sprint.dt_start),'days')

	let count =0;
	let categories=[];
	let counttotalok=0;
	let acumulado=0;
	let pro= 100  / (days+1)

	while (count < days+1){
		//moment(sprintkaban[0].sprint.dt_start).add(count,'days').format('DD/MM/YY')
	   categories.push(moment(sprintkaban[0].sprint.dt_start).add(count,'days').format('DD/MM'))
          let d= moment(sprintkaban[0].sprint.dt_start).add(count,'days').format('DDMM')
		 
	  let valor = burn.filter(c=> parseInt(c.dt_cad) == parseInt(d)).reduce((accum, obj) => accum + parseInt(obj.percent), 0);
 
      if(count==0){
		 
		evolution.push(valor/quantidade)
		desired_maturity.push(pro)
	  }else{

 
	 
		 
		let total = valor/quantidade;

		total=	 total +evolution[evolution.length-1];
		   let totald = pro;
		   totald =totald+ desired_maturity[desired_maturity.length-1];

	  desired_maturity.push(totald)
      evolution.push(total);
	 }
 
	   count++
	}
  

	return(
		<Card className='h-100'>
			<AppBar title='BURN UP'/>
			<div className='content'>
				<ReactHighcharts 
					config={BURNDOWN({
						language,
						categories: ['0',...categories],// lorem
						evolution:[0,...evolution],
						desired_maturity:[0,...desired_maturity],
					})} 
				/>
			</div>
		</Card>
	)
})