import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppBar from '@common/AppBar';
import { Card } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import CommonPage from '../../common/CommonPage';
import Actions from '@data/Actions';
import { Container, Row, Col } from 'react-grid-system';

const action = new Actions();

@observer
export default class TicketFormEdit extends CommonPage {
  constructor() {
    super();
    this.state = {
      company: '',
      contract: '',
      category: '',
      catalog: '',
      user: '',
      device: '',
      catalog_task: '',
      devicesUser: [],
      categoryOptions: [{ value: 0, text: '' }]
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  componentWillMount() {
    let { values } = this.props;
    if (values.id_access_remote != null) {
      let description = values.description;
      let id = values.id_access_remote;
      let concat = 'Id Acess Remote: ' + values.id_access_remote + ' <br/> ' + description;
      values.description = concat;
    }

    action
      .execute('get', `${this.APIEndpoints.DEVICE + 'user/' + values.fk_id_user}`, '')
      .then(res => {
        this.setState({ devicesUser: res.data[0] });
      });
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_company') {
      this.setState({
        company: value
      });
    } else if (field.name === 'fk_id_contract') {
      this.setState({
        contract: value
      });
    } else if (field.name === 'fk_id_device') {
      this.setState({
        device: value
      });
    } else if (field.name === 'fk_id_category') {
      this.setState({
        category: value
      });
    } else if (field.name === 'fk_id_catalog_service') {
      this.setState({
        catalog: value
      });
    } else if (field.name === 'fk_id_user') {
      this.setState({
        user: value
      });
    } else if (field.name === 'fk_id_catalog_task') {
      this.setState({
        catalog_task: value
      });
    } else if (field.name === 'type_tickets') {
      this.setState({
        categoryOptions: this.props.tickets_types_cat
          .filter(c => c.type_ticket == value)
          .map(ctr => ({ value: ctr.id_category, text: ctr.name }))
      });
    }
  }


  render() {
    let {
      impacts,
      type_tickets,
      language,
      handlePage,
      values,
      onFormSubmit,
      contracts,
      categories,
      groups,
      priorities,
      urgencies,
      users,
      companies,
      catalogs,
      chiefs,
      route,
      slas,
      taskcatalog
    } = this.props;

    let { contract, device, devicesUser } = this.state;

    let deviceOptions = [];

    let item = this.props.tickets_types_cat

      .map(ctr => ({ value: ctr.id_category, text: ctr.name }))

    let company = values ? values.fk_id_company : this.state.company;

    contract = values ? values.fk_id_contract : this.state.contract;
    device = values ? values.fk_id_device : this.state.device;
    let user = values ? values.fk_id_user : this.state.user;

    let category = values ? values.fk_id_category : this.state.category;

    let catalog = values ? values.fk_id_catalog_service : this.state.catalog;


    //console.log(device)

    let typeTicket =
      route && route === 'incident'
        ? 1
        : route && route === 'problem'
          ? 2
          : null;

    let userSelect = users.filter(c => c.id_user === user)[0];

    let typeOptions;/*= type_tickets.map(tk => ({
      value: tk.item_table,
      text: tk.description
    })); */
    let br = sessionStorage.getItem('sensr_lang') == 'PT_BR'
    if (sessionStorage.getItem('sensr_lang') == 'PT_BR' || sessionStorage.getItem('sensr_lang') == null) {

      typeOptions = type_tickets.map(tk => ({
        value: tk.item_table,
        text: tk.description == 'Incident' ? 'Incidente' :
          tk.description == 'Problem' ? 'Problema' :
            tk.description == 'Liberation' ? 'Liberação' : 'Solicitação'
      }))
    } else {
      typeOptions = type_tickets.map(tk => ({
        value: tk.item_table,
        text: tk.description
      }))
    }
    let contractOptions = [];
    let companyOptions = companies.map(c => ({
      value: c.id_company,
      text: c.name
    }));


    if (values.company[0].id_company == 1) {

      contracts.filter(c => c.fk_id_company === values.company[0].id_company).map(x => {
        x.device.map(x => {

          if (devicesUser && devicesUser.devices && devicesUser.devices.includes(x.id_device))
            deviceOptions.push({ value: x.id_device, text: x.name });
        })
      })
    } else {
      contracts.filter(c => c.fk_id_out_company === values.company[0].id_company).map(x => {
        x.device.map(x => {

          if (devicesUser && devicesUser.devices && devicesUser.devices.includes(x.id_device))
            deviceOptions.push({ value: x.id_device, text: x.name });
        })
      })
    }





    let tasksOptions = [];

    let techGroupOptions = [];
    let t = [];

    if (catalog === null) {
    } else {
      t = catalogs
        .filter(c => c.id_catalog_service === catalog)
        .map(t => t.id_group_users);

      //  techGroupOptions = groups.filter(g => g.type == 1 && g.type_tech == 1 && g.id_group_users == t[0]).map(tec => ({ value: tec.id_group_users, text: tec.name }));
      groups
        .filter(
          g => g.type == 1 && g.type_tech == 1 && g.id_group_users == t[0]
        )
        .map(tec =>
          techGroupOptions.push({ value: tec.id_group_users, text: tec.name })
        );

      groups
        .filter(
          g => g.type == 1 && g.type_tech == 1 && g.id_group_users != t[0]
        )
        .map(tec =>
          techGroupOptions.push({ value: tec.id_group_users, text: tec.name })
        );
    }


    let serviceCatalogOptions = [];
    let departmentOptions = [];
    let responsibleOptions = [];
    let responsible = null;
    let priorityOptions = [];
    let urgencyOptions = [];

    let slaOptions = [];

    let impactsOptions = [];
    let idsla = [];
    let idpriority = [];
    let idurgency = [];
    let idimpact = [];
    try {

      if (device) {
        contractOptions = [];



        if (values.company[0].id_company == 1) {

          contracts.filter(c => c.fk_id_company === values.company[0].id_company).map(x => {

            if (x.device.filter(x => x.id_device === device).length > 0) {
              if (contractOptions.filter(g => g.value == x.id_contract).length == 0)
                contractOptions.push({ value: x.id_contract, text: x.name });

            }
          })
        } else {
          contracts.filter(c => c.fk_id_out_company === values.company[0].id_company).map(x => {

            if (x.device.filter(x => x.id_device === device).length > 0) {
              if (contractOptions.filter(g => g.value == x.id_contract).length == 0)
                contractOptions.push({ value: x.id_contract, text: x.name });

            }
          })
        }



      }


      if (company && userSelect) {
        companies
          .filter(c => c.id_company == company)[0]
          .department.map(obj => {
            departmentOptions.push({
              value: obj.id_department,
              text: obj.name
            });
            if (obj.sub_dep.length) {
              obj.sub_dep.map(sd => {
                departmentOptions.push({
                  value: sd.id_department,
                  text: sd.name
                });
              });
            }
          });

        //responsibleOptions = chiefs.filter(cf => cf.fk_id_company === company && cf.fk_id_department === userSelect.fk_id_department).map(c => ({ value: c.id_user, text: c.name }));
        //responsible = responsibleOptions.length ? responsibleOptions[0].value : null;
      }
      responsibleOptions = chiefs.filter(cf => cf.fk_id_company === company && cf.fk_id_department ===
        userSelect.fk_id_department).map(c => ({ value: c.id_user, text: c.name }));






      if (category) {
        serviceCatalogOptions = categories
          .filter(c => c.id_category === category)[0]
          .catalog.filter(c => c.name != null).map(cat => ({
            value: cat.id_catalog_service,
            text: cat.name
          }));

        serviceCatalogOptions = serviceCatalogOptions.sort(function (a, b) {
          return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
        });




      }


    } catch (e) { }

    let fields = [];




    values.update = true;


    if (catalog) {


      tasksOptions = catalogs
        .filter(c => c.id_catalog_service === catalog)[0]
        .task.filter(c => c.name != null).map(t => ({ value: t.id_catalog_task, text: t.name }));
      tasksOptions = tasksOptions.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });



    }




    fields = fields.concat([
      {
        span: 3,
        name: 'id_type',
        label: language.TYPE,
        type: 'select',
        options: typeOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_category',
        label: language.CATEGORY,
        type: 'select',
        options: item,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_catalog_service',
        label: language.SERVICE_CATALOG,
        type: 'select',
        options: serviceCatalogOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_catalog_task',
        label: language.TASK,
        type: 'select',
        options: tasksOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_group_tech',
        label: language.GROUP_TECH,
        type: 'select',
        options: techGroupOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_device',
        label: language.DEVICE,
        type: 'select',
        options: deviceOptions,
        required: true
      },

      {
        span: 3,
        name: 'fk_id_contract',
        label: language.CONTRACT,
        type: 'select',
        options: contractOptions,
        required: true
      },

    ]);

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10 }
    };

    let colors = [
      '#F44336',
      '#DAA520',
      'Yellow',
      '#33ABA0',
      'Blue',
      'Purple',
      'Black',
      'White'
    ];
    //console.log(tasksOptions)
    return (
      <PageLayout
        icon={
          <div
            className="incidentManagementIcon"
            style={{ width: 44, height: 44 }}
          />
        }
        title={language.TICKET}
        subtitle={language.NEW_TICKET}
        rightElements={[
          this.props.btnback
        ]}
      >
        {values.files != undefined ? <Card style={{ height: '100%', marginTop: 35, overflow: 'visible' }}>
          <AppBar light>
            <div className="AppBarCustomContent">
              <div className="bg-icon">
                <div
                  className="attachmentIcon"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <span style={{ marginLeft: 8, fontSize: 18 }}>{language.ATTACHMENT + 's'}</span>
            </div>
          </AppBar>
          <div className="content">
            {values.files != undefined
              ? values.files.map(f => (
                <Row>
                  <Col md={1}>
                    {' '}
                    <div
                      className="attachmentIcon"
                      style={{ marginTop: 12 }}
                    />{' '}
                  </Col>
                  <Col md={7}>
                    <p
                      className="pointer "
                      onClick={() =>
                        this.props.ondonwFile(f.split('|')[2], f.split('|')[0])
                      }
                    >
                      {f.split('|')[0] + " - " + moment(f.split('|')[3]).format('L')}
                    </p>
                  </Col>{' '}
                </Row>
              ))
              : null}
          </div>

        </Card> : null}
        <Card style={{ marginTop: 10, padding: 10 }}>
          <CommonForm
            language={language}
            button={button}
            onFormSubmit={onFormSubmit}
            fields={fields}
            values={
              values || {
                type_tickets: typeTicket,
                fk_id_department: userSelect
                  ? userSelect.fk_id_department
                  : null,
                fk_id_user_sponsor: responsible
              }
            }
            onChangeField={this.onChangeField}
          />


        </Card>
      </PageLayout>
    );
  }
}
