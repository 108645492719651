import React, { Fragment, useCallback, useEffect, useState } from 'react';
import GSDGrid from './GSDGrid';
import GSDCard from './GSDCard';
import classnames from 'classnames';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';

const action = new Actions();

import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import InfiniteLoader from 'react-window-infinite-loader';

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <PerfectScrollbar
      ref={refSetter}
      style={{ ...style, overflow: 'hidden' }}
      className="pb-2"
      onScroll={onScroll}
      options={{
        minScrollbarLength: 30,
      }}
    >
      {children}
    </PerfectScrollbar>
  );
};

let _orderIndex = {
  0: 'id_tickets',
  1: 'dt_cad',
  null: 'id_tickets',
};
export default function (props) {
  let {
    index = null,
    title,
    language,
    getDashboard,
    onAdd,
    redirect,
    TicketManagement,
    RequestContainer,
    user,
    GSD_THEME,
    APIEndpoints,
    tokenApi,
    filter,
    filterUserGroup,
    setOpenTicket,
    setTicketToOpen
  } = props;

  const [idTicket, setIdTicket] = useState(0);
  const [orderIndex, setOrderIndex] = useState(null);
  const [orderPosition, setOrderPosition] = useState(null);
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortByDesc, setSortByDesc] = useState(true);
  const [groupTech, setGroupTech] = useState(filter?.groupTech ?? '');
  const [filterFind, setFilterFind] = useState(filter?.search ?? '');
  const [dataInicial, setDataInicial] = useState(filter?.dtinicial ?? null);
  const [dataFinal, setDataFinal] = useState(filter?.dtfinal ?? null);
  const [reload, setReload] = useState(filter?.reload ?? null);
  const [departament, setDepartament] = useState(filter?.departament ?? null);
  const [userTech, setUserTech] = useState(filter?.userTech ?? null);
  const [serviceCatalog, setServiceCatalog] = useState(filter?.serviceCatalog ?? null);
  const [category, setCategory] = useState(filter?.category ?? null);

  let users = [];
  try {
    users = JSON.parse(decryp(sessionStorage.getItem('users')));
  } catch (e) { }


  useEffect(() => {
    setSortBy(['grid_id', 'grid_date'][orderIndex]);
    setSortByDesc(orderPosition);
  }, [orderIndex, orderPosition]);

  useEffect(() => {
    getTickets();
  }, [
    sortBy,
    sortByDesc,
    filterFind,
    groupTech,
    dataInicial,
    dataFinal,
    reload,
    departament,
    userTech,
    serviceCatalog,
    category,
  ]);

  useEffect(() => {
    setFilterFind(filter?.search);
    setGroupTech(filter?.groupTech);
    setDataInicial(filter?.dtinicial);
    setDataFinal(filter?.dtfinal);
    setReload(filter?.reload);
    setDepartament(filter?.departament);
    setUserTech(filter?.userTech);
    setServiceCatalog(filter?.serviceCatalog);
    setCategory(filter?.category);
  }, [filter]);

  async function getTickets(startIndex = 0, endIndex = 20) {
    const dataPayload = {
      filter_user_group: filterUserGroup,
      statusFilter: [status],
      sortBy,
      sortByDesc,
      language: language.LOCATION_LANGUAGE,
      filterFind,
      groupTech,
      dataInicial,
      dataFinal,
      limit: endIndex + 1,
      offset: 0,
      departament,
      userTech,
      serviceCatalog,
      fk_id_category: category
    }
    
    action
      .execute('post', `${APIEndpoints.REQUEST + `/list`}`, '', dataPayload)
      .then((res) => {
        setTotalTickets(res.data.length ? res.data[0].count : 0);
        setTickets([...res.data]);
      })
      .catch((error) => {
        console.log(error)
        setTickets([]);
        setTotalTickets(0);
      });
  }

  function isItemLoaded(index) {
    return index < totalTickets && !!tickets[index];
  }

  return (
    <div
      style={{ width: '300px' }}
      className={classnames('pl-2 pr-2 pb-1', {
        'pl-1': index == 0,
        'pr-1': index == 7,
      })}
    >
      <GSDGrid
        title={title}
        onAdd={onAdd}
        tickets={tickets}
        totalTickets={totalTickets}
        DASHBOARD_THEME={GSD_THEME.columns}
        orderIndex={orderIndex}
        setOrderIndex={setOrderIndex}
        orderPosition={orderPosition}
        setOrderPosition={setOrderPosition}
      >
        {tickets.length ? (
          <AutoSizer style={{ height: 'auto', width: 'auto' }}>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={500}
                loadMoreItems={getTickets}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    itemCount={tickets.length}
                    itemSize={120}
                    width={width}
                    outerElementType={CustomScrollbarsVirtualList}
                  >
                    {({ index, style, data }) =>
                      tickets.length > index ? (
                        <div
                          style={style}
                          className="content py-0 d-flex align-items-center justify-content-center"
                        >
                          <GSDCard
                            onClick={() =>
                              setIdTicket({
                                id_request: tickets[index].id_request,
                              })
                            }
                            src={
                              undefined /*--passa a imagem do usuário aqui se não tiver deixa a props como undefined--*/
                            }
                            users={users}
                            current={0}
                            progress={index != 0}
                            title={tickets[index].subject}
                            indicator={tickets[index].sla}
                            ticket={tickets[index]}
                            user={user}
                            name_user={tickets[index].name}
                            date={moment(tickets[index].dt_cad).format('L')}
                            version={'R' + tickets[index].id_request}
                            progressColor={'var(--success-light)'}
                          />
                        </div>
                      ) : (
                        <span>Carregando...</span>
                      )
                    }
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        ) : (
          <h3
            style={{
              color: 'white',
              fontWeight: 100,
              fontSize: 16,
              textAlign: 'center',
            }}
            className="mt-2"
          >
            {language.NO_TICKETS}
          </h3>
        )}
      </GSDGrid>
      {idTicket != 0 && idTicket.id_request && (
        <RequestContainer
          onClose={() => {
            setIdTicket(0);
            getDashboard();
          }}
          id_request={idTicket.id_request}
          setTicketToOpen={setTicketToOpen}
          setOpenTicket={setOpenTicket}
          updateRequests={getTickets}
        />
      )}
    </div>
  );
}
