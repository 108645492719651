import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Paper, Card } from '@material-ui/core';
import { FaClose } from 'react-icons/lib/fa';
import Constants from '../../data/Constants';
import CommonPage from '../../common/CommonPage';
import CommonForm from '../../common/CommonForm2';
import RequestApproval from './RequestApproval';
import RequestList from './RequestList';
import RequestForm from './RequestForm';
import RequestView from './RequestView';
import TicketForm from '../tickets/TicketForm';
import Dialog from '@common/Dialog';
import Animate from '@common/Animate';
import { testRoute } from '@common/Func';
import DialogForm from '../dashboards/GSDContainer/DialogForm';
import DialogFormTicket from '../TicketManagement/DialogForm';
import TicketManagement from '../TicketManagement';
import DownloadFiles from '../../util/DownloadFiles';
@observer
export default class RequestContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      values: undefined,
      files: [],
      fliesnk: [],
      fileError: '',
      sendFile: false,
      arrayreject: [],
      filesdelete: [],
      dialog: 0,
      request: '',
      catModal: false,
      catvalues: [],
      ds: true,
      currentPage: 0,
      values_: {},
      requests: [],
      totalrequest: 0,
      start: false,
      ticketHistory: [],
      openTicket: false,
      sortBy: '',
      sortByDesc: null,
    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitAprove = this.onFormSubmitAprove.bind(this);
    this.onReloadRequests = this.onReloadRequests.bind(this);

    this.onDrop = this.onDrop.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onClose = this.onClose.bind(this);
    this.UpdateFiles = this.UpdateFiles.bind(this);
    this.onFormSubmitItem = this.onFormSubmitItem.bind(this);
    this.onFormSubmitItem_ = this.onFormSubmitItem_.bind(this);
    this.redirect = this.redirect.bind(this);
    this.ondeletefile = this.ondeletefile.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.catHandle = this.catHandle.bind(this);
    this.onFormSubmitTab = this.onFormSubmitTab.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.downloadRequests = this.downloadRequests.bind(this);
    this.callSortItem = this.callSortItem.bind(this);
    this.onFormSubmitMultiTicket = this.onFormSubmitMultiTicket.bind(this);
  }
  handleDialog = (n = false) => {
    this.setState({ dialog: n });
  };

  downloadRequests() {
    this.action
      .execute('post', this.APIEndpoints.REQUEST + '/exportrequestsapprove', '')
      .then((res) => {
        DownloadFiles(
          this.APIEndpoints.REQUEST +
            '/downloadrequestsexport/' +
            res.data.file,
          res.data.file
        );
      });
  }

  catHandle(obj, array) {
    if (this.store.getUserLoged().role === 'solicitant') {
      this.store.toggleAlert(
        true,
        this.store.language.USER_NOT_ALLOWED_TO_CATEGORIZE,
        'error'
      );
    } else {
      this.action
        .execute(
          'get',
          this.APIEndpoints.REQUEST + 'one' + '/' + obj.fk_id_request,
          'request'
        )
        .then((g) => {
          let request = g.data[0];

          obj.description = request.description;
          obj.devices_names = request.devices_names
          obj.devices_ids = request.devices_ids
          obj.has_multi_ticket = request.has_multi_ticket

          if (array != undefined && array.length > 0) {
            obj.arraytk = array;
          }

          this.setState({ catModal: true, catvalues: obj, start: obj.start });

          //        this.setState({ request: g.data[0] });
        });
    }
  }

  onFormSubmitItem_(values) {
    let filesSend = [];
    let cont = 0;

    let total = values.files.length;
    Array.from(values.files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then((result) => {
        if (file.size >= 40000000) {
          alert(
            `${this.store.language.file} ${file.name} ${this.store.language.MORE_THAN_40MB}`
          );
          this.setState({ sendFile: false });
          filesSend = [];
          //this.state.files = [];
          cont = 0;
        }
        filesSend.push({
          file: result,
          type: file.type,
          namefile: file.name,
        });
        cont = cont + 1;
        if (total === cont) {
          values.file = filesSend;
          let tokenApi = this.store.getToken();

          this.action
            .execute('post', `${this.APIEndpoints.TICKET}`, '', values)
            .then((data) => {
              window.location.reload();
            });
        }
      });
    });

    // this.redirect('/dash/request');
  }
  ondeletefile(obj) {
    this.action
      .execute(
        'get',
        this.APIEndpoints.REQUESTS +
          '/deletefilerequest/' +
          0 +
          '&' +
          obj.split('|')[1] +
          '',
        'filedelete'
      )
      .then((h) => {
        this.action.execute('get', this.APIEndpoints.REQUEST, 'requests');
      });

    let filesdelete = this.state.filesdelete;
    filesdelete.push(obj);

    this.setState({ filesdelete });
  }

  handlePage(page, edit) {
    try {
      if (page === 'reject') {
        if (edit.request.status === 'Reject') {
          this.store.toggleAlert(
            true,
            this.store.language.OPERATION_NOT_PERFORMED,
            'error'
          );
        } else {
          this.setState({ page: page, values: edit });
        }
      } else if (page === 'view') {
        this.action
          .execute(
            'get',
            this.APIEndpoints.REQUEST + 'one' + '/' + edit.id_request,
            'request'
          )
          .then((g) => {
            this.setState({ page: page, values: g.data[0] });
          });
      } else if (page === 'approve') {
        this.action
          .execute(
            'get',
            this.APIEndpoints.REQUEST + 'one' + '/' + edit.id_request,
            'request'
          )
          .then((g) => {
            if (window.location.href.indexOf('approve') > -1) {
              this.redirect2('/dash/requestapprove', g.data[0]);
            } else {
              this.redirect2('/dash/request', g.data[0]);
            }
            //            this.setState({ page: page, values: g.data[0] });
          });
      } else {
        this.setState({ page: page, values: edit });
      }
    } catch (e) {
      this.setState({ page: page, values: edit });
    }
  }
  ondonwFile(id, file) {
    DownloadFiles(
      this.APIEndpoints.REQUESTS + '/api/downloadrequest/' + id + '&' + file,
      file
    );
  }

  onReloadRequests() {
    window.location.reload();
  }

  onFormSubmitAprove(values, pach) {
    values.action = 'approval';
    values.status = 'Approved';

    let { state } = this.history2.location;
    let fromDash = '';
    let { id_request } = this.props;
    this.history2.location.state = null;
    this.history2.location.state = null;

    this.action
      .execute(
        'post',
        this.APIEndpoints.REQUEST + '/' + values.requestId,
        'requests',
        values
      )
      .then((e) => {
        let history = this.history2;
        let { state, pathname } = history.location;
        if (fromDash) {
          if (window.location.href.indexOf('approve') > -1) {
            this.redirect('/dash/requestapprove');
          } else {
            this.redirect('/dash');
            }
        } else {
            if (id_request) {
              this.redirect('/dash/gsd');
            } else {
              this.handlePage('list');
              this.callPageSelectFilter(
                this.state.currentPage,
                this.state.values_,
                75
              );
              }
         }});
  }

  onFormSubmit(type, values, general_parameters) {
    let contador = 0;
    this.store.loading = true;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    let fields = [];
    let { id_request } = this.props;
    let { state } = this.history2.location;
    let fromDash = '';

    if (
      type == '' &&
      ![true].includes(general_parameters.ticket_simplified) &&
      (values.fk_id_category == undefined ||
        values.fk_id_catalog_service == undefined ||
        values.fk_id_catalog_task == undefined)
    ) {
      this.store.toggleAlert(
        true,
        `Informe todos os campos do formulário!`,
        'error'
      );
    } else {
      if (type === 'ticket') {
        if (values.ticketArray != undefined && values.ticketArray.length > 1) {
          values.ticketArray.forEach((num) => {
            contador++;

            let item = values.req.filter((x) => x.id_request === num)[0];

            values.fk_id_request = num;
            values.description = item.description;
            values.subject = item.subject;
            values.fk_id_user = item.fk_id_user;
            values.manys = true;
            this.action
              .execute('post', this.APIEndpoints.TICKET, 'requests', values)
              .then((e) => {
                if (contador == values.ticketArray.length) {
                  if (fromDash) {
                    this.redirect2('/dash');
                  } else {
                    if (id_request) {
                      this.redirect('/dash/gsd');
                    } else {
                      //   window.location.reload();
                    }
                  }
                }
              });
          });
        } else {
          if (
            values.fk_id_user_sponsor == null ||
            values.fk_id_user_sponsor <= 0
          ) {
            this.store.toggleAlert(
              true,
              `Resposável não cadastrado favor acessar
           configurações -> lista de empresas -> adicionar departamento -> escolha o chefe de departamento`,
              'error'
            );
          }

          this.action
            .execute('post', this.APIEndpoints.TICKET, 'requests', values)
            .then((e) => {
              if (fromDash) {
                this.redirect2('/dash');
              } else {
                this.redirect('/dash/request');
              }
            });

          //setTimeout(function () { this.setState({ page, values }) }.bind(this), 1000);
        }

        // this.redirect('/dash/request');
      } else if (type === 'reject') {
        values.values.action = 'approval';
        values.values.status = 'Rejected';

        if (values.values.reason == null || values.values.reason == '') {
          this.store.toggleAlert(true, language.INFORM_THE_REASON, 'error');
          return;
        }
        let contador = 0;

        if (values.id_request.length > 1) {
          values.id_request.forEach((num) => {
            contador++;
            this.store.loading++;

            this.action
              .execute(
                'post',
                this.APIEndpoints.REQUEST + '/' + num,
                'requests',
                values.values
              )
              .then((e) => {
                if (fromDash) {
                  this.redirect2('/dash');
                } else {
                  if (id_request) {
                    this.redirect('/dash/gsd');
                  } else {
                    this.redirect('/dash/request');
                  }
                }
              });
            this.store.loading = false;
          });
        } else {
          if (id_request) {
            this.action
              .execute(
                'post',
                this.APIEndpoints.REQUEST + '/' + values.id_request,
                'requests',
                values.values
              )
              .then((e) => {
                if (fromDash) {
                  this.redirect2('/dash');
                } else {
                  this.redirect('/dash/gsd');
                }
              });
          } else {
            this.action
              .execute(
                'post',
                this.APIEndpoints.REQUEST + '/' + values.id_request,
                'requests',
                values.values
              )
              .then((e) => {
                if (fromDash) {
                  this.redirect2('/dash');
                } else {
                  this.redirect('/dash/request');
                  this.callPageSelectFilter(
                    this.state.currentPage,
                    this.state.values_,
                    75
                  );
                  // this.redirect('/dash/request');
                }
              });
          }
        }
      } else {
        let campossize = false;
        if ($('#template').html() != undefined) {
          $('#template textarea').each(function () {
            $(this).attr('value', $(this).val());

            $(this).html($(this).val());
            fields.push({ name: $(this).attr('name'), value: $(this).val() });
          });

          $('#template input').each(function () {
            if ($(this).attr('type') == 'radio') {
              if (
                $('input[name=' + $(this).attr('name') + ']:checked').val() ==
                $(this).val()
              ) {
                $(this).attr('checked', true);
                let value = undefined;
                try {
                  value = $(this).parent().children()[1].textContent;
                } catch (e) {}
                fields.push({
                  name: $(this).attr('name'),
                  value: value || $(this).val(),
                });
              } else {
                $(this).removeAttr('checked');
              }
              let ip = $(this);

              $('#template  label').each(function () {
                var for_ = '';
                try {
                  for_ = $(this).attr('for');
                } catch (e) {}

                if (for_ === ip.attr('name')) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      camposobrigatoriosradio = true;
                      $('[name=' + for_ + ']').each(function () {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatoriosradio = false;
                          }
                        } catch (e) {}
                      });
                    }
                  }
                }
              });
            } else if ($(this).attr('type') == 'checkbox') {
              if (
                $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
                $(this).attr('id')
              ) {
                $(this).attr('checked', true);
                fields.push({
                  name: $(this).attr('name'),
                  value: $(this).val(),
                });
              } else {
                $(this).removeAttr('checked');
              }
              let ip = $(this);
              $('#template  label').each(function () {
                var for_ = ' ';
                try {
                  for_ = $(this).attr('for');
                } catch (e) {}

                if (ip.attr('name').indexOf(for_) > -1) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      camposobrigatorioscheck = true;
                      $('#template  input').each(function () {
                        try {
                          if ($(this).attr('name').indexOf(for_) > -1) {
                            try {
                              if ($(this).attr('checked')) {
                                camposobrigatorioscheck = false;
                              }
                            } catch (e) {}
                          }
                        } catch (e) {}
                      });
                    }
                  }
                }
              });
            } else if ($(this).attr('type') == 'datetime-local') {
              const userLogin = Store.getUserLoged();
              const timezone = userLogin?.timezone?.value;

              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: moment($(this).val()).tz(timezone).utc().toISOString(),
              });
              $(this).attr('value', $(this).val());
            } else {
              $(this).attr('value', $(this).val());

              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: $(this).val(),
              });

              let siz = $(this).attr('max');
              let min = $(this).attr('min');
              if (siz) {
                if (
                  parseInt($(this).val().length) > parseInt(siz) ||
                  parseInt($(this).val().length) < parseInt(min)
                ) {
                  $(this).css('border-color', 'red');
                  $(this).before(
                    `<label  id="formtemplabel__" style='color:red'>  ${
                      min || 0
                    } á ${siz || 0} caracteres</label>`
                  );

                  campossize = true;
                }
              }

              let ip = $(this);
              $('#template  label').each(function () {
                if ($(this).attr('for') === ip.attr('id')) {
                  if ($(this).text().split('*')[1] != undefined) {
                    if ($(this).text().split('*')[1].length >= 0) {
                      if (ip.attr('value').length == 0) {
                        camposobrigatorios = true;
                      }
                    }
                  }
                }
              });
            }
          });

          $('#template select').each(function () {
            $('#' + $(this).children('option:selected').attr('id')).attr(
              'selected',
              'selected'
            );
            '#' +
              fields.push({
                name: $(this).attr('name') || $(this).attr('id'),
                value: $(this).find(':selected').text(),
              });
          });

          $('#template label').each(function () {
            try {
              if ($(this).attr('for').indexOf('textarea') > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    // alert($('#'+$(this).attr('for')).text())

                    let node_ = $(this)
                      .parent()
                      .parent()
                      .nextAll()
                      .slice(2, 3)
                      .children()[0];
                    if (
                      (node_ &&
                        node_.firstChild.tagName == 'P' &&
                        node_.firstChild.textContent.length == 0) ||
                      (!node_ &&
                        $('#' + $(this).attr('for')).text().length == 0)
                    ) {
                      camposobrigatorios = true;
                    }
                  }
                }
              }
            } catch (e) {}
          });

          values.template = this.createRandomToken($('#template').html());
        }

        values.fields = fields;

        setTimeout(function () {
          $('#formtemplabel__').fadeOut().empty();
          $('#template .form-control').css('border-color', '#019fc2');
        }, 9000);

        if (
          camposobrigatorios == false &&
          campossize == false &&
          camposobrigatorioscheck == false &&
          camposobrigatoriosradio == false
        ) {
          setTimeout(
            function () {
              this.onFormSubmitItem(values);
            }.bind(this),
            2000
          );
        } else {
          this.setState({ ds: true });

          if (
            camposobrigatorios ||
            camposobrigatoriosradio ||
            camposobrigatorioscheck
          )
            this.store.toggleAlert(
              true,
              'Informe dos campos obrigatórios',
              'error'
            );

          if (campossize)
            this.store.toggleAlert(
              true,
              'Verifique o tamanho dos campos',
              'error'
            );
        }
      }
    }
  }

  callSortItem(sortBy, sortByDesc) {
    this.setState({ sortBy: sortBy, sortByDesc: sortByDesc });
    setTimeout(() => {
      this.callPageSelectFilter(this.state.currentPage, this.state.filter, 75);
    }, 50);
  }

  callPageSelectFilter(page = 0, values = {}, totalPerPage = 75) {
    this.setState({ currentPage: page, values_: values });
    let limit = totalPerPage || 75;
    let pageCount = page + 1;
    let offset = page * limit;

    values.sortBy = this.state.sortBy;
    values.sortByDesc = this.state.sortByDesc;

    if (values) {
      values.limit = totalPerPage;
      values.offset = offset;
    }

    if (window.location.href.indexOf('approve') > -1) {
      if (values) {
        values.approveitem = true;
      } else {
        values = { approveitem: true };
      }
    }

    this.action
      .execute('post', `${this.APIEndpoints.REQUEST}/list?limit=${limit}&offset=${offset}`, '', values)
      .then((res) => {
        this.setState({
          requests: res.data,
          totalrequest: parseInt(res.data[0] ? res.data[0].count : 0),
        });
      })
      .catch((error) => {
        console.error(error);
        // this.store.toggleAlert(true, 'Aguarde estamos processando os dados', 'success');
      });
  }

  createRandomToken(text) {
    const aSecret = 'Orch3str0!@forever!';
    try {
      const crypto = require('crypto');

      let cipher, tRet;

      cipher = crypto.createCipher('aes-256-cbc', aSecret);
      tRet = cipher.update(text.toString(), 'utf8', 'base64');
      tRet += cipher.final('base64');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  onDrop(e) {
    Array.from(e).forEach((f) => {
      this.state.fliesnk.push(f);
    });
    this.setState({ files: this.state.fliesnk });
  }

  removeFile(item) {
    var filesArray = [];
    Array.from(this.state.fliesnk).forEach((f) => {
      if (f.name != item.name) {
        filesArray.push(f);
      }
    });
    this.state.fliesnk = filesArray;
    this.setState({ files: filesArray });
  }
  UpdateFiles() {}

  onClose() {
    this.setState({
      fileError: '',
    });
  }

  onFormSubmitItem(values, id_request) {
    this.setState({ sendFile: true });
    let filesSend = [];
    let cont = 0;
    if (values.file && values.file.length > 0) {
      let total = Array.from(values.file).length;
      Array.from(values.file).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
            this.setState({ sendFile: false });
            filesSend = [];
            this.state.files = [];
            cont = 0;
            setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              1000
            );
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            let host = Constants.APIRoot;
            values.file = filesSend;
            let tokenApi = this.store.getToken();

            this.action
              .execute('post', `${host}/api/request/`, '', values)
              .then((data) => {
                setTimeout(
                  function () {
                    if (id_request) {
                      this.redirect('/dash/gsd');
                    } else {
                      window.location.reload();
                    }
                  }.bind(this),
                  1000
                );
              });
          }
        });
      });
    } else {
      this.action
        .execute(
          'post',
          this.APIEndpoints.REQUEST,
          'requests',
          values,
          'id_request'
        )
        .then((e) => window.location.reload());
      this.setState({ values: '' });
    }

    // this.redirect('/dash/request');
  }

  async onFormSubmitMultiTicket(values){
    const categorizations = await  this.action.execute('post', Constants.APIEndpoints.TASK + '/getmultiticketrequest', '', {
      fk_id_request: values.fk_id_request
    }).then(res => res.data)
      if(categorizations.length>0){
        values.categorizations =categorizations;
      }
      this.action
        .execute('post', this.APIEndpoints.TICKET, 'requests', values)
        .then((e) => {
          this.handlePage('list')
          this.callPageSelectFilter(
            this.state.currentPage,
            this.state.values_,
            75
          );
          this.setState({ catModal: false });
        });

      if(categorizations.length>0){
        await new Promise(resolve => setTimeout(resolve, 6000));
        window.location.reload();
      }
  }

  onFormSubmitTab(values) {
    if (values.has_multi_ticket) {
      this.onFormSubmitMultiTicket(values)
    } else {
      let contador = 0;
      let { id_request } = this.props;
      if (values.files.length > 0) {
        this.onFormSubmitItem_(values, id_request);
      } else {
        if (values.ticketArray != undefined && values.ticketArray.length > 1) {
          values.ticketArray.forEach((num) => {
            contador++;
            let item = values.req.filter((x) => x.id_request === num)[0];

            values.fk_id_request = num;
            values.description = item.description;
            values.subject = item.subject;
            values.fk_id_user = item.fk_id_user;
            values.template = item.template;
            values.manys = true;
            if (values.keepOriginalCatalog == true) {
              values.fk_id_category = item.fk_id_category;
              values.fk_id_catalog_service = item.fk_id_catalog_service;
              values.fk_id_catalog_task = item.fk_id_catalog_task;
              values.fk_id_priority = item.fk_id_priority;
              values.fk_id_urgency = item.fk_id_urgency;
              values.id_impact = item.id_impact;
              values.fk_id_group_tech = item.fk_id_group_tech;
            }
            this.action
              .execute('post', this.APIEndpoints.TICKET, 'requests', values)
              .then((e) => {
                if (contador == values.ticketArray.length) {
                  if (id_request) {
                    this.redirect('/dash/gsd');
                  } else {
                    this.handlePage('list');
                    this.callPageSelectFilter(
                      this.state.currentPage,
                      this.state.values_,
                      75
                    );
                    this.setState({ catModal: false });
                  }
                }
              });
          });
        } else {
          this.action
            .execute('post', this.APIEndpoints.TICKET, 'ticketss m', values)
            .then((h) => {
              if (id_request) {
                if (
                  this.props.setTicketToOpen &&
                  this.props.setOpenTicket &&
                  this.state.start
                ) {
                  let ticketHistory = {
                    id_tickets: h.data.id_tickets,
                    real_id: 'R' + h.data.id_request,
                  };
                  this.props.setTicketToOpen(ticketHistory);
                  this.props.setOpenTicket(true);
                  this.props.onClose();
                  this.props.updateRequests();
                } else {
                  this.redirect('/dash/gsd');
                }
              } else {
                if (this.state.start == true) {
                  let ticketHistory = {
                    id_tickets: h.data.id_tickets,
                    real_id: 'R' + h.data.id_request,
                  };
                  this.setState({
                    ticketHistory,
                    openTicket: true,
                    catModal: false,
                    page: 'list',
                  });
                } else {
                  this.handlePage('list');

                  this.callPageSelectFilter(
                    this.state.currentPage,
                    this.state.values_,
                    75
                  );
                  this.setState({ catModal: false });
                }
              }
            });
        }
      }
    }
  }

  componentWillMount() {
    let { id_request } = this.props;

    let { state } = history;
    try {
      if (state && state.state.id) {
        this.action
          .execute(
            'get',
            this.APIEndpoints.REQUEST + 'one' + '/' + state.state.id,
            'request'
          )
          .then((g) => {
            this.setState({ request: g.data[0] });
          });
      }
    } catch (e) {}

    if (id_request) {
      this.action
        .execute(
          'get',
          this.APIEndpoints.REQUEST + 'one' + '/' + id_request,
          'request'
        )
        .then((g) => {
          this.setState({ request: g.data[0] });
        });
    } else {
      this.action.execute(
        'get',
        this.APIEndpoints.COMPANY_TIME + '/time',
        'time'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.GENERAL_PARAMETERS,
        'general_params'
      );

      // this.action.execute('get', this.APIEndpoints.REQUEST, 'requests');
      this.action.execute('get', this.APIEndpoints.SLA, 'slas');
      this.action.execute('get', this.APIEndpoints.USER + '/view', 'view');
      this.action.execute(
        'get',
        this.APIEndpoints.REQUESTS + '/api/allmailcontract',
        'mailcontracts'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/40',
        'urgencies'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/60',
        'priorities'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/70',
        'type_tickets'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/50',
        'impacts'
      );
      //this.action.execute('get', this.APIEndpoints.TICKET + '?type=' + route, 'tickets');
      this.action.execute(
        'get',
        this.APIEndpoints.USER + '/department/chief',
        'chiefs'
      );
      this.action.execute('get', this.APIEndpoints.TASK, 'tasks');
      this.action.execute(
        'get',
        this.APIEndpoints.TASK + '/tasksTemplateforms',
        'tasksTemplateforms'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TASK + '/getforms',
        'formstemplate'
      );
    }
  }
  render() {
    let {
      language,
      impacts,
      contracts,
      categories,
      groups,
      urgencies,
      priorities,
      users,
      ticket,
      companies,
      type_tickets,
      slas,
      catalogs,
      chiefs,
      taskcatalog,
      tasks,
      formstemplate,
      loading,
      general_params,
      tasksTemplateforms,
    } = this.store;
    let { id_request } = this.props;
    let { page, request } = this.state;

    let history = this.history2;
    let { state, pathname } = history.location;
    let approve = false;
    if (pathname === '/dash/requestapprove') {
      approve = true;
    }
    var values = state;

    if (state && state.id) {
      values = request;
    }

    if (
      !state &&
      (testRoute('/dash/request/view', pathname) ||
        testRoute('/dash/request/categorize', pathname))
    ) {
      history.goBack();
      return null;
    }

    const aprove = (obj) => {
      let valor = false;

      if (
        obj.chief &&
        obj.chief.includes(this.store.getUserLoged().id) == true
      ) {
        valor = true;
      }
      return valor;
    };

    let diag = (
      <Dialog
        open={page === 'reject'}
        bodyStyle={{ padding: 0, overflowY: 'none' }}
        title={
          this.store.getUserLoged().role == 'solicitant'
            ? language.CANCEL
            : language.REJECT
        }
        onClose={() => this.handlePage('list')}
        contentProps={{
          className: 'pt-4',
        }}
      >
        <CommonForm
          fields={[
            {
              col: 12,
              name: 'reason',
              label: language.JUSTIFICATION,
              type: 'textEditor',
              required: true,
            },
          ]}
          onSubmit={(_values) =>
            this.onFormSubmit('reject', {
              id_request: this.state.values.id_request,
              values: _values,
            })
          }
          button={{ label: language.SAVE }}
        />
      </Dialog>
    );

    return id_request ? (
      request != '' && (
        <Fragment>
          {this.state.catModal == true ? (
            <DialogFormTicket
              store={this.store}
              onFormSubmitTab={this.onFormSubmitTab}
              open={this.state.catModal}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              ondonwFile={this.ondonwFile}
              values={this.state.catvalues}
              onClose={() => this.setState({ catModal: false })}
            />
          ) : null}
          {diag}
          {aprove(request) == true && request.approval == true ? (
            <RequestApproval
              id={request.id_request}
              fromDash={true}
              redirect2={this.redirect}
              fromDashApprove={request.fromDashApprove}
              language={language}
              onFormSubmit={this.onFormSubmit}
              request={request}
              onFormSubmitAprove={this.onFormSubmitAprove}
              {...this.props}
            />
          ) : (
            <RequestView
              version={'R' + id_request}
              language={language}
              catHandle={this.catHandle}
              handlePage={this.handlePage}
              solicit={
                this.store.getUserLoged().role == 'solicitant' ? true : false
              }
              dash={true}
              store={this.store}
              filedelete={this.store.filedelete}
              onClose={this.props.onClose}
              request={request}
              ondeletefile={this.ondeletefile}
              redirect2={this.redirect2}
              filesdelete={this.state.filesdelete}
              ondonwFile={this.ondonwFile}
            />
          )}
          )
        </Fragment>
      )
    ) : (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        {this.state.openTicket && (
          <Dialog
            title={''}
            open={this.state.openTicket}
            maxWidth="xl"
            onClose={() => this.setState({ openTicket: false })}
          >
            <TicketManagement
              detail={true}
              id_ticket_detail={this.state.ticketHistory}
              isHistory
            ></TicketManagement>
          </Dialog>
        )}

        {this.state.catModal == true ? (
          <DialogFormTicket
            store={this.store}
            onFormSubmitTab={this.onFormSubmitTab}
            open={this.state.catModal}
            APIEndpoints={this.APIEndpoints}
            action={this.action}
            ondonwFile={this.ondonwFile}
            values={this.state.catvalues}
            onClose={() => this.setState({ catModal: false })}
          />
        ) : null}

        {(testRoute('/dash/request', pathname) ||
          testRoute('/dash/requestapprove', pathname)) && (
          <Animate>
            {this.state.dialog == 1 ? (
              <DialogForm
                onFormSubmit={this.onFormSubmit}
                store={this.store}
                APIEndpoints={this.APIEndpoints}
                action={this.action}
                tasks={tasks}
                ondonwFile={this.ondonwFile}
                formstemplate={formstemplate}
                redirect={this.redirect}
                language={language}
                categories={this.store.categories}
                catalogs={this.store.catalogs}
                open={this.state.dialog == 1}
                onClose={() => this.handleDialog()}
                ds={this.state.ds}
                setDs={(state) => this.setState({ ds: state })}
              />
            ) : null}

            <RequestList
              handleDialog={this.handleDialog}
              catHandle={this.catHandle}
              approve={approve}
              language={language}
              redirect={this.redirect}
              mailcontracts={this.store.mailcontracts}
              handlePage={this.handlePage}
              page={page}
              toggleAlert={this.store.toggleAlert}
              onFormSubmitAprove={this.onFormSubmitAprove}
              onFormSubmit={this.onFormSubmit}
              request={values}
              iduser={this.store.getUserLoged().id}
              role={this.store.getUserLoged().role}
              solicit={
                this.store.getUserLoged().role == 'solicitant' ? true : false
              }
              view={this.store.view}
              pathname={pathname}
              timez={this.store.time}
              users={users}
              onReloadRequests={this.onReloadRequests}
              general_params={general_params}
              values={values}
              companies={companies}
              redirect2={this.redirect2}
              callPageSelectFilter={this.callPageSelectFilter}
              callSortItem={this.callSortItem}
              requests={this.state.requests}
              totalrequest={this.state.totalrequest}
              downloadRequests={this.downloadRequests}
            />
          </Animate>
        )}

        {testRoute('/dash/request/new', pathname) && (
          <Animate>
            <RequestForm
              StoreLoading={loading}
              APIRoot={this.APIRoot}
              UpdateFiles={this.UpdateFiles}
              files={this.state.files}
              fileError={this.state.fileError}
              values={values}
              formstemplate={formstemplate}
              handlePage={this.handlePage}
              onFormSubmit={this.onFormSubmit}
              redirect={this.redirect}
              redirect2={this.redirect2}
              onDrop={this.onDrop}
              removeFile={this.removeFile}
              language={language}
              impacts={impacts}
              chiefs={chiefs}
              contracts={contracts}
              categories={categories}
              groups={groups}
              urgencies={urgencies}
              priorities={priorities}
              users={users}
              companies={companies}
              type_tickets={type_tickets}
              slas={slas}
              catalogs={catalogs}
              taskcatalog={taskcatalog}
              tasks={tasks}
              tasksTemplateforms={tasksTemplateforms}
            />
          </Animate>
        )}

        {this.state.page == 'view' && (
          <RequestView
            language={language}
            handlePage={this.handlePage}
            catHandle={this.catHandle}
            solicit={
              this.store.getUserLoged().role == 'solicitant' ? true : false
            }
            onClose={() => this.handlePage('list')}
            store={this.store}
            filedelete={this.store.filedelete}
            request={this.state.values}
            ondeletefile={this.ondeletefile}
            redirect2={this.redirect2}
            filesdelete={this.state.filesdelete}
            ondonwFile={this.ondonwFile}
          />
        )}

        {testRoute('/dash/request/categorize', pathname) && (
          <Animate>
            <TicketForm
              language={language}
              handlePage={this.handlePage}
              values={values}
              onFormSubmit={(_values) => this.onFormSubmit('ticket', _values)}
              redirect={this.redirect}
              redirect2={this.redirect2}
              impacts={impacts}
              ondonwFile
              contracts={contracts}
              ondonwFile={this.ondonwFile}
              categories={categories}
              groups={groups}
              urgencies={urgencies}
              taskcatalog={taskcatalog}
              priorities={priorities}
              users={users}
              companies={companies}
              groupstech={this.store.groupstech}
              type_tickets={type_tickets}
              chiefs={chiefs}
              slas={slas}
              catalogs={catalogs}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              categorize={true}
              tasksTemplateforms={tasksTemplateforms}
            />
          </Animate>
        )}

        {diag}
      </CommonPage>
    );
  }
}
