import React, { useState, useEffect } from 'react';
import CommonForm2 from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import Dialog from '../../common/Dialog';
import Button from '../../common/Button';
import CommonDelete from '../../common/CommonDelete';
import { Chip } from '@material-ui/core';
import Store from '@data/Store';
import { translateFromEn } from '../../common/languages/Dictionary';

const CustomizePiority = ({ language, action, APIEndpoints, company }) => {
  const [page, setPage] = useState('list');
  const [values, setValues] = useState([]);
  const [data, setData] = useState([]);
  const [priorities, setPriorities] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const prioritiesResult = await action.execute('get', APIEndpoints.COMPANY + '/customizationpiority/options', '');
    const prioritiesOptions = prioritiesResult.data.reduce((acc, curr) => {
      const { comment, description } = curr;
      acc[curr.id_table] = { comment, description };
      return acc;
    }, {});
    setPriorities(prioritiesOptions);

    const prioritiesCompany = await action.execute('get', APIEndpoints.COMPANY + '/getcustomizationpiority/' + (company.id_company ? company.id_company : 0), '');
    const itens = prioritiesCompany.data.customizations.map((d) => ({
      id: d.id_customize_priority_company,
      impact: translateFromEn(prioritiesOptions[d.fk_id_impact]?.description, language),
      urgency: translateFromEn(prioritiesOptions[d.fk_id_urgency]?.description, language),
      priority: translateFromEn(prioritiesOptions[d.fk_id_priority]?.description, language),
      dt_cad: moment(d.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
      actions: (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="circle"
                  icon="fas fa-edit"
                  label={language.EDIT}
                  color="default"
                  style={{ fontSize: 12, width: 21, height: 21 }}
                  size={0.9}
                  onClick={() => handlePage('edit', d)}
                />
                <Button
                  variant="circle"
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 12, width: 21, height: 21 }}
                  size={0.9}
                  className="mx-2"
                  onClick={() => handlePage('remove', d)}
                />
              </div>
            }
          />
        </React.Fragment>
      )
    }));
    setData(itens);
  };

  const handlePage = (page, values) => {
    setPage(page);
    setValues(values);
  };

  const buildPrioritiesByKey = (priority) => {
    const prioritiesData = Object.keys(priorities).filter((key) => priorities[key].comment === priority)
    return prioritiesData.map((key) => ({
      value: parseInt(key),
      label: translateFromEn(priorities[key].description, language),
    }));
  };

  const cols = [
    { key: 'id', label: 'ID' },
    { key: 'impact', label: language.IMPACT },
    { key: 'urgency', label: language.URGENCY },
    { key: 'priority', label: language.PRIORITY },
    { key: 'dt_cad', label: language.DT_CAD },
    { key: 'actions', label: language.ACTIONS },
  ];

  const fields = [
    {
      col: 4,
      type: 'select',
      name: 'fk_id_impact',
      label: language.IMPACT,
      options: buildPrioritiesByKey('Impact'),
      required: true,
    },
    {
      col: 4,
      type: 'select',
      name: 'fk_id_urgency',
      label: language.URGENCY,
      options: buildPrioritiesByKey('Urgency'),
      required: true,
    },
    {
      col: 4,
      type: 'select',
      name: 'fk_id_priority',
      label: language.PRIORITY,
      options: buildPrioritiesByKey('Pririoty'),
      required: true,
    },
  ];

  const savePriorization = (data) => {
    data.fk_id_company = company.id_company || 0;
    const url = data.id_customize_priority_company
      ? '/updatecustomizationpiority'
      : '/createcustomizationpiority';
    action
      .execute('post', APIEndpoints.COMPANY + url, '', data)
      .then((res) => {
        handlePage('list');
        getData();
      })
      .catch((e) => {
        Store.toggleAlert(
          true,
          'Já existe um cadastro para esse impacto e urgência',
          'error'
        );
      });
  };

  const onDelete = () => {
    action
      .execute(
        'post',
        APIEndpoints.COMPANY + '/updatecustomizationpiority',
        '',
        {
          action: 'remove',
          id_customize_priority_company: values.id_customize_priority_company,
        }
      )
      .then((res) => {
        handlePage('list');
        getData();
      });
  };

  const button = {
    md: 12,
    label: language.SAVE,
    primary: true,
    style: { marginTop: 90 },
  };
  return (
    <div>
      <CommonTable
        columns={cols}
        data={data}
        beforeBar={
          <Button
            variant="circle"
            icon="fas fa-plus"
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            label={language.ADD}
            primary={true}
            onClick={() => handlePage('add')}
          />
        }
      />
      {['add', 'edit'].includes(page) && (
        <Dialog
          title={language.ADD}
          open={true}
          onClose={() => setPage('list')}
          maxWidth="md"
        >
          <CommonForm2
            fields={fields}
            values={values}
            onSubmit={savePriorization}
            button = {button}
          />
        </Dialog>
      )}

      {page == 'remove' && (
        <CommonDelete
          language={language}
          closeModal={() => handlePage('list')}
          onDelete={() => onDelete()}
          message={
            language.WARNING_DELETE_COMPANY +
            values?.id_customize_priority_company
          }
        />
      )}
    </div>
  );
};

export default CustomizePiority;
