import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Card, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
import { Checkbox } from '@material-ui/core';
import Store from '@data/Store'

import Actions from '@data/Actions';
import Constants from '@data/Constants';
import { Tooltip } from 'react-tippy';

@observer
export default class PasswordList extends CommonPage {
  constructor() {
    super();
    this.state = {
      openTest: false,
      devices: [],
      companies: [],
    };

    this.openmail = this.openmail.bind(this);
  }

  componentWillMount() {
    const action = new Actions();
    action
      .execute('get', Constants.APIEndpoints.DEVICE, 'devices')
      .then((result) => {
        this.setState({ devices: result.data });
      });

    action
      .execute('get', Constants.APIEndpoints.COMPANY, 'companies')
      .then((result) => {
        this.setState({ companies: result.data });
      });
  }

  openmail() {
    this.setState({ openTest: true });
  }
  render() {
    let {
      language,
      handlePage,
      openHistory,
      createNewPass,
      changeCompany,
      isLogged,
      redirect,
      onDelete,
      companyName,
      companyTooltip,
      companies
    } = this.props;

    let col = [
      {
        key: 'id_password_safe',
        label: language.ID,
        thConfig: true,
        style: { width: 1, textAlign: 'center', paddingRight: 0 },
      },
      {
        key: 'description',
        label: language.DESCRIPTION,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'user_email',
        label: language.USER,
        thConfig: true,
        style: { width: 100 },
      },

      {
        key: 'user_company',
        label: language.COMPANY,
        thConfig: true,
        style: { width: 100 },
      },

      {
        key: 'fk_id_device',
        label: language.DEVICE,
        thConfig: true,
        style: { width: 140 },
      },

      {
        key: 'link',
        label: language.LINK,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'observation',
        label: language.OBSERVATION,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'expiration_date',
        label: language.EXPIRATION_DATE,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'profile_type',
        label: language.PROFILE_TYPE,
        thConfig: true,
        style: { width: 140 },
      },

      {
        key: 'action',
        label: language.ACTION,
        thConfig: true,
        style: { width: 100, textAlign: 'center' },
      },
    ];

    let companyOptions = this.state.companies.map((c) => ({
      value: c.id_company,
      label: c.name,
    }));

    let data = (this.props.store || []).filter(c => typeof(companies[0]) == 'number' ? companies.includes(c.fk_id_company) : 1 == 1).map((obj, index) => {
     
      const deviceName = (id) => {
        let devices = this.state.devices.filter(
          (device) => device.id_device == id
        );
        if (devices[0] != undefined) {
          return devices[0].name;
        }
      };

      const showUser = (user) => {
        var base64 =
          /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
        if (base64.test(user)) {
          try {

            const crypto = require('crypto');
            let decipher, tRet;
            var aSecret = 'Orch3str0!@forever!';

            let aMsg = user.replace(/\s/g, '');
            decipher = crypto.Decipher('aes-256-cbc', aSecret);
            tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
            tRet += decipher.final('utf8');
            return tRet;
          } catch (e) {
            return user;
          }
        } else {
          return user;
        }
      };

     const getNameCompany = (id) =>{
        let companyName = Store.companies.filter((company) => company.id_company == id);
        if (companyName[0]){
          return companyName[0].name
        }else{
          return ''
        }
      }

      return {
        id_password_safe: obj.id_password_safe,
        description: obj.description,
        user_email: showUser(obj.user_email),
        user_company : getNameCompany(obj.fk_id_company),
        fk_id_device: deviceName(obj.fk_id_device),
        link: obj.link,
        observation: obj.observation.replace(/<.*?>/g, ''),
        expiration_date: obj.expiration_date
          ? moment(obj.expiration_date).format('DD/MM/YYYY')
          : '',
        profile_type:
          obj.profile_type == '1'
            ? 'Privativa'
            : obj.profile_type == '2'
            ? 'Customizada'
            : obj.profile_type == '3'
            ? 'Livre'
            : '',

        action: (
          <React.Fragment>
            {openHistory && (
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <RaisedButton
                      circleButton
                      icon="fas fa-plus"
                      label={language.ADD_NEW_PASSWORD2}
                      color="default"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      className="mr-2"
                      onClick={() => createNewPass(obj)}
                    />
                    <RaisedButton
                      circleButton
                      icon="fas fa-eye"
                      label={language.SEE}
                      color="success"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      className="mr-2"
                      onClick={() => openHistory(obj)}
                    />
                    <RaisedButton
                      circleButton
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      className="mr-2"
                      onClick={() => handlePage('form', obj)}
                    />
                    <RaisedButton
                      circleButton
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      onClick={() => handlePage('remove', obj)}
                    />
                  </div>
                }
              />
            )}
          </React.Fragment>
        ),
      };
    });

    let button = { label: language.SAVE };
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        {isLogged && openHistory && (
          <CommonTable
            title={''}
            language={language}
            columns={col}
            index="MailList"
            data={data}
            searchColumn
            orderColumn
            width="100%"
            orderColumn
            paginationTop={false}
            searchColumn={true}
            beforeBar2={[
              <Tooltip
              html={
                 <div style={{ color: 'white' }}>{companyTooltip}</div>
              }
              arrow={true}
              position='left'
              theme='dark'
              style={{ width: '100%' }}
              className='ellipsis'
           >
              <span style ={{color: 'white', marginRight: 10}}>
                {companyName}
              </span>
              </Tooltip>
              ,
            
              <RaisedButton
                circleButton
                icon="fas fa-edit"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                primary={true}
                label={language.COMPANY_CHANGE}
                onClick={() => changeCompany()}
              />
            ]}
            beforeBar={
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                primary={true}
                label={language.ADD}
                onClick={() => handlePage('form')}
              />
            }
          />
        )}


{isLogged && !openHistory && (
          <CommonTable
            title={''}
            language={language}
            columns={col}
            index="MailList"
            data={data}
            searchColumn
            orderColumn
            width="100%"
            orderColumn
            paginationTop={false}
            searchColumn={true}
            beforeBar={
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                primary={true}
                label={language.ADD}
                onClick={() => handlePage('form')}
              />
            }
          />
        )}





      </Fragment>
    );
  }
}
