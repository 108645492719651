import React, { useState, useEffect } from 'react';
import { fetch } from 'fetch';
import { observer } from 'mobx-react-lite';

import Store from '../../../data/Store';
import Dialog from '../../../common/Dialog';
import { MaskMoney } from '../../../common/Mask';
import CommonForm from '../../../common/CommonForm2';

import Options from './options';

export default observer(
  ({
    open,
    onClose,
    title,
    values = {},
    action,
    APIEndpoints,
    budgetcontrols,
  }) => {
    let { contracts, language, companies } = Store;
    let [typeItem, setTypeItem] = useState(0);
    let [form, setForm] = useState(null);
    let [currency_symbol_disabled, set_currency_symbol_disabled] = useState(
      !values.cotacion
    );

    let options = Options({
      language,
      company: Store.companies,
      contracts,
    });

    function onSubmit(v) {
      fetch({
        method: v.id_budgetcontrol ? 'put' : 'post',
        url: '/api/budgetcontrol',
        model: 'budgetcontrols',
        body: v,
        key: v.id_budgetcontrol ? 'id_budgetcontrol' : undefined,
        config: { btnLoading: 'sendBudget' },
      }).then(onClose);
      //  action.execute('get', APIEndpoints.BUGET, 'budgetcontrols');
    }

    function onChangeField(f, v) {
      if (f) {
        if (f.name == 'currency_symbol') {
          if (v == 'BRL') {
            set_currency_symbol_disabled(true);
            form.handleValue(undefined, 'cotacion');
          } else {
            set_currency_symbol_disabled(false);
          }
        }
        if (f.name == 'type') {
          setTypeItem(v);
        }

        if (typeItem == 3 && f.name == 'fk_id_contract') {
          let contract = contracts.find((e) => e.id_contract == v);
          form.handleValue(contract.name, 'name');
          form.handleValue(contract.fk_id_company, 'fk_id_company');
          form.handleValue(
            contract.type_contract == 'capex' ? 1 : 2,
            'type_capital_expenditure'
          );
          form.handleValue(contract.value, 'expected_cost');
          form.handleValue(contract.start_date, 'dt_start');
          form.handleValue(contract.end_date, 'dt_end');

          let dateStart = moment(contract.start_date);
          let dateEnd = moment(contract.end_date);
          let timeValues = [];

          while (
            dateEnd > dateStart ||
            dateStart.format('M') === dateEnd.format('M')
          ) {
            timeValues.push(dateStart.months() + 1);
            dateStart.add(1, 'month');
          }
          form.handleValue(
            [...new Set(timeValues.sort((a, b) => a - b))],
            'period'
          );
        }
      }
    }

    return (
      <Dialog
        title={title}
        open={open}
        onClose={onClose}
        maxWidth={'lg'}
        monitor_scroll
      >
        <CommonForm
          values={{
            ...values,
            expected_cost: (values.expected_cost || 0) / (values.cotacion || 1),
          }}
          onRef={setForm}
          onChangeField={onChangeField}
          fields={[
            {
              col: 4,
              type: 'select',
              name: 'type',
              label: language.TYPE,
              options: options.type(),
            },
            {
              col: 4,
              type: 'text',
              name: 'name',
              label: language.NAME,
              visible: typeItem != 3,
            },
            {
              col: 4,
              type: 'select',
              name: 'status',
              label: language.STATUS,
              options: options.status(),
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_contract',
              label: language.NAME,
              visible: typeItem == 3,
              options: options.contract(),
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_company',
              label: language.COMPANY,
              options: options.company(),
            },
            {
              col: 4,
              type: 'date',
              name: 'dt_start',
              label: language.INITIAL_DATE,
            },
            {
              col: 4,
              type: 'date',
              name: 'dt_end',
              label: language.FINAL_DATE,
            },
            {
              col: 4,
              type: 'select',
              name: 'type_capital_expenditure',
              label: language.BILLING_TYPE,
              options: options.type_expenditure(),
            },
            {
              col: 8,
              type: 'select',
              name: 'period',
              label: language.PERIOD,
              isMulti: true,
              options: options.period(),
            },
            {
              col: 4,
              type: 'select',
              name: 'currency_symbol',
              label: language.MONETARY_TYPE,
              options: [
                { value: 'BRL', label: 'Real (BRL)' },
                { value: 'USD', label: 'Dollar (USD)' },
                { value: 'EUR', label: 'Euro (EUR)' },
              ],
            },
            {
              col: 4,
              type: 'text',
              outPut: (value) => parseFloat(value),
              InputProps: {
                inputComponent: MaskMoney,
              },
              placeholder: 'RS 0,00',
              name: 'cotacion',
              label: language.QUOTE + ' (R$)',
              disabled: currency_symbol_disabled,
            },
            {
              col: 4,
              type: 'text',
              outPut: (value) => parseFloat(value),
              InputProps: {
                inputComponent: MaskMoney,
              },
              placeholder: 'RS 0,00',
              name: 'expected_cost',
              label: language.EXPECTED_COST,
            },
            {
              col: 12,
              type: 'textEditor',
              name: 'description',
              label: language.DESCRIPTION,
            },
          ]}
          loading="sendBudget"
          onSubmit={onSubmit}
        />
      </Dialog>
    );
  }
);
