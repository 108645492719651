import React, { useState, useEffect, useReducer, Fragment } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import knowledgeBaseIcon2 from '@images/knowledgeBaseIcon2.png';
import imgRoboto from '@images/Roboto2.png';
import { MenuItem } from '@material-ui/core';
import Button from '@common/Button';
import './DialogForm.css';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Card, Grid } from '@material-ui/core';
import KnowledgeBase from '@images/svg-icon/KnowledgeBase';
import AppBar from '@common/AppBar2';
import Avaliation from '../../TicketManagement/Avaliation';
import List from '@common/List';
const crypto = require('crypto');
import SolutionContainer from '../../solution/SolutionContainer';

function decryp(aMsg) {
  try {
    var aSecret = 'Orch3str0!@forever!';

    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');

    return tRet;
  } catch (e) {
    return [];
  }
}

const MyHeader = ({ title, subTitle, className }) => (
  <AppBar
    contentProps={{
      className: 'pl-0 ' + className,
    }}
    titleProps={{
      style: { color: 'var(--primary)' },
    }}
    title={title}
    subTitle={subTitle}
  />
);

export default function (props) {
  let {
    open = false,
    onClose,
    onFormSubmit,
    APIEndpoints,
    action,
    language,
    redirect,
    tasks,
    ds,
    setDs,
  } = props;
  let { general_parameters, companiess, users, tasksTemplateforms } =
    props.store;

  const [category, setCategory] = useState(0);
  const [idTemplate, setidTemplate] = useState(null);
  const [formstemplate, setformstemplate] = useState([]);

  const [categories, setCategories] = useState([]);
  const [catalogs, setCatalogs] = useState([]);

  const [refresh, setRefresh] = useState(false);
  let [channel, setChannel] = useState([]);
  let [ticketavaliation, setTicketavaliation] = useState([]);
  const [catalog, setCatalog] = useState(0);
  const [formRef, setFormRef] = useState(null);
  const [formRefs, setFormRefs] = useReducer((state, val) => {
    return [...state, val];
  }, []);
  const [openModal, setOpenModal] = useState(false);
  const [values, setValues] = useState([]);
  const [forms, setForms] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [categoryDepartment, setcategorydepartment] = useState([]);
  const [categorydep, setCategoryDep] = useState([]);

  const [idCompany, setIdCompany] = useState(0);
  const [idDepartment, setIdDeparment] = useState(0);

  const [openKnowledge, setOpenKnowledge] = useState(false);

  //Variáveis para o formulário
  const [subdptName, setSubdptName] = useState(language.SUBDEPARTMENT);
  const [dptName, setDptName] = useState(language.DEPARTMENT);
  const [chieftDptName, setChieftDptName] = useState(language.DEPARTMENT_BOSS);
  const [companyName, setCompanyName] = useState(language.COMPANY);

  const [idCatalogTask, setIdCatalogTask] = useState(0);
  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );

  useEffect(() => {
    action
      .execute('get', APIEndpoints.TABLE_STRUCTURED + '/120', 'channel')
      .then((item) => {
        setChannel(item.data);
      });
  }, []);

  useEffect(() => {
    action.execute(
      'get',
      APIEndpoints.TASK + '/tasksTemplateforms',
      'tasksTemplateforms'
    );
  }, []);

  useEffect(() => {
    if (subdptName != language.SUBDEPARTMENT) {
      action
        .execute(
          'post',
          APIEndpoints.DEPARTMENT + '/getDepartmentBySubName/',
          '',
          { name: subdptName }
        )
        .then((e) => {
          if (e.data.length > 0) {
            updateState({ departments_var: e.data[0].name });
            setDptName(e.data[0].name);
          } else {
            updateState({ departments_var: '' });
            setDptName(language.DEPARTMENT);
          }
        });
    }
  }, [subdptName]);

  useEffect(() => {
    if (dptName != language.DEPARTMENT) {
      action
        .execute('post', APIEndpoints.COMPANY + '/getCompanyByDptName/', '', {
          name: dptName,
        })
        .then((e) => {
          if (e.data.length > 0) {
            setCompanyName(e.data[0].name);
            updateState({ companies_var: e.data[0].name });
          } else {
            setCompanyName(language.COMPANY);
            updateState({ companies_var: '' });
          }
        });

      action
        .execute('post', APIEndpoints.DEPARTMENT + '/getChiefByDptName/', '', {
          name: dptName,
        })
        .then((e) => {
          if (e.data.length > 0) {
            setChieftDptName(e.data[0].name);
            updateState({ chieftdpt_var: e.data[0].name });
          } else {
            setChieftDptName(language.DEPARTMENT_BOSS);
            updateState({ chieftdpt_var: '' });
          }
        });
    }
  }, [dptName]);

  useEffect(() => {
    const template = formstemplate.filter(
      (c) => c.fk_id_catalog_task === parseInt(idCatalogTask)
    );
    let c = 0;
    let item = template.map((obj) => {
      c = c + 1;
      let id = 'ID' + c;
      return (
        <div className="contain">
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={id}>
              {obj.html.length > 0 ? formshtml(decryp(obj.html)) : ''}
            </p>
          </div>
          <div style={{ marginTop: '80px' }} />
          <div>
            {obj.user_up != null
              ? `${language.LAST_UPDATE}: ` +
                obj.user_up +
                '-' +
                moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
              : null}
          </div>
          <hr />
        </div>
      );
    });

    try {
      setForms(item);
    } catch (e) {
      console.log(e);
    }
  }, [dptName, chieftDptName, subdptName, companyName]);

  const formshtml = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    /**
     * Added the timezone of the user who created the ticket.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];

    t.split('<div').map((i, key) => {
      if (i.indexOf('companies_var') > -1) {
        data.push(
          <div id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: companyName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: companyName,
                  routeAll: `${APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('allusers_var') > -1) {
        data.push(
          <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: language.USER,
                  textinit: language.USER,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Usuário',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          nameField = textWithoutTags[1].split('|')[1];
        } catch (e) {}
        data.push(
          <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: language.HOURS,
                  type: 'time',
                  format: '##:##',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = '';
        let name_field = '';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          name_field = textWithoutTags[1].split('|')[1];
          value_field = textWithoutTags[1].split('|')[2];
        } catch (e) {}
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`;
        data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('departments_var') > -1) {
        data.push(
          <div id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: dptName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: dptName,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('subsdpt_var') > -1) {
        data.push(
          <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
                setSubdptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: language.SUBDEPARTMENT,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('contracts_var') > -1) {
        data.push(
          <div id="contracts_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: language.CONTRACT,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chieftdpt_var') > -1) {
        data.push(
          <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss',
                  label: '',
                  textlabel: ``,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: chieftDptName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chieftDptName,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        );
      } else {
        if (i.length > 0) {
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }

          data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
        }
      }
    });
    return data;
  };

  useEffect(() => {
    action
      .execute('get', APIEndpoints.TASK + '/getforms', 'formstemplate')
      .then((g) => {
        setformstemplate(g.data);
      });
  }, []);

  const selectcategory = (fk_id_company, fk_id_department) => {
    let cat = categoryDepartment
      .filter(
        (c) =>
          c.fk_id_company == fk_id_company &&
          c.fk_id_department == fk_id_department
      )
      .map((v) => v.fk_id_category);

    setCategoryDep(cat);
  };

  const catalogCompany = (company, v) => {
    if (company && v) {
      action
        .execute(
          'get',
          APIEndpoints.CATALOG + '/company/' + company + '/' + v,
          ''
        )
        .then((b) => {
          setCatalogs(b.data);
        });
    } else {
      action.execute('get', APIEndpoints.CATALOG, '').then((b) => {
        setCatalogs(b.data);
      });
    }
  };

  const categoryCompany = (company) => {
    if (company) {
      action
        .execute('get', APIEndpoints.CATEGORY + '/company/' + company, '')
        .then((b) => {
          setCategories(b.data);
        });
    } else {
      action.execute('get', APIEndpoints.CATEGORY, '').then((b) => {
        setCategories(b.data);
      });
    }
  };

  const DepartmentList = (id) => {
    action.execute('get', APIEndpoints.DEPARTMENT + '/' + id, '').then((v) => {
      let departments = v.data
        .filter((b) => b.catalog > 0)
        .map((d) => ({
          value: parseInt(d.id_department),
          label: d.name,
        }));
      setDepartmentOptions(departments);
    });
  };
  useEffect(() => {
    setValues({ fk_id_company: props.store.getUserLoged().id_company });
    setIdCompany(props.store.getUserLoged().id_company);
    setForms([]);

    action.execute('get', APIEndpoints.COMPANY + '/category', '').then((b) => {
      action.execute(
        'get',
        APIEndpoints.REQUESTS + '/api/generalparameters',
        'general_parameters'
      );

      setcategorydepartment(b.data);
    });

    categoryCompany(props.store.getUserLoged().id_company);

    //action.execute('get', APIEndpoints.CATALOG, 'catalogs');

    // action.execute('get', APIEndpoints.CATEGORY, 'categories');
    try {
      if (companiess[0] == undefined) {
        action.execute('get', APIEndpoints.COMPANY).then((b) => {
          let emp = b.data;
          setCompanies(emp);
          setCompanyOptions(
            emp.map((c) => ({ value: c.id_company, label: c.name }))
          );
          let dpt = [];
          DepartmentList(props.store.getUserLoged().id_company);
          setIdCompany(props.store.getUserLoged().id_company);
        });
      } else {
        action.execute('get', APIEndpoints.COMPANY).then((b) => {
          setCompanies(b.data);
          setCompanyOptions(
            b.data.map((c) => ({ value: c.id_company, label: c.name }))
          );
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [companiess]);

  useEffect(() => {
    setTicketavaliation(false);

    action
      .execute('get', APIEndpoints.REQUESTS + '/api/generalparameters')
      .then((e) => {
        if (e.data.avaliation_tickets == true) {
          action
            .execute(
              'post',
              APIEndpoints.TICKET + '/ticketFind',
              '',
              { avaliationticketunique: true },
              {},
              null,
              null
            )
            .then((g) => {
              if (g.data.length > 0) {
                setOpenModal(true);
                setTicketavaliation(true);
              }
            });
        }
      });
  }, []);

  const _onFormSubmit = (item, values) => {
    let v = {
      ...values,
      ...formRefs.reduce((obj, e) => ({ ...obj, ...e.state.values }), {}),
    };

    if (v.subject) {
      setDs(false);

      let values_ = {
        ...values,
        ...formRefs.reduce((obj, e) => ({ ...obj, ...e.state.values }), {}),
      };

      if (
        values_.fk_id_catalog_service == null ||
        values_.fk_id_catalog_task == null ||
        values_.fk_id_category == null
      ) {
        values_.fk_id_catalog_service = values.fk_id_catalog_service;
        values_.fk_id_catalog_task = values.fk_id_catalog_task;
        values_.fk_id_category = values.fk_id_category;
      }
      if (values_.fk_id_catalog_service == null || values_)
        values_.fk_id_company = idCompany;

      if (chieftDptName !== language.DEPARTMENT_BOSS) {
        values_.approver_name = chieftDptName;
      }

      onFormSubmit('', values_, general_parameters);
    }
  };

  const loadtemp = (idTemplate) => {
    try {
      const template = formstemplate.filter(
        (c) => c.fk_id_template_forms === parseInt(idTemplate)
      );
      if (idTemplate) {
        let values = {
          //  subject: template[0].name,

          fk_id_category: template[0].fk_id_category,
          fk_id_catalog_service: template[0].fk_id_catalog_service,
          fk_id_catalog_task: template[0].fk_id_catalog_task,

          description: template[0].name,
          idtemplate: template[0].fk_id_catalog_task,
          fk_id_department: template[0].fk_id_department,
          name_company: template[0].company_name,
          fk_id_company: template[0].fk_id_company,
        };
        DepartmentList(template[0].fk_id_company);
        setIdDeparment(template[0].fk_id_department);
        setIdCompany(template[0].fk_id_company);
        selectcategory(template[0].fk_id_company, template[0].fk_id_department);
        //categoria
        setCategory(template[0].fk_id_category);
        catalogCompany(template[0].fk_id_company, template[0].fk_id_category);

        setCatalog(template[0].fk_id_catalog_service);

        let c = 0;

        let item = template.map((obj) => {
          c = c + 1;
          let id = 'ID' + c;
          return (
            <div className="contain">
              <div className="itemTicketDetail">
                <p style={{ color: '#888080' }} id={id}>
                  {formshtml(decryp(obj.html))}
                </p>
              </div>
              <div style={{ marginTop: '80px' }} />
              <div>
                {obj.user_up != null
                  ? `${language.LAST_UPDATE}: ` +
                    obj.user_up +
                    '-' +
                    moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
                  : null}
              </div>
              <hr />
            </div>
          );
        });
        setForms(item);

        formRefs[1].getFields().map((e) => {
          if (e.name == 'fk_id_category') {
            formRefs[1].handleValue(
              template[0].fk_id_category,
              e.name,
              categories.filter(
                (g) => g.id_category == template[0].fk_id_category
              )[0].name
            );
          }
        });

        setValues(values);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const red = () => {
    if (window.location.href.indexOf('/dash/gsd') > -1) {
      redirect('/dash/gsd');
    } else {
      redirect('/dash/request');
    }
  };
  const onChangeField = (field, value) => {
    // setformstemplate
    if (field.name === 'fk_id_category') {
      setCategory(value);
      catalogCompany(idCompany, value);
    } else if (field.name === 'fk_id_catalog_service') {
      // catalogCompany(value);
      setCatalog(value);
    } else if (field.name === 'fk_id_user') {
    } else if (field.name === 'fk_id_catalog_task') {
    }
  };

  const cleanValues = () => {
    formRefs[0].getFields().map((e) => {
      formRefs[0].handleValue(0, e.name, '');
    });

    formRefs[1].getFields().map((e) => {
      formRefs[1].handleValue(0, e.name, 0);
    });

    formRefs[2].getFields().map((e) => {
      formRefs[2].handleValue(0, e.name, '');
    });
  };

  let categoryOptions = categories.map((c) => ({
    value: c.id_category,
    label: c.name,
  }));

  if ([true].includes(general_parameters.mult_catalog)) {
    categoryOptions = categoryOptions.filter((v) =>
      categorydep.includes(v.value)
    );
  }

  let serviceCatalogOptions = [];
  let tasksOptions = [];
  let template = [];
  if (category > 0) {
    serviceCatalogOptions = catalogs.map((cat) => ({
      value: cat.id_catalog_service,
      label: cat.name,
    }));

    if (catalog > 0) {
      try {
        tasksOptions = catalogs
          .filter((c) => [catalog].includes(c.id_catalog_service))[0]
          .task.map((t) => ({ value: t.id_catalog_task, label: t.name }));
      } catch (t) {
        console.log(t);
      }
    }
  }
  let button = {
    label: language.SAVE,
    primary: true,
  };

  let listTemplate = [];
  let listTemplate_ = [];

  if (tasksTemplateforms != undefined) {
    try {
      listTemplate_ = tasksTemplateforms.map((ctr) => ({
        value: ctr.id_template_forms /*fk_id_catalog_task*/,
        label: ctr.name,
      }));
    } catch (ctr) {
      console.log(ctr);
    }
  }
  // let listTemplate_ = tasks
  //   .filter((g) => g.template === true && g.novisible != true)

  //   .map((ctr) => ({ value: ctr.id_catalog_task, label: ctr.name }));
  //     if(tasksTemplateforms  != 'undefined'){
  //      listTemplate_ = tasksTemplateforms.map((ctr) => ({ value: ctr.fk_id_catalog_task, label: ctr.name }));
  // }

  return ticketavaliation == true ? (
    <Dialog
      onClose={() => red()}
      title={language.PENDING_TICKET_LIST}
      maxWidth="lg"
      open={open}
    >
      <Avaliation
        store={props.store}
        action={action}
        APIEndpoints={APIEndpoints}
      ></Avaliation>
    </Dialog>
  ) : (
    <Dialog
      PaperProps={{
        id: 'GSD-new-solicitation',
      }}
      maxWidth="xl"
      open={open}
      onClose={(e) => onClose()}
      contentProps={{
        style: { zIndex: 3 },
      }}
      contentTitle={{
        style: { width: '100%' },
      }}
      titleContentProps={{
        style: {
          width: 'calc(100% - 60px)',
        },
      }}
      title={
        <div className="w-100 bar">
          <CommonForm
            values={values}
            //        alert={false}
            //      individual
            onRef={(ref) => setFormRefs(ref)}
            fields={[
              {
                col: 6,
                type: 'text',
                name: 'subject',
                label: language.SUBJECT,
                required: true,
                className: 'subject-ticket',
              },
              {
                col: 3,
                type: 'select',
                name: 'idtemplate',
                label: language.REQUEST_TEMPLATE,
                className: 'template-solicit',
                startIcon: 'fas fa-file-alt no-shrink',
                visible:
                  [true].includes(general_parameters.ticket_simplified) != true,
                options: [{ value: 0, label: 'Nenhum' }, ...listTemplate_],
              },
              {
                col: 3,
                type: 'element',
                content: (
                  <Button
                    variant="normal"
                    icon={
                      <KnowledgeBase style={{ fill: 'white', width: 20 }} />
                    }
                    label={language.KNOWLEDGE_BASE}
                    color="warning"
                    style={{ heigth: 43, minHeight: 43, marginTop: 9 }}
                    fluid
                    onClick={
                      () =>
                        setOpenKnowledge(true) /*redirect('/dash/solution')*/
                    }
                    disabled={catalog ? false : true}
                  />
                ),
              },
            ]}
            onChangeField={(f, v) => {
              if (f.name == 'idtemplate') {
                loadtemp(v);
                setIdCatalogTask(v);
              }
            }}
          />
        </div>
      }
    >
      <Dialog
        title={language.KNOWLEDGE_BASE}
        open={openKnowledge}
        maxWidth="lg"
        contentProps={{
          style: { marginLeft: -50, marginRight: -20 },
        }}
        onClose={() => setOpenKnowledge(false)}
      >
        <SolutionContainer company={idCompany} subcategory={catalog} />
      </Dialog>

      {openModal == true ? (
        <Dialog
          title={language.ASSOCIATE_TO_TEMPLATE}
          open={true}
          onClose={() => setOpenModal(false)}
        >
          <TextField
            id="outlined-select-currency"
            select
            label={language.TEMPLATE}
            style={{ minWidth: 100, marginRight: '1em' }}
            value={idTemplate || ''}
            onChange={(event, key, values) => {
              //    onChangeTemplate(event.target.value);
              setOpenModal(false);
            }}
            margin="dense"
            variant="outlined"
          >
            {listTemplate}
          </TextField>{' '}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Dialog>
      ) : null}

      <CommonForm
        white
        childFields={[
          {
            name: 'fk_id_category',
            required: ![true].includes(general_parameters.ticket_simplified),
          },
          {
            name: 'fk_id_catalog_service',
            required: ![true].includes(general_parameters.ticket_simplified),
          },
          {
            name: 'fk_id_catalog_task',
            required: ![true].includes(general_parameters.ticket_simplified),
          },
          { name: 'description', required: true },
          { name: 'file' },
        ]}
        fields={[
          {
            col: 12,
            type: 'element',
            name: 'group-field',

            content: (v, handleValue) => (
              <div className="mt-2 wrap">
                <AppBar title={language.MAKE_YOUR_REQUEST_HERE} />
                <div className="content pt-0">
                  <CommonForm
                    values={values}
                    onRef={(ref) => setFormRefs(ref)}
                    fields={[
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.COMPANY}
                            subTitle={
                              language.SELECT_THE_COMPANY_THAT_WILL_RECEIVE_THE_SERVICE
                            }
                          />
                        ),
                        type: 'autocomplete',
                        name: 'fk_id_company',
                        label: language.COMPANY,
                        method: 'POST',
                        route: `${APIEndpoints.COMPANY}/filter`,
                        fieldquery: 'search',
                        textlabel: language.COMPANY,
                        textinit:
                          values && values.name_company
                            ? values.name_company
                            : language.COMPANY,
                        fieldvaluedb: 'id_company',
                        fieldlabeldb: 'name',
                        filterAll: { ismult: general_parameters.mult_catalog },
                        filter: { ismult: general_parameters.mult_catalog },
                        idinit: values.fk_id_company,
                        routeAll: `${APIEndpoints.COMPANY}/all?`,
                        methodGetAll: 'POST',
                        routeGetCount: `${APIEndpoints.COMPANY}/count`,
                        detail: true,
                        visible:
                          [true].includes(general_parameters.mult_catalog) ||
                          [true].includes(general_parameters.ticket_simplified),
                        required: true,
                        /*  col: 4,
                            type: 'select',
                            name: 'fk_id_company',
                            label: 'Empresa',
                            options: companyOptions,
                            visible: values.ticketArray.length > 1 ? false : true,
                            required: true*/
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.SOLICITANT}
                            subTitle={language.SELECT_REQUESTER}
                          />
                        ),
                        type: 'autocomplete',
                        name: 'fk_id_user',
                        label: language.SOLICITANT,
                        textlabel: language.SOLICITANT,
                        method: 'POST',
                        filter: { fk_id_company: idCompany || 0 },
                        route: `${APIEndpoints.USER}/filter`,
                        routeGetCount: `${APIEndpoints.USER}/active/count/${
                          idCompany || 0
                        }`,

                        fieldquery: 'search',
                        textinit:
                          values.fk_id_request > 0
                            ? values.solicitant
                            : language.SOLICITANT,
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: props.store.getUserLoged().id,
                        routeAll: `${APIEndpoints.USER}/filter/all?`,
                        filterAll: { fk_id_company: idCompany || 0 },
                        methodGetAll: 'POST',
                        detail: true,
                        required: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                        visible:
                          ([true].includes(general_parameters.mult_catalog) &&
                            [true].includes(
                              general_parameters.request_solicitant_select
                            )) ||
                          ([true].includes(
                            general_parameters.ticket_simplified
                          ) &&
                            [true].includes(
                              general_parameters.request_solicitant_select
                            )),

                        /*  col: 4,
                            type: 'select',
                            name: 'fk_id_user',
                            label: 'Solicitante',
                            options: solicitantOptions,
                            detail: true,
                            visible: values.ticketArray.length > 1 ? false : true,
                            required: values.ticketArray.length > 1 ? false : true,
                */
                      },

                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.DEPARTMENT}
                            subTitle={language.SELECT_A_DEPARTMENT}
                          />
                        ),
                        name: 'fk_id_department',
                        type: 'select',
                        label: language.DEPARTMENT,
                        options: departmentOptions,
                        required: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                        visible:
                          [true].includes(general_parameters.mult_catalog) &&
                          ![true].includes(
                            general_parameters.ticket_simplified
                          ),
                      },
                      {
                        beforeContent: (
                          <MyHeader
                            title={language.CATEGORY}
                            subTitle={language.SELECT_A_MAIN_CATEGORY}
                          />
                        ),
                        col: 3.5,
                        name: 'fk_id_category',
                        type: 'select',
                        label: language.CATEGORY,
                        options: categoryOptions,
                        visible: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                        required: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                      },
                      {
                        beforeContent: (
                          <MyHeader
                            title={language.SUBCATEGORY}
                            subTitle={language.SELECT_A_SUBCATEGORY}
                          />
                        ),
                        col: 4,
                        name: 'fk_id_catalog_service',
                        type: 'select',
                        label: language.SUBCATEGORY,
                        options: serviceCatalogOptions,
                        visible: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                        required: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                      },
                      {
                        beforeContent: (
                          <MyHeader
                            title={language.THIRD_LEVEL_CATEGORY}
                            subTitle={language.SELECT_A_THIRD_LEVEL_CATEGORY}
                          />
                        ),
                        col: 3.5,
                        name: 'fk_id_catalog_task',
                        type: 'select',
                        label: language.THIRD_LEVEL_CATEGORY,
                        options: tasksOptions,
                        visible: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                        required: ![true].includes(
                          general_parameters.ticket_simplified
                        ),
                      },
                      {
                        beforeContent: (
                          <MyHeader
                            title={language.CHANNEL}
                            subTitle={language.SELECT_A_CHANNEL}
                          />
                        ),
                        col: 3.5,
                        name: 'fk_id_channel',
                        type: 'select',
                        label: language.CHANNEL,
                        options: channel.map((ch) => ({
                          value: ch.id_table,
                          label: ch.description,
                        })),
                      },
                      {
                        beforeContent: <MyHeader title="" subTitle="" />,
                        type: 'element',
                        col: 1,
                        content: (
                          <Button
                            icon="fas fa-trash-alt"
                            variant="circle"
                            color="danger"
                            label={language.ERASE}
                            outlined
                            size={0.7}
                            style={{
                              border: 'none',
                              marginTop: '65px',
                            }}
                            onClick={() => {
                              cleanValues();

                              //setFormRefs([])
                            }}
                          />
                        ),
                      },
                    ]}
                    onChangeField={(f, v) => {
                      if (f.name === 'fk_id_catalog_task') {
                        setIdCatalogTask(v);
                        const template = formstemplate.filter(
                          (c) => c.fk_id_catalog_task === parseInt(v)
                        );
                        let c = 0;

                        let item = template.map((obj) => {
                          c = c + 1;
                          let id = 'ID' + c;
                          return (
                            <div className="contain">
                              <div className="itemTicketDetail">
                                <p style={{ color: '#888080' }} id={id}>
                                  {obj.html.length > 0
                                    ? formshtml(decryp(obj.html))
                                    : ''}
                                </p>
                              </div>
                              <div style={{ marginTop: '80px' }} />
                              <div>
                                {obj.user_up != null
                                  ? `${language.LAST_UPDATE}: ` +
                                    obj.user_up +
                                    '-' +
                                    moment(obj.dt_up).format(
                                      'DD-MM-YY HH:mm:ss'
                                    )
                                  : null}
                              </div>
                              <hr />
                            </div>
                          );
                        });

                        setForms(item);
                      }

                      if (f.name === 'fk_id_company') {
                        setIdCompany(v);
                        categoryCompany(v);

                        let dpt = [];

                        DepartmentList(v);

                        /* companies
                           .filter((c) => c.id_company == v)[0]
                           .department.filter(b=> b.catalog > 0 ).map((obj) => {
                             dpt.push({
                               value: obj.id_department,
                               label: obj.name,
                             });
                             if (obj.sub_dep.length) {
                               obj.sub_dep.map((sd) => {
                                
                                 
 
                               });
                             }*/
                        //});
                      }
                      if (f.name === 'fk_id_department') {
                        setIdDeparment(v);
                        selectcategory(idCompany, v);
                      }

                      handleValue(v, f.name);
                      onChangeField(f, v);
                    }}
                  />
                </div>
              </div>
            ),
          },
          {
            type: 'element',
            content: <hr />,
          },
          {
            type: 'element',
            content: (v, h) => (
              <div className="wrap mb-3">
                <AppBar title={language.BASIC_INFO} />
                <div className="content pt-0">
                  <CommonForm
                    values={values}
                    onRef={(ref) => setFormRefs(ref)}
                    fields={[
                      {
                        beforeContent: (
                          <MyHeader
                            title={language.DESCRIPTION}
                            subTitle={
                              language.LEAVE_A_BRIEF_DESCRIPTION_ABOUT_THIS_CHANGE
                            }
                          />
                        ),
                        col: 8,
                        type: 'textEditor',
                        name: 'description',
                        placeholder: language.DESCRIPTION,
                        required: true,
                      },
                      {
                        beforeContent: (
                          <MyHeader
                            title={language.ATTACH}
                            subTitle={
                              language.DRAG_AND_DROP_AN_IMAGE_FILE_HERE_OR_CLICK
                            }
                          />
                        ),
                        col: 4,
                        type: 'file',
                        name: 'file',
                        label: language.ATTACH,
                        contentProps: {
                          className: 'pt-2',
                        },
                      },
                    ]}
                    onChangeField={(f, v) => {
                      h(v, f.name);
                    }}
                  />
                </div>
              </div>
            ),
          },
        ]}
        values={values}
        _onSubmit={() => {
          //   formRef.submit();
          formRefs.forEach((e) => {
            e.submit();
          });
        }}
        onSubmit={(_values) => (ds == true ? _onFormSubmit('', _values) : '')}
        loading={['post-/dash/gsd', 'post-/dash/request']}
        button={button}
        beforeButtonElement={
          <Button
            icon="fas fa-times"
            color="danger"
            variant="normal"
            label={language.ATTACH}
            onClick={() => onClose()}
            className="mr-3"
          />
        }
      />
      {forms && forms.length > 0 ? (
        <Card className="cardDetail">
          {' '}
          <div id="template" style={{ marginTop: '70px' }} className="template">
            {' '}
            {forms}
          </div>
        </Card>
      ) : null}
    </Dialog>
  );
}
